import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  EditarIcon,
  LixoIcon,
  NovoIcon,
  OkIcon,
  SalvarIcon,
  VoltarIcon
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useCadastros, useToastSaurus } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useGetProdutoById } from 'data/api/gestao/produto/produto/get-produto-by-id';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { ProdutoAtualizarModel } from 'model/api/gestao/produto/produto/produto-atualizar-model';
import { isEqual } from 'utils/is-equal';
import { usePutProduto } from 'data/api/gestao/produto/produto/put-produto';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  FormProdutoPrincipalEdit,
  ProdutoPrincipalEditRefs
} from '../../../../form/produto/form-produto-edit/produto-edit-principal/form-produto-principal-edit';
import classNames from 'classnames';
import { useGetProdutoCategoriaById } from 'data/api/gestao/produto/produto-categoria/get-produto-categoria-by-id';
import { useGetNcmById } from 'data/api/gestao/ncm/get-ncm-by-id';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { guidEmpty } from 'utils/guid-empty';
import { ProdutoCompletoModel } from 'model/api/gestao/produto/produto/produto-completo-model';
import { ProdutoGradeModel } from 'model/api/gestao/produto/produto-grade/produto-grade-model';
import { usePutProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/put-produto-variacao';
import { ProdutoAtualizarVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-atualizar-variacao-model';
import { ProdutoImagemModel } from 'model/api/gestao/produto/produto-imagem/produto-imagem';
import { imagemForUpload } from 'utils/imagem-for-upload';
import { newGuid } from 'utils/new-guid';
import { EnumRetornoApiBase } from 'data/api/base/api-base-response';
import { usePostImagemBase64 } from 'data/api/imagem/post-imagem';
import { usePostProdutoImagem } from 'data/api/gestao/produto/produto-imagem/post-produto-imagem';
import { usePutProdutoImagem } from 'data/api/gestao/produto/produto-imagem/put-produto-imagem';
import { useDeleteProdutoImagem } from 'data/api/gestao/produto/produto-imagem/delete-produto-imagem';
import { isEmpty } from 'lodash';
import { usePutProdutoPreco } from 'data/api/gestao/produto/produto-preco/put-produto-preco';
import { ProdutoPrecoModel } from 'model/api/gestao/produto/produto-preco/produto-preco-model';
import { ProdutoVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-variacao';
import { useGetProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/get-produto-variacao';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
// import {
//   TabSaurusContent,
//   TabSaurusLabel,
//   TabsSaurus,
// } from '../../../../tabs/tabs-saurus';
import { ProdutoResumoEditFormModel } from 'model/app/forms/produto/produto-edit/produto-resumo-edit-form-model';
import { FormProdutoResumoEdit } from '../../../../form/produto/form-produto-edit/produto-edit-resumo/form-produto-resumo-edit';
import { ProdutoPrincipalEditFormModel } from 'model/app/forms/produto/produto-edit/produto-principal-edit-form-model';
// import { ProdutoSubItens } from './components/produto-subitens/produto-subitens';
import { useStyles } from './produto-edit-styles';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { CardPropagandaUrl } from 'views/components/cards/card-propaganda-url/card-propaganda-url';
import { useGetNcms, usePostNcm } from 'data/api/gestao/ncm';
import { NcmMasterSummaryModel } from 'model/api/ncm-master/ncm-master-model';
import { useGetNcmsMaster } from 'data/api/ncm-master/get-ncms-master';
import { ProdutoImpostoModel } from 'model/api/gestao/produto/produto-imposto/produto-imposto-model';
import { useGetProdutoFiscalById } from 'data/api/gestao/produto-fiscal/get-produto-fiscal-by-id';
import { usePutProdutoVincularFiscal } from 'data/api/gestao/produto/produto/put-produto-vincular-fiscal';
import { ProdutoVincularFiscalModel } from 'model/api/gestao/produto/produto-imposto/produto-vincular-fiscal';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { useGetProdutoMedidas } from 'data/api/gestao/produto/produto-medida/get-produto-medidas';
import {
  FormProdutoCodigoRefs,
  FormProdutoCodigosEdit
} from 'views/components/form/produto/form-produto-edit/produto-edit-codigos/form-produto-codigos-edit';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { useGetSetores } from 'data/api/gestao/setores';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import {
  ProdutoSubItens,
  ProdutoSubItensRefs
} from './components/produto-subitens/produto-subitens';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useSessaoAtual } from 'services/app';
import { useLocation } from 'react-router-dom';
import { produtoPageNome } from 'views/pages/private/cadastros/produto/produto-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { ContratoConfigCompletoModel } from 'model/api/gestao/master/contrato-completo-config';
import { useConfirm } from 'material-ui-confirm';
import { useDeleteProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/delete-produto-variacao';
import { PutConfigContratoProps } from 'data/api/gestao/contrato/put-contrato-configuracao';
import { SwitchSaurus } from 'views/components/controles';
import { useThemeQueries } from 'views/theme';
import { isPlanoControleMesasComandas, isPlanoFarmaceutico } from 'utils/plano-utils';
import { MedicamentoModel } from 'model/api/gestao/medicamento/medicamento-model';
import { useGetMedicamentoByCod } from 'data/api/gestao/medicamentos/get-medicamento-by-cod';
import { TpProdutoFarmaciaMock, TpProdutoMock } from 'data/mocks/tp-produto-mock';
import { RadioList } from 'views/components/controles/radio-list/radio-list';
import { FormMedicamentoEditPrincipal, MedicamentoEditFormModel } from 'views/components/form/produto/form-produto-edit/medicameto-edit-principal/medicamento-edit-principal';
import { usePutMedicamento } from 'data/api/gestao/medicamentos/put-medicamento';
import { MedicamentoPrecoModel } from 'model/api/gestao/medicamento/medicamento-preco-model';
import { useGetMedicamentoPrecoByMedId } from 'data/api/gestao/medicamento-preco/get-medicamento-by-med-id';
import { useGetMarcaById } from 'data/api/gestao/marca/get-marca-by-id';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { EnumFormCodigo, FormProdutoCodigoPreCadastro } from 'views/components/form/produto/produto-pre-cadastro/form-produto-codigo/form-produto-codigo-pre-cadastro';
import { ProdutoCodigoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-codigo-form-model';
import { useGetMedicamentos } from 'data/api/gestao/medicamentos/get-medicamentos';
import { ProdutoMedicamentoPrecoFormModel } from 'model/app/forms/produto/medicamento/medicamento-preco-form-model';

export interface FormComponentCodigoModel {
  title: string;
  model: ProdutoCodigoModel;
}

export const ProdutoEdit = (props: {
  id: string;
  empresaId: string;
  contratoId: string;
  tipo: EnumTipoProduto;
}) => {

  //hooks
  const modalClasses = useModalStyles();
  const confirm = useConfirm();
  const { fecharCadastroProduto, abrirCadastroProduto } = useCadastros();
  const { getProdutoById, carregando: carregandoGetProduto } =
    useGetProdutoById();
  const { putProduto, carregando: carregandoPutProduto } = usePutProduto();
  const { getProdutoCategoriaById, carregando: carregandoGetCategoria } =
    useGetProdutoCategoriaById();
  const { getSetores, carregando: carregandoSetores } = useGetSetores();
  const { getNcmById, carregando: carregandoGetNcm } = useGetNcmById();
  const { putProdutoVariacao, carregando: carregandoPutVariacao } =
    usePutProdutoVariacao();
  const { getProdutoVariacao, carregando: carregandoGetProdutoVariacao } =
    useGetProdutoVariacao();
  const { postImagemBase64, carregando: carregandoPostImagemBase64 } =
    usePostImagemBase64();
  const { postProdutoImagem, carregando: carregandoPostProdutoImagem } =
    usePostProdutoImagem();
  const { putProdutoImagem, carregando: carregandoPutProdutoImagem } =
    usePutProdutoImagem();
  const { deleteProdutoImagem, carregando: carregandoDeleteProdutoImagem } =
    useDeleteProdutoImagem();
  const { deleteProdutoVariacao, carregando: carregandoDelete } =
    useDeleteProdutoVariacao();
  const { putProdutoPreco, carregando: carregandoPutProdutoPreco } =
    usePutProdutoPreco();
  const { putVincularFiscal, carregando: carregandoProdutoVincular } =
    usePutProdutoVincularFiscal();
  const { getNcms, carregando: carregandoNCMS } = useGetNcms();
  const { postNcm, carregando: carregandoPost } = usePostNcm();
  const { getProdutoFiscalById, carregando: carregandoProdutoFiscal } = useGetProdutoFiscalById();
  const { getNcmsMaster, carregando: carregandoNCMMaster } = useGetNcmsMaster();
  const { showToast } = useToastSaurus();
  const { showAviso } = useShowAviso();
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const { theme } = useThemeQueries();
  const { getProdutoMedidas, carregando: carregandoMedidas } = useGetProdutoMedidas();
  const { getMedicamentoByCod, carregando: carregandoMedicamentoByCod } = useGetMedicamentoByCod();
  const { putMedicamento, carregando: carregandoPutMedicamento } = usePutMedicamento();
  const { getMedicamentoPrecoByMedId, carregando: carregandoMedPreco } = useGetMedicamentoPrecoByMedId();
  const { getMarcaById, carregando: carregandoGetById } = useGetMarcaById();
  const { getMedicamentos, carregando: carregandoGetMedicamentos } = useGetMedicamentos();
  const { carregando: carregandoEmpresaAtual, getEmpresaAtual } = useEmpresaAtual();
  const {
    getConfigCompleto,
    putContratoConfig,
    carregando: carregandoContrato
  } = useContratoAtual();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const location = useLocation();
  const classes = useStyles();

  //States
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [showOptions, setShowOptions] = useState(true);
  const [formInSubmit, setFormInSubmit] = useState(false);
  const [contratoCompleto, setContratoCompleto] =
    useState<ContratoConfigCompletoModel>(new ContratoConfigCompletoModel());
  const [produtoFormPrincipalState, setProdutoFormPrincipalState] =
    useState<ProdutoPrincipalEditFormModel>(
      new ProdutoPrincipalEditFormModel()
    );
  const [produtoFormResumoState, setProdutoFormResumoState] =
    useState<ProdutoResumoEditFormModel>(new ProdutoResumoEditFormModel());
  const [abaSelecionada, setAbaSelecionada] = useState<number>(0);
  const [produtoCompleto, setProdutoCompleto] = useState<ProdutoCompletoModel>(
    new ProdutoCompletoModel()
  );
  const [changeTipo, setChangeTipo] = useState<boolean>(false);
  const [irParaTelaDeCodigoAnvisa, setIrParaTelaDeCodigoAnvisa] = useState<boolean>(false);

  //refs
  const refEditPrincipalForm = useRef<ProdutoPrincipalEditRefs>(null);
  const refEditResumoForm = useRef<DefaultFormRefs<ProdutoResumoEditFormModel>>(null);
  const codigoProdutoRefs = useRef<FormProdutoCodigoRefs>(null);
  const refEditMedicamentoForm = useRef<DefaultFormRefs<MedicamentoModel>>(null);
  const refSubitens = useRef<ProdutoSubItensRefs>(null);
  const tituloRef = useRef<HTMLHeadingElement>(null);
  const medicamentoRef = useRef<MedicamentoModel | null>(null);
  const medicamentoPrecoRef = useRef<MedicamentoPrecoModel | null>(null);
  const refPreCadastroCodigo = useRef<DefaultFormRefs<ProdutoCodigoPreCadastroFormModel>>(null)
  const refProdutoModel = useRef<ProdutoAtualizarModel>(
    new ProdutoAtualizarModel()
  );
  const refProdutoVariacaoPadrao = useRef<ProdutoVariacaoModel>(
    new ProdutoVariacaoModel()
  );
  const refProdutoImagemPadrao = useRef<ProdutoImagemModel>(
    new ProdutoImagemModel()
  );
  const refProdutoPrecoPadrao = useRef<ProdutoPrecoModel>(
    new ProdutoPrecoModel()
  );
  const refProdutoCodigoPadrao = useRef<ProdutoCodigoModel>(
    new ProdutoCodigoModel()
  );
  const refProdutoCodigos = useRef<Array<ProdutoCodigoModel>>(
    new Array<ProdutoCodigoModel>()
  );

  const refProdutoimposto = useRef<ProdutoImpostoModel>(
    new ProdutoImpostoModel()
  );
  const refMedicamentoPrecoModelForm = useRef<ProdutoMedicamentoPrecoFormModel>(
    new ProdutoMedicamentoPrecoFormModel()
  );
  const refMedicamentoModelForm = useRef<MedicamentoModel>(
    new MedicamentoModel()
  );
  const refCodigoModelForm = useRef<ProdutoCodigoPreCadastroFormModel>(
    new ProdutoCodigoPreCadastroFormModel()
  );
  const refBuscouMedicamento = useRef<boolean>(false);
  const refcodAtualizado = useRef<boolean>(false);
  const buscouProduto = useRef<boolean>(false);
  const medicamentoEncontrado = useRef<boolean>(false);
  const precoEncontrado = useRef<boolean>(false);
  const refProdutoSaldo = useRef<number>(0);
  const refNCMId = useRef<string>('');

  const isMedicamento = produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento;

  const carregando =
    carregandoGetProduto ||
    preenchendoTela ||
    carregandoPutProduto ||
    carregandoGetCategoria ||
    carregandoGetNcm ||
    formInSubmit ||
    carregandoPutVariacao ||
    carregandoGetProdutoVariacao ||
    carregandoPostImagemBase64 ||
    carregandoPostProdutoImagem ||
    carregandoPutProdutoImagem ||
    carregandoDeleteProdutoImagem ||
    carregandoPutProdutoPreco ||
    carregandoMedidas ||
    carregandoNCMS ||
    carregandoPost ||
    carregandoNCMMaster ||
    carregandoProdutoVincular ||
    carregandoProdutoFiscal ||
    carregandoSetores ||
    carregandoEmpresaAtual ||
    carregandoContrato ||
    carregandoDelete ||
    carregandoMedicamentoByCod ||
    carregandoPutMedicamento ||
    carregandoMedPreco ||
    carregandoGetById ||
    carregandoGetMedicamentos;

  const atualizarContrato = useCallback((obj: any) => {
    setContratoCompleto(obj);
  }, []);

  useEffect(() => {
    atualizarContrato(getConfigCompleto());
    addHandler(AppEventEnum.ContratoAtualizado, atualizarContrato);
    return () =>
      removeHandler(AppEventEnum.ContratoAtualizado, setContratoCompleto);
  }, [
    addHandler,
    atualizarContrato,
    getConfigCompleto,
    getEmpresaAtual,
    removeHandler
  ]);

  const produtoServico =
    contratoCompleto &&
    contratoCompleto.configuracoes.find(
      (x) => x.cod === EnumContratoConfig.ProdutoServico
    );
  const produtoGenerico =
    contratoCompleto &&
    contratoCompleto.configuracoes.find(
      (x) => x.cod === EnumContratoConfig.ProdutoGenerico
    );
  const produtoEntrega = contratoCompleto.configuracoes.find(
    (x) => x.cod === EnumContratoConfig.ProdutoEntrega
  );

  const recarregarFormMedicamento = useCallback((model: MedicamentoEditFormModel) => {
    refEditMedicamentoForm.current?.fillForm(model);
  }, []);

  const recarregarFormPrincipal = useCallback(
    (model: ProdutoPrincipalEditFormModel) => {
      refEditPrincipalForm.current?.fillForm(model);
    },
    []
  );
  const recarregarFormResumo = useCallback(
    (model: ProdutoResumoEditFormModel) => {
      refEditResumoForm.current?.fillForm(model);
    },
    []
  );

  useEffect(() => {
    recarregarFormResumo(produtoFormResumoState);
  }, [produtoFormResumoState, recarregarFormResumo]);

  const getCategoriaByIdWrapper = useCallback(
    async (id: string) => {
      let categoria = new ProdutoCategoriaModel();

      if (id === guidEmpty()) return categoria;

      try {
        const res = await getProdutoCategoriaById(id);
        if (res.erro) {
          throw res.erro;
        }

        categoria = picker<ProdutoCategoriaModel>(
          res.resultado?.data,
          categoria
        );
      } catch (e: any) {
        showToast(
          'error',
          `Não conseguimos carregar a categoria do ${produtoPageNome(
            produtoFormPrincipalState.tipo,
            false
          ).toLowerCase()}. Detalhe: ${e.message}`
        );
      }
      return categoria;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getProdutoCategoriaById, showToast]
  );

  const putConfigContratoWrapper = useCallback(
    async (cod: number, value: string) => {
      try {
        const getConfig = getConfigCompleto()?.configuracoes.find(
          (x) => x.cod === cod
        );

        if (isEmpty(getConfig)) {
          throw new Error(
            'Não foi possível atualizar... Tente novamente mais tarde.'
          );
        }

        getConfig.vConfig = value;

        await putContratoConfig([
          new PutConfigContratoProps(null, getConfig.cod, getConfig.vConfig)
        ]);

        showToast(
          'success',
          `${produtoPageNome(
            produtoFormPrincipalState.tipo,
            false
          )} atualizado!`
        );
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEmpresaAtual, getEmpresaSelecionada, putContratoConfig, showToast]
  );

  const getSetorByIdWrapper = useCallback(
    async (id: string | null) => {
      let setor = new SetoresModel();

      if (id === null) {
        return {
          id: null,
          descricao: 'Sem Setor',
          uriImage: ''
        };
      }

      try {
        const ret = await getSetores(
          getEmpresaSelecionada()?.Id ?? '',
          `?pageSize=0`
        );

        if (ret.erro) throw ret.erro;

        const setorFilter = (ret.resultado?.data.list as SetoresModel[]).find(
          (setor) => setor.id === id
        );

        if (isEmpty(setorFilter)) {

          return;
        }

        setor = picker<SetoresModel>(setorFilter, setorFilter);
      } catch (e: any) {

      }
      return setor;
    },
    [getEmpresaSelecionada, getSetores]
  );

  const getNcmByIdWrapper = useCallback(
    async (id: string) => {
      let ncm = new NcmModel();
      try {
        const res = await getNcmById(id);
        if (res.erro) {
          throw res.erro;
        }

        ncm = picker<NcmModel>(res.resultado?.data, ncm);
      } catch (e: any) {
        showToast(
          'error',
          `Não conseguimos carregar o NCM do ${produtoPageNome(
            produtoFormPrincipalState.tipo,
            false
          ).toLowerCase()}. Detalhe: ${e.message}`
        );
      }

      return ncm;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNcmById, showToast]
  );

  const getMarcaByIdWrapper = useCallback(
    async (id: string) => {
      let marca = new MarcaModel();
      try {
        const res = await getMarcaById(id);
        if (res.erro) {
          throw res.erro;
        }

        marca = picker<MarcaModel>(res.resultado?.data, marca);
      } catch (e: any) {
        showToast(
          'error',
          `Não conseguimos carregar a Marca do ${produtoPageNome(
            produtoFormPrincipalState.tipo,
            false
          ).toLowerCase()}. Detalhe: ${e.message}`
        );
      }

      return marca;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMarcaById, showToast]
  );

  const getImpostoByIdWrapper = useCallback(
    async (grupoImpostoId: string): Promise<string> => {
      const res = await getProdutoFiscalById(grupoImpostoId);

      if (res.erro) throw res.erro;
      return res.resultado?.data.descricao;
    },
    [getProdutoFiscalById]
  );

  const getProdutoVariacaoWrapper = useCallback(
    async (produtoGrade: ProdutoGradeModel) => {
      const res = await getProdutoVariacao(props.id, produtoGrade.id);
      if (res.erro) {
        throw res.erro;
      }
      const variacao = res.resultado?.data as ProdutoVariacaoModel;

      refProdutoCodigos.current = variacao.codigos;

      const imagemPadrao = variacao.imagens.find((x) => x.padrao === true);
      if (imagemPadrao) refProdutoImagemPadrao.current = imagemPadrao;

      const precoPadrao = variacao.precos.find((x) => true);
      if (precoPadrao) refProdutoPrecoPadrao.current = precoPadrao;

      const codigoPadrao = variacao.codigos.find((x) => true);
      if (codigoPadrao) refProdutoCodigoPadrao.current = codigoPadrao;

      const imposto = variacao.impostos.find((x) => true);
      if (imposto) refProdutoimposto.current = imposto;

      const saldo = variacao.qSaldoTotal;
      if (saldo !== null && saldo !== undefined)
        refProdutoSaldo.current = saldo;

      if (produtoGrade.codigoAnvisa) {
        variacao.codigoAnvisa = produtoGrade.codigoAnvisa
      }

      refProdutoVariacaoPadrao.current = variacao;
      // setVariacaoId(variacao.id);
    },
    [getProdutoVariacao, props.id]
  );

  const getMedidasWrapper = useCallback(async () => {
    const empresaId = getEmpresaSelecionada()?.Id || '';
    const ret = await getProdutoMedidas('', empresaId, 1, 100);
    if (ret.erro) throw ret.erro;
    let medidas: Array<ProdutoMedidaModel> = new Array<ProdutoMedidaModel>();
    if (ret.resultado?.data?.list?.length > 0) {
      medidas = ret.resultado?.data?.list as Array<ProdutoMedidaModel>;
    }
    return medidas;
  }, [getEmpresaSelecionada, getProdutoMedidas]);

  const getProdutoByIdWrapper = useCallback(async () => {
    const res = await getProdutoById(props.empresaId, props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as ProdutoCompletoModel;

    setProdutoCompleto(ret);

    refProdutoModel.current = ret;

    let produto = picker<ProdutoPrincipalEditFormModel>(
      ret,
      new ProdutoPrincipalEditFormModel()
    );

    await getProdutoVariacaoWrapper(ret.grades[0]);
    produto = picker<ProdutoPrincipalEditFormModel>(ret.grades[0], produto);
    try {
      produto.imagemUrl = atob(refProdutoImagemPadrao.current.imagemUrl);
    } catch {
      produto.imagemUrl = refProdutoImagemPadrao.current.imagemUrl;
    }
    produto.qSaldoTotal = refProdutoSaldo.current;
    //busco a categoria
    const categoria = await getCategoriaByIdWrapper(produto.categoriaId || '');
    produto.nomeCategoria = categoria.nome;

    const setor = await getSetorByIdWrapper(produto.setorId || null);
    produto.nomeSetor = setor?.descricao ?? '';

    const medidas = await getMedidasWrapper();

    const medidaEntrada = medidas.find((x) => x.id === produto.medidaEntradaId);
    if (medidaEntrada) produto.medidaEntrada = medidaEntrada.sigla;

    const medidaSaida = medidas.find((x) => x.id === produto.medidaSaidaId);
    if (medidaSaida) produto.medidaSaida = medidaSaida.sigla;

    //e se tiver ncm eu busco
    if (
      produto.ncmId &&
      produto.ncmId !== null &&
      produto.ncmId !== guidEmpty()
    ) {
      const ncm = await getNcmByIdWrapper(produto.ncmId);
      produto.codigoNcm = ncm.codigo;
    }

    //e se tiver ncm eu busco
    if (
      produto.marcaId &&
      produto.marcaId !== null &&
      produto.marcaId !== guidEmpty()
    ) {
      const marca = await getMarcaByIdWrapper(produto.marcaId);
      produto.marca = marca.nome;
    }

    produto.pLucro = toDecimalString(
      (toDecimal(produto.vPreco) / toDecimal(produto.vCompra)) * 100 - 100,
      2
    );

    //se tiver imposto eu busco
    if (refProdutoimposto.current.grupoImpostoId) {
      //buscando a descrição do imposto caso o produto tenha um grupoImpostoId
      const descricao = await getImpostoByIdWrapper(
        refProdutoimposto.current.grupoImpostoId
      );

      produto.imposto = descricao;
      produto.impostoId = refProdutoimposto.current.grupoImpostoId ?? '';
    }

    return produto;
  }, [
    getCategoriaByIdWrapper,
    getImpostoByIdWrapper,
    getMedidasWrapper,
    getNcmByIdWrapper,
    getProdutoById,
    getProdutoVariacaoWrapper,
    getSetorByIdWrapper,
    props.empresaId,
    props.id,
    getMarcaByIdWrapper
  ]);

  const getMedicamentoPrecoWrapper = useCallback(
    async (medicamentoId: string) => {
      try {
        const res = await getMedicamentoPrecoByMedId(medicamentoId);
        if (res.erro) throw res.erro

        if (isEmpty(res.resultado?.data.precos)) return

        const preco = res.resultado?.data.precos[0] as MedicamentoPrecoModel;

        medicamentoPrecoRef.current = preco;

      } catch (e: any) {
        showToast('error', e.message)
      }
    }, [getMedicamentoPrecoByMedId, showToast]
  )

  const getMedicamentoWrapper = useCallback(
    async (cod: string) => {
      try {
        const res = await getMedicamentoByCod(cod);

        if (res.erro) throw res.erro;

        if (res.resultado?.data.list.length < 1) {
          throw new Error('Não foi encontrado um medicamento com código ANVISA cadastrado no produto.')
        }

        const ret = res.resultado?.data.list[0] as MedicamentoModel;

        medicamentoRef.current = ret;

        await getMedicamentoPrecoWrapper(ret.id);
      } catch (e: any) {
        showAviso('error', e.message, 5000, true, true);
      }
    },
    [getMedicamentoByCod, getMedicamentoPrecoWrapper, showAviso]
  );

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true);
      const produtoPrincipal = await getProdutoByIdWrapper();

      //CASO SEJA MEDICAMENTO, PEGAR INFOS DO MEDICAMENTO

      if (
        !refBuscouMedicamento.current &&
        produtoPrincipal.tipo === EnumTipoProduto.Medicamento &&
        produtoPrincipal.codigoAnvisa
      ) {
        await getMedicamentoWrapper(produtoPrincipal.codigoAnvisa);
        refBuscouMedicamento.current = true;
      }
      const produtoResumido = picker<ProdutoResumoEditFormModel>(
        produtoPrincipal,
        new ProdutoResumoEditFormModel()
      );
      if (medicamentoPrecoRef.current) {
        produtoPrincipal.precoFabricacao = medicamentoPrecoRef.current.precoFabricacao
        produtoPrincipal.precoMaximoConsumidor = medicamentoPrecoRef.current.precoMaximoConsumidor

        if (medicamentoRef.current) {
        }
      }
      setProdutoFormPrincipalState(produtoPrincipal);
      setProdutoFormResumoState(produtoResumido);

      if (
        produtoPrincipal.tipo === EnumTipoProduto.Medicamento &&
        medicamentoRef.current
      ) {
        recarregarFormMedicamento(new MedicamentoEditFormModel(medicamentoRef.current, medicamentoPrecoRef.current?.precoFabricacao, medicamentoPrecoRef.current?.precoMaximoConsumidor));
      }
      recarregarFormPrincipal(produtoPrincipal);

    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setPreenchendoTela(false);
    }
  }, [
    getMedicamentoWrapper,
    getProdutoByIdWrapper,
    recarregarFormMedicamento,
    recarregarFormPrincipal,
    showToast
  ]);

  const buscarMedicamento = async (
    codigo: string,
    modelo: ProdutoCodigoPreCadastroFormModel,
  ) => {
    refCodigoModelForm.current = { ...refCodigoModelForm.current, codigo };
    if (codigo.length > 0) {
      setPreenchendoTela(true)
      //SETO ESSE BUSCOU PRODUTO PARA QUE A PESSOA INFORME O CÓDIGO DA ANVISA CASO NÃO IDENTIFIQUE

      const res = await getMedicamentos(`codigoAnvisa=${codigo}`);
      if (res.erro) throw res.erro
      const list = res.resultado?.data.list as MedicamentoModel[]

      if (list && list.length > 0) {
        const ret = picker<MedicamentoModel>(list[0], new MedicamentoModel());

        medicamentoEncontrado.current = true;

        const retPreco = await getMedicamentoPrecoByMedId(ret.id);

        if (
          retPreco.erro ||
          !retPreco.resultado?.data ||
          retPreco.resultado.data.precos.length === 0
        ) {

          precoEncontrado.current = false;
          refMedicamentoPrecoModelForm.current =
            new ProdutoMedicamentoPrecoFormModel();
          throw retPreco.erro
        } else {
          precoEncontrado.current = true;
          const preco = retPreco.resultado.data
            .precos[0] as MedicamentoPrecoModel;

          refMedicamentoPrecoModelForm.current = {
            ...refMedicamentoPrecoModelForm.current,
            dataFinal: preco.dataFinal,
            dataInicial: preco.dataInicial,
            precoFabricacao: preco.precoFabricacao,
            precoMaximoConsumidor: preco.precoMaximoConsumidor
          };
        }

        refMedicamentoModelForm.current = { ...ret };
        modelo.codigo = ret.codigoAnvisa;
        return;
      }

      medicamentoEncontrado.current = false;
      precoEncontrado.current = false;
      setPreenchendoTela(false)
      throw new Error();

    }
  };

  const handleSubmitFormPreCadastroCodigo = async (
    modelo: ProdutoCodigoPreCadastroFormModel
  ) => {
    try {
      ;
      if (!buscouProduto.current) {
        await buscarMedicamento(modelo.codigo, modelo);
      }

      await handleSubmit(
        {
          ...produtoFormPrincipalState, tipo: EnumTipoProduto.Medicamento,
          codigoAnvisa: refMedicamentoModelForm.current.codigoAnvisa,

        },

        produtoFormPrincipalState
      );
      setIrParaTelaDeCodigoAnvisa(false)
      setChangeTipo(false)
      buscouProduto.current = false;
      refCodigoModelForm.current = modelo;
      await preencherTela()

    } catch (e: any) {
      showToast('error', 'Medicamento não encontrado. Detalhes: ' + e.message)
      refPreCadastroCodigo.current?.fillForm(
        new ProdutoCodigoPreCadastroFormModel()
      );
      return;
    }
  }

  useEffect(() => {
    (async () => {
      await preencherTela();
    })();
    return () => {
      //funcao de limpeza
      setProdutoFormPrincipalState(new ProdutoPrincipalEditFormModel());
    };
  }, [showToast, getProdutoByIdWrapper, preencherTela]);

  const onCloseClick = useCallback(() => {
    const isFormOpen = codigoProdutoRefs.current?.isOpenned();
    const isEstoqueOpen = refEditPrincipalForm.current?.isAberto();
    const isSubitensOpen = refSubitens.current?.isOpenned();

    if (isFormOpen) {
      codigoProdutoRefs.current?.goBack();
      return;
    }
    if (isEstoqueOpen) {
      refEditPrincipalForm.current?.fecharEstoque();
      return;
    }
    if (isSubitensOpen) {
      refSubitens.current?.goBack();
      return;
    }
    if (irParaTelaDeCodigoAnvisa) {
      setIrParaTelaDeCodigoAnvisa(false);
      callEvent(AppEventEnum.AttTituloEdicaoProduto, { titulo: '' })
      return;
    }
    if (changeTipo) {
      setChangeTipo(false);
      return;
    }
    const url = location.pathname.split('/')[1];
    fecharCadastroProduto('/' + url);
  }, [callEvent, changeTipo, irParaTelaDeCodigoAnvisa, fecharCadastroProduto, location.pathname]);

  const searchNcm = useCallback(
    async (modelo: ProdutoAtualizarModel) => {
      try {
        //primeiro vou buscar na minha base
        const retNcmGestao = await getNcms(
          `Codigo=${modelo.codigoNcm}&TemFiltro=true`
        );
        if (retNcmGestao.erro) throw retNcmGestao.erro;
        if (retNcmGestao.resultado?.data.list.length > 0) {
          const ncm = retNcmGestao.resultado?.data?.list[0] as NcmModel;
          modelo.codigoNcm = ncm.codigo;
          modelo.ncmId = ncm.id;
          refNCMId.current = ncm.id;
        }
      } catch (e: any) { }
      //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado

      if (modelo.ncmId === guidEmpty()) {
        //entao busco na api de ncms master
        const retNcmGestao = await getNcmsMaster(modelo.codigoNcm);
        if (retNcmGestao.erro) throw retNcmGestao.erro;
        if (retNcmGestao.resultado?.data.list.length > 0) {
          const ncm = retNcmGestao.resultado?.data
            ?.list[0] as NcmMasterSummaryModel;
          const ncmModel = new NcmModel(
            guidEmpty(),
            props.contratoId,
            ncm.id,
            ncm.codigo,
            ncm.descricao,
            ncm.cest
          );
          //cadastro em nossa base
          const ret = await postNcm(ncmModel);

          if (ret.erro) {
            throw ret.erro;
          }
          const novoNcm = ret.resultado?.data as NcmModel;
          modelo.codigoNcm = novoNcm.codigo;
          modelo.ncmId = novoNcm.id;
          refNCMId.current = novoNcm.id;
        }
      }
    },
    [getNcms, getNcmsMaster, postNcm, props.contratoId]
  );

  const saveChangesProduto = useCallback(
    async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
      const produto = picker<ProdutoAtualizarModel>(
        produtoModelForm,
        refProdutoModel.current
      );
      produto.codigoNcm = produtoModelForm.codigoNcm;
      produto.setorId = produtoModelForm.setorId;
      produto.categoriaId = produtoModelForm.categoriaId;

      try {
        if (produtoModelForm.codigoNcm) {
          await searchNcm(produto);
        }
        const ret = await putProduto(produto, props.empresaId);
        if (ret.erro) {
          throw ret.erro;
        }

        const labelToast = produtoPageNome(
          produtoModelForm.tipo,
          false
        ).toLowerCase()

        showToast(
          'success',
          `${labelToast.charAt(0).toUpperCase() + labelToast.slice(1)} atualizado com Sucesso!`
        );
      } catch (e: any) {
        showToast('error', e.message);
        refEditPrincipalForm.current?.resetForm();
      }
    },
    [props.empresaId, putProduto, searchNcm, showToast]
  );
  const saveChangesProdutoVariacao = useCallback(
    async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
      /*
      estou fazendo isso senão ele mandava uns dados que nao precisava,
      aí dou um cast na referencia para produto ProdutoAtualizarVariacaoModel primeiro
    */
      let prodRef = picker<ProdutoAtualizarVariacaoModel>(
        refProdutoVariacaoPadrao.current,
        new ProdutoAtualizarVariacaoModel()
      );
      prodRef.tabelaPrecoId = refProdutoPrecoPadrao.current.tabelaPrecoId;
      const produto = picker<ProdutoAtualizarVariacaoModel>(
        produtoModelForm,
        prodRef
      );
      if (!produto.codigo) {
        produto.codigo = null;
      }
      try {
        const ret = await putProdutoVariacao(produto.produtoId, produto);

        if (ret.erro) {
          throw ret.erro;
        }

        setProdutoFormPrincipalState(produtoModelForm);
        showToast(
          'success',
          `${produtoPageNome(
            produtoModelForm.tipo,
            false
          )} atualizado com Sucesso!`
        );
      } catch (e: any) {
        showToast('error', e.message);
        refEditPrincipalForm.current?.resetForm();
      }
    },
    [putProdutoVariacao, showToast]
  );

  const sendImage = useCallback(
    async (imagemUrl: string) => {
      let imagem = '';

      const imgUpload = imagemForUpload(imagemUrl);
      if (imgUpload.length > 0) {
        const retImagem = await postImagemBase64(
          imgUpload,
          `${produtoPageNome(produtoFormPrincipalState.tipo).toLowerCase()}/${props.contratoId
          }/`,
          newGuid()
        );
        if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
          throw new Error('Erro ao processar  a Imagem selecionada.');
        }
        if (retImagem.resultado?.data.data.status === 2) {
          throw new Error(
            'Erro ao processar a Imagem selecionada.Detalhe: ' +
            retImagem.resultado?.data?.data?.retorno
          );
        }
        imagem =
          retImagem.resultado?.data?.data?.url_blob +
          '?timestamp=' +
          new Date().getTime();
      }

      return imagem.length > 0 ? btoa(imagem) : imagem;
    },
    [postImagemBase64, produtoFormPrincipalState.tipo, props.contratoId]
  );

  const saveChangesProdutoImagem = useCallback(
    async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
      const produtoImagem = picker<ProdutoImagemModel>(
        produtoModelForm,
        refProdutoImagemPadrao.current
      );

      let prodRef = picker<ProdutoAtualizarVariacaoModel>(
        refProdutoVariacaoPadrao.current,
        new ProdutoAtualizarVariacaoModel()
      );

      produtoImagem.produtoGradeId = prodRef.id;
      produtoImagem.produtoId = prodRef.produtoId;

      try {
        if (
          refProdutoImagemPadrao.current.id === guidEmpty() &&
          isEmpty(produtoImagem.imagemUrl)
        ) {
          return;
        }

        produtoImagem.imagemUrl = await sendImage(produtoImagem.imagemUrl);

        const ret =
          produtoImagem.id !== guidEmpty()
            ? produtoImagem.imagemUrl.length > 0
              ? await putProdutoImagem(
                props.id,
                refProdutoVariacaoPadrao.current.id,
                produtoImagem
              )
              : await deleteProdutoImagem(
                props.id,
                refProdutoVariacaoPadrao.current.id,
                produtoImagem.id
              )
            : await postProdutoImagem(
              props.id,
              refProdutoVariacaoPadrao.current.id,
              produtoImagem
            );

        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Imagem atualizada com sucesso!');
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível alterar a imagem do produto. Detalhe: ${e.message}`
        );
        refEditPrincipalForm.current?.resetForm();
      }
    },
    [
      deleteProdutoImagem,
      postProdutoImagem,
      props.id,
      putProdutoImagem,
      sendImage,
      showToast
    ]
  );
  const saveChangesProdutoPreco = useCallback(
    async (model: ProdutoPrincipalEditFormModel) => {
      try {
        const produtoPreco = picker<ProdutoPrecoModel>(
          model,
          refProdutoPrecoPadrao.current
        );
        const ret = await putProdutoPreco(
          props.id,
          refProdutoVariacaoPadrao.current.id,
          produtoPreco
        );

        if (ret.erro) {
          throw ret.erro;
        }

        showToast(
          'success',
          `Preço do ${produtoPageNome(
            props.tipo,
            false
          ).toLowerCase()} atualizado com sucesso!`
        );
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível alterar o preço do produto. Detalhe: ${e.message}`
        );
        refEditPrincipalForm.current?.resetForm();
      }
    },
    [props.id, props.tipo, putProdutoPreco, showToast]
  );

  const alterarProdutoFiscal = useCallback(
    async (
      informacaoFiscal: ProdutoVincularFiscalModel,
      empresaId: string,
      produtoId: string
    ): Promise<void> => {
      setPreenchendoTela(true)
      const res = await putVincularFiscal(
        informacaoFiscal,
        empresaId,
        produtoId
      );

      if (res.erro) throw res.erro;
    },
    [putVincularFiscal]
  );

  const submitMedicamento = async (model: MedicamentoModel) => {
    try {
      if (isEqual(model, medicamentoRef.current)) {
        return;
      } setPreenchendoTela(true)
      const res = await putMedicamento(model);

      if (res.erro) throw res.erro;

    } catch (e: any) {
      showToast(
        'error',
        `Não foi possível atualizar o Medicamento. Detalhe: ${e.message}`
      );
    }
  };

  const handleSubmit = useCallback(
    async (
      model: ProdutoPrincipalEditFormModel,
      beforeModel: ProdutoPrincipalEditFormModel
    ) => {
      try {
        model = picker<ProdutoPrincipalEditFormModel>(
          produtoFormResumoState,
          model
        );
        //LEMBRAR ARRUMAR ESSA COISA
        const produtoEqual = isEqual(
          picker<ProdutoAtualizarModel>(model, new ProdutoAtualizarModel()),
          picker<ProdutoAtualizarModel>(
            beforeModel,
            new ProdutoAtualizarModel()
          )
        );
        if (
          (produtoServico?.vConfig === props.id ||
            produtoGenerico?.vConfig === props.id) &&
          [EnumTipoProduto.Insumo, EnumTipoProduto.Combo].includes(model.tipo)
        ) {
          showToast(
            'error',
            'Produtos do tipo insumo ou combo não podem ser utilizados como produto genérico e nem produto de serviço (Taxa).'
          );
          return;
        }
        if (!produtoEqual) {
          await saveChangesProduto({
            ...model,
            categoriaId:
              model.categoriaId === guidEmpty() ? null : model.categoriaId
          });
        }

        const produtoGradeEqual = isEqual(
          picker<ProdutoAtualizarVariacaoModel>(
            model,
            new ProdutoAtualizarVariacaoModel()
          ),
          picker<ProdutoAtualizarVariacaoModel>(
            beforeModel,
            new ProdutoAtualizarVariacaoModel()
          )
        );

        if (!produtoGradeEqual) {
          await saveChangesProdutoVariacao(model);
          refcodAtualizado.current = true;
        }

        if (
          model.codigo !== beforeModel.codigo &&
          refcodAtualizado.current === false
        ) {
          await saveChangesProdutoVariacao(model);
        }

        const ncmVazio = refNCMId.current.length < 1 || isEmpty(model.ncmId)
        const produtoImposto =
          model.imposto === beforeModel.imposto &&
          model.impostoId === beforeModel.impostoId

        if (!produtoImposto && !ncmVazio) {
          const attprodutoFiscal = new ProdutoVincularFiscalModel();
          attprodutoFiscal.ncmId = refNCMId.current ?? model.ncmId ?? null;
          attprodutoFiscal.grupoImpostoId = model.impostoId ?? '';
          attprodutoFiscal.produtoGradeId = refProdutoVariacaoPadrao.current.id;
          await alterarProdutoFiscal(
            attprodutoFiscal,
            props.empresaId,
            props.id
          );
        }

        const produtoImagemEqual = model.imagemUrl === beforeModel.imagemUrl;
        if (!produtoImagemEqual) {
          await saveChangesProdutoImagem(model);
        }

        const produtoPrecoEqual = model.vPreco === beforeModel.vPreco;
        if (!produtoPrecoEqual) {
          await saveChangesProdutoPreco(model);
        }

        // Auxiliar para atualização do código do produto
        refcodAtualizado.current = false;

        // if (model.tipo === EnumTipoProduto.Medicamento) {
        //   refEditMedicamentoForm.current?.submitForm();
        // } 
        if (
          produtoEqual &&
          produtoGradeEqual &&
          produtoImagemEqual &&
          produtoPrecoEqual
        ) {
          showToast('info', 'Nenhuma informação foi alterada');
          return
        }

        refEditPrincipalForm.current?.fillForm(model);

      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [alterarProdutoFiscal, produtoFormResumoState, produtoGenerico?.vConfig, produtoServico?.vConfig, props.empresaId, props.id, saveChangesProduto, saveChangesProdutoImagem, saveChangesProdutoPreco, saveChangesProdutoVariacao, showToast]
  );

  const submitProduto = async (
    model: ProdutoPrincipalEditFormModel,
    beforeModel: ProdutoPrincipalEditFormModel
  ) => {
    handleSubmit(model, beforeModel);
  };

  const trocarTipoProduto = useCallback(
    (produtoId: string, tpProdutoAtual: number) => {
      showToast('info', 'Alteração desativada temporariamente.');
    },
    [showToast]
  );

  const handleSubmitResumo = useCallback(
    async (
      model: ProdutoResumoEditFormModel,
      beforeModel: ProdutoResumoEditFormModel
    ) => {
      const produtoEqual = isEqual(model, beforeModel);
      if (!produtoEqual) setProdutoFormResumoState(model);

      refEditPrincipalForm.current?.submitForm();
    },
    []
  );

  const changeStatus = useCallback(async () => {
    let produtoChangedStatus = { ...produtoFormPrincipalState };
    produtoChangedStatus.ativo = !produtoChangedStatus.ativo;
    handleSubmit(produtoChangedStatus, produtoFormPrincipalState);
  }, [handleSubmit, produtoFormPrincipalState]);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteProdutoVariacao(
        getEmpresaAtual()?.id || '',
        refProdutoVariacaoPadrao.current.produtoId,
        refProdutoVariacaoPadrao.current.id
      );

      if (res.erro) throw res.erro;
      if (res.statusCode !== 204)
        throw new Error('Erro ao excluir produto. Tente Novamente');

      showToast('success', 'Produto Excluído com sucesso.');

      const url = location.pathname.split('/')[1];
      fecharCadastroProduto('/' + url);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    deleteProdutoVariacao,
    fecharCadastroProduto,
    getEmpresaAtual,
    location.pathname,
    showToast
  ]);

  const excluirProduto = useCallback(() => {
    confirm({
      title: 'Tem certeza?',
      description:
        'Deseja excluir este produto? Esta ação não poderá ser desfeita futuramente.',
      cancellationText: 'Cancelar',
      confirmationText: 'Excluir'
    }).then(() => {
      handleDelete();
    });
  }, [confirm, handleDelete]);

  const produtoModelCompleto = refProdutoModel.current as ProdutoCompletoModel;

  const menuOptions = () => {
    const options: any[] = [
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              {produtoFormPrincipalState.ativo ? 'Desabilitar' : 'Habilitar'}{' '}
              {produtoPageNome(produtoFormPrincipalState.tipo, false)}
            </Typography>
            <SwitchSaurus
              size="small"
              value={produtoFormPrincipalState.ativo}
              variant="DEFAULT"
              checked={produtoFormPrincipalState.ativo}
            />
          </Box>
        ),
        <></>,
        changeStatus
      ),
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              {`Excluir ${produtoPageNome(
                produtoFormPrincipalState.tipo,
                false
              )}`}
            </Typography>
            <LixoIcon tipo="BUTTON" fill={theme.palette.error.main} />
          </Box>
        ),
        <></>,
        excluirProduto
      ),
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoGenerico?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Produto Genérico</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoGenerico,
                props.id
              )
          )
          : null,
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoEntrega?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Taxa de Entrega</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoEntrega,
                props.id
              )
          )
          : null,
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoServico?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Taxa de Serviço</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoServico,
                props.id
              )
          )
          : null,
      isPlanoControleMesasComandas(plano?.plano) &&
        produtoFormPrincipalState.tipo !== EnumTipoProduto.Medicamento
        ? new MenuOptionsModel(
          (
            <Box className={classes.menuItem}>
              <Typography>Calcula Taxa de Serviço</Typography>
              <SwitchSaurus
                size="small"
                value={produtoFormPrincipalState.cobraTaxaServico}
                variant="DEFAULT"
                checked={produtoFormPrincipalState.cobraTaxaServico}
              />
            </Box>
          ),
          <></>,
          async () => {
            await handleSubmit(
              {
                ...produtoFormPrincipalState,
                cobraTaxaServico: !produtoFormPrincipalState.cobraTaxaServico
              },
              produtoFormPrincipalState
            );
            setPreenchendoTela(true);
            await preencherTela();
          }
        )
        : null,
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              {TpProdutoMock.find(
                (x) => x.Key === produtoFormPrincipalState.tipo
              )?.Value || 'Produto'}
            </Typography>
            <EditarIcon tipo="BUTTON" />
          </Box>
        ),
        <></>,
        () => {
          callEvent(AppEventEnum.AttTituloEdicaoProduto, {
            titulo: 'Alterar Tipo Produto'
          });
          setChangeTipo(true);
        }
      )
    ];
    return options;
  };

  const renderTopForm = useMemo(() => {
    return (
      <div className={classes.principal}>
        <FormProdutoResumoEdit
          tipo={props.tipo}
          ref={refEditResumoForm}
          onSubmit={handleSubmitResumo}
          setCarregandoExterno={setFormInSubmit}
          showLoading={false}
          loading={carregando}
          trocarTipoProduto={trocarTipoProduto}
          empresaId={props.empresaId}
          contratoId={props.contratoId}
          id={props.id}
          ativo={produtoFormPrincipalState.ativo}
          tpProdutosConfig={{
            entrega: produtoEntrega?.vConfig,
            servico: produtoServico?.vConfig,
            generico: produtoGenerico?.vConfig
          }}
        />
      </div>
    );
  }, [
    carregando,
    classes.principal,
    handleSubmitResumo,
    produtoFormPrincipalState.ativo,
    props.tipo,
    produtoGenerico,
    produtoServico,
    props.contratoId,
    props.empresaId,
    props.id,
    trocarTipoProduto,
    produtoEntrega
  ]);

  //RENDERIZAR FORM CASO SEJA MEDICAMENTO
  const renderMedicamentoForm = (
    <div className={classes.tabContent}>
      <FormMedicamentoEditPrincipal
        ref={refEditMedicamentoForm}
        onSubmit={submitMedicamento}
        showLoading={false}
        loading={carregando}
      />
    </div>
  );

  //RENDERIZAR FORM SUBITENS

  const renderSubitens = (
    <Grid className={classes.tabContent}>
      <ProdutoSubItens
        produtoGradeId={
          !isEmpty(produtoCompleto.grades) ? produtoCompleto.grades[0].id : ''
        }
        setCarregandoExterno={setFormInSubmit}
        carregandoExterno={carregando}
        empresaId={props.empresaId}
        produtoId={props.id}
        variacaoId={refProdutoVariacaoPadrao.current.id}
        tipo={produtoFormPrincipalState.tipo}
        tpProduto={produtoCompleto.tipo}
        ref={refSubitens}
      />
    </Grid>
  );

  //RENDERIZAR TABS CASO SEJA PRODUTO
  const renderProdutoForm = (
    <Grid className={classes.tabContent}>
      <FormProdutoPrincipalEdit
        tipo={produtoFormPrincipalState.tipo}
        id={props.id}
        ref={refEditPrincipalForm}
        onSubmit={submitProduto}
        setCarregandoExterno={setFormInSubmit}
        atualizarVariacao={async () => {
          try {
            setPreenchendoTela(true);
            await getProdutoVariacaoWrapper(produtoModelCompleto.grades[0]);

            setProdutoFormPrincipalState((prev) => {
              return {
                ...prev,
                qSaldoTotal: refProdutoSaldo.current
              };
            });
            recarregarFormPrincipal({
              ...produtoFormPrincipalState,
              qSaldoTotal: refProdutoSaldo.current
            });
            setPreenchendoTela(false);
          } catch (e: any) {
            showToast('error', e.message);
          }
        }}
        showLoading={false}
        variacaoPadrao={refProdutoVariacaoPadrao.current}
        loading={preenchendoTela}
        empresaId={props.empresaId}
        contratoId={props.contratoId}
        openDialogCodigo={(codigo?: string) => {
          let model = new ProdutoCodigoModel();
          if (codigo) {
            model =
              refProdutoVariacaoPadrao.current.codigos.find(
                (x) => x.codigo === codigo
              ) || model;
          }
          codigoProdutoRefs.current?.adicionarNovo(model, model.id !== guidEmpty() ? 'Editar Código' : 'Adicionar Código');
        }}
      />
    </Grid>
  );

  useEffect(() => {
    const atualizarTitulo = (obj: any) => {
      if (tituloRef.current) {
        if (!isEmpty(obj.titulo)) {
          tituloRef.current.innerText = obj.titulo;

          if (obj.titulo === 'Editar Código' || obj.titulo === 'Adicionar Código' || obj.titulo === 'Depósitos') {
            setShowOptions(false)
          }
          return;
        }
        tituloRef.current.innerText = `Edição de ${produtoPageNome(
          props.tipo,
          false
        )}`;
        setShowOptions(true)
      }
    };
    addHandler(AppEventEnum.AttTituloEdicaoProduto, atualizarTitulo);
    return () => {
      removeHandler(AppEventEnum.AttTituloEdicaoProduto, atualizarTitulo);
    };
  }, [addHandler, props.tipo, removeHandler]);

  const isPlanoFarma = useMemo(() =>
    isPlanoFarmaceutico(plano?.plano)
    , [plano?.plano])

  return (
    <div className={classNames(modalClasses.root, classes.root)}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={
          carregando
            ? ''
            : `Edição de ${produtoPageNome(
              produtoFormPrincipalState.tipo,
              false
            )}`
        }
        titleRef={tituloRef}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          showOptions ? <MenuOptions
            options={menuOptions()}
            labels={[
              'Ações',
              '',
              'Produtos Especiais',
              '',
              '',
              'Taxa de Serviço',
              'Tipo de Produto'
            ]}
          /> : null
        }
      />
      {!irParaTelaDeCodigoAnvisa ? (
        <div className={classNames(modalClasses.content, classes.relative)}>
          <div
            className={classNames(
              modalClasses.contentForms,
              carregando ? modalClasses.contentFormsLoading : undefined
            )}
          >
            {renderTopForm}

            <div
              className={classNames(
                classes.contentAbas,
                isMedicamento ? classes.contentAbasMedicamento : undefined
              )}
            >
              <TabsSaurus
                selectedTabIndex={abaSelecionada}
                onChange={(aba) => {
                  setAbaSelecionada(aba);
                }}
                contentRelative={false}
                tabsLabel={[
                  new TabSaurusLabel('Principal', 0),
                  new TabSaurusLabel(
                    produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento
                      ? 'Medicamento'
                      : 'Ficha Técnica',
                    1
                  )
                ]}
                tabsContent={[
                  new TabSaurusContent(
                    0,
                    (
                      <>
                        {renderProdutoForm}
                        <div>
                          <FormProdutoCodigosEdit
                            model={refProdutoVariacaoPadrao.current.codigos}
                            produtoId={refProdutoVariacaoPadrao.current.produtoId}
                            variacaoId={refProdutoVariacaoPadrao.current.id}
                            preencherTela={preencherTela}
                            hideList={
                              refProdutoVariacaoPadrao.current.codigos.length < 2
                            }
                            ref={codigoProdutoRefs}
                          />
                        </div>
                      </>
                    )
                  ),
                  new TabSaurusContent(
                    1,
                    produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento
                      ? renderMedicamentoForm
                      : renderSubitens
                  )
                ]}
              />
            </div>
          </div>

          <div className={modalClasses.acoes}>
            <div className={classes.propagandaContent}>
              <CardPropagandaUrl />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  disabled={carregando}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    abrirCadastroProduto('', props.tipo, '', true);
                  }}
                >
                  <NovoIcon tipo="BUTTON" />
                  Novo
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  disabled={carregando}
                  onClick={() => {
                    refEditResumoForm.current?.submitForm();
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <SalvarIcon tipo="BUTTON_PRIMARY" />
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </div>
          {changeTipo && (
            <RadioList
              handleSubmit={async (tipo) => {

                const prodAtualizado = { ...produtoFormPrincipalState };

                if (isPlanoFarma && tipo === EnumTipoProduto.Medicamento) {
                  callEvent(AppEventEnum.AttTituloEdicaoProduto, { titulo: 'Conversão de Produto para Medicamento' })
                  setIrParaTelaDeCodigoAnvisa(true)
                  return
                }

                if (prodAtualizado.tipo === EnumTipoProduto.Medicamento && tipo !== EnumTipoProduto.Medicamento) {
                  prodAtualizado.codigoAnvisa = '';
                }

                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                  titulo: ''
                });
                setChangeTipo(false);
                await handleSubmit(
                  {
                    ...prodAtualizado,
                    tipo
                  },
                  produtoFormPrincipalState
                );

                await preencherTela();
              }}
              selecionado={produtoFormPrincipalState.tipo}
              mock={isPlanoFarma ? TpProdutoFarmaciaMock : TpProdutoMock}
              text="Para alterar o tipo do produto, selecione um tipo abaixo e confirme:"
            />
          )}

        </div>)


        :
        <Grid container justifyContent='space-between' spacing={2} className={classes.formularioAnvisa}>
          <Grid item xs={12}>
            <FormProdutoCodigoPreCadastro
              obrigatorio
              showLoading={false}
              ref={refPreCadastroCodigo}
              loading={carregando}
              setCarregandoExterno={setPreenchendoTela}
              onSubmit={handleSubmitFormPreCadastroCodigo}
              label={'Código ANVISA'}
              tipoForm={EnumFormCodigo.AMBOS}
            /> </Grid>
          <Grid item xs={12} className={classes.botaoPesquisar} alignItems='flex-end'>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type='submit'
              onClick={() => { refPreCadastroCodigo.current?.submitForm() }}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      }

    </div>
  );
};
export default ProdutoEdit;
