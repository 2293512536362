import { Box, Button, Tooltip, Typography } from 'views/design-system';
import { useStyles } from './card-menu-pdv-styles';
import { useThemeQueries } from 'views';
import { CardOpcaoProps } from './card-menu-pdv-props';
import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { IconsHelper } from 'views/components/icons-helper';
import { EstrelaFavoritoIcon } from 'views/components/icons/estrela-favorito-icon';
import { EnumMenu } from 'model';
import { stopPropagationWithFeedback } from 'utils/custom-stop-propagation';
import { PadraoIcon } from 'views/components/icons';

const CardMenuPDV = ({
    model,
    insercaoPadrao,
    onClick,
    handleFavorite,
    handleAddPadrao,
    isDeliveryAtivo = false,
}: CardOpcaoProps) => {
    // AUX
    const classes = useStyles();
    const { theme } = useThemeQueries();

    const handleClick = () => {
        onClick(model);
    }

    const handleClickAddPadraoInterno = (rota: EnumMenu, event: any) => {
        handleAddPadrao(rota);
        stopPropagationWithFeedback(event);
    }

    const handleClickFavorite = (event: any) => {
        handleFavorite({ ...model, favorito: !model.favorito });
        stopPropagationWithFeedback(event);
    };


    const opcoesParaPadrao = [
        EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO,
        EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO,
        EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO,
        EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS,
        EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR,
        EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY,
        EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY,
    ].includes(model.codigo)

    const idFavorito = useCallback
        (() => {
            switch (model.codigo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                    return 'tour-favoritar'
                default:
                    return model.nome
            }
        }, [model.codigo, model.nome])

    const idPadrao = useCallback
        (() => {
            switch (model.codigo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                    return 'tour-padronizar'
                default:
                    return model.nome
            }
        }, [model.codigo, model.nome])

    const isCancelarVenda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR;
    const isFinalizarVenda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA;
    const isRetaguarda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA;
    const isSincronizar = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS;
    const isDelivery = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY && isDeliveryAtivo;

    return (
        <Button classes={{
            label: classes.buttonLabel,
        }} onClick={handleClick} className={classNames(
            classes.content,
            (isCancelarVenda ? classes.red : null),
            (isFinalizarVenda ? classes.green : null),
            (isRetaguarda || isSincronizar ? classes.destaque : null),
            (isDelivery ? classes.moduloAtivo : null)
        )}>
            <Box flex flexDirection='column' className={classes.contentBox}>
                <Box className={classes.contentIcon}>
                    <IconsHelper
                        class={classes.icon}
                        codigoModulo={model.codigo}
                        fill={
                            isCancelarVenda ? theme.palette.error.dark :
                                isFinalizarVenda ? theme.palette.success.dark :
                                    isRetaguarda || isSincronizar ? theme.palette.primary.dark :
                                        isDelivery ? theme.palette.info.main :
                                            theme.palette.primary.main}
                        tipo="GERAL"
                    />
                </Box>
                <Box className={classes.contentTexto} flex flexDirection="row">
                    <Typography className={classes.text} variant='body1' color="textPrimary">
                        {model.nome}
                    </Typography>
                    <span className={classes.descricao}>
                        {model.descricao}
                    </span>
                </Box>
            </Box>
            {opcoesParaPadrao && (
                <Tooltip title="Tornar modo de venda padrão">
                    <Button
                        id={idPadrao()}
                        className={classes.btnModo}
                        variant="text"
                        onClick={(event) => handleClickAddPadraoInterno(model.codigo, event)}
                    >
                        <PadraoIcon fill={insercaoPadrao ? theme.palette.primary.main : '#E0E0E0'} />
                    </Button>
                </Tooltip>
            )}
            <Tooltip title="Favoritar opção">
                <Button
                    id={idFavorito()}
                    className={classes.btnFavorito}
                    variant="text"
                    onClick={(event) => handleClickFavorite(event)}
                >
                    <EstrelaFavoritoIcon fill={model.favorito ? '#FFBF00' : '#E0E0E0'} />
                </Button>
            </Tooltip>
        </Button>

    );
};

export default memo(CardMenuPDV);
