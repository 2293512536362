import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEntradaCadastroValidation } from './form-entrada-cadastro-validation';
import { FormEntradaCadastroProps } from './form-entrada-cadastro-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EntradaCadastroFormModel } from 'model/app/forms/entrada/entrada-cadastro-form-model';
import { TpModFreteMock } from 'data/mocks/tp-mod-frete-mock';
import { FinNFeMock } from 'data/mocks/fin-nfe-mock';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { FecharIcon } from 'views/components/icons';
import { useStyles } from './form-entrada-cadastro-styles';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { picker } from 'utils/picker';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import classNames from 'classnames';
import { stringNumeros } from 'utils/string-numeros';

export const FormEntradaCadastro = forwardRef<
    DefaultFormRefs<EntradaCadastroFormModel>,
    FormEntradaCadastroProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles()
    const { formEntradaCadastroValidationYup } = useFormEntradaCadastroValidation();
    const [, setAtt] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues
    } = useForm<EntradaCadastroFormModel>({
        resolver: yupResolver(formEntradaCadastroValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: {
            ...new EntradaCadastroFormModel(),
        }
    });

    const onSubmit = (values: EntradaCadastroFormModel) => {
        props.onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: () => { },
    }));

    const removerTransp = useCallback(() => {
        setValue('transp', null)
        setValue('transpNomeTel', '')
        setAtt(prev => !prev)
    }, [setValue])

    return (
        <div>
            <div className={utilClasses.formContainer}>
                {(props.loading) && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2} justifyContent='space-between'>
                        <Grid item xs={12} className={classes.itemContent}>
                            <Grid>
                                <Box pb={1}>
                                    <Typography variant="h6" color="primary">
                                        Emitente
                                    </Typography>
                                    <Divider className={classes.divider} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="emitCnpj"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompletePessoas
                                            disabled={props.loading}
                                            label="Selecione o Emitente *"
                                            placeholder='Informe o nome ou documento'
                                            nomeCnpj={EnumNomeCnpj.Todos}
                                            error={Boolean(errors.emitCnpj && errors.emitCnpj.message)}
                                            helperText={
                                                touchedFields.emitCnpj || errors.emitCnpj
                                                    ? errors.emitCnpj?.message
                                                    : undefined
                                            }
                                            {...field}
                                            exibirTipo
                                            mensagemNaoEncontrado='Nenhum registro encontrado'
                                            onChange={(retorno) => {
                                                if (!retorno.isString) {
                                                    const pessoa = picker<PessoaModel>(
                                                        retorno.value,
                                                        new PessoaModel(),
                                                    );
                                                    pessoa.contatos = retorno.value.contatos
                                                    pessoa.documentos = retorno.value.documentos
                                                    const telefone = pessoa.contatos.find(x => x.tipo === EnumTipoPessoaContato.TELEFONE)?.valor
                                                    const email = pessoa.contatos.find(x => x.tipo === EnumTipoPessoaContato.EMAIL)?.valor
                                                    setValue('emit.doc', pessoa.cpfcnpj);
                                                    setValue('emit.xNome', pessoa.nome);
                                                    setValue('emit.fone', telefone ? stringNumeros(telefone) : '');
                                                    setValue('emit.email', email ? email : '');
                                                    setValue('emit.xFant', pessoa.fantasia);
                                                    setValue('emit.id', pessoa.id);
                                                    setValue('emit.im', pessoa.im)
                                                    setValue('emitCnpj', pessoa.nome)
                                                    setAtt(prev => !prev)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                        <Grid item xs={12} className={classes.itemContent}>
                            <Grid >
                                <Box pb={1}>
                                    <Typography variant="h6" color="primary">
                                        Transportadora
                                    </Typography>
                                    <Divider className={classes.divider} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.itemInput}>
                                <Box display="flex" width="100%" height='100%'>
                                    <Controller
                                        name="transpNomeTel"
                                        control={control}
                                        render={({ field }) => (
                                            <AutocompletePessoas
                                                disabled={props.loading}
                                                label="Selecione a Transportadora"
                                                placeholder='Informe o nome ou documento'
                                                nomeCnpj={EnumNomeCnpj.Todos}
                                                error={Boolean(errors.emit && errors.emit.message)}
                                                helperText={
                                                    touchedFields.emit || errors.emit
                                                        ? errors.emit?.message
                                                        : undefined
                                                }
                                                {...field}
                                                exibirTipo
                                                mensagemNaoEncontrado='Nenhum registro encontrado'
                                                onChange={(retorno) => {
                                                    if (!retorno.isString) {
                                                        const pessoa = picker<PessoaModel>(
                                                            retorno.value,
                                                            new PessoaModel(),
                                                        );
                                                        pessoa.contatos = retorno.value.contatos
                                                        pessoa.documentos = retorno.value.documentos
                                                        setValue('transp', pessoa);
                                                        setValue('transpNomeTel', pessoa.nome)
                                                        setAtt(prev => !prev)
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Button className={classes.removerBtn} disabled={!getValues('transp')} onClick={removerTransp}>
                                        <FecharIcon tipo="BUTTON" class={classNames(classes.iconPessoa, !getValues('transp') ? classes.disabledIcon : '')} />
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="modFrete"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            disabled={props.loading}
                                            fullWidth
                                            conteudo={TpModFreteMock}
                                            variant="outlined"
                                            label="Modalidade do Frete"
                                            allowSubmit
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.modFrete && errors.modFrete.message)}
                                            helperText={
                                                touchedFields.modFrete || errors.modFrete
                                                    ? errors.modFrete?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                const item = TpModFreteMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0]?.Key;
                                                setValue('modFrete', item);
                                            }}
                                            value={getValues('modFrete')}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>

                        <Grid item xs={12} className={classes.itemContent}>
                            <Grid item xs={12}>
                                <Box pb={1}>
                                    <Typography variant="h6" color="primary">
                                        Fiscal
                                    </Typography>
                                    <Divider className={classes.divider} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} className={classes.itemInput}>
                                <Controller
                                    name="natOp"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={props.loading}
                                            fullWidth
                                            tipo="TEXTO"
                                            variant="outlined"
                                            label="Natureza da Operação *"
                                            allowSubmit
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.natOp && errors.natOp.message)}
                                            helperText={
                                                touchedFields.natOp || errors.natOp
                                                    ? errors.natOp?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="finNFe"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            disabled={props.loading}
                                            fullWidth
                                            conteudo={FinNFeMock}
                                            variant="outlined"
                                            label="Finalidade"
                                            allowSubmit
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.finNFe && errors.finNFe.message)}
                                            helperText={
                                                touchedFields.finNFe || errors.finNFe
                                                    ? errors.finNFe?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                const item = FinNFeMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0]?.Key;
                                                setValue('finNFe', item);
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </div>
    );
});
