import { Controller, useForm } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useFormInfoAdicionalProdutoValidation } from "./form-info-adicional-produto-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "views/design-system";
import { forwardRef, useImperativeHandle } from "react";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";
import { Grid } from "@material-ui/core";

export class FormAdicionarProdutoDetalhadoModel {
    constructor(
        public infAdic: string = '',
        public valorDesconto: number = 0,
        public percDesconto: number = 0,
    ) { }
}

export const FormInfoAdicionalProduto = forwardRef<
    DefaultFormRefs<FormAdicionarProdutoDetalhadoModel>,
    DefaultFormProps<FormAdicionarProdutoDetalhadoModel>
>(({ loading, ...props }, ref) => {

    const { FormInfoAdicionalProdutoValidation } = useFormInfoAdicionalProdutoValidation();

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<FormAdicionarProdutoDetalhadoModel>({
        resolver: yupResolver(FormInfoAdicionalProdutoValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (model: FormAdicionarProdutoDetalhadoModel) => {
        props.onSubmit(model)
    }

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: () => {
        },
    }));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="percDesconto"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                label='% Desconto'
                                tipo='DECIMAL'
                                multiline
                                fullWidth
                                allowSubmit
                                error={Boolean(
                                    errors.infAdic && errors.infAdic.message,
                                )}
                                helperText={
                                    errors.infAdic
                                        ? errors.infAdic?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(e) => {
                                    if (getValues('valorDesconto')) {
                                        setValue('valorDesconto', 0)
                                    }
                                    field.onChange(e);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="valorDesconto"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                label='Valor Desconto'
                                tipo='DECIMAL'
                                multiline
                                fullWidth
                                allowSubmit
                                showStartAdornment
                                error={Boolean(
                                    errors.infAdic && errors.infAdic.message,
                                )}
                                helperText={
                                    errors.infAdic
                                        ? errors.infAdic?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(e) => {
                                    if (getValues('percDesconto')) {
                                        setValue('percDesconto', 0)
                                    }
                                    field.onChange(e);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="infAdic"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                label=' Observação do Produto'
                                tipo='TEXTO'
                                minRows={2}
                                maxRows={4}
                                multiline
                                fullWidth
                                allowSubmit
                                placeholder='Informe uma observação para o produto...'
                                error={Boolean(
                                    errors.infAdic && errors.infAdic.message,
                                )}
                                helperText={
                                    errors.infAdic
                                        ? errors.infAdic?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
    );
})