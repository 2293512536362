import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import AtivarEmailPage from 'views/pages/public/ativar-email/ativar-email-page';
import PoliticaPage from 'views/pages/private/politica/politica-page';
import RecuperarSenhaPage from 'views/pages/public/recuperar-senha/recuperar-senha-page';
import RecuperarSenhaRetornoPage from 'views/pages/public/recuperar-senha-retorno/recuperar-senha-retorno-page';
import LogoutPage from 'views/pages/public/logout/logout-page';
import LoginPage from 'views/pages/public/login/login-page';
import NovoContratoPage from 'views/pages/public/novo-contrato/novo-contrato-page';
import RouterMain from './components/router-main/router-main';
import SelecionarPlanoPage from 'views/pages/private/plano/selecionar-plano/selecionar-plano-page';
import { LandingRoute } from './components/landing-route';
import { ExpiredTokenRoute } from './components/expired-token-route';
import ConvitePage from 'views/pages/public/convite/convite-page';
import ConviteConcluidoPage from 'views/pages/public/convite/convite-concluido/convite-concluido-page';
import PendenciasPage from 'views/pages/public/pendencias/pendencias-page';
import { MensagemRepresentanteOk } from 'views/components/mensagem-representante-ok/mensagem-representante-ok';
import { VariaveisAmbiente } from 'config/enviroment';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import SelecionarEmpresaPage from 'views/pages/private/empresas/selecionar-empresa/selecionar-empresa-page';
import { useMemo } from 'react';
import LandingPage from 'views/pages/public/landing/landing-page';
import SelecionarContratoPage from 'views/pages/public/selecionar-contrato/selecionar-contrato-page';
import AceitarCompartilhamento from 'views/pages/public/aceitar-compatilhamento/aceitar-compartilhamento';
import { PlanoValidationRoute } from './components/plano-validation-route/plano-validation-route';
import NovoContratoChavePage from 'views/pages/public/novo-contrato-chave/novo-contrato-chave-page';
import { GestaoTokenProvider, SessaoAtualProvider } from 'services/app';
import ApelidoPage from 'views/pages/private/apelido/apelido-page';
import TestePage from 'views/pages/public/material-vs-proprio/teste-page'
import { AvisoDialog } from 'views/components/render-modais/components/aviso-dialog';
import { RenderModaisPublic } from 'views/components/render-modais-public/render-modais-public';

export const RouterApp = () => {

  const switchApp = useMemo(() => (
    <Switch>
      <Route exact path="/testematerial" component={TestePage} />
      <Route
        exact
        path="/landing"
        component={LandingPage}
      />
      <Route
        exact
        path="/selecionar-plano"
        component={SelecionarPlanoPage}
      />
      <Route
        exact
        path="/selecionar-empresa"
        component={SelecionarEmpresaPage}
      />
      <Route
        exact
        path="/pendencias"
        component={PendenciasPage}
      />
      <Route
        exact
        path="/termos-de-uso"
        component={PoliticaPage}
      />
      <Route
        exact
        path="/cadastro-apelido"
        component={ApelidoPage}
      />
      <Route
        exact
        path="/ativar-email"
        component={AtivarEmailPage}
      />
      <Route
        exact
        path="/aceitar-compartilhamento"
        component={AceitarCompartilhamento}
      />
      <Route
        exact
        path="/convite-empresa/novo-usuario"
        component={ConvitePage}
      />
      <Route
        exact
        path="/convite/concluido"
        component={ConviteConcluidoPage}
      />
      <LandingRoute>
        <Switch>
          <Route exact path="/logout" component={LogoutPage} />
          <Route
            exact
            path="/recuperar-senha"
            component={RecuperarSenhaPage}
          />
          <Route
            exact
            path="/recuperar-senha/codigo"
            component={RecuperarSenhaRetornoPage}
          />
          <Route
            exact
            path="/chave-contrato"
            component={NovoContratoChavePage}
          />
          <Route
            exact
            path="/novo-contrato"
            component={NovoContratoPage}
          />
          <Route
            exact
            path="/login/:parametro?"
            component={LoginPage}
          />
          <Route
            exact
            path="/selecionar-contrato"
            component={SelecionarContratoPage}
          />
          <ExpiredTokenRoute>
            <PlanoValidationRoute>
              <RouterMain />
            </PlanoValidationRoute>
          </ExpiredTokenRoute>
        </Switch>
        <RenderModaisPublic/>
      </LandingRoute >
    </Switch >
  ), []);
  return (
    <>
      <GestaoTokenProvider>
        <SessaoAtualProvider>
          {(VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) ? (
            <HashRouter>
              {switchApp}
            </HashRouter>
          ) : (
            <BrowserRouter>
              {switchApp}
            </BrowserRouter>
          )}
          <MensagemRepresentanteOk />
        </SessaoAtualProvider>
      </GestaoTokenProvider>
      <AvisoDialog />
    </>
  );
};
