import { useCallback } from 'react';
import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import {
    DefaultCard,
    useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views/theme';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { Grid, Tooltip, Typography } from 'views/design-system';
import { useStyles } from './card-produto-revisao-styles';
import { CardProdutosRevisaoProps } from './card-produto-revisao-props';

export const CardProdutoRevisao = ({
    model,
    onClick,
    onCheck,
    selected,
    select
}: CardProdutosRevisaoProps) => {
    const classes = useDefaultCardStyles();
    const produtosClasses = useStyles();
    const { theme } = useThemeQueries();

    const retornarCorStatus = useCallback((): string => {
        return theme.palette.grey[300];
    }, [theme.palette.grey]);

    const img = (imagemUrl: string) => {
        try {
            if (imagemUrl.length > 0) {
                const url = atob(imagemUrl);
                return <img src={url} className={classes.cardImg} alt={model.nome} />;
            }

            return (
                <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
            );
        } catch (err) {
            return (
                <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
            );
        }
    };

    return (
        <>
            <DefaultCard
                isSelected={false}
                onClick={() => {
                    onCheck({
                        categoria: model.categoria,
                        id: model.id,
                        imgUrl: model.imgUrl,
                        nome: model.nome,
                        removido: !!model.removido,
                        sincronizado: model.sincronizado,
                        marca: model.marca
                    });
                }}
            >
                <Tooltip arrow title={'Selecionado'} placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{ background: retornarCorStatus() }}
                    ></div>
                </Tooltip>
                <div className={classNames(classes.cardContent, produtosClasses.card)}>
                    <div
                        className={classes.celulaGrid}
                        style={{ marginLeft: 8, marginRight: 8 }}
                    >
                        {img(model?.imgUrl ?? '')}
                    </div>
                    <Grid container flexDirection="column" style={{ flex: 1 }}>
                        <Grid item xs={12} md={12} className={produtosClasses.item}>
                            <Typography color="textSecondary" variant="caption">
                                Nome:
                            </Typography>
                            <Typography color="textPrimary" variant="body1">
                                {model.nome}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} className={produtosClasses.item}>
                            <Grid container style={{ flex: 1 }}>
                                <Grid item xs={6} md={6} className={produtosClasses.item}>
                                    <Typography color="textSecondary" variant="caption">
                                        Categoria:
                                    </Typography>
                                    <Typography color="textPrimary" variant="body1">
                                        {!isEmpty(model.categoria)
                                            ? model.categoria
                                            : 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={6} className={produtosClasses.item}>
                                    <Typography color="textSecondary" variant="caption">
                                        Marca/Laboratório:
                                    </Typography>
                                    <Typography color="textPrimary" variant="body1">
                                        {!isEmpty(model.marca) ? model.marca : 'Não informado'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Tooltip arrow title="selecionar produto">
                    <div
                        className={classNames(
                            classes.cardRightButton,
                            produtosClasses.iconRight
                        )}
                    >
                        {select && (
                            <>
                                <div
                                    style={{
                                        borderLeftWidth: '2px',
                                        borderColor: '#E6E6E6',
                                        borderStyle: 'solid',
                                        height: '45px'
                                    }}
                                />
                                <CheckboxDefault checked={selected} />
                            </>
                        )}
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    );
}

export default React.memo(CardProdutoRevisao);
