import { Grid, Typography, Button } from 'views/design-system';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogBarCodeCamComandaTransferirItens } from 'views/components/dialog/dialog-barcode-cam-transferir-itens/dialog-barcode-cam-transferir-itens';
import { DialogTransferirItem } from 'views/components/dialog/dialog-transferir-itens/dialog-transferir-itens';
import { TransferirIcon } from 'views/components/icons/transferir-item';
import { TransferirItensListData } from './components/transferir-itens-list/transferir-itens-list-data';
import { useStyles } from './mov-transferir-itens-pedido-styles';
import { isEmpty } from 'lodash';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

interface LocationProps {
  pedido: PedidoModel;
  transferirPedido: boolean;
  alterarVendedor: boolean;
  vendedor: PessoaModel | null;
}

export const MovTransferirItensPedidoPage = () => {
  // STATES E REFS
  const [itemSelecionado, setItemSelecionado] = useState<string[]>([]);
  const [dialogTransferir, setDialogTransferir] = useState<boolean>(false);
  const [dialogTransferirCam, setDialogTransferirCam] =
    useState<boolean>(false);

  const location = useLocation<LocationProps>();
  const classes = useStyles();
  const history = useHistory();

  const pedido = location.state;
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();

  const isTipoTrabalhoComanda =
    getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA;

  useEffect(() => {
    if (isEmpty(pedido?.pedido)) {
      history.push(MovRotasMock.pedidosRota);
    }
  }, [history, pedido]);

  const onCardItemSelected = useCallback((produto: PedidoProdutoModel) => {
    setItemSelecionado((prev: string[]) => {
      const temEsseItemSelecionado = prev.filter(
        (prod) =>
          prod === produto.id ||
          produto.adicionais.map((p) => p.id).includes(prod)
      );

      if (temEsseItemSelecionado.length > 0) {
        return prev.filter((prod) => !temEsseItemSelecionado.includes(prod));
      }

      return [...prev, produto.id, ...produto.adicionais.map((p) => p.id)];
    });
  }, []);

  //Validar se é um produto pai com valor de venda zerado, mas possui adicionais

  const produtosAgrupados = location.state?.pedido?.produtos.filter(
    (p) =>
      /*
      caso o produto tenha indFin false e valorTotal 0, ele pode ser um produto pai com valor zerado, então faço
      essa validação pra ter certeza que ele não será ignorado na listagens dos itens
      */
      ((!p.indFin && p.valorTotal === 0) ? (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem) : p.indFin) &&
      //
      (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem ||
        p.tpProduto.codigo === EnumTpProduto.Produto ||
        p.tpProduto.codigo === EnumTpProduto.Medicamento)
  );

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: location.state?.alterarVendedor
        ? `Alterar Vendedor`
        : `Transferir itens`,
      voltar: {
        onClick: () => {
          if (pedido?.alterarVendedor) {
            history.push({
              pathname: MovRotasMock.pedidoAlterarVendedor,
              state: {
                pedido: pedido?.pedido
              }
            });
          } else {
            if (!pedido.pedido.mesaId && !pedido.pedido.comandaId) {
              history.push({
                pathname: `/venda-simples/visualizar-pedido/${pedido.pedido.id}`,
                search: history.location.search,
                state: {
                  pedido: pedido.pedido
                }
              });
              return;
            }
            if (isTipoTrabalhoComanda) {
              history.push({
                pathname: `${MovRotasMock.mesaEComandaRota}/${pedido.pedido.mesaId}`,
                search: history.location.search,
                state: {
                  pedido: pedido?.pedido
                }
              });
              return;
            }
            history.push(
              {
                pathname: `/venda-simples/visualizar-pedido/${pedido.pedido.comandaId
                  ? pedido.pedido.comandaId
                  : pedido.pedido.mesaId
                  }`,
                search: history.location.search ? history.location.search : pedido.pedido?.comandaId ? '?modo=comanda' : '?modo=mesa',
                state: {
                  pedido: pedido?.pedido
                }
              }
            );
          }
        }
      }
    })
  }, [history, isTipoTrabalhoComanda, location.state?.alterarVendedor, pedido?.alterarVendedor, pedido.pedido, setHeaderProps])

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.containerInfo}>
          <Typography
            style={{
              fontWeight: '500',
              backgroundColor: '#FFF',
              borderRadius: 4,
              padding: '4px 10px',
              boxShadow: '1px 1px 2px rgba(0,0,0,0.1)'
            }}
            variant="body2"
          >
            {produtosAgrupados?.length}{' '}
            {produtosAgrupados?.length === 1 ? 'Produto' : 'Produtos'}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <TransferirItensListData
            itens={
              produtosAgrupados.filter((produto) => {
                if (location.state.alterarVendedor) {
                  if (produto.vendedorId === location.state.vendedor?.id) {
                    return false;
                  }
                  if (
                    produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
                  ) {
                    return true;
                  }

                  return false;
                }
                if (
                  produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
                ) {
                  return true;
                }
                return false;
              }) ?? []
            }
            carregando={false}
            onCardSelected={onCardItemSelected}
            itensSelecionados={itemSelecionado}
          />
        </Grid>
        <Grid className={classes.containerAcao}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="round"
                disabled={itemSelecionado.length === 0 ? true : false}
                onClick={() => setDialogTransferir(true)}
              >
                <TransferirIcon tipo="BUTTON_PRIMARY" />
                {location.state?.alterarVendedor
                  ? `Alterar Vendedor`
                  : 'Transferir Item'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {dialogTransferir && (
        <DialogTransferirItem
          aberto={dialogTransferir}
          dialogTransferirCam={setDialogTransferirCam}
          close={() => setDialogTransferir(false)}
          idsProdutos={itemSelecionado}
          pedido={location.state?.pedido}
          vendedor={location.state?.vendedor ?? null}
        />
      )}

      {dialogTransferirCam && (
        <DialogBarCodeCamComandaTransferirItens
          openned={dialogTransferirCam}
          closeModal={() => setDialogTransferirCam(false)}
          dialogTransferir={setDialogTransferir}
          idsProdutos={itemSelecionado}
          pedido={location.state?.pedido}
          vendedor={location.state?.vendedor ?? null}
        />
      )}
    </>
  );
};
