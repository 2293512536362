import AlterarPlanoPage from 'views/pages/private/plano/alterar-plano/alterar-plano-page';
import ConfiguracoesPage from 'views/pages/private/configuracoes/configuracoes-page';
import PessoaPage from 'views/pages/private/cadastros/pessoa/pessoa-page';
import FinalizadoraPage from 'views/pages/private/cadastros/finalizadora/finalizadora-page';
import OutrosCadastrosPage from 'views/pages/private/cadastros/outros-cadastros/outros-cadastros-page';
import CategoriaPage from 'views/pages/private/cadastros/categoria/categoria-page';
import NcmPage from 'views/pages/private/cadastros/ncm/ncm-page';
import ProdutoPage from 'views/pages/private/cadastros/produto/produto-page';
import PerfisPage from 'views/pages/private/cadastros/perfis/perfis-page';
import DashboardPage from 'views/pages/private/relatorios/dashboard/dashboard-page';
import ImpostosPage from 'views/pages/private/cadastros/impostos/impostos-page';
import PontosVendaPage from 'views/pages/private/pontos-venda/pontos-venda-page';
import { HistoricoVendaPage } from 'views/pages/private/historico-vendas/historico-vendas-page';
import UploadCargaPage from 'views/pages/private/cadastros/upload-carga/upload-carga-page';
import UploadCargaDetalhesPage from 'views/pages/private/cadastros/upload-carga-detalhes/upload-carga-detalhes-page';
import AdicionarEmpresaPage from 'views/pages/private/empresas/adicionar-empresa/adicionar-empresa';
import ConfiguracoesEmpresaPage from 'views/pages/private/empresas/configuracoes/configuracoes-page';
import ListaEmpresasPage from 'views/pages/private/empresas/lista-empresas/lista-empresas-page';
import DocumentoFiscalPage from 'views/pages/private/fiscal/documento-fiscal/documento-fiscal-page';
import { AtivarRevisarNFCE } from 'views/pages/private/fiscal/ativar-revisar-nfc-e/ativar-revisar-nfce-page';
import DepositoPage from 'views/pages/private/cadastros/deposito/deposito-page';
import SetoresProcessosPage from 'views/pages/private/cadastros/setores-processos/setores-processos-page';
import ImpressorasPage from 'views/pages/private/cadastros/impressoras/impressoras-page';
import { DetalhesVendaPage } from 'views/pages/private/detalhes-venda/detalhes-venda-page';
import MesasComandasPage from 'views/pages/private/cadastros/mesas-comandas/mesas-comandas-page';
import SaloesPage from 'views/pages/private/cadastros/saloes/saloes-page';
import AutenticacaoPage from 'views/pages/private/autenticacao/autenticacao-page';
import ModificadoresPage from 'views/pages/private/cadastros/modificadores/modificadores-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import EntradaPage from 'views/pages/private/entrada/entrada-page';
import OpcoesEntradaPage from 'views/pages/private/entrada/pages/opcoes-entrada/opcoes-entrada-page';
import DadosEntradaPage from 'views/pages/private/entrada/pages/dados-entrada/dados-entrada-page';
import DadosEmitentePage from 'views/pages/private/entrada/pages/dados-emitente/dados-emitente-page';
import DadosTransportadoraPage from 'views/pages/private/entrada/pages/dados-transportadora/dados-transportadora-page';
import DadosDestinatarioPage from 'views/pages/private/entrada/pages/dados-destinatario/dados-destinatario-page';
import InfoComplementaresPage from 'views/pages/private/entrada/pages/info-complementares/info-complementares-page';
import InfoPedidoCompraPage from 'views/pages/private/entrada/pages/info-pedido-compra/info-pedido-compra-page';
import DocReferenciadosPage from 'views/pages/private/entrada/pages/doc-referenciados/doc-referenciados-page';
import DadosPagamentoPage from 'views/pages/private/entrada/pages/dados-pagamento/dados-pagamento-page';
import SituacaoEntradaPage from 'views/pages/private/entrada/pages/situacao-entrada/situacao-entrada-page';
import DadosProdutoPage from 'views/pages/private/entrada/pages/dados-produto/dados-produto-page';
import NovoProdutoEntradaPage from 'views/pages/private/entrada/pages/novo-produto/novo-produto-page';
import MovGerenciamentoSessoesPage from 'views/pages/private/movimentacao/mov-gerenciamento-sessoes/mov-gerenciamento-sessoes-page';
import ManifestosPage from 'views/pages/private/manifestos/manifestos-page';
import ImportacaoEntradaXMLPage from 'views/pages/private/entrada/pages/importacao-xml/importacao-entrada-xml-page';
import MarcaPage from 'views/pages/private/cadastros/marca/marca-page';
import { ComissaoPage } from 'views/pages/private/cadastros/comissao/comissao-page';
import ConvenioPage from 'views/pages/private/cadastros/convenio/convenio-page';
import { PromocaoPage } from 'views/pages/private/cadastros/promocao/promocao-page';
import ConvenioFaturaPage from 'views/pages/private/convenio-fatura/convenio-fatura-page';
import NotAllowedPage from 'views/pages/private/sem-permissao/sem-permissao-page';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import RelatoriosAvancadosPage from 'views/pages/private/relatorios/relatorios-avancados/relatorios-avancados-page';


interface RouteProtected {
  path: string;
  component: React.ComponentType<any>;
  roles: EnumCodigosPermissoes[]
  exact?: boolean;
  [x: string]: any
}

export const RouterMainList: Array<RouteProtected> = [
  // CONFIGURACOES
  {
    exact: false,
    path: RetaguardaRotasMock.configuracoesRota.path,
    component: ConfiguracoesPage,
    roles: RetaguardaRotasMock.configuracoesRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.permissoesRota.path,
    component: PerfisPage,
    roles: RetaguardaRotasMock.permissoesRota.roles
  },

  // RELATORIOS
  {
    exact: true,
    path: RetaguardaRotasMock.dashboardRota.path,
    component: DashboardPage,
    roles: RetaguardaRotasMock.dashboardRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.historicoRota.path,
    component: HistoricoVendaPage,
    roles: RetaguardaRotasMock.historicoRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.detalhesVendaRota.path,
    component: DetalhesVendaPage,
    roles: RetaguardaRotasMock.detalhesVendaRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.relatoriosAvancadosRota.path,
    component: RelatoriosAvancadosPage,
    roles: RetaguardaRotasMock.relatoriosAvancadosRota.roles
  },

  // CADASTROS
  {
    exact: false,
    path: RetaguardaRotasMock.impostosRota.path,
    component: ImpostosPage,
    roles: RetaguardaRotasMock.impostosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.categoriasRota.path,
    component: CategoriaPage,
    roles: RetaguardaRotasMock.categoriasRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.pdvRota.path,
    component: PontosVendaPage,
    roles: RetaguardaRotasMock.pdvRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.outrosCadastrosRota.path,
    component: OutrosCadastrosPage,
    roles: RetaguardaRotasMock.outrosCadastrosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.produtosRota.path,
    component: ProdutoPage,
    roles: RetaguardaRotasMock.produtosRota.roles,
    tipo: EnumTipoProduto.Produto
  },
  {
    exact: false,
    path: RetaguardaRotasMock.combosRota.path,
    component: ProdutoPage,
    roles: RetaguardaRotasMock.combosRota.roles,
    tipo: EnumTipoProduto.Combo
  },
  {
    exact: false,
    path: RetaguardaRotasMock.modificadoresRota.path,
    component: ModificadoresPage,
    roles: RetaguardaRotasMock.modificadoresRota.roles,
    tipo: EnumTipoProduto.Combo
  },
  {
    exact: false,
    path: RetaguardaRotasMock.insumosRota.path,
    component: ProdutoPage,
    roles: RetaguardaRotasMock.insumosRota.roles,
    tipo: EnumTipoProduto.Insumo
  },
  {
    exact: false,
    path: RetaguardaRotasMock.pessoasRota.path,
    component: PessoaPage,
    roles: RetaguardaRotasMock.pessoasRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.finalizadorasRota.path,
    component: FinalizadoraPage,
    roles: RetaguardaRotasMock.finalizadorasRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.comissoesRota.path,
    component: ComissaoPage,
    roles: RetaguardaRotasMock.comissoesRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.conveniosRota.path,
    component: ConvenioPage,
    roles: RetaguardaRotasMock.conveniosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.conveniosFaturasRota.path,
    component: ConvenioFaturaPage,
    roles: RetaguardaRotasMock.conveniosFaturasRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.promocoesRota.path,
    component: PromocaoPage,
    roles: RetaguardaRotasMock.promocoesRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.gerenciarMesaComandaRota.path,
    component: MesasComandasPage,
    roles: RetaguardaRotasMock.gerenciarMesaComandaRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.saloesRota.path,
    component: SaloesPage,
    roles: RetaguardaRotasMock.saloesRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.setoresRota.path,
    component: SetoresProcessosPage,
    roles: RetaguardaRotasMock.setoresRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.impressoraRota.path,
    component: ImpressorasPage,
    roles: RetaguardaRotasMock.impressoraRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.ncmsRota.path,
    component: NcmPage,
    roles: RetaguardaRotasMock.ncmsRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.marcasRota.path,
    component: MarcaPage,
    roles: RetaguardaRotasMock.marcasRota.roles,
  },

  // OUTROS
  {
    exact: false,
    path: RetaguardaRotasMock.alterarPlanoRota.path,
    component: AlterarPlanoPage,
    roles: RetaguardaRotasMock.alterarPlanoRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.depositosRota.path,
    component: DepositoPage,
    roles: RetaguardaRotasMock.depositosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.configuracoesEmpresaRota.path,
    component: ConfiguracoesEmpresaPage,
    roles: RetaguardaRotasMock.configuracoesEmpresaRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.adicionarEmpresaRota.path,
    component: AdicionarEmpresaPage,
    roles: RetaguardaRotasMock.adicionarEmpresaRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.documentosRota.path,
    component: DocumentoFiscalPage,
    roles: RetaguardaRotasMock.documentosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.ativarNFCERota.path,
    component: AtivarRevisarNFCE,
    roles: RetaguardaRotasMock.ativarNFCERota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.autenticacaoRota.path,
    component: AutenticacaoPage,
    roles: RetaguardaRotasMock.autenticacaoRota.roles,
  },

  // ENTRADA MERCADORIA
  {
    exact: true,
    path: RetaguardaRotasMock.entradaMercadoriasRota.path,
    component: EntradaPage,
    roles: RetaguardaRotasMock.entradaMercadoriasRota.roles,
  },
  {
    exact: true,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarRota.path,
    component: OpcoesEntradaPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarEntradaRota.path,
    component: DadosEntradaPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarEntradaRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarEmitenteRota.path,
    component: DadosEmitentePage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarEmitenteRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarTransportadoraRota.path,
    component: DadosTransportadoraPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarTransportadoraRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarDestinatarioRota.path,
    component: DadosDestinatarioPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarDestinatarioRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaInformacaoComplementarRota.path,
    component: InfoComplementaresPage,
    roles: RetaguardaRotasMock.entradaMercadoriaInformacaoComplementarRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaInfoPedidoCompraRota.path,
    component: InfoPedidoCompraPage,
    roles: RetaguardaRotasMock.entradaMercadoriaInfoPedidoCompraRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaDocReferenciaRota.path,
    component: DocReferenciadosPage,
    roles: RetaguardaRotasMock.entradaMercadoriaDocReferenciaRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaPagamentoRota.path,
    component: DadosPagamentoPage,
    roles: RetaguardaRotasMock.entradaMercadoriaPagamentoRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaSituacaoEntradaRota.path,
    component: SituacaoEntradaPage,
    roles: RetaguardaRotasMock.entradaMercadoriaSituacaoEntradaRota.roles,
  },
  {
    exact: true,
    path: RetaguardaRotasMock.entradaMercadoriaProdutosRota.path,
    component: DadosProdutoPage,
    roles: RetaguardaRotasMock.entradaMercadoriaProdutosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarProdutosRota.path,
    component: NovoProdutoEntradaPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarProdutosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.entradaMercadoriaVisualizarXML.path,
    component: ImportacaoEntradaXMLPage,
    roles: RetaguardaRotasMock.entradaMercadoriaVisualizarXML.roles,
  },

  {
    exact: false,
    path: RetaguardaRotasMock.gerenciamentoSessoesRota.path,
    component: MovGerenciamentoSessoesPage,
    roles: RetaguardaRotasMock.gerenciamentoSessoesRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.manifestosRota.path,
    component: ManifestosPage,
    roles: RetaguardaRotasMock.manifestosRota.roles,
  },
  {
    exact: false,
    path: RetaguardaRotasMock.listaEmpresasRota.path,
    component: ListaEmpresasPage,
    roles: RetaguardaRotasMock.listaEmpresasRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.uploadCargaDetalhadaRota.path,
    component: UploadCargaDetalhesPage,
    roles: RetaguardaRotasMock.uploadCargaDetalhadaRota.roles
  },
  {
    exact: false,
    path: RetaguardaRotasMock.uploadCargaRota.path,
    component: UploadCargaPage,
    roles: RetaguardaRotasMock.uploadCargaRota.roles
  },
  {
    exact: true,
    path: RetaguardaRotasMock.semPermissaoRota.path,
    component: NotAllowedPage,
    roles: RetaguardaRotasMock.semPermissaoRota.roles
  },
];