import { Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-transferir-item-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useHistory } from 'react-router-dom';
import { usePutPedidoProdutoTransferir } from 'data/api/gestao/pedido-produto/put-pedido-produto-transferir';
import { PedidoTransferirProduto } from 'model/api/gestao/pedido/pedido-transferir-produto';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { usePutPedidoProdutoAlterarVendedor } from 'data/api/gestao/pedido-produto/put-pedido-produto-alterar-vendedor';
import { PedidoAlterarVendedorProduto } from 'model/api/gestao/pedido/pedido-alterar-vendedor';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumComandas } from 'model/enums/enum-comandas';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { TabelaSaloes } from 'database/interfaces/interface-tabela-saloes';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';

interface DialogTransferirItemProps {
  close: () => void;
  aberto: boolean;
  pedido: PedidoModel;
  idsProdutos: Array<string>;
  vendedor: PessoaModel | null;
  dialogTransferirCam: (valor: boolean) => void;
}

export const DialogTransferirItem = ({
  close,
  aberto,
  pedido,
  idsProdutos,
  vendedor,
  dialogTransferirCam
}: DialogTransferirItemProps) => {
  // STATES E REFS
  const [cod, setCod] = useState('');
  const [saloesList, setSaloesList] = useState<TabelaSaloes[]>([]);
  const [salaoId, setSalaoId] = useState<string | undefined>(undefined);

  // CHAMADAS API
  const { putPedidoProdutoTransferir, carregando: PutTransferirProdutos } =
    usePutPedidoProdutoTransferir();
  const {
    putPedidoProdutoAlterarVendedor,
    carregando: PutAlterarVendedorProdutos
  } = usePutPedidoProdutoAlterarVendedor();

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();

  // AUX
  const classesComponent = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const submitRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const carregando = PutTransferirProdutos || PutAlterarVendedorProdutos;

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    const getSaloes = async () => {
      try {
        const res = await TouchoneDBPrimary.saloes.toArray();

        const saloes = res.filter(x => x.status?.codigo === EnumStatusSalao.ATIVO)

        if (saloes.length > 1) {
          setSaloesList(saloes);
        } else if (saloes.length === 1) {
          setSaloesList(saloes)
          setSalaoId(saloes[0].id ?? undefined);
        }
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível buscar os salões, Detalhes: ${e.message}`
        );
      }
    };

    getSaloes();
  }, [showToast]);

  const handleClickTransferirItens = useCallback(
    async (
      codigo: string,
      idsProdutos: string[],
      pedido: PedidoModel,
      salaoId?: string
    ) => {
      try {
        let comanda = null;
        let mesa = null;

        // valido o tipo de trabalho
        if (
          getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          mesa = await TouchoneDBPrimary.mesas.get({
            codigo: codigo,
            salaoId: salaoId
          });

          if (!mesa) {
            throw new Error(`Mesa com o código ${codigo} não encontrada.`);
          }

          if (mesa && mesa.status?.codigo !== EnumMesas.ATIVO) {
            throw new Error(
              `A mesa com o código ${codigo} está com status de INATIVA.`
            );
          }
        } else {
          comanda = await TouchoneDBPrimary.comandas.get({
            codigoComanda: codigo
          });

          if (!comanda) {
            throw new Error(`Comanda com o código ${codigo} não encontrada.`);
          }

          if (comanda && comanda?.status?.codigo !== EnumComandas.ATIVO) {
            throw new Error(
              `Comanda com o código ${codigo} está com status de inativo.`
            );
          }
        }

        const produtosParaTransferencia = new PedidoTransferirProduto();
        produtosParaTransferencia.produtosIds = idsProdutos;

        if (
          getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          produtosParaTransferencia.destino.mesaId = mesa!.id;
        } else {
          produtosParaTransferencia.destino.comandaId = comanda!.id;
        }

        const respostaPut = await putPedidoProdutoTransferir(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          produtosParaTransferencia
        );
        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast(
          'success',
          `Os produtos selecionados foram transferidos para a ${getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.MESA
            ? 'mesa'
            : 'comanda'
          } com o código: ${codigo} com sucesso.`
        );

        close()
        history.goBack()
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [close, getConfiguracoesMesaEComanda, getEmpresaSelecionada, history, putPedidoProdutoTransferir, showToast]
  );

  const handleClickAlterarVendedor = useCallback(async () => {
    try {
      if (!vendedor) {
        throw new Error('O vendedor não foi identificado.');
      }

      const produtosAlterarVendedor = new PedidoAlterarVendedorProduto(
        idsProdutos,
        vendedor.id,
        vendedor.nome
      );

      const respostaPut = await putPedidoProdutoAlterarVendedor(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        produtosAlterarVendedor
      );

      if (respostaPut.erro) {
        throw new Error(respostaPut.erro);
      }

      showToast(
        'success',
        `Os produtos selecionados foram transferidos para o ${vendedor.nome} com sucesso.`
      );

      if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA) {
        history.push(`/venda-simples/visualizar-pedido/${pedido.mesaId}`);
        return
      }
      close()
      history.push(`/venda-simples/visualizar-pedido/${pedido.comandaId}`);

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [vendedor, idsProdutos, putPedidoProdutoAlterarVendedor, getEmpresaSelecionada, pedido.id, pedido.comandaId, pedido.mesaId, showToast, getConfiguracoesMesaEComanda, close, history]);

  const buttonClick = useCallback(async () => {
    if (vendedor) {
      await handleClickAlterarVendedor();
      return
    } else {
      await handleClickTransferirItens(cod, idsProdutos, pedido, salaoId);
    }
  }, [vendedor, handleClickAlterarVendedor, handleClickTransferirItens, cod, idsProdutos, pedido, salaoId]);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      await buttonClick();
    },
    [buttonClick]
  );

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo={vendedor ? `Alterar Vendedor` : `Transferir Itens`}
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        {!vendedor && (
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: theme.spacing(2),
              fontWeight: '600',
              fontSize: '20px',
              color: theme.palette.grey[400]
            }}
          >
            {`Digite o código da ${getConfiguracoesMesaEComanda()?.tipoTrabalho ===
              EnumTipoTrabalho.MESA
              ? 'mesa'
              : 'comanda'
              } para qual deseja transferir os itens.`}
          </Typography>
        )}

        {vendedor && (
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: theme.spacing(2),
              fontWeight: 500,
              fontSize: '16px',
              color: theme.palette.grey[400]
            }}
          >
            Ao clicar em confirmar, o vendedor:{' '}
            <strong style={{ color: '#666666' }}>{vendedor.nome}</strong> será
            relacionado aos produtos selecionados.
          </Typography>
        )}
        <Grid className={classesComponent.cardContent}>
          <form onSubmit={handleSubmit}>
            {!vendedor && (
              <>
                {getConfiguracoesMesaEComanda()?.tipoTrabalho ===
                  EnumTipoTrabalho.MESA && saloesList.length > 1 && (
                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <SelectSaurus
                        label="Selecione o Salão"
                        conteudo={saloesList.map((valor: any) => {
                          return new KeyValueModel(
                            valor.id.toString(),
                            valor.descricao
                          );
                        })}
                        fullWidth={true}
                        value={salaoId}
                        variant="outlined"
                        disabled={saloesList.length <= 1}
                        onChange={(event) => {
                          if (event) {
                            setSalaoId(event.target.value);
                          }
                        }}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={carregando}
                    fullWidth
                    variant="outlined"
                    allowSubmit
                    label="Código"
                    InputLabelProps={{
                      shrink: true
                    }}
                    endAdornmentButton={
                      <BarcodeScanIcon
                        tipo="INPUT"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          dialogTransferirCam(true);
                          onCloseClick();
                        }}
                      />
                    }
                    placeholder={'EX: 1'}
                    value={cod}
                    onChange={(e) => setCod(e.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid container className={classesComponent.acoes} spacing={2}>
              <Grid item xs={5}>
                <Button
                  disabled={carregando}
                  onClick={onCloseClick}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  disabled={
                    carregando ||
                    (!vendedor && getConfiguracoesMesaEComanda()?.tipoTrabalho ===
                      EnumTipoTrabalho.MESA &&
                      !salaoId)
                  }
                  variant="contained"
                  ref={submitRef}
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
