import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerFooter: {
    display: 'flex',
  },
  unselectable: {
    "-webkit-touch-callout": 'none',
    "-webkit-user-select": 'none',
    "-khtml-user-select": 'none',
    "-moz-user-select": 'none',
    "-ms-user-select": 'none',
    "user-select": 'none',
  },
  containerTela: {
    minHeight: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentTela: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerItemFooter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  textPreview: {
    color: '#fff',
    background: theme.palette.primary.main,
    fontSize: '16px',
    lineHeight: '31px',
    fontWeight: 600,
    borderRadius: '4px',
    height: '57px'
  },
  containerSomatorio: {
    with: '100%',
    padding: '0px 10px',
    fontSize: 13,
  },
}));