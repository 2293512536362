import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useStyles } from './form-pesquisa-header-styles';
import { useRef } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useThemeQueries } from 'views/theme';
import { HeaderTextField } from '../header-text-field/header-text-field';

export interface PessoaPesquisaGenericoFormModel {
  generico: string;
}

interface FormPesquisaHeaderProps {
  onSubmit: (model: PessoaPesquisaGenericoFormModel) => void;
  text: string;
  placeholder?: string;
  closeSearch?: () => void;
  forceMobile?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const FormPesquisaHeader = ({
  onSubmit,
  text,
  placeholder = 'Digite aqui...',
  closeSearch,
  forceMobile,
  inputRef
}: FormPesquisaHeaderProps) => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const inputRefInterno = useRef<HTMLInputElement>(null);

  const { handleSubmit, control } = useForm<PessoaPesquisaGenericoFormModel>({
    defaultValues: {
      generico: text
    },
    criteriaMode: 'all',
    mode: 'onChange'
  });

  return (
    <>
      <Box className={classNames(classes.container, !(forceMobile || isMobile) && classes.containerDesktop)}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmit(onSubmit)();
          }}
        >
          <Controller
            name="generico"
            control={control}
            render={({ field }) => (
              <HeaderTextField
                placeholder={placeholder}
                forceMobile={forceMobile}
                inputRef={inputRef ?? inputRefInterno}
                searchable
                onSearch={async () => {
                  if (isEmpty(field.value)) {
                    await handleSubmit(onSubmit)();
                    closeSearch && closeSearch();
                    return;
                  }
                  await handleSubmit(onSubmit)();
                }}
                {...field}
              >
              </HeaderTextField>
            )}
          />
        </form>
      </Box>
    </>
  );
};
