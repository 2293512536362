import { useStyles } from './menu-principal-perfil-styles';
import { Grid, Tooltip, Typography } from 'views/design-system';
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { MenuPrincipalPerfilProps } from './menu-principal-perfil-props';
import { PermissaoIcon } from '../../../../../../components/icons/permissao-icon';
import { useCallback, useState, useEffect } from 'react';
import UsuarioSemImagem from 'assets/img/usuario-sem-imagem.png';
import {
  EditarIcon,
  MedalhaFillIcon,
} from '../../../../../../components/icons';
import { useHistory } from 'react-router-dom';
import { ModulosRetaguardaMock } from 'data/mocks';
import { EnumMenu, KeyValueModel } from 'model';
import classNames from 'classnames';
import { PlanoCorMock } from 'data/mocks/plano-cor-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { isEmpty } from 'lodash';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { useImagemWhitelabel } from 'services/app/hooks/imagem-whitelabel';
import { consoleDev } from 'utils/console-dev';
import { useMovRota } from 'services/app/hooks/mov-rota';

export const MenuPrincipalPerfil = (props: MenuPrincipalPerfilProps) => {
  const classes = useStyles();
  const { addHandler, removeHandler } = useEventTools()
  const {
    usuario,
    getEmpresaSelecionada,
    getPermissaoBoolean,
    selecionarEmpresa,
    plano
  } = useSessaoAtual();
  const { imagemWhitelabel } = useImagemWhitelabel();
  const { cancelarMov, getMov } = useMovAtual();
  const confirm = useConfirm();
  const [imagemAtual, setImagemAtual] = useState(UsuarioSemImagem);
  const nomeUsuario = usuario?.saudacao || 'Visitante';
  const perfilUsuario = getEmpresaSelecionada()?.Perfil.Nome || 'Administrador';
  const permConfig = getPermissaoBoolean(5001);
  const { push } = useHistory();
  const { fecharMenu } = useMenuPrincipal();
  const { redirectLanding } = useMovRota();
  const [empresaAtual, setEmpresaAtual] = useState<string>(
    getEmpresaSelecionada()?.Id || '',
  );
  const { showToast } = useToastSaurus();
  const { getUltimaSincronizacao, limparCadastros } = useSincronizacaoCadastros();

  consoleDev('MenuPrincipalPerfil')

  useEffect(() => {
    addHandler(AppEventEnum.EmpresaSelecionada, setEmpresaAtual)

    return () => removeHandler(AppEventEnum.EmpresaSelecionada, setEmpresaAtual)
  }, [addHandler, removeHandler])

  useEffect(() => {
    let imagem = '';
    try {
      if (!isEmpty(usuario?.imagem)) {

        if ((usuario?.imagem || '').indexOf('http') > -1) {
          imagem = usuario?.imagem!;
        } else {
          imagem = (usuario?.imagem || '').length > 0 ? atob(usuario?.imagem || '') : '';
        }
      } else if (!isEmpty(imagemWhitelabel)) {
        if ((imagemWhitelabel || '').indexOf('http') > -1) {
          imagem = imagemWhitelabel!;
        } else {
          imagem = (imagemWhitelabel || '').length > 0 ? atob(imagemWhitelabel || '') : '';
        }
      }
    } catch { }
    setImagemAtual(imagem);
  }, [getEmpresaSelecionada, imagemWhitelabel, usuario]);

  const addDefaultSrc = useCallback((e: any) => {
    setImagemAtual(UsuarioSemImagem);
  }, []);

  const editarPerfil = React.useCallback(() => {
    const config = ModulosRetaguardaMock.filter(
      (x) => x.codigo === EnumMenu.ADMINISTRATIVO_CONFIGURACOES,
    )[0];
    if (props.isMobile) {
      fecharMenu();
    }
    push(config.rota);
  }, [fecharMenu, props.isMobile, push]);

  const EmpresaListMock = React.useMemo(() => {
    return usuario!.empresa.map((item) => {
      return new KeyValueModel(item.Id, item.Descricao);
    });
  }, [usuario]);

  interface propsColor {
    id: string;
    cor: string;
  }

  const filterColor = React.useCallback(
    (item: propsColor) => {
      if (item.id === plano?.plano?.id) return item.cor;
    },
    [plano?.plano?.id],
  );

  const handleEmpresa = React.useCallback(
    async (id: string) => {
      if (getMov()) {
        confirm({
          title: 'Venda em Andamento',
          description:
            'Identificamos uma venda em Andamento. Caso selecione a empresa, a venda será descartada e será necessario uma nova sincronização para que uma nova venda possa ser aberta. Deseja selecionar?',
          cancellationText: 'Voltar',
          cancellationButtonProps: {
            color: 'default',
          },
          confirmationText: 'Selecionar',
        }).then(async () => {
          await cancelarMov('');
          await limparCadastros();
          await selecionarEmpresa(id);
          setEmpresaAtual(id);
          push('/dashboard');
          return;
        });
      } else if (getUltimaSincronizacao()?.dataSucesso) {
        confirm({
          title: 'Sincronização já realizada',
          description:
            'Identificamos que você já possui uma sincronização realizada. Caso selecione a empresa, a sincronização será descartada e será necessario uma nova sincronização para que possa ser possivél iniciar uma nova venda. Deseja selecionar?',
          cancellationText: 'Voltar',
          cancellationButtonProps: {
            color: 'default',
          },
          confirmationText: 'Selecionar',
        }).then(async () => {
          try {
            await limparCadastros();
            setEmpresaAtual(id);
            await selecionarEmpresa(id);
            await redirectLanding();
          } catch (e: any) {
            showToast('error', e.message);
          }
        });
      } else {
        setEmpresaAtual(id);
        await selecionarEmpresa(id);
        push('/dashboard');
        return;
      }
    },
    [cancelarMov, confirm, getMov, getUltimaSincronizacao, limparCadastros, push, redirectLanding, selecionarEmpresa, showToast],
  );

  const component = React.useMemo(() => {
    return (
      <>
        <div
          className={classNames(
            classes.drawerResumoPerfilContainer,
            props.modeloAtual === 'Completo' && !props.isMobile
              ? !props.tiraMarginDrawer
                ? classes.drawerPerfilCompletoNotMobile
                : undefined
              : undefined,
            props.modeloAtual === 'Mini' ? classes.drawerPerfilMini : undefined,
          )}
        >
          <div className="fotoPerfilContainer">
            {imagemAtual !== UsuarioSemImagem && (
              <img src={imagemAtual} onError={addDefaultSrc} alt="" />
            )}
            {plano?.plano?.id !== '' && plano?.plano.nome.toLowerCase() !== 'avulso' && (
              <div className={classes.usuarioPlano}>
                <MedalhaFillIcon
                  tipo="PRIVATE_HEADER"
                  fill={PlanoCorMock.find(filterColor)?.cor || props.theme.palette.text.primary}
                />
              </div>
            )}
            {imagemAtual === UsuarioSemImagem && (
              <img src={imagemAtual} alt="" />
            )}
            {permConfig && (
              <div className="editarPerfil" onClick={editarPerfil}>
                <EditarIcon tipo="GERAL" />
                Editar Perfil
              </div>
            )}
          </div>
          {props.modeloAtual === 'Completo' && (
            <div className="nomeContainer">
              <div className={classes.containerNomeEPlano}>
                <Tooltip arrow title={'E-mail: ' + usuario?.email}>
                  <Typography className={classes.usuarioNome}>
                    {nomeUsuario}
                  </Typography>
                </Tooltip>
              </div>
              <Typography className={classes.usuarioPerfil}>
                <PermissaoIcon
                  tipo="GERAL"
                  fill={props.theme.palette.secondary.main}
                />
                {perfilUsuario}
              </Typography>
              {usuario!.empresa.length > 1 && (
                <Grid item xs={12}>
                  <SelectSaurus
                    allowSubmit
                    size="small"
                    conteudo={EmpresaListMock}
                    variant="standard"
                    className={classes.selectEmpresa}
                    onChange={(event) => {
                      if (event) {
                        const newStatus = EmpresaListMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        handleEmpresa(newStatus);
                      }
                    }}
                    value={empresaAtual}
                  />
                </Grid>
              )}
            </div>
          )}
        </div>
      </>
    );
  }, [
    EmpresaListMock,
    addDefaultSrc,
    classes.containerNomeEPlano,
    classes.drawerPerfilCompletoNotMobile,
    classes.drawerPerfilMini,
    classes.drawerResumoPerfilContainer,
    classes.selectEmpresa,
    classes.usuarioNome,
    classes.usuarioPerfil,
    classes.usuarioPlano,
    editarPerfil,
    empresaAtual,
    filterColor,
    handleEmpresa,
    imagemAtual,
    nomeUsuario,
    perfilUsuario,
    permConfig,
    plano?.plano?.id,
    props.isMobile,
    props.modeloAtual,
    props.tiraMarginDrawer,
    props.theme.palette.secondary.main,
    props.theme.palette.text.primary,
    usuario,
    plano?.plano?.nome
  ]);

  return component;
};
