import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { useState, useImperativeHandle, forwardRef, useCallback } from 'react';
import { CircularLoading, makeUtilClasses } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ConfigEmpresaModel } from 'model/api/gestao/config-empresa/config-empresa-model';
import { KeyValueModel } from 'model';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useStyles } from './form-config-empresa-edit-styles'
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormConfigEmpresaEditValidation } from './form-config-empresa-edit-validation';
import { toDecimal } from 'utils/to-decimal';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { stringNumeros } from 'utils/string-numeros';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumTipoFinalizacaoPDV } from 'model/enums/enum-tipo-finalizacao-pdv';
import { useConfirmSaurus } from 'services/app/hooks/confirm-saurus';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTipoFieldEmpresa } from 'model/enums/enum-tipo-field-empresa';
export interface FormConfigEditProps
  extends DefaultFormProps<ConfigEmpresaModel[]> {
  setCarregandoExterno(carregando: boolean): void;
}

interface Configs {
  configs: Array<ConfigEmpresaModel>
}

interface Erros {
  cod: number
  message: string
}

export const FormConfigEmpresaEdit = forwardRef<
  DefaultFormRefs<ConfigEmpresaModel[]>,
  FormConfigEditProps
>((props: FormConfigEditProps, refs) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles()

  const [model, setModel] = useState<Configs>({ configs: new Array<ConfigEmpresaModel>() },);
  const [error, setError] = useState<Erros[]>([])
  const [, setAtt] = useState<boolean>(false)
  const { getEmpresaAtual } = useEmpresaAtual();
  const { showConfirm } = useConfirmSaurus();
  const { callEvent } = useEventTools();

  const { FormConfigEmpresaEditValidationYup } = useFormConfigEmpresaEditValidation()

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValues
  } = useForm<Configs>({
    resolver: yupResolver(FormConfigEmpresaEditValidationYup),
    defaultValues: { ...model },
  });

  const { fields } = useFieldArray({
    control,
    name: "configs"
  });

  const fieldsSerializados = fields.map((fields, index) => {
    return {
      ...fields,
      indexOriginal: index,
    };
  }).filter(config => config.cod !== 1);

  const onSubmit = async (form: Configs) => {

    let erros: Erros[] = []

    const taxaRecomendada = toDecimal(form.configs.find(x => x.cod === 800)?.vConfig || '0')
    const taxaMinima = toDecimal(form.configs.find(x => x.cod === 830)?.vConfig || '0')
    const taxaMaxima = toDecimal(form.configs.find(x => x.cod === 831)?.vConfig || '0')

    if (taxaMinima > taxaMaxima) {
      erros.push({ cod: 830, message: 'A Taxa de Serviço Mínima não pode ser maior que a Máxima.' })
    }
    if (taxaMaxima < taxaMinima) {
      erros.push({ cod: 831, message: 'A Taxa de Serviço Máxima não pode ser menor que a Mínima.' })
    }

    if (taxaRecomendada < taxaMinima || taxaRecomendada > taxaMaxima) {
      erros.push({ cod: 800, message: 'A Taxa de Serviço Recomendada deve estar entre a Mínima e a Máxima.' })
    }

    const vendaOnlineConfigPDV = form.configs.find(config => config.cod === EnumEmpresaConfig.TipoFinalizacaoVendaPDV && config.vConfig !== EnumTipoFinalizacaoPDV.OFFLINESEMPREQUEPOSSIVEL)

    if (vendaOnlineConfigPDV &&
      !getEmpresaAtual()?.uf
    ) {
      const msgErro = `Para realizar vendas online é necessário informar a UF da empresa.`
      erros.push({ cod: EnumEmpresaConfig.TipoFinalizacaoVendaPDV, message: msgErro })
      showConfirm({
        title: `Configuração ${vendaOnlineConfigPDV.titulo}`,
        description: msgErro + ' Deseja configurar a UF na sua empresa?',
        primaryButtonText: 'Configurar Empresa',
        secondaryButtonText: 'Cancelar',
      }).then(() => {
        callEvent(AppEventEnum.AbrirConfiguracoesEmpresa, { focusField: 'uf' });
      });
    }

    if (erros.length > 0) {
      setError(erros)
      return;
    }

    setError([])

    props.onSubmit(form.configs);
  };

  useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ configs: new Array<ConfigEmpresaModel>() });
      reset();
    },
    fillForm: (model: ConfigEmpresaModel[]) => {
      setModel({ configs: model });
      reset({ configs: model });
    },
  }));


  const unique = Array.from(
    new Set<string>(model.configs.map((item) => item.grupoConfig)),
  );

  const verificarCod = useCallback((cod: number) => {
    return [326, 910, 814, 8001].includes(cod)
  }, [])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${props.loading ? utilClasses.controlLoading : ''}`}
        >
          {unique
            .sort(function (a, b) {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            }).map(grupo => {
              return (
                <div className={classes.root}>
                  <AccordionSaurus
                    labelPrimary={grupo && grupo.substr ? grupo.substr(3) : ''}
                    showDivider
                    noPaperRoot
                    noPaddingContent
                  >
                    <Grid container spacing={2}>
                      {fieldsSerializados.filter((x) => x.grupoConfig === grupo)
                        .filter((x) => ![8021].includes(x.cod))
                        .sort(function (a, b) {
                          if (a.ordem < b.ordem) {
                            return -10;
                          }
                          if (a.ordem > b.ordem) {
                            return 10;
                          }
                          if (a.descConfig < b.descConfig) {
                            return -1;
                          }
                          if (a.descConfig > b.descConfig) {
                            return 1;
                          }
                          return 0;
                        }).map((config, index) => {
                          if (config.opcoes) {
                            let items: any[] = config.opcoes.opcoesValores ? config.opcoes.opcoesValores : (config.opcoes.opcoesTexto || [])
                            if (
                              (items || []).length === 0 &&
                              config.opcoes.intervalo && config.opcoes.intervalo.maximo !== config.opcoes.intervalo.minimo
                            ) {
                              for (let i = config.opcoes.intervalo.minimo; i <= config.opcoes.intervalo.maximo; i++) {
                                items.push(i.toString());
                              }
                            }
                            const valorAtual = getValues(`configs.${config.indexOriginal}.vConfig`)

                            let mock: Array<KeyValueModel> = []

                            if (config.tpControle === 5 && config.opcoes?.opcoesTexto && config.opcoes?.opcoesValores) {
                              const textos = config.opcoes?.opcoesTexto
                              const valores = config.opcoes?.opcoesValores
                              mock = textos.map((txt, index) => new KeyValueModel(valores[index], txt))

                            } else {
                              mock = items.map(
                                (valor, iSplit) => {
                                  if (config.cod === EnumEmpresaConfig.MaximoKM) {
                                    return new KeyValueModel(
                                      (Number(stringNumeros(valor))).toString(),
                                      valor,
                                    );
                                  }
                                  //VALUE DO DROP É INTEIRO
                                  if (config.opcoes && config.opcoes.intervalo && config.opcoes.intervalo.maximo !== config.opcoes?.intervalo.minimo) {
                                    return new KeyValueModel(
                                      (iSplit + config.opcoes.intervalo.minimo).toString(),
                                      valor,
                                    );
                                  } else {
                                    return new KeyValueModel(valor, valor);
                                  }
                                },
                              );
                            }
                            if (config.tpControle === 5 && config.opcoes?.opcoesTexto && config.opcoes?.opcoesValores) {
                              return (
                                <Grid key={`gridConfig${index}`} item xs={12}>
                                  <Controller
                                    name={`configs.${config.indexOriginal}.vConfig`}
                                    control={control}
                                    render={({ field }) => (
                                      <FormControl variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel
                                          id={"simple-select-outlined-label-" + index}
                                        >
                                          {config.titulo}
                                        </InputLabel>
                                        <Select
                                          multiple
                                          variant="outlined"
                                          id={"simple-select-outlined-label" + index}
                                          label={config.titulo}
                                          fullWidth
                                          {...field}
                                          inputProps={{
                                            name: field.name,
                                            id: "simple-select-outlined-label-" + index,
                                          }}
                                          value={mock.filter(x => x.Key & Number(field.value)).map(x => x.Key)}
                                          onChange={(event: any) => {
                                            const item = event.target.value as number[]
                                            setValue(`configs.${config.indexOriginal}.vConfig`, item.reduce((a: any, b: any) => a + b, 0))
                                            setValue(`configs.${config.indexOriginal}.editado`, true)
                                            setAtt(prev => !prev)
                                          }}
                                          renderValue={(selected) => {
                                            return mock.filter(x => (selected as any[]).includes(x.Key)).map(x => x.Value).join(', ')
                                          }}
                                        // MenuProps={MenuProps}
                                        >
                                          {mock.map((name) => (
                                            <MenuItem key={name.Key} value={name.Key}>
                                              <Checkbox checked={Boolean(name.Key & Number(valorAtual))} />
                                              <ListItemText primary={name.Value} />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                              )
                            }

                            return (
                              <Grid key={`gridConfig${index}`} item xs={12}>
                                <Controller
                                  name={`configs.${config.indexOriginal}.vConfig`}
                                  control={control}
                                  render={({ field }) => (
                                    <SelectSaurus
                                      fullWidth
                                      variant="outlined"
                                      label={config.titulo}
                                      disabled={props.loading || verificarCod(config.cod)}
                                      key={index}
                                      conteudo={mock}
                                      {...field}
                                      onChange={(item) => {
                                        const value = mock.filter(opt => opt.Key === item.target.value.trim())[0]
                                        setValue(`configs.${config.indexOriginal}.vConfig`, value.Key)
                                        setValue(`configs.${config.indexOriginal}.editado`, true)
                                        setAtt(prev => !prev)
                                      }}
                                      error={error.filter(x => x.cod === config.cod).length > 0}
                                      helperText={error.filter(x => x.cod === config.cod).length > 0
                                        ? error.find(x => x.cod === config.cod)?.message : undefined}
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          } else if (!config.opcoes) {
                            return (
                              <Grid key={`gridConfig${index}`} item xs={12}>
                                <Controller
                                  name={`configs.${config.indexOriginal}.vConfig`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextFieldSaurus
                                      tipo={config.tpControle === EnumTipoFieldEmpresa.Numero ? 'NUMERO' : "TEXTO"}
                                      disabled={props.loading}
                                      readOnly={verificarCod(config.cod)}
                                      key={config.indexOriginal}
                                      fullWidth
                                      variant="outlined"
                                      label={config.titulo}
                                      {...field}
                                      onChange={(item) => {
                                        setValue(`configs.${config.indexOriginal}.vConfig`, item.target.value.trim())
                                        setValue(`configs.${config.indexOriginal}.editado`, true)
                                      }}
                                      error={error.filter(x => x.cod === config.cod).length > 0}
                                      helperText={error.filter(x => x.cod === config.cod).length > 0
                                        ? error.find(x => x.cod === config.cod)?.message : undefined}
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          } else {
                            return <></>;
                          }
                        })
                      }
                    </Grid>
                  </AccordionSaurus>
                </div>
              )
            })
          }
          < Button style={{ display: 'none' }} type="submit" />
        </form>
      </div>
    </>
  );
});
