import classNames from 'classnames';
import { DefaultCardProps } from './default-card-props';
import { useDefaultCardStyles } from './default-card-styles';
import { handleClickFeedbackTatil } from 'utils/feedback-tatil-fn';

export const DefaultCard = ({
  onClick,
  onCheck,
  isSelected,
  children,
  disabled,
  className,
  hasTagStatus,
  notClickable,
  cardRef,
}: DefaultCardProps) => {
  const classes = useDefaultCardStyles();

  return (
    <>
      <div
        ref={cardRef}
        onClick={(event) => {
          if (onClick) {
            handleClickFeedbackTatil();
            onClick(event);
          }
        }}
        className={classNames(
          classes.cardContainer,
          'card',
          hasTagStatus === false ? classes.cardWithoutTag : undefined,
          isSelected ? classes.cardSelected : undefined,
          disabled ? classes.cardinativo : undefined,
          !notClickable ? classes.pointer : undefined,
          className,
        )}
      >
        {(hasTagStatus === true || hasTagStatus === undefined) && (
          <div className={classes.tagStatus} style={{ background: "#D9D9D9" }}></div>
        )}
        {children}
      </div>
    </>
  );
};
