import { useCallback, useEffect } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { ConvenioHeader } from './components/convenio-header/convenio-header';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCadastros } from 'services/app';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { ConvenioList } from './components/convenio-list/convenio-list';

const ConvenioPage = () => {
  const classes = useDefaultCadastroStyles();
  const { location } = useHistory();
  const { abrirCadastroConvenio } = useCadastros();
  const { callTour } = useTourSaurus()

  useEffect(() => {
    callTour(EnumTour.CADASTROPESSOAS)
  }, [callTour])

  const onClickAdd = useCallback(() => {
    abrirCadastroConvenio({ id: '' }, location.pathname, true)
  }, [abrirCadastroConvenio, location.pathname]);


  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <ConvenioHeader />
        </Grid>
        <Grid className={classes.list}>
          <ConvenioList />
        </Grid>
        <ButtonFab
          id='tour-cadConvenio'
          tooltip="Adicionar Cartão"
          icon={<NovoIcon tipo="BUTTON_FAB" />}
          onClick={onClickAdd}
        />
      </Grid>
    </>
  );
};

export default ConvenioPage;
