import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { makeUtilClasses } from '../../../../theme';
import { picker } from '../../../../../utils/picker';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import { TextFieldSaurus } from '../../../controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormSaloesEditValidation } from './form-salao-edit-validation';
import { SaloesEditFormModel } from 'model/app/forms/saloes/saloes-edit-form-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpBalcaoSalaoMock } from 'data/mocks/tp-balcao-salao-mock';

export const FormSaloesEdit = forwardRef<
  DefaultFormRefs<SaloesEditFormModel>,
  DefaultFormProps<SaloesEditFormModel>
>(({ loading, ...props }: DefaultFormProps<SaloesEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNumSalao = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<SaloesEditFormModel>(
    new SaloesEditFormModel(),
  );
  const { FormSaloesEditYupValidation } = useFormSaloesEditValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    getValues,
  } = useForm<SaloesEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormSaloesEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: SaloesEditFormModel) => {
    const salao = picker<SaloesEditFormModel>(
      values,
      new SaloesEditFormModel(),
    );
    props.onSubmit({ ...salao }, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...modelForm });
      refInputNumSalao.current?.focus();
    },
    fillForm: (model: SaloesEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      setTimeout(() => {
        refInputNumSalao.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'TEXTO'}
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição do Salão (Informativo)"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: Salão primeiro andar"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <Controller
                  name="balcao"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Balcao"
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={TpBalcaoSalaoMock}
                      error={Boolean(errors.balcao && errors.balcao.message)}
                      helperText={
                        touchedFields.balcao || errors.balcao
                          ? errors.balcao?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpBalcaoSalaoMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('balcao', item);
                        }
                      }}
                      value={getValues('balcao')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
