import { useDefaultCadastroStyles } from "../cadastros/components/default-cadastro-styles";
import { Grid } from "@material-ui/core";
import { ConvenioFaturaList } from "./components/convenio-fatura-list/convenio-fatura-list";
import { ConvenioFaturaHeader } from "./components/convenio-fatura-header/convenio-fatura-header";
import { useStyles } from "./convenio-fatura-styles";
import classNames from "classnames";

const ConvenioFaturaPage = () => {
  const defaultCadastrosClasses = useDefaultCadastroStyles();
  const classes = useStyles()

  return (
    <Grid className={defaultCadastrosClasses.root}>
      <Grid className={defaultCadastrosClasses.header}>
        <ConvenioFaturaHeader
        />
      </Grid>
      <Grid className={classNames(defaultCadastrosClasses.list, classes.list)}>
        <ConvenioFaturaList />
      </Grid>
    </Grid>
  );
};

export default ConvenioFaturaPage