import { Box, Card, Divider, Grid, Typography } from "@material-ui/core"
import { CardProdutosDetalheVendaProps } from "./card-produtos-detalhe-venda-props"
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";

export const CardProdutosDetalheVenda = (props: CardProdutosDetalheVendaProps) => {

    const { theme } = useThemeQueries();

    const order = props.index + 1;
    const model = props.model;
    const valorTotalProduto = ((model.vUnCom * model.qCom) - model.vDesc) + model.vOutro + model.vOutroEmbutido
    return (
        <>
            <Card style={{ boxShadow: theme.shadows[1] }}>
                <Grid container spacing={1} style={{ padding: '8px' }}>
                    <Grid item xs={6} md={2}>
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Ordem
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            <Box fontWeight={500}>
                                {`Nº ${order}`}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} >
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Código
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            <Box fontWeight={500}>
                                {model.cProd}
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Nome
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {model.xProd}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Qtd
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {model.uCom ? `${model.qCom} ${model.uCom}` : model.qCom}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Valor Unitário
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {`R$ ${toDecimalString(model.vUnCom)}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Desconto
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {model.vDesc > 0 ? `R$ ${toDecimalString(model.vDesc)}` : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} md={4} >
                        <Typography
                            variant="caption"
                            color="textPrimary"
                        >
                            Acréscimo
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {model.vOutro > 0 ? `R$ ${toDecimalString(model.vOutro)}` : model.vOutroEmbutido > 0 ?
                                `R$ ${toDecimalString(model.vOutroEmbutido)}` : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            <Box fontWeight={500}>
                                {`Valor Total: R$ ${toDecimalString(valorTotalProduto)}`}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}