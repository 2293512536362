import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuOptionsProps } from './menu-options-props';
import { useThemeQueries } from 'views/theme';
import { Box, Divider, Tooltip, Typography } from '@material-ui/core';
import { useMenuOptionsStyles } from './menu-options-styles';

export const MenuOptions = ({
  options,
  headerPage = false,
  disable,
  labels,
  divider,
  size = 'medium'
}: MenuOptionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { isMobile } = useThemeQueries();
  const classes = useMenuOptionsStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const wrapperClick = (click: () => any) => {
    click();
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick} className={size === 'small' ? classes.menuIconSmall : classes.menuIcon}>
        <MoreVertIcon
          style={{
            color: !headerPage ? '#FFFFFF' : isMobile ? '#FFFFFF' : '#666', 
          }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option, index) => {
          const key = `menu-options__${index}`;
          if (option) {
            return (
              <Tooltip
                key={key}
                title={option.tooltip ? option.tooltip : ''}
                disableHoverListener={!option.tooltip}
                disableTouchListener={!option.tooltip}
              >
                <>
                  {labels && labels[index] ? (
                    <Box mx={1}>
                      <Typography variant="caption" color="textSecondary">
                        {labels[index]}
                      </Typography>
                      <Divider className={classes.divider} />
                    </Box>
                  ) : divider && index > 0 ? (
                    <Box mx={2}>
                      <hr className={classes.divider} />
                    </Box>
                  ) : null}
                  <MenuItem
                    key={index}
                    onClick={() => wrapperClick(option.click!)}
                    disabled={disable || option.disabled}
                    style={{
                      height: size === 'small' ? 40 : size === 'large' ? 60 : 50
                    }}
                  >
                    {option.icon}
                    {option?.label}
                  </MenuItem>
                </>
              </Tooltip>
            );
          }

          return null;
        })}
      </Menu>
    </div>
  );
};
