import { PrivatePageHeader } from "views/components/headers";
import { MenuButtonHeader } from "../../components/header/menu-button-header/menu-button-header";

interface HeaderProps {
    title: string
}

export const ClienteDeliveryHeader = ({ title }: HeaderProps) => {
    return (
        <>
            <PrivatePageHeader title={title} leftArea={<MenuButtonHeader />} />
        </>
    );
};