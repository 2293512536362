import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    fixedHeightSmall: {
        height: '70px',
    },
    fixedHeightMedium: {
        height: '110px',
    },
}))
