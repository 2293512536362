import { Button, Grid } from "@material-ui/core";
import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect } from "react";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { useToastSaurus, useCadastros } from "services/app";
import { CancelarIcon, LixoIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import classNames from "classnames";
import { VolumeTransportadoConteudoProps } from "./volume-transportado-conteudo-props";
import { FormVolumeTran } from "views/components/form/entrada/dados-volume-tran/form-volume-tran";
import { VendaCompletaModel, VolTranspModel } from "model/api/gestao/venda/venda-completa-model";
import { TranpsVolumeFormModel } from "model/app/forms/entrada/transp-volume-form-model";
import { isEqual } from "lodash";
import { picker } from "utils/picker";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import { useConfirm } from "material-ui-confirm";
import { useEntrada } from "views/pages/private/entrada/hooks/entrada";

export const VolumeTransportadoConteudo = ({ volume, mov }: VolumeTransportadoConteudoProps) => {
  const { fecharVolumeTransportadoCadastro } = useCadastros();
  const cadFormRef = useRef<DefaultFormRefs<TranpsVolumeFormModel>>(null);
  const classes = useModalStyles();
  const { showToast } = useToastSaurus();
  const volumePadrao = isEqual(volume, new VolTranspModel())
  const { saveChangesVendaCompleta, carregando } = useEntrada(mov)
  const confirm = useConfirm()

  useEffect(() => {
    if (mov) {
      if (!volumePadrao) {
        const volumeMov = mov.infMov.transp?.vol.find(x => isEqual(x, volume))
        cadFormRef.current?.fillForm(volumeMov);
        return
      }
      cadFormRef.current?.fillForm(new TranpsVolumeFormModel());
    }
  }, [mov, volume, volumePadrao]);

  const saveChanges = useCallback(async (modelForm: TranpsVolumeFormModel) => {
    const movAtual = structuredClone(mov) as VendaCompletaModel
    if (!movAtual.infMov.transp) return;
    let volumeModel = new VolTranspModel()
    if (!volumePadrao) {
      const volumeMov = movAtual.infMov.transp.vol.find(x => isEqual(volume, x))
      volumeModel = picker<VolTranspModel>(modelForm, volumeMov)
      const index = movAtual.infMov.transp.vol.indexOf(volumeMov!)
      movAtual.infMov.transp.vol[index] = volumeModel
    } else {
      volumeModel = picker<VolTranspModel>(modelForm, new VolTranspModel())
      movAtual.infMov.transp.vol.push(volumeModel)
    }

    await saveChangesVendaCompleta(movAtual)
  }, [mov, saveChangesVendaCompleta, volume, volumePadrao])


  const handleSubmit = useCallback(
    async (model: TranpsVolumeFormModel, beforeModel: TranpsVolumeFormModel) => {
      try {
        const modelEqualPadrao = isEqual(model, new TranpsVolumeFormModel())
        const modelEqual = isEqual(model, beforeModel)

        if (modelEqualPadrao) {
          showToast('info', 'Preencha os campos!')
          return
        }
        if (modelEqual) {
          showToast('info', 'Nenhuma informação alterada')
          return
        }

        await saveChanges(model)
        showToast('success', `Volume ${!volumePadrao ? 'alterado' : 'adicionado'} com sucesso!`)

        fecharVolumeTransportadoCadastro(true)

      } catch (error: any) {
        showToast('error', error.message)
      }
    },
    [fecharVolumeTransportadoCadastro, saveChanges, showToast, volumePadrao]
  );

  const submitForm = () => cadFormRef.current?.submitForm()

  const handleRemoveVolume = async () => {
    try {
      const movAtual = { ...mov }
      if (!movAtual.infMov.transp) return;

      const volumes = movAtual.infMov.transp.vol.filter(x => !isEqual(volume, x))
      movAtual.infMov.transp.vol = volumes

      await saveChangesVendaCompleta(movAtual)

      showToast('info', 'Volume removido com sucesso')
      fecharVolumeTransportadoCadastro(true)
    } catch (error: any) {
      showToast('error', error.message)
    }
  }

  const onCloseClick = useCallback(() => {
    fecharVolumeTransportadoCadastro();
  }, [fecharVolumeTransportadoCadastro]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={!volumePadrao ? 'Editar Volume' : "Novo Volume"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={volumePadrao ? null : (
          <MenuOptions options={[
            new MenuOptionsModel(
              `Remover Volume`,
              <LixoIcon tipo="BUTTON" />,
              () => confirm({
                title: 'Tem certeza?',
                description: 'Deseja remover este volume? Esta ação não poderá ser desfeita futuramente.',
                cancellationText: 'Cancelar',
                confirmationText: 'Confirmar'
              }).then(() => handleRemoveVolume())
            )
          ]} />
        )}
      />
      <div className={classes.content}>
        <div className={classNames(classes.contentForms, carregando ? classes.contentFormsLoading : undefined)}>
          <FormVolumeTran
            ref={cadFormRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Button
                disabled={carregando}
                onClick={() => submitForm()}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth 
              >
                <CancelarIcon tipo="BUTTON" />
                Cancelar
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                disabled={carregando}
                onClick={() => submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                {volumePadrao ? "Adicionar" : "Alterar"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};