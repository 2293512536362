import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardProdutoProps } from './card-produto-props';
import { useCallback } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { useThemeQueries } from '../../..';
import classNames from 'classnames';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { TpProdutoFarmaciaMock } from 'data/mocks/tp-produto-mock';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { useStyles } from './card-produto-styles';

export const CardProdutoMobile = ({
  model,
  onClick,
  onCheck,
  selected,
  isButtonEdit,
}: CardProdutoProps) => {
  const classes = useDefaultCardStyles();
  const { theme } = useThemeQueries();
  const cardClasses = useStyles();
  const { plano } = useSessaoAtual();
  const myClasses = useStyles()

  const planoFarmacia = isPlanoFarmaceutico(plano?.plano)

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [model.ativo, theme.palette.error.main, theme.palette.success.main]);

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={classes.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    }
  };

  const isTelaProduto = model.tipo === EnumTipoProduto.Produto || model.tipo === EnumTipoProduto.Medicamento

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.produtoId);
        }}
      >
        <Tooltip
          arrow
          title={model.ativo ? 'Habilitado' : 'Desabilitado'}
          placement="right"
        >
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          />
        </Tooltip>
        <div className={cardClasses.imgContainer}>
          {img(model.imagemUrl)}
        </div>
        <Grid container className={myClasses.cardContent}>
          <Grid item xs={planoFarmacia ? 12 : 9}>
            <Typography color="textSecondary" variant="caption">
              Nome do Produto
            </Typography>
            <Typography
              className={classNames('celula-grid-value', cardClasses.overflowText)}
              color="textPrimary"
              variant="body1"
            >
              {model.nome}
            </Typography>
          </Grid>
         
          {isTelaProduto &&
            <>
              <Grid item xs={9}>
                <Typography color="textSecondary" variant="caption">
                  Tipo
                </Typography>
                <Typography
                  className={classNames('celula-grid-value', cardClasses.overflowText)}
                  color="textPrimary"
                  variant="body1"
                >
                  {TpProdutoFarmaciaMock.find(x => x.Key === model.tipo)?.Value || ''}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="textSecondary" variant="caption">
                  Qtd Estoque
                </Typography>
                <Typography
                  className={classNames('celula-grid-value', cardClasses.overflowText)}
                  color="textPrimary"
                  variant="body1"
                >
                  {model.qtdEstoque}
                </Typography>
              </Grid>
            </>
          }
          {isTelaProduto && <Grid item xs={9}>
            <Typography color="textSecondary" variant="caption">
              Categoria
            </Typography>
            <Typography
              className={classNames('celula-grid-value', cardClasses.overflowText)}
              color="textPrimary"
              variant="body1"
            >
              {model.categoriaDescricao || 'Sem Categoria'}
            </Typography>
          </Grid>}
          <Grid item xs={3}>
            <Typography color="textSecondary" variant="caption">
              Valor
            </Typography>
            <Typography
              className={classNames('celula-grid-value', cardClasses.overflowText)}
              color="textPrimary"
              variant="body1"
            >
              <Tooltip
                arrow
                title={
                  'R$ ' +
                  toDecimalString(model.vPreco) +
                  ' a cada ' +
                  model.medidaDesc
                }
                placement="right"
              >
                <>
                  R$ {toDecimalString(model.vPreco)}
                </>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
