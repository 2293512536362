import { Redirect, Route, Switch } from 'react-router-dom';
import { MainPage } from 'views/pages/private/main/main-page';
import RouterVenda from '../router-venda';
import MovCancelarPage from 'views/pages/private/movimentacao/mov-cancelar/mov-cancelar-page';
import { SuporteComponent } from 'routers/components/suporte/suporte';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { RenderModais } from 'views/components/render-modais/render-modais';
import { SemConexaoRoute } from '../sem-conexao-route';
import { PerformanceDebugPage } from 'views/pages/private/relatorios/performance-debug/performance-debug-page';
import { WhiteLabelProvider } from 'services/app/providers/whitelabel-provider';
import { consoleDev } from 'utils/console-dev';
import { VariaveisAmbiente } from 'config/enviroment';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';
import { ProtectedRoute } from '../protected-route/protected-router';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { PerfilModel } from 'model';
import { useCallback } from 'react';
import { RouterMainList } from './router-main-list';


export const RouterMain = () => {
  consoleDev('RouterMain');
  const { getPerfil, getEmpresaSelecionada, usuario } = useSessaoAtual();

  const emp = getEmpresaSelecionada();
  let perfilId = emp?.Perfil.Id;
  if (isEmpty(emp)) {
    perfilId = usuario?.empresa[0].Perfil.Id;
  }

  const perfilAtual: PerfilModel = getPerfil().filter(
    (x) => x.id === perfilId
  )[0];

  const validarPermissao = useCallback(
    (roles: EnumCodigosPermissoes[]): boolean => {
      if(roles.length === 0) return true; 
      if (!perfilAtual?.permissoes) return false;
      return roles.some((role) => {
        const permission = perfilAtual.permissoes.find(
          (p) => p.codigo === role
        );
        return permission?.valor === 'true';
      });
    },
    [perfilAtual?.permissoes]
  );

  return (
    <WhiteLabelProvider>
      <MainPage>
        <SemConexaoRoute>
          <Switch>
            <Route path={MovRotasMock.baseRota} component={RouterVenda} />

            <Route
              path={MovRotasMock.cancelarRota + '/:id'}
              component={MovCancelarPage}
            />

            {RouterMainList.map(route => (
              <ProtectedRoute key={route.path} validatePermission={validarPermissao} {...route} />
            ))}

            {VariaveisAmbiente.performanceDebugMode && (
              <ProtectedRoute
                path={RetaguardaRotasMock.relatorioPerformanceRota.path}
                component={PerformanceDebugPage}
                roles={RetaguardaRotasMock.relatorioPerformanceRota.roles}
                validatePermission={validarPermissao}
              />
            )}

            <Route path="/">
              {VariaveisAmbiente.paymentDevice !== EnumDeviceType.NAVIGATOR ? (
                <Redirect
                  to={MovRotasMock.landingRota + '?verificarCadastros=true'}
                />
              ) : (
                <Redirect to={RetaguardaRotasMock.dashboardRota.path} />
              )}
            </Route>
          </Switch>
        </SemConexaoRoute>
        <SuporteComponent />
        <RenderModais />
      </MainPage>
    </WhiteLabelProvider>
  );
};
export default RouterMain;
