import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useRef, useState } from 'react';
import { focusableElement } from 'utils/focusable-element';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useKeyDownHandler } from './keydown-handler';
import { stopPropagationWithFeedback } from 'utils/custom-stop-propagation';
import { useLocation } from 'react-router-dom';
import { EnumKey } from 'model/enums/enum-keycode';
import { CapturarProps } from 'views/components/headers/venda-header/stores/venda-header-store';

interface Props {
    ativo: boolean;
    value: string;
    setValue: (val: string) => any;
    aceitarLetras: boolean;
    aceitarNumeros: boolean;
    capturarDigitacao?: boolean | CapturarProps;
}

export const useAutoInput = (props: Props) => {

    const abertoPorClick = useRef<boolean>(false);
    const permiteUsar = useRef<boolean>(true)
    const firstRender = useRef<boolean>(true);

    const [digitando, setDigitando] = useState<boolean>(false);

    const { addHandler, removeHandler, callEvent } = useEventTools();
    const { handleKeyDown } = useKeyDownHandler();
    const location = useLocation();

    const podeSerAberto = useCallback((aberto: boolean) => {
        permiteUsar.current = aberto
    }, [])

    useEffect(() => {
        const abrirPorClick = (abrir: boolean) => {
            abertoPorClick.current = abrir;
            setDigitando(abrir);
        }

        addHandler(AppEventEnum.TelaPesquisa, abrirPorClick)
        addHandler(AppEventEnum.PermiteEntradaDigitada, podeSerAberto)
        return () => {
            removeHandler(AppEventEnum.PermiteEntradaDigitada, podeSerAberto)
            removeHandler(AppEventEnum.TelaPesquisa, abrirPorClick)
        }
    }, [addHandler, podeSerAberto, removeHandler])

    useEventListener('keydown', (event: any) => {

        let permitirDigitacao = true;
        if (!digitando) {
            permitirDigitacao = typeof props.capturarDigitacao === 'boolean' && props.capturarDigitacao;

            if (typeof props.capturarDigitacao === 'object') {
                const isCharNumber = !isNaN(Number(event.key));
                if (isCharNumber) {
                    permitirDigitacao = Boolean(props.capturarDigitacao.capturarNumeros);
                } else {
                    permitirDigitacao = Boolean(props.capturarDigitacao.capturarLetras);
                }
            }
        }

        if (
            !event.defaultPrevented &&
            !focusableElement(document.activeElement) &&
            permiteUsar.current &&
            props.ativo &&
            permitirDigitacao &&
            !event.ctrlKey
        ) {
            const ev = event as KeyboardEvent;
            if (!ev.key) return

            if (ev.key === EnumKey.BACKSPACE && props.value.length > 0) {
                stopPropagationWithFeedback(event);
                ev.preventDefault();
            }

            if (ev.key === "Escape") {
                abertoPorClick.current = false;
                setDigitando(false)
                props.setValue('');
                return;
            }
            const newValue = handleKeyDown(
                ev,
                props.value || '',
                (!digitando ? props.aceitarNumeros : true),
                (props.aceitarLetras),
                props.aceitarLetras,
                0,
                1000
            );

            if (
                ev.key === 'Enter'
            ) {
                callEvent(AppEventEnum.SubmitPesquisa, {
                    termo: newValue,
                });
                return;
            }

            if (newValue !== props.value) {
                props.setValue(newValue)

                stopPropagationWithFeedback(event)
                ev.preventDefault();
            }

            if (newValue.length > 0 && !digitando) {
                setDigitando(true);
            }

            if (newValue.length === 0 && !abertoPorClick.current) {
                setDigitando(false);
            }
        }
    });

    const fecharAutoInput = () => {
        setDigitando(false);
    }

    useEffect(() => {
        if (props.value.length === 0 && !abertoPorClick.current && !firstRender.current) {
            fecharAutoInput();
        }
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, [props.value.length])

    useEffect(() => {
        if (!firstRender.current) {
            abertoPorClick.current = false;
            fecharAutoInput();
        }
    }, [location])

    return {
        digitando,
        fecharAutoInput,
    }
}