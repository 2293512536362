import { Box, Grid, Typography } from 'views/design-system';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { toDecimalString } from 'utils/to-decimal';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { MovProdAvulsoFragmentProps } from './mov-prod-avulso-fragment-props';
import { useStyles } from './mov-prod-avulso-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { CarrinhoContainer } from '../../mov-carrinho/components/cart-wrapper/carrinho-container';
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { PesquisaProduto } from '../../components/pesquisa/produtos/pesquisa-produtos/pesquisa-produto';
import { FinalizarVendaButton } from '../../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';

const MovProdAvulsoFragment = ({ submit, add, textChanged, ...props }: MovProdAvulsoFragmentProps) => {
  const defaultClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { getMov } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools()
  const { getDesktopMode } = useDesktopMode();

  const isDesktopMode = getDesktopMode();

  const [carrinhoAvulso, setCarrinhoAvulso] = useState<
    Array<number> | undefined
  >(undefined);
  const inicial = useRef<boolean>(true)
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')

  const valueRef = useRef<HTMLParagraphElement | null>(null);

  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
  } = useKeyboard({
    maxLength: 7,
    floatCases: 2,
    isNumeric: true,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    textoAtual: textoAtual,
    digitado: digitado
  });

  const showFinalizarButton = !isDesktopMode

  const attValueDisplay = useCallback((any: any) => {
    const v = getFormattedText();
    if (valueRef.current) valueRef.current.textContent = v;
  }, [getFormattedText])

  const atualizaCarrinhoAvulso = useCallback(() => {
    const mov = getMov();
    if (!props.produtoAvulso) return;

    if (isEmpty(mov?.produtos)) {
      setCarrinhoAvulso([]);
      return;
    }

    const movProdAvulsos = mov!.produtos.filter(
      (x) =>
        x.produtoGradeId === props.produtoAvulso!.produtoGradeId &&
        x.ativo === true,
    );


    if (movProdAvulsos.length === 0 || !movProdAvulsos) {
      setCarrinhoAvulso([]);
    } else {
      setCarrinhoAvulso(movProdAvulsos.map((a) => a.vProd * 100));
    }
  }, [getMov, props.produtoAvulso]);

  const movProdAlterado = useCallback((prod: MovSimplesProdutoModel) => {
    atualizaCarrinhoAvulso();
  }, [atualizaCarrinhoAvulso]);

  //EFFECT PARA ATUALIZAR O CARRINHO APOS O CARREGAMENTO DA TELA
  useEffect(() => {
    atualizaCarrinhoAvulso();
  }, [atualizaCarrinhoAvulso]);

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    //addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado)

    return () => {
      setHeaderProps({
        title: {
          mobile: 'Produto Avulso',
          desktop: 'Passe o Produto Avulso'
        },
        id: 'menuAdm',
        showMenuPdv: true,
        showDefinicoes: true,
        pesquisa: {
          allowLetras: true,
          allowNumbers: true,
          capturarDigitacao: {
            capturarNumeros: false,
            capturarLetras: true,
          },
        },

      })
      removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
      //removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado)
    }
  }, [addHandler, attValueDisplay, removeHandler, movProdAlterado, setHeaderProps])

  //EFFECT PARA PREENCHER O ULTIMO VALOR DO CARRINHO (CARREGAMENTO TELA)
  useEffect(() => {
    if (inicial.current && carrinhoAvulso) {
      if (carrinhoAvulso.length > 0) {
        resetText(
          toDecimalString(carrinhoAvulso[carrinhoAvulso.length - 1], 2),
        );
      }
      inicial.current = false;
    }

  }, [carrinhoAvulso, resetText]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    submitKey();
  }, [submitKey]);

  // const carrinho = useMemo(() => {
  //   const digitadoInterno = toDecimal(getFormattedText());
  //   return (
  //     <>
  //       {(carrinhoAvulso?.length || 0) > 1 && (
  //         <Grid className={classes.containerCarrinhoAvulso}>
  //           <span>
  //             Histórico:{' '}
  //             {carrinhoAvulso &&
  //               carrinhoAvulso
  //                 .filter((item, index) => {
  //                   if (digitadoInterno > 0 && index === carrinhoAvulso.length - 1) {
  //                     return false;
  //                   }
  //                   return true;
  //                 })
  //                 .map((item, index) => {
  //                   return (
  //                     toDecimalString(item / 100, 2).replace('R$', '') + ' + '
  //                   );
  //                 })}
  //           </span>
  //         </Grid>
  //       )}
  //     </>
  //   );
  // }, [carrinhoAvulso, classes.containerCarrinhoAvulso, getFormattedText]);

  const display = useMemo(() => {
    return (
      <Grid className={classes.contentTela}>
        <Typography
          color='primary'
          variant='caption'
          style={{ marginBottom: '1.2em', marginRight: '1em', fontSize: '1rem', fontWeight: '500' }}
        >
          R$
        </Typography>
        <Typography
          ref={valueRef}
          color='primary'
          style={{
            fontSize: '3rem',
            fontWeight: 700,
          }}
        >
          0,00
          {/* {versao && getFormattedText()} */}
        </Typography>
      </Grid>
    );
  }, [classes.contentTela]);

  const teclado = React.useMemo(() => {
    return (
      <>
        <Keyboard
          isButtonAddTopKeyboard
          isButtonKeyboardAdd
          handleAdd={handleAdd}
          handleBackSpace={handleBackSpace}
          handleAddValue={handleAddValue}
          handleSubmit={handleSubmit}
          handleText={handleText}
          isNumeric
          isButtonBackspace
        />
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  // const carrinhoButton = useMemo(() => {
  //   return (
  //     <>
  //       <CarrinhoButton />
  //     </>
  //   );
  // }, []);

  const finalizarVendaButton = useMemo(() => {
    return (
      <>
        <FinalizarVendaButton />
      </>
    );
  }, []);

  return (
    <Grid className={defaultClasses.root}>
      <Box className={defaultClasses.pagesContainer}>
        <Box className={classNames(defaultClasses.halfPage, isDesktopMode ? '' : defaultClasses.fullWidth)}>
          <Grid className={classNames(classes.containerTela, classes.unselectable)}>
            {/* {carrinho} */}
            {display}
          </Grid>
          <Grid
            container
            className={classes.unselectable}
            style={{ flex: 1, overflowX: 'hidden' }}
          >
            {teclado}
          </Grid>
          <PesquisaProduto />
        </Box>
        {isDesktopMode && <Box className={classNames(defaultClasses.cartPage, defaultClasses.divisoria)}>
          <CarrinhoContainer />
        </Box>}
      </Box>
      {showFinalizarButton && <Grid
        className={classNames(classes.containerFooter, classes.unselectable)}
      >
        {finalizarVendaButton}
      </Grid>}
    </Grid>
  );
};

export default MovProdAvulsoFragment;
