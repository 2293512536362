import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowX: 'hidden',
    flexGrow: 1
  },
  rootRfID: {
    background: theme.palette.background.paper
  },
  visorCodigo: {
    background: theme.palette.background.paper,
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  visorApagar: {
    "& svg": {
      padding: "10%",
      marginLeft: "-5%",
    }
  },
  visorLeitor: {
    "& svg": {
      padding: "20%",
    }
  },
  contentVisor: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  label: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
  },
  visorButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '100%',
    padding: '0',
    "& svg": {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  },

  textVisor: {
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(1),
    borderBottom: "1px solid #D0D0D0",
    minWidth: '220px',
  },
  textVisorAguardando: {
    fontSize: '1rem',
    fontWeight: "normal",
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderBottom: "1px solid #D0D0D0",
    minWidth: '220px',
  },
  containerItemFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: theme.spacing(1),

    '& .MuiBadge-badge': {
      'z-index': 0
    }
  },
  textButton: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '33px',
    textAlign: 'center'
  },
  containerFooter: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1)
  },
  content: {
    flex: 1,
    height: '60px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '0px 3px 3px 0px',
    marginBottom: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nfcAnim: {
    height: 30
  }
}));
