import { Tooltip, Typography } from 'views/design-system';
import { CardCadastrosProps } from './card-cadastros-props';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useHistory } from 'react-router';
import { useStyles } from './card-cadastros-styles';
import classNames from 'classnames';
export const CardCadastros = (props: CardCadastrosProps) => {
  const cardClasses = useDefaultCardStyles();
  const classes = useStyles()

  const { push } = useHistory();


  return (
    <>
      <DefaultCard
        onClick={() => {
          push(props.rota);
        }}
      >
        {' '}
        <Tooltip title={`Ir para página de cadastros de ${props.titulo}`}>
          <div
            className={classNames(cardClasses.cardContent)}
            style={{
              minHeight: 200,
              maxWidth: 200,
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'space-around',
              padding: '1.5rem'
            }}
          >
            <div className={classNames(cardClasses.celulaGrid, classes.celulaGrid)}>
              <props.icone
                tipo="BUTTON"
                style={{ marginBottom: 20, width: '60px', height: '60px' }}
              />
            </div>
            <div className={classNames(cardClasses.celulaGrid, classes.celulaGrid)}>
              <Typography color="primary" variant="h5">
                {props.titulo}
              </Typography>
            </div>
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
