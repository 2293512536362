import { Paper, Typography, useTheme } from '@material-ui/core';
import { CardDashboardChartProdutosProps } from '../card-dashboard-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';

export const CardDashboardChartProdutos = ({
  model,
  carregando
}: CardDashboardChartProdutosProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(model.map((obj) => [obj.produto, obj]));

  const modsUnicosList = Array.from(modUnicos.values());

  let produtosSerelizados = modsUnicosList.map((item) => {
    return {
      ...item,
      vVenda: 0,
      qCom: 0,
    };
  });

  produtosSerelizados = produtosSerelizados.map((item) => {
    model.map((i) => {
      if (i.produto === item.produto) {
        let novaQuantidade = (item.vVenda += i.vVenda);
        let novaQTDE = (item.qCom += i.qCom);
        return {
          ...item,
          vVenda: novaQuantidade,
          qCom: novaQTDE,
        };
      }
      return i;
    });
    return item;
  });

  const totalVendas = model.reduce((a, b) => a + b.vVenda, 0);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Top 10 Produtos
            </Typography>
          </div>
          <div style={{ padding: '0 10px', width: '100%' }}>
            <div className={utilClasses.divider} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
            }}
          >
            {model.length === 0 ? (
              <Typography variant="body1" style={{ textAlign: 'center' }}>
                Não há dados para exibir
              </Typography>
            ) : (
              <>
                {produtosSerelizados
                  .sort((a, b) => b.vVenda - a.vVenda)
                  .filter((item, index) => index < 10)
                  .map((item, index) => {
                    let dinheiro = item.vVenda;
                    let vendas = item.qCom;

                    const porcentagem = (
                      (dinheiro * 100) /
                      parseInt(totalVendas.toString())
                    ).toFixed(0);

                    const options = {
                      chart: {
                        sparkline: {
                          enabled: true,
                          offsetY: -20,
                        },
                      },
                      plotOptions: {
                        radialBar: {
                          hollow: {
                            size: '65%',
                          },
                          dataLabels: {
                            name: {
                              show: true,
                              offsetY: 5,
                              fontSize: '14px',
                              fontWeight: 700,
                              color: '#666',
                              formatter: function (val: any) {
                                return `R$ ${val}`;
                              },
                            },
                            value: {
                              show: false,
                            },
                          },
                        },
                      },
                      labels: [String(toDecimalString(dinheiro, 2))],
                      colors: [
                        '#' +
                        ((Math.random() * 0xffffff) << 0)
                          .toString(16)
                          .padStart(6, '0'),
                      ],
                    };

                    return (
                      <Paper
                        key={index}
                        className={classNames('card')}
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>
                            <Typography
                              style={{ fontWeight: 600, fontSize: '1.2rem' }}
                            >
                              {index + 1}°{' '}
                              {item.produto !== null && item.produto !== ''
                                ? item.produto
                                : 'Sem nome'}
                            </Typography>
                            <Typography variant="body2">
                              {vendas < 2
                                ? `${vendas} Produto vendido`
                                : `${vendas} Produtos vendidos`}
                            </Typography>
                          </Typography>
                          <div>
                            {VariaveisAmbiente.paymentDevice === undefined ? (
                              <div
                                style={{
                                  textAlign: 'right',
                                  padding: '10px 0',
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '1.2rem',
                                  }}
                                >
                                  R$ {String(toDecimalString(dinheiro, 2))}
                                </Typography>
                                <Typography
                                  style={{ fontWeight: 500, fontSize: '1rem' }}
                                >
                                  {porcentagem}%
                                </Typography>
                              </div>
                            ) : (
                              <Chart
                                options={options}
                                series={[Number(porcentagem)]}
                                type="radialBar"
                                height={130}
                                width={130}
                              />
                            )}
                          </div>
                        </div>
                        {VariaveisAmbiente.paymentDevice === undefined ? (
                          <div
                            style={{
                              width: `${porcentagem}%`,
                              backgroundColor: `#${(
                                (Math.random() * 0xffffff) <<
                                0
                              )
                                .toString(16)
                                .padStart(6, '0')}`,
                              height: 5,
                              zIndex: '-1000',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Paper>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};
