import { Paper, Typography, useTheme } from '@material-ui/core';
import { CardDashboardChartLineProps } from '../card-dashboard-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';

export const CardDashboardChartLine = ({
  model,
  carregando
}: CardDashboardChartLineProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();
  const theme = useTheme();

  const hora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora} h`),
    ),
  );
  const compararHora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora}`),
    ),
  );

  const options = useCallback(() => {
    const options = {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '20px',
        },
        y: {
          formatter: (val: any) => `R$ ${toDecimalString(val)}`
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (val: any) => `R$ ${val.toFixed(0)}`
        }
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
      ],
      chart: {
        width: '700px',
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: hora,
      },
    };
    return options;
  }, [hora]);

  const retornaSeries = useCallback(() => {
    const series: any[] = [
      {
        name: 'Custo',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vCusto, 0).toFixed(2),
        ),
      },
      {
        name: 'Total vendido',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vVenda, 0).toFixed(2),
        ),
      },
      {
        name: 'Lucro',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + (b.vVenda - b.vCusto), 0).toFixed(2),
        ),
      },
    ];
    return series;
  }, [compararHora, model]);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Receita por horário
            </Typography>
          </div>
          <div style={{ padding: '0 10px', width: '100%' }}>
            <div className={utilClasses.divider} />
          </div>
          <div className={utilClasses.alinharChart}>
            <div style={{ display: 'block', marginTop: 20 }}>
              {model.length === 0 ? (
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                  Não há dados para exibir
                </Typography>
              ) : (
                <>
                  {VariaveisAmbiente.paymentDevice === undefined ? (
                    <>
                      {compararHora.map((item, index) => {
                        return (
                          <Paper
                            key={index}
                            className={classNames('card')}
                            style={{
                              paddingLeft: theme.spacing(2),
                              paddingRight: theme.spacing(2),
                              marginLeft: theme.spacing(1),
                              marginRight: theme.spacing(1),
                              marginBottom: theme.spacing(1),
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              textAlign: 'right',
                            }}
                          >
                            <div>
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 700 }}
                              >
                                Hora
                              </Typography>
                              <Typography>{item}h</Typography>
                            </div>
                            <div>
                              <Typography
                                variant="caption"
                                style={{ fontWeight: 700 }}
                              >
                                Total Vendido
                              </Typography>
                              <Typography>
                                R$ {toDecimalString(
                                  model
                                    .filter((i) => String(i.hora) === item && i)
                                    .reduce((a, b) => a + b.vVenda, 0),
                                  2,
                                )}
                              </Typography>
                            </div>
                            <div>
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ fontWeight: 700 }}
                                >
                                  Lucro
                                </Typography>
                                <Typography style={{ color: 'green' }}>
                                  R$ {toDecimalString(
                                    model
                                      .filter(
                                        (i) => String(i.hora) === item && i,
                                      )
                                      .reduce(
                                        (a, b) => a + (b.vVenda - b.vCusto),
                                        0,
                                      ),
                                    2,
                                  )}
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  style={{
                                    color: 'red',
                                    bottom: 6,
                                    fontSize: '12px',
                                    position: 'relative',
                                  }}
                                >
                                  -{' '}
                                  {toDecimalString(
                                    model
                                      .filter(
                                        (i) => String(i.hora) === item && i,
                                      )
                                      .reduce((a, b) => a + b.vCusto, 0),
                                    2,
                                  )}
                                </Typography>
                              </div>
                            </div>
                          </Paper>
                        );
                      })}
                    </>
                  ) : (
                    <Chart
                      options={options()}
                      series={retornaSeries()}
                      type="line"
                      className={utilClasses.chart}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
