import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { ConfigInicialMesasEcomandasModel } from 'model/api/gestao/config-inicial/config-inicial-mesas-e-comandas-model';
import { useStyles } from './form-mesas-e-comandas-style';
import { useFormMesaValidation } from './form-mesas-e-comandas-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccordionSaurusSecondary } from 'views/components/accordions/accordion-saurus-secondary/accordion-saurus-secondary';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { ComandasIconAlt } from 'views/components/icons/comanda-icon-alt';

export const FormMesasEcomandas = forwardRef<

    DefaultFormRefs<ConfigInicialMesasEcomandasModel>,
    DefaultFormProps<ConfigInicialMesasEcomandasModel>
>((props: DefaultFormProps<ConfigInicialMesasEcomandasModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { theme } = useThemeQueries()
    const [openAccordionMesas, setOpenAccordionMesas] = useState<boolean>(false)
    const [openAccordionComandas, setOpenAccordionComandas] = useState<boolean>(false)
    const { FormMesaValidationYup } = useFormMesaValidation(openAccordionMesas, openAccordionComandas);

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
    } = useForm<ConfigInicialMesasEcomandasModel>({
        defaultValues: { ...props.model },
        resolver: yupResolver(FormMesaValidationYup),
        criteriaMode: 'all',
        mode: 'onChange'
    });

    const onSubmit = (values: ConfigInicialMesasEcomandasModel) => {
        let modelo = new ConfigInicialMesasEcomandasModel()

        if (!openAccordionComandas) {
            values.comandaInicial = null;
            values.comandaFinal = null;
        } if (!openAccordionMesas) {
            values.mesaInicial = null;
            values.mesaFinal = null;
        }
        modelo.comandaFinal = getValues('comandaFinal')
        modelo.comandaInicial = getValues('comandaInicial')
        modelo.mesaFinal = getValues('mesaFinal')
        modelo.mesaInicial = getValues('mesaInicial')
        props.onSubmit(modelo);
    };

    const classes = useStyles()


    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {

        },
        fillForm: () => {


        }
    }));

    const FormularioMesas = () => {
        return (
            <Grid xs={12} container  >
                <Grid className={classes.descricaoForm} item xs={12} spacing={2}>
                    <Typography >Insira a quantidade de mesas do estabelecimento.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <Grid item xs={6} className={classes.inputLeft} >
                        <Controller
                            name="mesaInicial"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    autoComplete='off'
                                    variant="outlined"
                                    label="Mesa Inicial"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={'EX: 1'}
                                    error={Boolean(
                                        errors.mesaInicial && errors.mesaInicial.message
                                    )}
                                    helperText={
                                        touchedFields.mesaInicial || errors.mesaInicial
                                            ? errors.mesaInicial?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('mesaInicial', valor);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inputRight} >
                        <Controller
                            name="mesaFinal"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    autoComplete='off'
                                    variant="outlined"
                                    label="Mesa Final"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={'EX: 10'}
                                    error={Boolean(
                                        errors.mesaFinal && errors.mesaFinal.message
                                    )}
                                    helperText={
                                        touchedFields.mesaFinal || errors.mesaFinal
                                            ? errors.mesaFinal?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('mesaFinal', valor);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

            </Grid>
        )
    }
    const FormularioComandas = () => {
        return (
            <Grid xs={12} container spacing={1} >
                <Grid className={classes.descricaoForm} item xs={12}>
                    <Typography>Insira a quantidade de comandas do estabelecimento.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                    <Grid item xs={6} className={classes.inputLeft}>
                        <Controller
                            name="comandaInicial"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    variant="outlined"
                                    label="Comanda Inicial"
                                    autoComplete='new-password'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={'EX: 1'}
                                    error={Boolean(
                                        errors.comandaInicial && errors.comandaInicial.message,
                                    )}
                                    helperText={
                                        touchedFields.comandaInicial || errors.comandaInicial
                                            ? errors.comandaInicial?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('comandaInicial', valor);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inputLeft}>
                        <Controller
                            name="comandaFinal"
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo="NUMERO"
                                    disabled={props.loading}
                                    fullWidth
                                    autoComplete='new-password'
                                    variant="outlined"
                                    label="Comanda Final"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder={'EX: 10'}
                                    error={Boolean(
                                        errors.comandaFinal && errors.comandaFinal.message,
                                    )}
                                    helperText={
                                        touchedFields.comandaFinal || errors.comandaFinal
                                            ? errors.comandaFinal?.message
                                            : undefined
                                    }
                                    {...field}
                                    onChange={(e: any) => {
                                        let valor = parseInt(e.target.value)
                                        setValue('comandaFinal', valor);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>



            </Grid>
        )
    }
    const formularios = [
        {
            titulo: 'Mesas',
            estado: openAccordionMesas,
            mudancaDeEstado: setOpenAccordionMesas,
            formulario: <FormularioMesas />,
            icon: <MesaIcon tipo='BUTTON_PRIMARY' fill={theme.palette.primary.main} />
        },
        {
            titulo: 'Comandas',
            estado: openAccordionComandas,
            mudancaDeEstado: setOpenAccordionComandas,
            formulario: <FormularioComandas />,
            icon: <ComandasIconAlt tipo='BUTTON_PRIMARY' fill={theme.palette.primary.main} />
        }]
    return (
        <>
            <div>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >

                    <Grid container spacing={2} className={classes.formularioUnico}>
                        {formularios.map((item) =>
                            <Grid item>
                                <AccordionSaurusSecondary
                                    key={item.titulo}
                                    labelPrimary={item.titulo}
                                    titleBold
                                    labelIcon={item.icon}
                                    tipoExpand="bold"
                                    noIconRotation={true}
                                    noPaperRoot={false}
                                    heightDivider={2}
                                    showDivider={item.estado}
                                    colorDivider={theme.palette.primary.main}
                                    colorExpand={theme.palette.primary.main}
                                    expanded={item.estado}
                                    botaoChecked={item.estado}
                                    onChange={() => item.mudancaDeEstado(!item.estado)}
                                    className={classes.card}
                                >
                                    {item.formulario}
                                </AccordionSaurusSecondary>
                            </Grid>
                        )}

                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </Grid>
                </form>
            </div>
        </>
    );
});
