import { useCallback, useMemo } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { FiltroIcon, MenuIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { isEmpty } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';

export const HistoricoVendaHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const { location } = history
  const { abrirHistoricoVendaFiltroModal } = useFiltrosModais();


  const filtros = useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search)
    return {
      status: isEmpty(urlParams.get('status')) ? -1 : Number(urlParams.get('status')),
      generico: urlParams.get('generico') || '',
      dInicial: urlParams.get('dInicial') || '',
      dFinal: urlParams.get('dFinal') || '',
      serie: urlParams.get('serie') || '',
      empresa: urlParams.get('empresa') || guidEmpty(),
      nnf: urlParams.get('nnf') || '',
      tpMov: !isEmpty(urlParams.getAll('tpMov')) ? urlParams.getAll('tpMov').map(tp => Number(tp)) : [-1]
    }
  }, [history.location.search])

  const leftArea = useCallback(() => {
    if (location.pathname.indexOf('venda-simples') > -1) {
      return (
        <ButtonPrivateHeader
          icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
          tooltip="Ir para Menu Administrativo"
          onClick={abrirMenu}
        />
      );
    }
    return isMobile ? (
      <ButtonPrivateHeader
        icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
        tooltip="Menu"
        onClick={abrirMenu}
      ></ButtonPrivateHeader>
    ) : null
  }, [location.pathname, isMobile, abrirMenu]);

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<FiltroIcon tipo="PRIVATE_HEADER" />}
        tooltip='Abrir Filtros'
        onClick={() => {
          abrirHistoricoVendaFiltroModal({
            dFinal: filtros.dFinal,
            dInicial: filtros.dInicial,
            empresa: filtros.empresa,
            status: filtros.status,
            serie: filtros.serie,
            nnf: filtros.nnf,
            tpMov: filtros.tpMov
          })
        }}
      ></ButtonPrivateHeader>
    ),
    [abrirHistoricoVendaFiltroModal, filtros.dFinal, filtros.dInicial, filtros.empresa, filtros.nnf, filtros.serie, filtros.status, filtros.tpMov],
  );

  return (
    <>
      <PrivatePageHeader
        title="Histórico de Vendas"
        leftArea={leftArea()}
        rightArea={rightArea()}
      />
    </>
  );
};
