import { Grid } from '@material-ui/core';
import { useStyles } from './tab-resultado-pesquisa-styles';
import { CardItemTab } from './components/card-item-tab/card-item-tab';
import { ReactElement } from 'react';
import classNames from 'classnames';
import { TabResultadoPesquisaProps } from './tab-resultado-pesquisa-props';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { CestaProdutoIcon } from 'views/components/icons/cesta-produto-icon';
import { VarejoIcon } from 'views/components/icons/varejo-icon';
import { PrincipioAtivoIcon } from 'views/components/icons/principio-ativo-icon';
// import { CatalogoIcon } from 'views/components/icons/catalogo-icon';

type Opcoes = {
  nome: string;
  path: '/medicamentos' | '/produtos' | '/principioAtivo' | '/varejo' | '/revista';
  icon: ReactElement;
  isFarma: boolean;
};

export const TabResultadoPesquisa = ({
  path,
  setPath,
  planoFarmaceutico
}: TabResultadoPesquisaProps) => {
  const classes = useStyles();
  const opcoes: Opcoes[] = [
    {
      nome: 'Todos Produtos',
      path: '/produtos',
      icon: <CestaProdutoIcon tipo="BUTTON" class={classes.icon} />,
      isFarma: false
    },
    {
      nome: 'Medicamentos',
      path: '/medicamentos',
      icon: <MedicamentoIcon tipo="BUTTON" class={classes.icon} />,
      isFarma: true
    },
    {
      nome: 'Varejo',
      path: '/varejo',
      icon: <VarejoIcon tipo="BUTTON" class={classes.icon} />,
      isFarma: true
    },
    {
      nome: 'Princípio Ativo',
      path: '/principioAtivo',
      icon: <PrincipioAtivoIcon tipo="BUTTON" class={classes.icon} />,
      isFarma: true
    },
    // {
    //   nome: 'Revista',
    //   path: '/revista',
    //   icon: <CatalogoIcon tipo="BUTTON" />,
    //   isFarma: true
    // }
  ];
  const opcoesRender = opcoes
    .filter((opcao: Opcoes) => {
      if (opcao.isFarma) {
        if (planoFarmaceutico) {
          return opcao
        }
        return false
      }

      return opcao
    });

  if (opcoesRender.length === 1) { return <></> }

  return (
    <Grid container className={classNames(classes.drawer)}>
      {
        opcoesRender.map((model, i, list) => {
          const isApenasUm = list.length === 1
          return (
            <CardItemTab
              key={model.nome}
              model={model}
              {...{
                path,
                setPath,
                isApenasUm
              }}
            />
          );
        })}
    </Grid>
  );
};
