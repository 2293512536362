import { CardComissaoProps } from './card-comissao-props';
import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import React from 'react';
import { useStyles } from './card-comissao-styles'
import classNames from 'classnames';
import { toDateString } from 'utils/to-date';
import { Grid, Tooltip, Typography } from 'views/design-system';
import { EnumStatusComissao } from 'model/enums/enum-status-comissao';
import { StatusComissaoMock } from 'data/mocks/status-comissao-mock';

const CardComissao = ({ model, onClick, selected }: CardComissaoProps) => {
  const classes = useDefaultCardStyles();
  const comissaoClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumStatusComissao.Ativo:
        return theme.palette.success.main;
      case EnumStatusComissao.Desativado:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status,
  ]);

  const descricao = StatusComissaoMock.filter((x) => x.Key === model.status)?.[0]
    ?.Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, comissaoClasses.card)}>
          <Grid container>
            <Grid item xs={12} md={6} className={comissaoClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Nome Comissão:
              </Typography>
              <Typography color='textPrimary'>
                {model.nome}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} className={comissaoClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Data início:
              </Typography>
              <Typography color='textPrimary'>
                {toDateString(model.dataInicial, 'DD/MM/yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} className={comissaoClasses.item}>
              <Typography color='textSecondary' variant='caption'>
                Data final:
              </Typography>
              <Typography color='textPrimary'>
                {toDateString(model.dataFinal, 'DD/MM/yyyy')}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Comissão">
          <div className={classNames(classes.cardRightButton, comissaoClasses.iconRight)}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardComissao);
