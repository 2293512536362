import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useKeyboard } from "services/app/hooks/keyboard";
import { CpfCnpjTecladoProps } from "./cpfcnpj-teclado-props";
import { FormControlLabel } from "@material-ui/core";
import { Grid, Typography } from "views/design-system";
import { useStyles } from "./cpfcnpj-teclado-styles";
import { Keyboard } from "views/components/keyboard/keyboard";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { CheckboxDefault } from "views/components/controles/checkboxes";
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import classNames from 'classnames';
import { stringNumeros } from 'utils/string-numeros';
import { EnumMovModelo } from "model";
import { getClipboardValue } from "utils/getClipboardValue";
import { isEmpty } from "lodash";
import { SugestaoSources, useClienteIdentificacaoUtils } from "../use-cliente-identificacao-utils";
import { useCadastroPadrao } from "services/app/hooks/cadastro-padrao";
import { useMovAtual } from "services/app/hooks/mov-atual";

export const CpfCnpjTeclado = ({
    textChanged,
    checkboxValue,
    getMov,
    ...props
}: CpfCnpjTecladoProps) => {
    const { showSuggestionToast } = useClienteIdentificacaoUtils()
    const classes = useStyles()
    const { addHandler, removeHandler } = useEventTools()
    const [apareceCheckbox, setApareceCheckbox] = useState<boolean>(false)

    const valueRef = useRef<HTMLParagraphElement | null>(null);
    const valueInfo = useRef<string>('');
    const digitado = useRef<boolean>(false)
    const textoAtual = useRef<string>('')

    const mov = getMov()
    const modeloVenda = mov?.mod

    const isVendaFiscal = [EnumMovModelo.NFE, EnumMovModelo.NFCE].includes(modeloVenda ?? EnumMovModelo.ORCAMENTO)

    const {
        getFormattedText,
        addValue,
        sendText,
        addKey,
        submitKey,
        backSpaceKey,
    } = useKeyboard({
        maxLength: 15,
        floatCases: 0,
        isNumeric: false,
        handleTextChanged: textChanged,
        digitado: digitado,
        textoAtual: textoAtual
    });

    const attValueDisplay = useCallback((any: any) => {
        const v = getFormattedText();
        if (valueRef.current) {
            if (v.length === 0) {
                valueRef.current.className = classes.placeholder
                valueRef.current.textContent = "Aguardando Digitação"
                valueInfo.current = ''
                setApareceCheckbox(false)
                checkboxValue(true)
                return
            }
            valueRef.current.className = classes.inputText
            valueRef.current.textContent = formatarCPFCNPJ(v)
            if (isVendaFiscal) {
                setApareceCheckbox(true)
            }
            valueInfo.current = v;
        };
    }, [checkboxValue, classes.inputText, classes.placeholder, getFormattedText, isVendaFiscal])

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    const handleAddValue = useCallback(
        (value: number) => {
            addValue(value);
        },
        [addValue],
    );

    const handleBackSpace = useCallback(() => {
        backSpaceKey();
    }, [backSpaceKey]);

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    const handleSubmit = useCallback(() => {
        submitKey();
    }, [submitKey]);


    const { currentClienteIsPadrao } = useMovAtual();
    const { getConsumidor } = useCadastroPadrao();

    const fillCpfCnpj = useCallback(async () => {
        let cpfCnpjSourceDict: SugestaoSources = 'Vazio'
        let valueToSet: string = ''
        const mov = getMov()
        if (mov) {
            const cliente = mov.cliente
            const clientePadrao = await getConsumidor();
            if (cliente) {
                const doc = cliente.cpfcnpj;
                if (!(doc === clientePadrao?.cpfcnpj && await currentClienteIsPadrao())) {
                    valueToSet = doc;
                    cpfCnpjSourceDict = 'Fluxo de venda atual'
                }
            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    valueToSet = receita.comprador.cpfcnpj
                    cpfCnpjSourceDict = 'Receita'
                }
            }
            if (isEmpty(valueToSet)) {
                valueToSet = await getClipboardValue() ?? ''
                cpfCnpjSourceDict = 'Clipboard'
            }

            if (!isEmpty(valueToSet)) {
                if (valueRef.current) {
                    if (validarCPFCNPJ(valueToSet)) {
                        valueRef.current!.className = classNames(classes.inputText);
                        valueRef.current.textContent = formatarCPFCNPJ(valueToSet);
                        handleText(stringNumeros(valueToSet))
                        showSuggestionToast({
                            key: 'CPF/CNPJ',
                            source: cpfCnpjSourceDict
                        })
                    }
                }
            }
        }

    }, [classes.inputText, currentClienteIsPadrao, getConsumidor, getMov, handleText, showSuggestionToast])

    useEffect(() => {
        fillCpfCnpj()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const teclado = useMemo(() => {
        return (
            <>
                <Grid container style={{ flex: 1 }}>
                    <Keyboard
                        handleAdd={handleAdd}
                        handleBackSpace={handleBackSpace}
                        handleAddValue={handleAddValue}
                        handleSubmit={handleSubmit}
                        handleText={handleText}
                        isNumeric
                        isButtonKeyboard00
                        isButtonBackspace
                    />
                </Grid>
            </>
        );
    }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

    return (
        <Grid className={classes.container}>
            <Grid className={classNames(classes.content, props.pequeno ? classes.contentPequeno : undefined)}>
                <Typography className={classes.title}>{'Informe o CPF ou CNPJ'}</Typography>
                <Grid className={classes.inputArea}>
                    <Typography className={classes.placeholder} ref={valueRef}>
                        Aguardando Digitação
                    </Typography>
                </Grid>
                {apareceCheckbox && isVendaFiscal && (
                    <FormControlLabel
                        control={<CheckboxDefault defaultChecked={true} />}
                        onChange={(event, checked) => checkboxValue(checked)}
                        label="CPF/CNPJ na Nota Fiscal"
                    />
                )}
            </Grid>
            {teclado}
        </Grid>
    )
}