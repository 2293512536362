import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({

    labelValor: {
        fontSize: 16,
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    botaoRedondo: {
        background: theme.palette.grey[200],
        width: 32,
        height: 32,
        margin: 0,
        borderRadius: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        "& svg": {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: 0,
            padding: 0,
            fill: theme.palette.text.primary
        }
    },
    iconCustom: {
        height: '18px',
        marginRight: '2px'
    },
    iconCustom2: {
        height: '18px',
        marginRight: '0px'
    },
    conteudoInfo: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    exibirIconClose: {
        transform: 'rotate(180deg)',
        margin: '0'
    },
    exibirIcon: {
        margin: '0'
    },
    totaisCard:{
        borderLeft: `1px solid ${theme.palette.grey[300]}`
    },
    destaqueTotais:{
        fontWeight: 700 
    },
    divider:{
        backgroundColor: theme.palette.grey[300]
    },
    botaoExibir:{
        margin:'auto'
    }


}));
