import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardProdutosProps } from './card-produto-selecionar-props';
import { useCallback } from 'react';
import React from 'react';
import { useStyles } from './card-produto-selecionar-styles';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views/theme';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import SemImagem from 'assets/img/sem-imagem.jpg';

export function CardProdutoSelecionar({
  model,
  isFarma,
  onClick,
  selectedDisabled,
  selected,
  select
}: CardProdutosProps) {
  const classes = useDefaultCardStyles();
  const produtosClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return theme.palette.grey[300];
  }, [theme.palette.grey]);

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={classes.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    }
  };

  return (
    <>
      <DefaultCard
        isSelected={false}
        className={selectedDisabled ? produtosClasses.disabled : undefined}
        onClick={() => { if (!selectedDisabled) onClick(model.id) }}
      >
        <Tooltip arrow title={'Selecionado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, produtosClasses.card)}>
          <div
            className={classes.celulaGrid}
            style={{ marginLeft: 8, marginRight: 8 }}
          >
            {img(model?.imagemUrl ?? '')}
          </div>
          <Grid container direction="column" style={{ flex: 1 }}>
            <Grid item xs={12} md={12} className={produtosClasses.item}>
              <Typography color="textSecondary" variant="caption">
                Nome:
              </Typography>
              <Typography color="textPrimary" variant="body1">
                {model.nome}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className={produtosClasses.item}>
              <Grid container direction="row" style={{ flex: 1 }}>
                <Grid item xs={6} md={6} className={produtosClasses.item}>
                  <Typography color="textSecondary" variant="caption">
                    Categoria:
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    {!isEmpty(model.categoriaDescricao)
                      ? model.categoriaDescricao
                      : 'Não informado'}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} className={produtosClasses.item}>
                  <Typography color="textSecondary" variant="caption">
                    {isFarma ? "Marca/Laboratório" : "Marca"}
                  </Typography>
                  <Typography color="textPrimary" variant="body1">
                    {!isEmpty(model.marcaDescricao) ? model.marcaDescricao : 'Não informado'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="selecionar produto">
          <div
            className={classNames(
              classes.cardRightButton,
              produtosClasses.iconRight
            )}
          >
            {select && (
              <>
                <div
                  style={{
                    borderLeftWidth: '2px',
                    borderColor: '#E6E6E6',
                    borderStyle: 'solid',
                    height: '45px'
                  }}
                />
                <CheckboxDefault disabled={selectedDisabled} checked={selected} />
              </>
            )}
          </div>
        </Tooltip>
      </DefaultCard >
    </>
  );
}

export default React.memo(CardProdutoSelecionar);
