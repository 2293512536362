import { useCallback, useEffect, useState, useRef } from "react";
import { NovoIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { Button, Grid, Typography } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { DefaultFormRefs } from "views/components/form/utils";
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { picker } from "utils/picker";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import classNames from "classnames";
import { isEqual } from "lodash";
import { useGetDepositoById, useGetDepositoEmpresa, usePutDeposito } from "data/api/gestao/deposito";
import { DepositoModel } from "model/api/gestao/deposito/deposito-model";
import { FormDepositoEdit } from "views/components/form/deposito/form-deposito-edit/form-deposito-edit";
import { CardEmpresaSelect } from "views/components/cards/card-empresa-select";
import { DepositoEmpresaModel } from "model/api/gestao/deposito/deposito-empresa-model";

export const DepositoEdit = (props: { id: string }) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();

  const { fecharCadastroDepositos, abrirCadastroDepositos } = useCadastros();
  const { getDepositoById, carregando: carregandoGet } = useGetDepositoById();
  const { putDeposito, carregando: carregandoPut } = usePutDeposito();
  const { usuario } = useSessaoAtual()
  const { getDepositoEmpresa, carregando: carregandoGetDeposito } = useGetDepositoEmpresa()

  const carregando =
    carregandoPut ||
    carregandoGet ||
    preenchendoTela ||
    carregandoGetDeposito

  const [depositoFormState, setDepositoForm] = useState<DepositoModel>(
    new DepositoModel()
  );
  const [empresaDep, setEmpresaDep] = useState<DepositoEmpresaModel[]>([])
  const refEditForm = useRef<DefaultFormRefs<DepositoModel>>(null);
  const refDepositoModel = useRef<DepositoModel>(new DepositoModel());

  const recarregarForm = useCallback((model: DepositoModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(depositoFormState);
  }, [depositoFormState, recarregarForm]);

  const getDepositoByIdWapper = useCallback(async () => {
    const res = await getDepositoById(props.id);
    if (res.erro) {
      throw res.erro;
    }

    const ret = res.resultado?.data as DepositoModel;
    refDepositoModel.current = ret;

    return ret;
  }, [getDepositoById, props.id]);

  const preencherTela = useCallback(async () => {
    try {
      const deposito = await getDepositoByIdWapper();
      setDepositoForm(deposito);
    } catch (e: any) {
      showToast("error", e.message);
    }
  }, [getDepositoByIdWapper, showToast]);

  useEffect(() => {
    (async () => {
      setPreenchendoTela(true);
      preencherTela();
      setPreenchendoTela(false);
    })();
    return () => {
      //funcao de limpeza
      setDepositoForm(new DepositoModel());
    };
  }, [preencherTela]);

  const saveChangesDeposito = useCallback(
    async (perfilModelForm: DepositoModel) => {
      let perfil = picker<DepositoModel>(
        perfilModelForm,
        refDepositoModel.current,
      );
      const ret = await putDeposito(perfil);

      if (ret.erro) {
        throw ret.erro;
      }

    },
    [putDeposito]
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroDepositos("", "", true);
  }, [abrirCadastroDepositos]);

  const handleSubmit = useCallback(
    async (model: DepositoModel, beforeModel: DepositoModel) => {
      try {
        const depositoEqual = isEqual(model, beforeModel);
        if (!depositoEqual) {
          await saveChangesDeposito(model);

          showToast("success", "Depósito atualizado!", );
        }
      } catch (e: any) {
        showToast("error", e.message);
        refEditForm.current?.resetForm();
      }
    },
    [showToast, saveChangesDeposito]
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroDepositos();
  }, [fecharCadastroDepositos]);

  const getDepositoEmpresaWrapper = useCallback(async () => {
    let query =
      'page=1' +
      '&pageSize=100' +
      `&depositoId=${props.id}`
    const res = await getDepositoEmpresa(query)

    if (res.erro) throw res.erro
    setEmpresaDep(res.resultado?.data.list)

  }, [getDepositoEmpresa, props.id])

  useEffect(() => {
    getDepositoEmpresaWrapper();
  }, [getDepositoEmpresaWrapper])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Editar Depósito"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormDepositoEdit
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
          {(usuario?.empresa.length ?? 0) > 1 && (
            <>
              <Typography variant="h5">
                Empresas
              </Typography>
              <Typography variant="body1" style={{
                marginBottom: 16
              }}>
                Selecione as empresas que deseja utilizar este depósito
              </Typography>
              <Grid container spacing={1}>
                {(usuario?.empresa.length ?? 0) > 1 && usuario?.empresa.map((item, index) => {

                  const temEmpresa = empresaDep.filter(x => x.empresaId === item.Id)[0];

                  return (
                    <Grid item xs={12}>
                      <CardEmpresaSelect
                        model={item}
                        depositoId={props.id}
                        index={index}
                        temEmpresa={temEmpresa}
                        getDepositoWrapper={getDepositoEmpresaWrapper}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </>
          )}
        </div>
        <div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default DepositoEdit;
