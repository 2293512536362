import { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './promocao-list-styles';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { PromocaoListData } from './promocao-list-data';
import { PromocaoModel } from 'model/api/gestao/promocao/promocao-model';
import { useGetPromocao } from 'data/api/gestao/promocao/get-promocao';

export const PromocaoList = () => {
    // STATES E REFS
    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<PromocaoModel>()
    });
    const [modalEditAberto, setModalEditAberto] = useState(false);
    const [selectedList, setSelectedList] = useState<Array<string>>([]);

    // HOOKS
    const { abrirCadastroPromocao } = useCadastros();
    const { getPromocao, carregando } = useGetPromocao();
    const { addHandler, removeHandler } = useEventTools();
    const history = useHistory();
    const { showToast } = useToastSaurus();
    const classes = useStyles();

    const filtros = useMemo(() => {
        const urlParams = new URLSearchParams(history.location.search);
        return {
            ativo: isEmpty(urlParams.get('ativo'))
                ? 'true'
                : urlParams.get('ativo')?.toLowerCase(),
            termo: urlParams.get('termo') || ''
        };
    }, [history.location.search]);

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<PromocaoModel>
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages
            });
        },
        []
    );

    const search = useCallback(
        async (newPage: number) => {

            const query =
                '' +
                (!isEmpty(filtros.termo) ? '&Nome=' + filtros.termo : '') +
                (filtros.ativo !== 'todos' ? ('&ativo=' + filtros.ativo) : '');

            try {
                const res = await getPromocao(
                    query,
                    newPage
                );

                if (res.erro) throw res.erro;

                //se o index for maior que as paginas ele busca a ultima
                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
                    res.resultado?.data?.totalResults > 0
                ) {
                    search(res.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data?.pageIndex ?? 0,
                    res.resultado?.data?.totalPages ?? 0,
                    res.resultado?.data?.totalResults ?? 0,
                    res.resultado?.data?.list ?? []
                );
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [filtros, getPromocao, fillResult, showToast]
    );

    const modalEdit = useCallback(({ openned }: any) => {
        setModalEditAberto(openned);
    }, []);

    useEffect(() => {
        if (!modalEditAberto) {
            search(queryStatus.page);
        }

        addHandler(AppEventEnum.PromocaoModal, modalEdit);

        return () => {
            removeHandler(AppEventEnum.PromocaoModal, modalEdit);
        };
    }, [
        addHandler,
        modalEdit,
        modalEditAberto,
        queryStatus.page,
        removeHandler,
        search
    ]);

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                search(newPage);
            }
        },
        [search, queryStatus.totalPages]
    );

    const onCardSelected = (id: string) => {
        abrirCadastroPromocao(id, history.location.pathname, true);
    };

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    return (
        <>
            <div className={classes.defaultContainer}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container>
                    <Paginacao
                        pageChanged={pageChanged}
                        totalPages={queryStatus.totalPages}
                        totalRegisters={queryStatus.totalResults}
                        currentPage={queryStatus.page}
                    />
                    <Grid item xs={12} className={classes.listContainer}>
                        <PromocaoListData
                            carregando={carregando}
                            list={queryStatus.list}
                            selectedList={selectedList}
                            onCardSelected={onCardSelected}
                            onCardChecked={onCardChecked}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
