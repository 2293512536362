import { memo, useCallback, useEffect, useState } from 'react';
import { useStyles } from './carrinho-pagamento-styles';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumPagTipo, EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCadastros, useToastSaurus } from 'services/app';
import { usePayment } from 'services/app/hooks/payment';
import { CircularLoading } from 'views/components';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useDevice } from 'services/app/hooks/device';
import { isEmpty } from 'lodash';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { guidEmpty } from 'utils/guid-empty';
import { CardCarrinhoPagamento } from 'views/components/cards/card-carrinho-pagamento/card-carrinho-pagamento';
import { useMovRota } from 'services/app/hooks/mov-rota';

const CarrinhoPagamento = () => {
  const [active, setActive] = useState(false);
  const [payments, setPayments] = useState<
    Array<{
      name: string;
      type: EnumPagTpMod;
      methods: FinalizadoraModel[];
    }>
  >([]);

  // HOOKS
  const { startPayment, loading } = usePayment();
  const { getMov, carregando } = useMovAtual();
  const { showToast } = useToastSaurus();
  const { abrirPagamentosDialog } = useCadastros();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = useContratoAtual()
  const { tpPayments, getPayments } = useDevice();
  const { redirectPagamentoAvancado } = useMovRota();
  useEffect(() => {
    if (isEmpty(tpPayments)) {
      getPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayments, tpPayments]);

  // AUX
  const classes = useStyles();

  const groupByTpMod = useCallback((methods: FinalizadoraModel[]) => {
    const tpModArray = [
      EnumPagTpMod.DINHEIRO,
      EnumPagTpMod.CARTAO_CREDITO,
      EnumPagTpMod.CARTAO_DEBITO,
      EnumPagTpMod.PAGAMENTO_INSTANTANEO
    ];

    const groupPayments = [
      {
        name: 'Dinheiro',
        type: EnumPagTpMod.DINHEIRO,
        methods: [] as FinalizadoraModel[],
        ordem: 1
      },
      {
        name: 'Crédito',
        type: EnumPagTpMod.CARTAO_CREDITO,
        methods: [] as FinalizadoraModel[],
        ordem: 2
      },
      {
        name: 'Débito',
        type: EnumPagTpMod.CARTAO_DEBITO,
        methods: [] as FinalizadoraModel[],
        ordem: 3
      },
      {
        name: 'PIX',
        type: EnumPagTpMod.PAGAMENTO_INSTANTANEO,
        methods: [] as FinalizadoraModel[],
        ordem: 4
      }
    ];

    return methods
      .filter((p) => tpModArray.includes(p.tpMod)).filter((item) => item.ativo === true)
      .filter((item) => {
        if (
          item.tpTransacao === EnumPagTpTransacao.S2_PAY &&
          (tpPayments ?? []).find((x) => x === item.tpMod)
        ) {
          return item;
        } else if (item.tpTransacao !== EnumPagTpTransacao.S2_PAY) {
          return item;
        }

        return null
      })
      .reduce((group, payment) => {
        const groupIndex = group.find((g) => g.type === payment.tpMod);

        if (payment.tpMod === groupIndex?.type) {
          return group.map((x) => {
            if (x.type === groupIndex.type) {
              return {
                ...x,
                methods: [...x.methods, payment]
              };
            }

            return x;
          });
        }
        return group;
      }, groupPayments);
  }, [tpPayments]);

  const getPaymentMethods = useCallback(async () => {
    const result =
      (await TouchoneDBPrimary.finalizadoras.toArray()) as FinalizadoraModel[];
    const group = groupByTpMod(result);
    setPayments(group.map(g => ({
      ...g,
      methods: g.methods.sort()
    })).filter(groupPayment => groupPayment.methods.length > 0).map(g => ({
      ...g,
      ordem: g.methods[0]?.ordem ?? 999
    })).sort((a, b) => a.ordem - b.ordem));
  }, [groupByTpMod]);

  const onClick = useCallback(
    async (model: {
      type: EnumPagTpMod;
      name: string;
      methods: FinalizadoraModel[];
    }) => {
      if (model.methods.length === 0) {
        showToast(
          'info',
          'Você não possui nenhuma forma de pagamento com o tipo selecionado ou a forma não é permitida no ambiente atual, por favor verifique seus cadastros.'
        );
        return;
      }

      if (model.methods.length > 1) {
        abrirPagamentosDialog(model.methods);
        return;
      }

      await startPayment(model.methods[0]);
    },
    [abrirPagamentosDialog, showToast, startPayment]
  );

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const movProdAlterado = useCallback(
    (any: any) => {
      const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
      const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))

      if ((produtos?.length ?? 0) > 0) {
        setActive(true);
      } else {
        setActive(false);
      }
    },
    [getConfigByCod, getMov]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);

  useEffect(() => {
    const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
    const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))
    if ((produtos?.length ?? 0) > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [getConfigByCod, getMov]);

  const outros = new FinalizadoraModel(guidEmpty(), guidEmpty(), 'Expandir', 'Expandir', EnumPagTpTransacao.NORMAL, EnumPagTpMod.OUTRO, EnumPagTipo.AMBOS);
  return (
    <>
      {active && (
        <div className={classes.container}>
          {(loading || carregando) && <CircularLoading tipo="FULLSIZED" />}
          <div className={classes.paymentArea} style={{ gridTemplateColumns: 'repeat(' + payments.length + 1 + ', 1fr);' }}>
            {payments.map((pay) => (
              <CardCarrinhoPagamento
                model={pay}
                key={pay.type}
                className={classes.botoesPagamento}
                onClick={onClick}
                selected={false}
              />
            ))}
            <CardCarrinhoPagamento
              model={{
                methods: [outros],
                name: 'Expandir',
                type: EnumPagTpMod.OUTRO
              }}
              key={EnumPagTpMod.OUTRO}
              onClick={async () => {
                try {
                  await redirectPagamentoAvancado();
                } catch (e: any) {
                  showToast('error', e.message)
                }
              }
              }
              selected={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(CarrinhoPagamento);
