import { Grid, Typography } from "views/design-system"

import { SexoIdentificacaoProps } from "./sexo-identificacao-props"
import { useCallback, useEffect, useState } from "react"
import { EnumSexo } from "model/enums/enum-sexo"
import { SugestaoSources, useClienteIdentificacaoUtils } from "../use-cliente-identificacao-utils"
import { useMovAtual } from "services/app/hooks/mov-atual"
import { makeUtilClasses } from "views/theme"
import { useStyles } from "./sexo-identificacao-styles"
import { DefaultCard } from "views/components/cards/components"
import { RadioSelecionadoIcon } from "views/components/icons/radio-selecionado-icon"
import { RadioVazioIcon } from "views/components/icons/radio-vazio-icon"
import classNames from "classnames"
import { useCadastroPadrao } from "services/app/hooks/cadastro-padrao"

export const SexoIdentificacao = ({ valueChanged, sexo }: SexoIdentificacaoProps) => {
    const [value, setValue] = useState<EnumSexo>(EnumSexo.NaoInformado)
    const { showSuggestionToast } = useClienteIdentificacaoUtils()
    const { getMov, currentClienteIsPadrao } = useMovAtual()
    const { getConsumidor } = useCadastroPadrao()
    const utilClasses = makeUtilClasses();
    const classes = useStyles()

    const handleChanged = useCallback((sexo: EnumSexo) => {
        valueChanged(sexo)
        setValue(sexo)
    }, [valueChanged, setValue])
    const fillSexo = useCallback(async () => {
        const mov = getMov()
        let sexoToSet: EnumSexo = EnumSexo.NaoInformado
        let fonteSugestao: SugestaoSources = 'Vazio'
        if (mov) {
            const isPadrao = await currentClienteIsPadrao()
            const clientePadrao = await getConsumidor();
            const cliente = mov.cliente
            if (cliente) {
                if (!(isPadrao && clientePadrao?.sexo === cliente.sexo)) {
                    sexoToSet = cliente.sexo
                    fonteSugestao = 'Fluxo de venda atual'
                }
            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    sexoToSet = receita.comprador.sexo
                    fonteSugestao = 'Receita'
                }
            }
            showSuggestionToast({
                key: 'Sexo',
                source: fonteSugestao

            })
            handleChanged(sexoToSet)
        }
    }, [currentClienteIsPadrao, getConsumidor, getMov, handleChanged, showSuggestionToast])

    useEffect(() => {
        fillSexo()
    }, [fillSexo])

    return (
        <Grid className={classNames(utilClasses.formContainer, classes.root)} flex alignItems='center' justifyContent='center'>
            <Grid className={classes.container} container spacing={1} mx={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h5" align='center'>
                        Informe o Gênero
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DefaultCard hasTagStatus={false} onClick={() => {
                        if (value !== EnumSexo.Masculino) {
                            handleChanged(EnumSexo.Masculino)
                        }
                    }} >
                        <Grid flex alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                            <Typography className={classes.text} variant="h5" align='center'>
                                Masculino
                            </Typography>
                            <Grid className={classes.radio}>
                                {value === EnumSexo.Masculino && <RadioSelecionadoIcon tipo="BUTTON" />}
                                {value !== EnumSexo.Masculino && <RadioVazioIcon tipo="BUTTON" />}
                            </Grid>
                        </Grid>
                    </DefaultCard>
                </Grid>
                <Grid item xs={12}>
                    <DefaultCard hasTagStatus={false} onClick={() => {
                        if (value !== EnumSexo.Feminino) {
                            handleChanged(EnumSexo.Feminino)
                        }
                    }} >
                        <Grid flex alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                            <Typography className={classes.text} variant="h5" align='center'>
                                Feminino
                            </Typography>
                            <Grid className={classes.radio}>
                                {value !== EnumSexo.Feminino && <RadioVazioIcon tipo="BUTTON" />}
                                {value === EnumSexo.Feminino && <RadioSelecionadoIcon tipo="BUTTON" />}
                            </Grid>
                        </Grid>
                    </DefaultCard>
                </Grid>
            </Grid>
        </Grid>
    )
}