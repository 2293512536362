import { CardConvenioFaturaProps } from './card-convenio-fatura-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-convenio-fatura-styles';
import classNames from 'classnames';
import { toDateString } from 'utils/to-date';
import { MoedaIcon } from 'views/components/icons';
import { toDecimalString } from 'utils/to-decimal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EnumConvenioTpFaturaStatus } from 'model/enums/enum-convenio-tp-fatura-status';
import { TpStatusFaturaConvenioMock } from 'data/mocks/status-tp-fatura-convenio-mock';
import { Grid, Tooltip, Typography } from 'views/design-system';
import { useGetPessoaById } from 'data/api/gestao/pessoa';
import { useToastSaurus } from 'services/app';
import { PessoaModel } from 'model/api/gestao/pessoa';

export const CardConvenioFatura = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardConvenioFaturaProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const classes = useStyles()
  const { getPessoaById, carregando } = useGetPessoaById()
  const { showToast } = useToastSaurus()
  const [pessoa, setPessoa] = useState<PessoaModel>(new PessoaModel())

  const getPessoaWrapper = useCallback(async () => {
    try {
      if (model.pessoaNome) return
      const res = await getPessoaById(model.pessoaId)
      if (res.erro) throw res.erro

      const resultado = res.resultado?.data as PessoaModel
      setPessoa(resultado)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getPessoaById, model.pessoaId, model.pessoaNome, showToast])

  const pessoaNome = carregando ? 'Carregando...' : model.pessoaNome ?? (pessoa?.nome ?? 'Não encontrado')

  const formatCompentencia = useMemo(() => {
    try {
      const competencia = model.anoMesCompetencia.toString()
      const ano = competencia.slice(0, competencia.length - 2)
      const mes = competencia.slice(4)
      return mes + '/' + ano
    } catch (err: any) {
      return 'Sem informação'
    }
  }, [model.anoMesCompetencia])

  const returnCorStatus = useCallback(() => ({
    [EnumConvenioTpFaturaStatus.Aberta]: '#FFBC00',
    [EnumConvenioTpFaturaStatus.Fechada]: '#607D8B',
    [EnumConvenioTpFaturaStatus.NaoPaga]: '#F44336',
    [EnumConvenioTpFaturaStatus.Paga]: '#10C822',
  })[model?.fatura?.status ?? EnumConvenioTpFaturaStatus.Aberta] || '#FFBC00', [model?.fatura?.status])

  const descricaoStatus = TpStatusFaturaConvenioMock.find(status => status.Key === model?.fatura?.status)?.Value

  useEffect(() => {
    getPessoaWrapper()
  }, [getPessoaWrapper])

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.pessoaId);
        }}
      >
        <Tooltip arrow title={descricaoStatus ?? 'Não encontrado'} placement="right">
          <div
            className={defaultCardClasses.tagStatus}
            style={{ background: returnCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(defaultCardClasses.cardContent, classes.card)}>
          <Grid container>
            <Grid item xs={8} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Pessoa
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                {pessoaNome}
              </Typography>
            </Grid>
            <Grid item xs={'auto'} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Vencimento
              </Typography>
              <Typography color='textPrimary'>
                {toDateString(model?.fatura?.dataVencimento)}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Competência
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                {formatCompentencia}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.item}>
              <Typography color='textSecondary' variant='caption'>
                Valor Fatura
              </Typography>
              <Typography color='textPrimary' className={classes.negrito}>
                R$ {toDecimalString(model?.fatura?.valorFechamento)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Visualizar Fatura">
          <div className={defaultCardClasses.cardRightButtonProcesso}>
            <MoedaIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
