import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs
} from '../../utils/form-default-props';
import { useFormMesaValidation } from './form-mesa-gerar-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpSubstituidoMock } from 'data/mocks/tp-substituir-mock';
import { MesasGerarModel } from 'model/api/gestao/mesa/mesa-gerar-model';
import { useStyles } from './form-mesa-gerar-styles';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';

export const FormMesaGerar = forwardRef<
  DefaultFormRefs<MesasGerarModel>,
  DefaultFormProps<MesasGerarModel>
>((props: DefaultFormProps<MesasGerarModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormMesaValidationYup } = useFormMesaValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset
  } = useForm<MesasGerarModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormMesaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const classes = useStyles()
  const onSubmit = (values: MesasGerarModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: () => { }
  }));

  const { callTour } = useTourSaurus()

  useEffect(() => {
      setTimeout(() => { callTour(EnumTour.RECONFIGMESASFORM) }, 500)
  }, [callTour])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            
            <Grid item xs={12} id='tour-reconfigMesas1'>
              <Grid item xs={12}>
                <Controller
                  name="mesaInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      disabled={props.loading}
                      fullWidth
                      autoComplete='off'
                      variant="outlined"
                      label="Mesa Inicial"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={'EX: 1'}
                      error={Boolean(
                        errors.mesaInicial && errors.mesaInicial.message
                      )}
                      helperText={
                        touchedFields.mesaInicial || errors.mesaInicial
                          ? errors.mesaInicial?.message
                          : undefined
                      }
                      {...field}
                      onChange={(e: any) => {
                        if (e.target.value === '' || e.target.value === null) {
                          setValue('mesaInicial', 0)
                        }
                        var numberPattern = /\d+/g;
                        const value = e.target.value.match(numberPattern);

                        if (value) {
                          setValue('mesaInicial', e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid className={classes.mesaFinal} item xs={12}>
                <Controller
                  name="mesaFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      disabled={props.loading}
                      fullWidth
                      autoComplete='off'
                      variant="outlined"
                      label="Mesa Final"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={'EX: 10'}
                      error={Boolean(
                        errors.mesaFinal && errors.mesaFinal.message
                      )}
                      helperText={
                        touchedFields.mesaFinal || errors.mesaFinal
                          ? errors.mesaFinal?.message
                          : undefined
                      }
                      {...field}
                      onChange={(e: any) => {
                        if (e.target.value === '' || e.target.value === null) {
                          setValue('mesaFinal', 0)
                        }
                        var numberPattern = /\d+/g;
                        const value = e.target.value.match(numberPattern);

                        if (value) {
                          setValue('mesaFinal', value.join(''));
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              
            </Grid>
           
            {/* <Grid item xs={12}>
              <Controller
                name="prefixo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Prefixo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={'EX: 1234'}
                    error={Boolean(errors.prefixo && errors.prefixo.message)}
                    helperText={
                      touchedFields.prefixo || errors.prefixo
                        ? errors.prefixo?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                name="substituir"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Substituir Mesas"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={TpSubstituidoMock}
                    error={Boolean(
                      errors.substituir && errors.substituir.message
                    )}
                    helperText={
                      touchedFields.substituir || errors.substituir
                        ? errors.substituir?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = TpSubstituidoMock.filter(
                        (item) => item.Key === event.target.value
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('substituir', item === 1 ? true : false);
                      }
                    }}
                    value={getValues('substituir')}
                    id='tour-reconfigMesas2'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
