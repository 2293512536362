import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CardComandaAtendimento } from 'views/components/cards/card-comanda-atendimento/card-comanda-atendimento';
import { Grid, Typography } from '@material-ui/core';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { guidEmpty } from 'utils/guid-empty';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useRef } from 'react';
import { useStyles } from '../list-styles';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';

export interface ComandasListDataProps {
  comandas: Array<PedidoModelComanda>;
  carregando: boolean;
  onCardSelected: (comanda: PedidoModelComanda) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  salaoAtualId: string;
  saloes: SaloesModel[];
  temMesaEmAlgumSalao: boolean;
}

export const ComandasListData = (props: ComandasListDataProps) => {
  const { getConfigByCod } = usePDV();
  const modeloTrabalho = getConfigByCod(101) as EnumModeloDeTrabalho;

  const agruparSalao = useRef<string>('');

  const classes = useStyles();

  const onCardSelected = (comanda: PedidoModelComanda) => {
    props.onCardSelected(comanda);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };

  const saloes = [
    ...props.saloes,
    new SaloesModel(null, 'Sem Salão')
  ]

  const comandas = props.comandas.filter(comanda => !comanda.isDeprecated);
  const comandasObsoletras = props.comandas.filter(comanda => comanda.isDeprecated);

  return (
    <>
      {props.comandas.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Comanda encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      <Grid item xs={12} spacing={2} className={classes.containerListCard}>
        {comandas.length > 0 &&
          props.salaoAtualId === guidEmpty() &&
          saloes
            .map((salao) =>
              comandas.filter((pedido) => pedido.salaoId === salao.id),
            )
            .reduce((acc, val) => acc.concat(val), [])
            .map((pedidoComanda, index) => {
              return (
                <>
                  {(index === 0 ||
                    agruparSalao.current !== pedidoComanda.salaoId) && (
                      <>
                        <Grid xs={12} style={{ margin: '8px 8px' }}>
                          <Typography>
                            {(agruparSalao.current = pedidoComanda.salaoId) ===
                              pedidoComanda.nomeSalao
                              ? pedidoComanda.nomeSalao
                              : ''}
                            {pedidoComanda.salaoId === guidEmpty()
                              ? ''
                              : 'Salão -'}{' '}
                            <b>{pedidoComanda.nomeSalao}</b>
                          </Typography>
                        </Grid>
                        <br />
                      </>
                    )}
                  <CardComandaAtendimento
                    model={pedidoComanda}
                    key={index + pedidoComanda.id}
                    onClick={onCardSelected}
                    onHold={onCardHolded}
                    modeloTrabalho={modeloTrabalho}
                  />
                </>
              );
            })}

        {comandas.length > 0 &&
          props.salaoAtualId !== guidEmpty() &&
          comandas.map((comanda, index) => {
            return (
              <CardComandaAtendimento
                model={comanda}
                key={index}
                onClick={onCardSelected}
                onHold={onCardHolded}
                modeloTrabalho={modeloTrabalho}
              />
            );
          })}

        {comandasObsoletras.length > 0 && (
          <>
            <Grid xs={12} style={{ margin: '8px 8px' }}>
              <Typography>
                Pedidos sem Comanda Vinculada
              </Typography>
            </Grid>
            <br />
            {comandasObsoletras.map((comanda, index) => {
              return (
                <>
                  <CardComandaAtendimento
                    model={comanda}
                    key={index}
                    onClick={onCardSelected}
                    onHold={onCardHolded}
                    modeloTrabalho={modeloTrabalho}
                  />
                </>
              );
            })}
          </>
        )}

        {/* {props.comandas.length > 0 &&
          props.salaoAtualId === guidEmpty() &&
          !props.temMesaEmAlgumSalao &&
          props.comandas.map((comanda, index) => {
            return (
              <CardComandaAtendimento
                model={comanda}
                key={index}
                onClick={onCardSelected}
                onHold={onCardHolded}
                modeloTrabalho={modeloTrabalho}
              />
            );
          })} */}
      </Grid>
    </>
  );
};
