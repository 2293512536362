import { Grid, Tooltip, Typography } from 'views/design-system';
import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import React from 'react';
import { useStyles } from './card-promocao-styles'
import classNames from 'classnames';
import { toDateString } from 'utils/to-date';
import { CardPromocaoProps } from './card-promocao-props';

const CardPromocao = ({ model, onClick, selected }: CardPromocaoProps) => {
  const classes = useDefaultCardStyles();
  const promocaoClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    if (model.ativo)
      return theme.palette.success.main;
    else
      return theme.palette.error.main;
  }, [
    theme.palette.success.main,
    theme.palette.error.main,
    model
  ]);

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={model.ativo ? 'Promoção Ativa' : 'Promoção Inativa'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, promocaoClasses.card)}>
          <Grid container>
            <Grid item className={classNames(classes.celulaGridFull, promocaoClasses.item)}>
              <Typography color='textSecondary' variant='caption'>
                Nome da Campanha
              </Typography>
              <Typography color='textPrimary'>
                <b>{model.nome}</b>
              </Typography>
            </Grid>
            <Grid item className={classNames(classes.celulaGrid, promocaoClasses.item)} flex justifyContent='center' flexDirection='column' alignItems='center' style={{ minWidth: 100 }}>
              <Typography color='textSecondary' variant='caption'>
                Data Inicial
              </Typography>
              <Typography color='textPrimary'>
                {toDateString(model.dataInicial, 'DD/MM/yyyy')}
              </Typography>
            </Grid>
            <Grid item className={classNames(classes.celulaGrid, promocaoClasses.item)} flex justifyContent='center' flexDirection='column' alignItems='center' style={{ minWidth: 100 }}>
              <Typography color='textSecondary' variant='caption'>
                Data Final
              </Typography>
              <Typography color='textPrimary'>
                {toDateString(model.dataFinal, 'DD/MM/yyyy')}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Editar Campanha">
          <div className={classNames(classes.cardRightButton, promocaoClasses.iconRight)} >
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardPromocao);
