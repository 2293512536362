import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumCadastrosMesas } from "model/enums/enum-cadastros-mesas";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GestaoStorageKeys, useGestaoStorage } from "services/app";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { MesasModal } from "views/components/modals/mesas/mesas-modal";

export const CadastroMesasModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const { getRegistro } = useGestaoStorage();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: ''
    });

    const mesasRoute = `/gerenciar/mesas`;
    const novoCadastroRoute = '/adicionar';
    const reconfigurarRote = '/reconfigurar';

    const validarRotas = useCallback(() => {
        const valor = getRegistro(GestaoStorageKeys.ModoCadastroMesa, false);
        switch (valor.modo) {
            case EnumCadastrosMesas.Reconfigurar:
                return reconfigurarRote;
            case EnumCadastrosMesas.Adicionar:
                return novoCadastroRoute;
        }
    }, [getRegistro])


    consoleDev('CadastroMesasModal')

    useEffect(() => {
        if (location.pathname.indexOf(mesasRoute) === 0) {
            const posrota = location.pathname.replace(mesasRoute, '');
            if (posrota.length > 0) {
                setModalState({
                    id: posrota.replace(validarRotas()!, ''),
                    aberto: true,
                    ultimaUrl: mesasRoute
                });
                return;
            }
        }
    }, [location.pathname, mesasRoute, validarRotas]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? mesasRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url
        });
        if (!openned) {
            push(mesasRoute)
        }
        if (trocarUrl) {
            push(mesasRoute + (id?.length === 0 ? validarRotas() : `/${id}`));
        }
    }, [mesasRoute, modalState.aberto, modalState.ultimaUrl, push, validarRotas])

    useEffect(() => {
        addHandler(AppEventEnum.MesasModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.MesasModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <MesasModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}