import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetPoliticaValidar() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPoliticaValidar = useCallback(
        () =>
            invocarApi({
                url: `/api/v2/Termo?tipoSolucao=touchone`,
                method: 'GET',
                enviarTokenUsuario: false,
                baseURL: 'https://api-termos-staging.touchone.com.br'
            })
        ,
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getPoliticaValidar,
    };

}
