import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  ProdsModel,
} from 'model/api/gestao/venda/venda-completa-model';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useThemeQueries } from 'views/theme';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { AutocompleteMedidas } from 'views/components/controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { AutocompleteNcmsGestao } from 'views/components/controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { picker } from 'utils/picker';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { BalancaMock } from 'data/mocks/balanca-mock';
import { useStyles } from './entrada-cadastro-xml-styles'
import { InformacaoIcon } from 'views/components/icons';
import { toCurrency, toDecimal, toDecimalString } from 'utils/to-decimal';
import { ProdsXMLModel, VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { guidEmpty } from 'utils/guid-empty';
import { AutocompleteProdutoCategorias } from 'views/components/controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { AutocompleteProdutos, EnumNomeCodigo } from 'views/components/controles/autocompletes/autocomplete-produtos/autocomplete-produtos';
import classNames from 'classnames';
import { roundTo } from 'utils/round-to';
import { AutocompleteMarcas } from 'views/components/controles/autocompletes/autocomplete-marcas/autocomplete-marcas';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useMarca } from 'services/app/hooks/marca';
import { CircularLoading } from 'views/components/utils';
import { useGetProdutoById } from 'data/api/gestao/produto/produto/get-produto-by-id';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';

interface FormEntradaCadastroProdutosProps {
  produto: ProdsXMLModel;
  produtoOriginal: ProdsModel;
  infoProdutosRef: React.MutableRefObject<ProdsModel[]>;
  alterouRef: React.MutableRefObject<boolean>;
  index: number;
  opennedAccordion: React.MutableRefObject<boolean>;
  originalQtds: {
    vCompra: number;
    vQtd: number;
    vProd: number;
  }
}

export default function FormEntradaCadastroProdutos({
  produto,
  index,
  infoProdutosRef,
  alterouRef,
  opennedAccordion,
  produtoOriginal,
  originalQtds: {
    vCompra,
    vProd,
    vQtd
  }
}: FormEntradaCadastroProdutosProps): React.ReactElement {
  const { theme } = useThemeQueries();
  const classes = useStyles()
  const { getEmpresaAtual } = useEmpresaAtual();
  const { addNovaMarca, carregando: carregandoMarca } = useMarca();
  const { getProdutoById, carregando: carregandoProd } = useGetProdutoById();
  const [produtoIdentificado, setProdutoIdentificado] = useState<boolean>(Boolean(produto.produtoId) && produto.produtoId !== guidEmpty());
  const carregando = carregandoProd || carregandoMarca;
  const [valorAntigo, setValorAntigo] = useState<number>(infoProdutosRef.current[index].vUnCom || 0);
  const {
    control,
    setValue,
    getValues,
    formState: { errors, touchedFields }
  } = useFormContext<VendaCompletaXMLModel>();

  const handleInsertProduct = () => {
    if (produtoIdentificado) {
      setValue(`infMov.prod.${index}.produtoId`, guidEmpty());
      setValue(`infMov.prod.${index}.produtoGradeId`, guidEmpty());
      setValue(`infMov.prod.${index}.xProd`, produto.xProd);
      setProdutoIdentificado(false);
      return
    }
    if (produtoOriginal && produtoOriginal.produtoId !== guidEmpty()) {
      setValue(`infMov.prod.${index}.produtoId`, produtoOriginal.produtoId);
      setValue(`infMov.prod.${index}.produtoGradeId`, produtoOriginal.produtoGradeId);
    }
    setValue(`infMov.prod.${index}.cadastrarCodigo`, true)
    setValue(`infMov.prod.${index}.cadastrarCodigoTrib`, false)
    setProdutoIdentificado(prev => !prev)
  };

  const calcularPLucro = useCallback((vVenda: any) => {
    const vCompra = getValues(`infMov.prod.${index}.vUnCom`)
    const pLucro = ((toDecimal(vVenda) - vCompra) / vCompra) * 100;
    setValue(`infMov.prod.${index}.valorLucro`, toDecimalString(isNaN(pLucro) ? 0 : pLucro, 2))
    return toDecimalString(isNaN(pLucro) ? 0 : pLucro, 2)
  }, [getValues, index, setValue])

  const calcularValorFinal = useCallback((pLucro: any) => {
    const vCompra = getValues(`infMov.prod.${index}.vUnCom`)
    const vCusto = getValues(`infMov.prod.${index}.vCusto`)
    setValue(`infMov.prod.${index}.valorVenda`, toDecimal(((vCompra + vCusto) * toDecimal(pLucro)) / 100 + (vCompra + vCusto)))

    return toDecimal((vCompra * toDecimal(pLucro)) / 100 + vCompra)
  }, [getValues, index, setValue])

  useEffect(() => {
    setTimeout(() => {
      const valorVenda = getValues(`infMov.prod.${index}.valorVenda`);
      const fator = getValues(`infMov.prod.${index}.fator`);

      setValue(`infMov.prod.${index}.valorLucro`, calcularPLucro(valorVenda))

      if (!fator) {
        setValue(`infMov.prod.${index}.fator`, '1')
      }
    }, 100)
    return () => {
      //faço isso pra converter em number por causa do calculo do lucro q necessita ser string =(^-^)=
      setValue(`infMov.prod.${index}.vProd`, roundTo(getValues(`infMov.prod.${index}.vProd`)))
      if (getValues(`infMov.prod.${index}.valorVenda`)) {
        setValue(`infMov.prod.${index}.valorVenda`, toDecimal(getValues(`infMov.prod.${index}.valorVenda`)))
      }
      if (getValues(`infMov.prod.${index}.valorLucro`)) {
        setValue(`infMov.prod.${index}.valorLucro`, getValues(`infMov.prod.${index}.valorLucro`))
      }
      setValue(`infMov.prod.${index}.vUnCom`, roundTo(getValues(`infMov.prod.${index}.vUnCom`)))
    }
  }, [calcularPLucro, getValues, index, setValue])

  const getProduto = useCallback(async () => {
    if (Boolean(produto.produtoId) && produto.produtoId !== guidEmpty()) {
      try {
        const res = await getProdutoById(getEmpresaAtual()?.id || '', produto.produtoId);
        if (res.erro) throw res.erro;
        if (!res.resultado) return;

        const arr = [...infoProdutosRef.current];
        arr[index].vUnCom = res.resultado.data.grades[0].vPreco;
        infoProdutosRef.current = arr;
        setValorAntigo(res.resultado.data.grades[0].vPreco || 0);
      } catch { }
    }
  }, [getEmpresaAtual, getProdutoById, index, infoProdutosRef, produto.produtoId])

  useEffect(() => {
    getProduto();
  }, [getEmpresaAtual, getProduto, getProdutoById, index, infoProdutosRef, produto.produtoId])

  // const calcValorProdTotal = useCallback(() => {
  //   const prods = getValues('infMov.prod').filter(x => {
  //     if (getValues(`infMov.prod.${index}.id`) !== guidEmpty()) {
  //       return x.id !== getValues(`infMov.prod.${index}.id`)
  //     }
  //     return x.cEan !== getValues(`infMov.prod.${index}.cEan`) && x.cProd !== getValues(`infMov.prod.${index}.cProd`)
  //   })

  //   let vProdTotal = prods.reduce<number>((prev, curr) => prev + curr.vProd, 0);
  //   vProdTotal += getValues(`infMov.prod.${index}.vUnCom`) * getValues(`infMov.prod.${index}.qCom`)

  //   setValue('infMov.total.vProd', vProdTotal)
  // }, [getValues, index, setValue])

  const cadastrarNovoLaboratorio = async (val: string) => {
    const ret = await addNovaMarca(new MarcaModel(guidEmpty(), '', val));
    if (ret) {
      setValue(`infMov.prod.${index}.marca`, ret.id)
    }
  }

  const triggerAlterou = useCallback(() => {
    alterouRef.current = true;
  }, [alterouRef])

  const identificado = produtoIdentificado && infoProdutosRef.current[index];

  const PMC = produto.vPMC || 0;

  const DuplicatedOptions = useMemo<React.ReactElement>((): React.ReactElement => (
    <Box className={classNames(classes.prodFormContainer, produtoIdentificado ? '' : classes.noPadding)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Controller
            name={`infMov.prod.${index}.uCom`}
            control={control}
            render={({ field }) => (
              <AutocompleteMedidas
                label="Medida de Venda"
                allowSubmit
                fetchOnLoad={false}
                permiteAdicionar
                error={Boolean(errors.infMov?.message)}
                {...field}
                onChange={(retorno) => {
                  if (!retorno.isString) {
                    const medida: ProdutoMedidaModel = retorno.value;
                    setValue(`infMov.prod.${index}.uCom`, medida.descricao);
                    setValue(`infMov.prod.${index}.uTrib`, medida.descricao);
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Controller
            name={`infMov.prod.${index}.fator`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label="Fator"
                allowSubmit
                limite={16}
                manterMascara
                InputLabelProps={{
                  shrink: true
                }}
                placeholder=""
                error={Boolean(errors?.infMov?.message)}
                helperText={
                  touchedFields.infMov || errors.infMov
                    ? errors.infMov?.message
                    : undefined
                }
                {...field}
                onChange={(e) => {
                  triggerAlterou();
                  setValue(`infMov.prod.${index}.fator`, e.target.value)

                  setValue(`infMov.prod.${index}.qCom`, roundTo((toDecimal(e.target.value)) * vQtd, 3));
                  setValue(`infMov.prod.${index}.vUnCom`, roundTo(vCompra / toDecimal(e.target.value)));

                  let valorProd = getValues(`infMov.prod.${index}.qCom`) * getValues(`infMov.prod.${index}.vUnCom`);
                  if (isNaN(valorProd)) {
                    valorProd = 0
                  }
                  setValue(`infMov.prod.${index}.vProd`, roundTo(valorProd))

                  if (getValues(`infMov.prod.${index}.valorVenda`) !== null && (getValues(`infMov.prod.${index}.valorVenda`) !== 0 || toDecimal(getValues(`infMov.prod.${index}.valorLucro`)) !== 0)) {
                    calcularPLucro(getValues(`infMov.prod.${index}.valorVenda`))
                  }

                  // calcValorProdTotal()
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Controller
            name={`infMov.prod.${index}.qCom`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label="Quantidade"
                manterMascara
                limite={16}
                casasDecimais={4}
                allowSubmit
                InputLabelProps={{
                  shrink: true
                }}
                placeholder=""
                error={Boolean(errors?.infMov?.message)}
                helperText={
                  touchedFields.infMov || errors.infMov
                    ? errors.infMov?.message
                    : undefined
                }
                {...field}
                onChange={(e: any) => {
                  triggerAlterou();
                  field.onChange(e);

                  let fator = toDecimal(e.target.value) * 1 / vQtd;

                  setValue(`infMov.prod.${index}.fator`, toDecimalString(fator));
                  setValue(`infMov.prod.${index}.vUnCom`, roundTo(vProd / toDecimal(e.target.value)));

                  let valorProd = getValues(`infMov.prod.${index}.qCom`) * getValues(`infMov.prod.${index}.vUnCom`);
                  if (isNaN(valorProd)) {
                    valorProd = 0
                  }
                  setValue(`infMov.prod.${index}.vProd`, valorProd)

                  if (getValues(`infMov.prod.${index}.valorVenda`) && getValues(`infMov.prod.${index}.valorVenda`) !== null && (getValues(`infMov.prod.${index}.valorVenda`) !== 0 || toDecimal(getValues(`infMov.prod.${index}.valorLucro`)) !== 0)) {
                    calcularPLucro(getValues(`infMov.prod.${index}.valorVenda`))
                  }

                  // calcValorProdTotal()

                }}
              />
            )}
          />
        </Grid>

        {identificado && (
          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Valor Unitário (Antigo)"
              showStartAdornment
              readOnly
              allowSubmit
              value={valorAntigo}
            />
          </Grid>
        )}

        <Grid item xs={identificado ? 6 : 4} md={identificado ? 3 : 4}>
          <Controller
            name={`infMov.prod.${index}.vUnCom`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label={identificado ? "Valor Unitário (Novo)" : "Valor Unitário"}
                readOnly
                hideCadeado
                allowSubmit
                showStartAdornment
                InputLabelProps={{
                  shrink: true
                }}
                placeholder=""
                error={Boolean(errors?.infMov?.message)}
                helperText={
                  touchedFields.infMov || errors.infMov
                    ? errors.infMov?.message
                    : undefined
                }
                {...field}
                onChange={(e) => {
                  triggerAlterou();
                  field.onChange(e)
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={identificado ? 6 : 4} md={identificado ? 3 : 4}>
          <Controller
            name={`infMov.prod.${index}.valorLucro`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label="% Lucro"
                allowSubmit
                manterMascara
                placeholder=""
                {...field}
                onChange={(e: any) => {
                  field.onChange(e)
                  triggerAlterou();
                  calcularValorFinal(e.target.value)
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={identificado ? 6 : 4} md={identificado ? 3 : 4}>
          <Controller
            name={`infMov.prod.${index}.valorVenda`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label="Valor de Venda"
                showStartAdornment
                positivo
                allowSubmit
                manterMascara
                endAdornmentButton={
                  PMC ? (
                    <Tooltip
                      title={
                        <Typography
                          className={classes.tooltipText}
                          align="center"
                        >
                          Preço Máximo Consumidor:
                          {` ${toCurrency(PMC)}`}
                        </Typography>
                      }
                    >
                      <div>
                        <InformacaoIcon
                          tipo="GERAL"
                          class={classes.infoIcon}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                InputLabelProps={{
                  shrink: true
                }}
                placeholder=""
                error={Boolean(errors?.infMov?.message)}
                helperText={
                  touchedFields.infMov || errors.infMov
                    ? errors.infMov?.message
                    : undefined
                }
                {...field}
                onChange={(e) => {
                  calcularPLucro(e.target.value)
                  triggerAlterou();
                  field.onChange(e)
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  ), [classes.prodFormContainer, classes.noPadding, classes.tooltipText, classes.infoIcon, produtoIdentificado, index, control, identificado, valorAntigo, errors.infMov, setValue, touchedFields.infMov, triggerAlterou, vQtd, vCompra, getValues, calcularPLucro, vProd, calcularValorFinal, PMC]);

  // const calcularValorReal = useCallback((vPreco: number, vCompra: number) => {
  //   const pLucro = ((vPreco - vCompra) / vCompra) * 100;

  //   return toDecimalString(isNaN(pLucro) ? 0 : pLucro);
  // }, []);

  // const calcularValorFinal = useCallback((vCompra: number, pLucro: number) => {
  //   return toDecimalString((vCompra * pLucro) / 100 + vCompra);
  // }, []);

  // const calcularValores = useCallback(
  //   (event: any) => {
  //     setValue(event.target.name, event.target.value);
  //     const vCompra = toDecimal(
  //       event.target.name === `infMov.prod.${index}.vCompra`
  //         ? event.target.value
  //         : getValues(`infMov.prod.${index}.vCompra`)
  //     );
  //     const vPreco = toDecimal(
  //       event.target.name === `infMov.prod.${index}.vPreco`
  //         ? event.target.value
  //         : getValues(`infMov.prod.${index}.vPreco`)
  //     );
  //     const pLucro = toDecimal(
  //       event.target.name === `infMov.prod.${index}.pLucro`
  //         ? event.target.value
  //         : getValues(`infMov.prod.${index}.pLucro`)
  //     );

  //     if (event.target.name === `infMov.prod.${index}.pLucro`) {
  //       setValue(
  //         `infMov.prod.${index}.pLucro`,
  //         calcularValorFinal(vCompra, pLucro)
  //       );
  //     } else {
  //       setValue(
  //         `infMov.prod.${index}.pLucro`,
  //         calcularValorReal(vPreco, vCompra)
  //       );
  //     }
  //   },
  //   [setValue, index, getValues, calcularValorFinal, calcularValorReal]
  // );

  return (
    <Grid container spacing={2}>
      {!produtoIdentificado ? (
        <>
          <Grid item xs={12} onClick={handleInsertProduct}>
            <Box className={classes.warningText}>
              <InformacaoIcon tipo='GERAL' />
              <Typography>
                Este produto já está cadastrado no seu estoque? <span>Clique aqui.</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className={classes.cEanContainer}>
            <Controller
              name={`infMov.prod.${index}.cadastrarCodigo`}
              render={({ field }) => (
                <FormControlLabel
                  control={<CheckboxDefault />}
                  label="Cadastrar EAN"
                  {...field}
                  checked={field.value}
                  onChange={() => {
                    triggerAlterou();
                    setValue(`infMov.prod.${index}.cadastrarCodigo`, !field.value)
                  }}
                />
              )}
            />
            <Controller
              name={`infMov.prod.${index}.cEan`}
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo="TEXTO"
                  fullWidth
                  variant="outlined"
                  label="EAN"
                  readOnly
                  allowSubmit
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder=""
                  error={Boolean(errors?.infMov?.message)}
                  helperText={
                    touchedFields.infMov || errors.infMov
                      ? errors.infMov?.message
                      : undefined
                  }
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    triggerAlterou();
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.cEanContainer}>
            <Controller
              name={`infMov.prod.${index}.cadastrarCodigoTrib`}
              render={({ field }) => (
                <FormControlLabel
                  control={<CheckboxDefault checked={field.value} />}
                  label="Cadastrar EAN Trib."
                  {...field}
                  checked={field.value}
                  onChange={() => {
                    setValue(`infMov.prod.${index}.cadastrarCodigoTrib`, !field.value)
                    triggerAlterou();
                  }}
                />
              )}
            />
            <Controller
              name={`infMov.prod.${index}.cEanTrib`}
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo="TEXTO"
                  fullWidth
                  variant="outlined"
                  label="EAN Trib."
                  readOnly
                  allowSubmit
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder=""
                  error={Boolean(errors?.infMov?.message)}
                  helperText={
                    touchedFields.infMov || errors.infMov
                      ? errors.infMov?.message
                      : undefined
                  }
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    triggerAlterou();
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <AccordionSaurus
              labelPrimary="Mais Opções de Cadastro"
              showDivider={true}
              colorDivider={theme.palette.grey[300]}
              style={{ boxShadow: theme.shadows[1] }}
              defaultExpanded={opennedAccordion.current}
              onChange={() => {
                opennedAccordion.current = !opennedAccordion.current
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name={`infMov.prod.${index}.xProd`}
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        fullWidth
                        variant="outlined"
                        label={'Nome do Produto'}
                        allowSubmit
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder=""
                        error={Boolean(errors?.infMov?.message)}
                        helperText={
                          touchedFields.infMov || errors.infMov
                            ? errors.infMov?.message
                            : undefined
                        }
                        {...field}
                        onChange={(e) => {
                          triggerAlterou();
                          field.onChange(e)
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`infMov.prod.${index}.categoriaNome`}
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutoCategorias
                        modo='categoria'
                        placeholder="Ex: Eletrônicos"
                        carregarInicialmente={false}
                        permiteAdicionar
                        label="Categoria"
                        error={Boolean(
                          errors.infMov && errors.infMov.message,
                        )}
                        helperText={
                          errors.infMov
                            ? errors.infMov?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) => {
                          if (!retorno) return
                          triggerAlterou();
                          let categoria = picker<ProdutoCategoriaModel>(
                            retorno.value,
                            new ProdutoCategoriaModel(),
                          );
                          setValue(`infMov.prod.${index}.categoriaNome`, categoria.nome);
                          setValue(`infMov.prod.${index}.categoriaId`, categoria.id);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name={`infMov.prod.${index}.balanca`}
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        allowSubmit
                        label="Produto de Balança"
                        variant="outlined"
                        fullWidth
                        conteudo={BalancaMock}
                        {...field}
                        onChange={(event) => {
                          triggerAlterou();
                          const item = BalancaMock.filter(
                            (item) => item.Key === event.target.value
                          )[0]?.Key;
                          if (item !== undefined) {
                            setValue(`infMov.prod.${index}.balanca`, item);
                          }
                        }}
                        value={getValues(`infMov.prod.${index}.balanca`) ?? 0}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`infMov.prod.${index}.marca`}
                    control={control}
                    render={({ field }) => (
                      <AutocompleteMarcas
                        error={
                          Boolean(errors.infMov?.message)
                        }
                        label='Marca'
                        permiteAdicionar
                        valueIsID
                        onChange={(retorno) => {
                          if (!retorno) return
                          triggerAlterou();
                          if (retorno.isNewVal) {
                            if (retorno.isString) {
                              cadastrarNovoLaboratorio(retorno.value)
                            }
                            return
                          }
                          let marca = picker<MarcaModel>(
                            retorno.value,
                            new MarcaModel(),
                          );
                          if (marca.id === guidEmpty()) {
                            marca.id = '';
                          }
                          setValue(`infMov.prod.${index}.marca`, marca.id);
                        }}
                        value={field.value}
                        helperText={
                          errors.infMov
                            ? errors.infMov?.message
                            : undefined
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`infMov.prod.${index}.ncm`}
                    control={control}
                    render={({ field }) => (
                      <AutocompleteNcmsGestao
                        modo="campoFormatado"
                        label="NCM"
                        placeholder="Digite um NCM:"
                        error={Boolean(errors.infMov?.message)}
                        {...field}
                        onChange={(retorno) => {
                          triggerAlterou();
                          if (!retorno.isString) {
                            const ncm = picker<NcmModel>(
                              retorno.value,
                              new NcmModel()
                            );
                            setValue(`infMov.prod.${index}.ncm`, ncm.codigo);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                {DuplicatedOptions}
              </Grid>
            </AccordionSaurus>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} onClick={handleInsertProduct}>
            <Box className={classes.warningText}>
              <InformacaoIcon tipo='GERAL' />
              <Typography>
                Este é um produto novo no seu estoque? <span>Clique aqui.</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={`infMov.prod.${index}.xProd`}
              control={control}
              render={({ field }) => (
                <AutocompleteProdutos
                  label='Buscar Produtos Cadastrados'
                  {...field}
                  onChange={(ret) => {
                    triggerAlterou();
                    if (!ret.isString) {
                      setValue(field.name, ret.value.nome)
                      setValue(`infMov.prod.${index}.produtoId`, ret.value.produtoId)
                      const arr = [...infoProdutosRef.current];
                      arr[index] = ret.value;
                      infoProdutosRef.current = arr
                      return
                    }
                  }}
                  exibirTipo
                  nomeCodigo={EnumNomeCodigo.Nome}
                />
              )}
            />
          </Grid>

          {DuplicatedOptions}

        </>
      )}
      {carregando && <CircularLoading tipo='FULLSIZED' />}
    </Grid>
  );
}
