import { Box, Divider, Grid, Typography } from 'views/design-system';
import { useEffect, useState } from 'react';
import { useStyles } from './mov-prod-catalogo-page-styles';
import { useDefaultCadastroStyles } from './../../cadastros/components/default-cadastro-styles';
import { MenuTabCategoria } from './components/menu-tab-categoria/menu-tab-categoria';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { CarrinhoContainer } from '../mov-carrinho/components/cart-wrapper/carrinho-container';
import classNames from 'classnames';
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { PesquisaProduto } from '../components/pesquisa/produtos/pesquisa-produtos/pesquisa-produto';
import { EnumMovModelo } from 'model';
import { FinalizarVendaButton } from '../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';

const MovProdCatalogoPage = () => {
  const classes = useDefaultCadastroStyles();
  const utilsClass = useStyles();
  const [openPesquisa, setOpenPesquisa] = useState<boolean>(false);
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { getMov } = useMovAtual();
  const { getDesktopMode } = useDesktopMode();

  const isDesktopMode = getDesktopMode();


  const mov = getMov();

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: {
        desktop: 'Selecione o Produto ou Digite o Produto ',
        mobile: 'Cátalogo de Produtos',
      },
      id: 'menuAdm',
      showDefinicoes: true,
      showMenuPdv: true,
      pesquisa: {
        allowLetras: true,
        allowNumbers: true,
        capturarDigitacao: true,
      },
    })
    callEvent(AppEventEnum.PermiteEntradaDigitada, true);
    addHandler(AppEventEnum.TabCategoria, setOpenPesquisa);
    return () => removeHandler(AppEventEnum.TabCategoria, setOpenPesquisa);
  }, [addHandler, callEvent, removeHandler, setHeaderProps]);

  return (
    <Grid className={classes.root}>
      {mov &&
        (mov?.mod === EnumMovModelo.PEDIDO || mov?.mod === EnumMovModelo.DELIVERY) &&
        (mov?.informacoesGeraisPedido?.codigoComanda ||
          mov?.informacoesGeraisPedido?.codigoMesa ||
          mov?.informacoesGeraisPedido?.codigoPedido) &&
        (
          <Grid className={utilsClass.infoPedido}>
            <Typography style={{ textAlign: 'center' }}>
              {' '}
              Você está {' '}
              {mov?.informacoesGeraisPedido?.comandaId
                ? ' na Comanda'
                : mov?.informacoesGeraisPedido?.mesaId
                  ? 'na Mesa'
                  : mov?.informacoesGeraisPedido?.codigoPedido || mov?.informacoesGeraisPedido?.identificador
                    ? 'no Pedido'
                    : ''}
              :{' '}
              <span className={utilsClass.textoDestaque}>
                {mov?.informacoesGeraisPedido?.comandaId
                  ? `${mov?.informacoesGeraisPedido?.codigoComanda}`
                  : mov?.informacoesGeraisPedido?.mesaId
                    ? `${mov?.informacoesGeraisPedido?.codigoMesa}`
                    : mov?.informacoesGeraisPedido?.identificador ?
                      `${mov?.informacoesGeraisPedido?.identificador}` :
                      mov?.informacoesGeraisPedido?.codigoPedido
                        ? `#${mov?.informacoesGeraisPedido?.codigoPedido}`
                        : ''}
              </span>
            </Typography>
            <Divider className={utilsClass.divider} />
          </Grid>
        )}
      <Box className={classes.pagesContainer}>
        <Grid className={classNames(classes.halfPage, isDesktopMode ? '' : classes.fullWidth)}>
          <MenuTabCategoria openPesquisa={openPesquisa} />
          <PesquisaProduto />
        </Grid>
        {isDesktopMode && <Grid className={classNames(classes.cartPage, classes.divisoria)}>
          <CarrinhoContainer />
        </Grid>}
      </Box>
      {!isDesktopMode && <Grid className={utilsClass.containerFooter}>
        <FinalizarVendaButton />
      </Grid>}
    </Grid>
  );
};

export default MovProdCatalogoPage;
