import { Button, Grid, Typography } from 'views/design-system';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { isEmpty } from 'lodash';
import { ConfiguracaoMesaEComanda } from 'model/app/mov-pedido-local/configuracao-mesa-e-comanda';
import { EnumComandas } from 'model/enums/enum-comandas';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useToastSaurus
} from 'services/app';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { LoadingFinalizacao } from 'views';
import { DialogAlteracaoCadastros } from 'views/components/dialog/dialog-alteracao-cadastros/dialog-alteracao-cadastros';
import { AtualizarIcon, CancelarIcon } from 'views/components/icons';
import { useDefaultMovimentacaoStyles } from '../components/styles/default-movimentacao-styles';
import { PDVDiferenteConta } from './components/pdv-diferente-conta';
import { PDVDiferenteEmpresa } from './components/pdv-diferente-empresa';
import { useStyles } from './mov-simples-landing-page-styles';
import { useSessaoAtual } from 'services/app';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { useGetConfigContratoByCod } from 'data/api/gestao/contrato/get-contrato-config-by-cod';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { consoleDev } from 'utils/console-dev';
import { validaGuid } from 'utils/valida-guid';
import { serializarGuids } from 'utils/serialize-guid';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

enum EnumPDVDiferente {
  Igual = 0,
  ContaDiferente = 1,
  EmpresaDiferente = 2,
}

export const MovSimplesLandingPage = () => {
  const [alteracaoCadastros, setAlteracaoCadastros] = useState<{ cadastrosAlterados: boolean, ultimaAlteracaoLocal: Date | undefined, ultimaAlteracaoAPI: Date | undefined }>({ cadastrosAlterados: false, ultimaAlteracaoAPI: undefined, ultimaAlteracaoLocal: undefined });
  const { getSessao } = useSessaoPDV();
  const { getUltimaSincronizacao } = useSincronizacaoCadastros();
  const {
    restaurarMov,
    getMov,
    iniciarMov,
    alterarSessaoId,
    carregando: carregandoProvider
  } = useMovAtual();
  const { getConsumidor, carregando: carregandoCadastros } =
    useCadastroPadrao();
  const { push } = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [error, setError] = useState<Error>();
  const movStyles = useDefaultMovimentacaoStyles();
  const { showToast } = useToastSaurus();
  const {
    procurarMeuPDV,
    getPDV,
    getConfigByCod,
    carregando: carregandoPDV,
  } = usePDV();
  const { setConfiguracaoMesaEComanda, getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { usuario, getEmpresaSelecionada, getPessoa } = useSessaoAtual();
  const { getConfigContratoByCod, carregando: carregandoContrato } = useGetConfigContratoByCod()
  const [pdvDiff, setPdvDiff] = useState<EnumPDVDiferente>(EnumPDVDiferente.Igual);

  const carregando = [
    carregandoProvider,
    carregandoPDV,
    carregandoCadastros,
    carregandoContrato
  ].includes(true);

  const verificarCadastros = useMemo(() => {
    const urlQuery = new URLSearchParams(location.search);
    return urlQuery.get('verificarCadastros')
  }, [location.search])

  const { avancarFluxoMov } = useMovRota();

  const tentarAbrirMov = React.useCallback(async () => {
    try {
      let mov = getMov();
      //RESILIENCIA APÓS A SINCRONIZACAO SE A VENDA TIVER VAZIA, RESETO ELA PARA PODER APLICAR AS NOVAS CONFIGURACOES
      if (mov?.vNF === 0) {
        await iniciarMov();
        mov = getMov();
      }

      if (!mov) {
        await restaurarMov();
        mov = getMov();

        try {
          if (!mov) {
            await iniciarMov();
          }
        } catch (e: any) {
          showToast('error', e.message);
        }
      } else {
        await alterarSessaoId();
      }

      await avancarFluxoMov();

    } catch (e: any) {
      showToast('error', e.message);
      setError(e);
    }
  }, [alterarSessaoId, avancarFluxoMov, getMov, iniciarMov, restaurarMov, showToast]);

  const [situacao, setSituacao] = useState<number>(0);

  const verificarSincronizacao = useCallback(() => {
    if (getUltimaSincronizacao()?.dataSucesso) {
      setSituacao(3);
      return;
    } else if (getUltimaSincronizacao()?.dataUltima) {
      return push(PdvRotasMock.sincronizarResumoRota);
    }
    return push(PdvRotasMock.sincronizarDadosRota);
  }, [getUltimaSincronizacao, push]);

  const verificarAlteracoesCadastrais = useCallback(async () => {

    const tipoConfig = getConfigByCod(15) || '2';
    const ignoraVerificacao = tipoConfig === '0';

    if (verificarCadastros && !ignoraVerificacao) {
      let res = undefined;
      if (navigator.onLine) {
        res = await getConfigContratoByCod(getPessoa().pessoa?.contratoId || '', EnumContratoConfig.DataUltimoCadastro);

        if (res.erro) {
          verificarSincronizacao();
          return;
        }
      }
      const ultimaSincronizacao = getUltimaSincronizacao()?.dataSucesso;
      const dataAlteracao = res?.resultado?.data?.valor ?? undefined;

      if (!dataAlteracao || !ultimaSincronizacao) {
        verificarSincronizacao();
        return;
      }

      const dAlteracao = new Date(dataAlteracao).getTime();
      const uSincronizacao = new Date(ultimaSincronizacao).getTime();

      if (dAlteracao > uSincronizacao) {
        if (tipoConfig === '2') {
          setAlteracaoCadastros({
            cadastrosAlterados: true,
            ultimaAlteracaoAPI: new Date(dataAlteracao),
            ultimaAlteracaoLocal: new Date(ultimaSincronizacao)
          });
        } else {
          return push(PdvRotasMock.sincronizarDadosRota);
        }
        return;
      }
    }

    setSituacao(3);
  }, [getConfigByCod, getConfigContratoByCod, getPessoa, getUltimaSincronizacao, push, verificarCadastros, verificarSincronizacao]);

  const iniciarLoading = useCallback(async () => {
    consoleDev('Iniciando Loading', situacao);
    switch (situacao) {
      case 0:
        try {
          const acheiPdv = await procurarMeuPDV(false);
          if (!acheiPdv) {
            push(MovRotasMock.selecionarPDVRota);
            return;
          }
          setSituacao(1);
        } catch (e: any) {
          const pdv = getPDV();
          if (!pdv && e.message !== undefined) {
            setError(e);
            return;
          }
          setSituacao(1);
        }
        break;
      case 1:
        const cli = await getConsumidor();
        if (!cli) {
          setError(new Error('Erro ao Identificar o Cliente Padrão.'));
          return;
        }
        setSituacao(2);
        break;
      case 2:
        await verificarAlteracoesCadastrais();
        break;
      case 3:

        const configMesaEComanda = getConfiguracoesMesaEComanda();

        if (!configMesaEComanda) {
          const saloesCadastrados = await TouchoneDBPrimary.saloes
            .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
            .toArray();
          const comandas = await TouchoneDBPrimary.comandas
            .filter((comanda) => comanda.status?.codigo === EnumComandas.ATIVO)
            .toArray();

          const comandasCadastradas = comandas.length
          const rfidIguais = comandas.filter(comanda => comanda.rfId === comanda.codigoComanda).length === comandas.length
          const temRFID = comandas.filter(comanda => !isEmpty(comanda.rfId)).length > 0

          const tipoTrabalhoComanda = rfidIguais ? EnumTipoTrabalhoComanda.APENAS_RFID : temRFID ? EnumTipoTrabalhoComanda.HIBRIDO : EnumTipoTrabalhoComanda.APENAS_CODIGO

          const mesasCadastradas = await TouchoneDBPrimary.mesas
            .filter((mesa) => {
              if (
                saloesCadastrados.filter((x) => x.id === mesa.salaoId).length >
                0
              ) {
                if (mesa.status?.codigo === EnumMesas.ATIVO) {
                  return true;
                }
              }
              return false;
            })
            .count();

          const configuracaoMesaComanda = new ConfiguracaoMesaEComanda(
            undefined,
            saloesCadastrados.length,
            mesasCadastradas,
            comandasCadastradas,
            tipoTrabalhoComanda
          );
          if (comandasCadastradas > 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.COMANDA
            });
          } else if (mesasCadastradas > 0 && comandasCadastradas < 1) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.MESA
            });
          }
          else if (comandasCadastradas === 0 && mesasCadastradas === 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.BALCAO
            });
          }
        }

        setSituacao(4);
        break;
      case 4:
        const sessao = await getSessao();

        if (!sessao) {
          return push(MovRotasMock.abrirCaixaRota);
        }

        setSituacao(5);
        break;
      case 5:
        await tentarAbrirMov();
        break;
    }
  }, [getConfiguracoesMesaEComanda, getConsumidor, getPDV, getSessao, procurarMeuPDV, push, setConfiguracaoMesaEComanda, situacao, tentarAbrirMov, verificarAlteracoesCadastrais]);

  const pdvDiferente = useCallback(async () => {
    const empresas = usuario?.empresa;
    const pdv = getPDV();
    const hasPDV = empresas?.filter((item) => item.Id === pdv?.empresaId);
    const isDeviceMobile = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS;
    if (!isEmpty(pdv)) {
      if (!isDeviceMobile) {
        let serial = validaGuid(pdv.chave) ? getPessoa().pessoa?.id : serializarGuids([getPessoa().pessoa?.id, getEmpresaSelecionada()!.Id]);

        if (serial !== pdv.chave && (!hasPDV || hasPDV.length < 1)) {
          setPdvDiff(EnumPDVDiferente.ContaDiferente)
          return;
        }
      }
      if ((hasPDV && hasPDV.length > 0) && pdv?.empresaId !== getEmpresaSelecionada()?.Id) {
        setPdvDiff(EnumPDVDiferente.EmpresaDiferente);
        return
      }
    }
    await iniciarLoading();
  }, [getEmpresaSelecionada, getPDV, getPessoa, iniciarLoading, usuario?.empresa]);

  const { callEvent } = useEventTools();
  const setHideHeader = useVendaHeaderStore((state) => state.setHideHeader);

  useEffect(() => {
    setHideHeader(true);
    callEvent(AppEventEnum.AlterarMenuPDV, false);
    (async () => {
      await pdvDiferente();
    })();

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    }

  }, [pdvDiferente, callEvent, setHideHeader]);

  return (
    <>
      <Grid className={movStyles.root}>
        {!error && (
          <>
            {pdvDiff === EnumPDVDiferente.Igual ? (
              <Grid className={classes.content}>
                <Grid className={classes.infoContainer}>
                  <Grid className={classes.containerLoader}>
                    <LoadingFinalizacao />
                  </Grid>
                  <Typography className={classes.textTitle}>Sincronizando...</Typography>
                  <Typography variant="subtitle1" className={classes.textSubtitle}>
                    Aguarde enquanto estamos realizando a sincronização dos seus
                    cadastros.
                  </Typography>
                  <Grid className={classes.containerSincAtual}>
                    <Grid className={classes.textInfo}>
                      {situacao === 0 && <>Identificando PDV</>}
                      {situacao === 1 && <>Localizando Clientes</>}
                      {situacao === 2 && <>Procurando Atualizações</>}
                      {situacao === 3 && <>Verificando Sincronização</>}
                      {situacao === 4 && <>Verificando Sessão</>}
                      {situacao === 5 && <>Iniciando Venda</>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


            ) : pdvDiff === EnumPDVDiferente.ContaDiferente ? (
              <>
                <PDVDiferenteConta
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              </>
            ) : (
              pdvDiff === EnumPDVDiferente.EmpresaDiferente && (
                <PDVDiferenteEmpresa
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              )
            )}
          </>
        )}
      </Grid>
      {!carregando && error && (
        <Grid
          container
          className={classes.containerError}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={10} sm={8} md={4}>
            <Grid className={classes.imageError}>
              <CancelarIcon tipo="GERAL" fill="#D22" />
            </Grid>
            <Typography variant="h5" align="center">
              {error.message}
            </Typography>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={carregando}
              className={classNames(classes.btnTentarNovamente, 'round')}
              onClick={() => {
                iniciarLoading();
              }}
            >
              <AtualizarIcon tipo="BUTTON_PRIMARY" />
              Tentar Novamente
            </Button>
          </Grid>
        </Grid>
      )}

      {alteracaoCadastros && (
        <DialogAlteracaoCadastros
          openned={alteracaoCadastros.cadastrosAlterados}
          ultimaAlteracaoAPI={alteracaoCadastros.ultimaAlteracaoAPI}
          ultimaAlteracaoLocal={alteracaoCadastros.ultimaAlteracaoLocal}

          closeModal={() => {
            setAlteracaoCadastros(
              { cadastrosAlterados: false, ultimaAlteracaoAPI: undefined, ultimaAlteracaoLocal: undefined }
            );
            verificarSincronizacao();
          }}
          handleClick={() => {
            return push(PdvRotasMock.sincronizarDadosRota);
          }}
        />
      )
      }
    </>
  );
};

export default MovSimplesLandingPage;
