import { Box, Grid, Typography } from 'views/design-system';
import classNames from 'classnames';
import { useMemo } from 'react';
import { CircularLoading } from 'views/components';
import { Stepper } from 'views/components/stepper';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { useThemeQueries } from 'views/theme';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { useStyles } from './ativar-revisar-nfce-styles';
import { AtivarRevisarNFCEHeader } from './components/ativar-revisar-nfce-header/ativar-revisar-nfce-header';
import { useAtivarRevisarNFCE } from './components/use-ativar-revisar-nfce/use-ativar-revisar-nfce';

export const AtivarRevisarNFCE = () => {
  const classesDefault = useDefaultCadastroStyles();

  const { carregando, formArray, formStepper } = useAtivarRevisarNFCE();
  const classes = useStyles();
  const stepperClasses = useStepperStyles();
  const { isMobile } = useThemeQueries();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        <Box mt={3}>
          <Grid
            spacing={2}
            container
            style={{
              minWidth: isMobile ? '100%' : '71%',
              maxWidth: isMobile ? '100%' : '71%',
              margin: '0 auto',
              alignItems: 'center',
            }}
          >
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={12} sm={5}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={12}
                sm={formArray[formStepper.currentStep].previousButton ? 7 : 12}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [
    stepperClasses.stepButtons,
    isMobile,
    formArray,
    formStepper.currentStep,
  ]);

  return (
    <div className={classesDefault.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <Grid className={classesDefault.header}>
        <AtivarRevisarNFCEHeader />
      </Grid>
      <div className={classes.content}>
        <div className={stepperClasses.stepper}>
          <Stepper
            linear={true}
            steps={formArray}
            stepAtual={formStepper.currentStep}
          />
        </div>
        <div className={stepperClasses.stepFix}>
          <div className={classNames(stepperClasses.step, classes.stepContent)}>
            <div
              className={stepperClasses.stepTitleContent}
              style={{ marginBottom: '15px' }}
            >
              <Typography
                align="center"
                className={stepperClasses.stepTitle}
                variant="h3"
                color="primary"
              >
                {formArray[formStepper.currentStep].formTitleIcon}
                {formArray[formStepper.currentStep].formTitle}
              </Typography>
              <Box mt={3}>
                <Typography align="center" variant="body1">
                  {formArray[formStepper.currentStep].formDesc}
                </Typography>
              </Box>
            </div>
            <div
              className={stepperClasses.stepForm}
              style={{
                minWidth: isMobile ? '100%' : '70%',
                maxWidth: isMobile ? '100%' : '70%',
                margin: '0 auto',
                alignItems: 'center',
              }}
            >
              {formArray[formStepper.currentStep].form}
            </div>
          </div>
        </div>

        {getButtons}
      </div>
    </div>
  );
};
