import { Grid, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './card-performance-relatorio-styles'
import { DefaultCard, useDefaultCardStyles } from 'views/components/cards/components';
import { RelatorioRender } from 'services/app/providers/relatorio-performance-provider/relatorio-performance-provider';

interface Props {
    model: RelatorioRender;
    onClick: (name: string) => any;
}

export const CardPerformanceRelatorio = ({
    model,
    onClick
}: Props) => {
    const classes = useDefaultCardStyles();
    const pessoaClasses = useStyles();
    const { md } = useThemeQueries()


    return (
        <>
            <DefaultCard
                className={pessoaClasses.card}
                onClick={() => onClick(model.nome)}
            >
                <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${pessoaClasses.gridContainer}`}>
                    <Grid item xs={12} md={2} className={pessoaClasses.nome}>
                        <Typography color="textPrimary" variant="caption">
                            Componente
                        </Typography>
                        <Typography
                            color='textPrimary'
                            variant="body1"
                        >
                            <b>{model.nome}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Tempo Máximo de Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.tempoMaximo} ms
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Tempo Mínimo de Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.tempoMinimo} ms
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Tempo Médio de Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.mediaTempo} ms
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Vezes que Renderizou
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.vezesRender} Vezes
                        </Typography>
                    </Grid>
                </Grid>
            </DefaultCard>
        </>
    );
};