import { Box, Grid, Typography } from 'views/design-system';
import { useCallback, useEffect, useMemo } from 'react';
import { useStyles } from './identificacao-fragment-styles';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useClienteIdentificacao } from './components/use-cliente-identificacao';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { Stepper } from 'views/components/stepper';
import classNames from 'classnames';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumTelaIdentificacao } from 'model/enums/enum-aba-tela-identificacao';
import { useHistory, useLocation } from 'react-router-dom';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { ConvenioCartaoAutenticarModel } from 'model/api/gestao/convenio';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { MovPessoasPesquisa } from '../../../components/pesquisa/pessoas/mov-pessoas-pesquisa';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

interface IdentificacaoClienteProps {
  redirecionarVenda: () => void;
  loading?: boolean
}

interface LocationProps {
  convenio?: {
    modoPagamento: MovSimplesPagamentoModel
    credenciais: CredenciamentoSafra
    credenciado: boolean
    loginConvenio?: ConvenioCartaoAutenticarModel
  }
}

export function IdentificacaoCliente({
  redirecionarVenda,
  loading
}: IdentificacaoClienteProps) {
  const { getConfigByCod } = useEmpresaAtual()
  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarCliente)
  const { state } = useLocation<LocationProps | undefined>()
  const history = useHistory()
  const { callEvent } = useEventTools();

  const soliClientesPorModeloTrabalho = useCallback(() => {
    return Number(solicitarClienteVenda)
  }, [solicitarClienteVenda])

  const { showToast } = useToastSaurus();
  const stepperClasses = useStepperStyles();

  //PROVIDERS
  const { setClientePessoa, carregando: carregandoVenda } = useMovAtual();
  const { carregando: carregandoStepper, formArray, formStepper } = useClienteIdentificacao({ redirecionarVenda })
  const { redirectProcessarPagamento, avancarFluxoMov } = useMovRota()
  const { getDesktopMode } = useDesktopMode();
  const isDesktopMode = getDesktopMode();


  //AUX E OUTROS
  const classesDefault = useDefaultCadastroStyles();
  const classes = useStyles()


  // const isFiscal = isPlanoFiscal(plano?.plano);

  const carregando = [carregandoVenda, carregandoStepper, loading].includes(true);


  const onCardSelected = async (cliente: any) => {
    try {
      await setClientePessoa(cliente);
      showToast('success', `Cliente ${cliente.nome} adicionado`);
      if (state?.convenio) {
        await redirectProcessarPagamento({
          ...state.convenio,
          loginConvenio: {
            ...state.convenio.loginConvenio,
            pessoaId: cliente.id
          }
        })
        return
      }
      redirecionarVenda();
    } catch (err: any) {
      showToast('error', err.message);
    }
  };

  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep]?.previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}
            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={!formArray[formStepper.currentStep]?.previousButton ? 12 : 6}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, carregando, formArray, formStepper.currentStep]);

  const renderIdentificarClienteEtapas = () => {

    const steps = [
      EnumTelaIdentificacao.CpfCnpj,
      EnumTelaIdentificacao.Telefone,
      EnumTelaIdentificacao.Nome,
      EnumTelaIdentificacao.DataNascimento,
      EnumTelaIdentificacao.Sexo
    ].filter(step => Boolean(step & soliClientesPorModeloTrabalho()))

    return (
      <Box className={classesDefault.pagesContainer}>
        {isDesktopMode && <Box className={classesDefault.halfPage}>
          <MovPessoasPesquisa onCardSelected={onCardSelected} abertoPermanentemente />
        </Box>}
        <Box className={classNames(classesDefault.cartPage, classesDefault.divisoria, isDesktopMode ? '' : classesDefault.fullWidth)}>
          <div className={classes.content}>
            <div className={classNames(classes.contentForms)}>
              {steps.length > 1 && (
                <div className={(stepperClasses.stepper, classes.stepper)}>
                  <Stepper
                    style={{ padding: 0 }}
                    steps={formArray}
                    stepAtual={formStepper.currentStep}
                  />
                </div>
              )}
              <Box flex style={{ height: "100%" }}>
                <div className={classNames(stepperClasses.stepFix)}>
                  <div
                    className={classNames(
                      stepperClasses.step,
                      stepperClasses.stepContent,
                      classes.step
                    )}
                  >
                    <div className={classNames(stepperClasses.stepForm, classes.stepForm)}>
                      {formArray[formStepper.currentStep].form}
                    </div>
                  </div>
                </div>
              </Box>
            </div>
            <div className={classes.acoes}>
              {getButtons}
            </div>
          </div>
        </Box>
      </Box>
    )
  }

  const handleCustomClickVoltar = useMemo(() => formStepper.currentStep > 0 ? () => {
    formStepper.prevStep()
  } : state?.convenio ? () => history.push('/venda-simples/novo-pagamento') : undefined,
    [formStepper, history, state?.convenio])


  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Identificação do Cliente',
      pesquisa: {
        capturarDigitacao: true,
        allowLetras: true,
        allowNumbers: false,
      },
      voltar: {
        onClick() {
          try {
            avancarFluxoMov();
          } catch (e: any) {
            showToast('error', e.message);
          }
        }
      }
    })
    callEvent(AppEventEnum.PermiteEntradaDigitada, true);
    return () => {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }
  }, [avancarFluxoMov, callEvent, handleCustomClickVoltar, setHeaderProps, showToast])

  const identificarConvenio = useMemo(() => {
    return (
      <Box className={classes.convenio}>
        <Typography align='center'>
          Pagamento com <span className={classes.palavraDestaque}>convênio</span>.
        </Typography>
      </Box>
    )
  }, [classes.convenio, classes.palavraDestaque])

  return (
    <>
      <Grid className={classesDefault.root}>
        <Grid
          style={{
            flex: '1 1 100%',
            minHeight: '451px',
            overflow: 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Grid container flexDirection="column" className={classes.container}>
            {state && identificarConvenio}
            <>
              {renderIdentificarClienteEtapas()}
              {!isDesktopMode && <MovPessoasPesquisa onCardSelected={onCardSelected} abertoPermanentemente={false} />}
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
