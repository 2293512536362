import { Button, Container, Divider, Grid, Typography } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useState } from "react"
import { CancelarIcon, EditarIcon, OkIcon, RelogioIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model"
import { useToastSaurus } from "services/app"
import { useStyles } from "./situacao-entrada-styles"
import { isEmpty } from "lodash"
import { toDateString } from "utils/to-date"
import classNames from "classnames"
import { ButtonSituacao } from "./components/button-situacao/button-situacao"
import { EnumTpAuditoria } from "model/enums/enum-tp-auditoria"
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"

const SituacaoEntradaPage = () => {

    //state
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const { getVendaCompleta, saveChangesVendaCompleta, carregando } = useEntrada(mov)

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        try {
            if (location.state?.mov && !att) {
                setMov({
                    ...location.state.mov,
                    infMov: {
                        ...location.state.mov.infMov,
                    }
                })
                return
            }
            const venda = await getVendaCompleta(movId)

            setMov({
                ...venda,
                infMov: {
                    ...venda.infMov,
                }
            })
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getVendaCompleta, location.state?.mov, movId, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.state, mov, movId]
    );

    useEffect(() => {
        getInfoVenda()
    }, [getInfoVenda])

    const handleAlterarStatusMov = async (status: EnumTpStatusMov) => {
        try {
            if (status === EnumTpStatusMov.Finalizado) {
                if (mov.infMov.prod.length <= 0) {
                    throw new Error("Precisa adicionar produtos para poder finalizar.")
                }
                // if (mov.infMov.pag.reduce((a, b) => a + b.vPag, 0) < mov.infMov.total.vnf) {
                //     throw new Error("O valor dos pagamentos é menor que o total da nota. Revise os pagamentos e tente novamente.")
                // }
            }

            const movAtual = structuredClone(mov) as VendaCompletaModel
            movAtual.infMov.prod = movAtual.infMov.prod.map(x => ({
                ...x,
            }))
            movAtual.status = status
            await saveChangesVendaCompleta(movAtual, false)

            showToast('success', 'Status alterado com sucesso!')
            getInfoVenda(true)
        } catch (error: any) {
            showToast('error', error.message)
        }
    }
    const dataFormat = useCallback((value: Date | string) => {
        const data = new Date(value)
        const hora = data.getHours()
        const minutos = data.getMinutes()

        return `${toDateString(data)} às ${hora.toString().length === 1 ? '0' + hora : hora}:${minutos.toString().length === 1 ? '0' + minutos : minutos} `
    }, [])

    const movSort = mov.audits.filter(x => x.tpAuditoria === EnumTpAuditoria.AlteracaoDeStatus).sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())

    const buttonsList = [
        {
            titulo: 'Em Digitação',
            status: EnumTpStatusMov.EmDigitacao,
            icon: EditarIcon,
            onClick: handleAlterarStatusMov
        },
        {
            titulo: 'Aguardando Recebimento',
            status: EnumTpStatusMov.Pendente,
            icon: RelogioIcon,
            onClick: handleAlterarStatusMov
        },
        {
            titulo: 'Finalizada',
            status: EnumTpStatusMov.Finalizado,
            icon: OkIcon,
            onClick: handleAlterarStatusMov
        },
        {
            titulo: 'Cancelada',
            status: EnumTpStatusMov.Cancelado,
            icon: CancelarIcon,
            onClick: handleAlterarStatusMov
        },
    ]

    const tratandoDescricao = useCallback((descricao: string) => {
        let desc = descricao
        if (desc.includes('EmDigitacao')) {
            desc = desc.replace('EmDigitacao', 'Em Digitação')
        }
        return desc
    }, [])

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Situação da Entrada"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2}>
                            {buttonsList.map(btn => (
                                <Grid item xs={3}>
                                    <ButtonSituacao
                                        icon={btn.icon}
                                        onClick={btn.onClick}
                                        status={btn.status}
                                        statusDestaque={mov.status}
                                        titulo={btn.titulo}
                                        key={btn.status}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} className={classes.marginTitle}>
                            <Typography variant="h6" color="primary" >
                                Últimas Modificações
                            </Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        {isEmpty(mov.audits) && (
                            <Grid item xs={12}>
                                <Typography>
                                    Nenhuma modificação feita
                                </Typography>
                            </Grid>
                        )}
                        {mov.audits.length > 0
                            && movSort.map((status, i) => {
                                return (
                                    <Grid item xs={12} key={status.id}>
                                        <Typography className={classNames(i === 0 ? classes.text : '')} variant="body2">
                                            {dataFormat(status.data)}
                                        </Typography>
                                        <Typography variant="body2" className={classNames(i === 0 ? classes.text : '')}>
                                            {tratandoDescricao(status.descricao)}
                                        </Typography>
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => history.push({
                                        pathname: `/entrada-mercadoria/visualizar/${movId}/pagamento`,
                                        state: {
                                            mov,
                                            manifesto: location.state?.manifesto,
                                            dtManifesto: location.state?.dtManifesto
                                        }
                                    })}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default SituacaoEntradaPage
