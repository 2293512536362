import { Grid } from 'views/design-system';
import useEventListener from '@use-it/event-listener';
import { useKeyDownHandler } from 'services/app/hooks/keydown-handler';
import { focusableElement } from 'utils/focusable-element';
import { ButtonKeyboardAdd } from './components/button-keyboard-add/button-keyboard-add';
import { ButtonKeyboardBackspace } from './components/button-keyboard-backspace/button-keyboard-backspace';
import { ButtonKeyboardNumber } from './components/button-keyboard-number/button-keyboard-number';
import { KeyboardProps } from './keyboard-props';
import { useStyles } from './keyboard-styles';
import { ButtonKeyboardString } from './components/button-keyboard-string/button-keyboard-string';
import { ButtonKeyboardTopMultiply } from './components/button-keyboard-top-multiply/button-keyboard-top-multiply';
import { ButtonKeyboardTopAdd } from './components/button-keyboard-top-add/button-keyboard-top-add';
import { handleClickFeedbackTatil } from 'utils/feedback-tatil-fn';
import { useEffect, useRef } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

export const Keyboard = ({
  isButtonKeyboard00,
  isButtonAddTopKeyboard,
  isButtonMultiplyTopKeyboard,
  isButtonKeyboardAdd,
  isButtonComma,
  isButtonMultiply,
  isNumeric,
  isButtonBackspace,
  isButtonCaracters,
  handleAddValue,
  handleMultiplyValue,
  handleBackSpace,
  handleEscape,
  handleText,
  handleAdd,
  handleSubmit,
  handleClickFocus,
}: KeyboardProps) => {
  const classes = useStyles();
  const { handleKeyDown } = useKeyDownHandler();

  const blockEvento = useRef<boolean>(false);
  const { addHandler, removeHandler } = useEventTools();

  useEffect(() => {
    const bloquearEvento = (abrir: boolean) => {
      blockEvento.current = abrir;
    }

    addHandler(AppEventEnum.TelaPesquisa, bloquearEvento)
    return () => {
      removeHandler(AppEventEnum.TelaPesquisa, blockEvento);
    }
  }, [addHandler, removeHandler])

  useEventListener('keydown', (event) => {
    handleClickFeedbackTatil();
    if (!event.defaultPrevented && !focusableElement(document.activeElement) && !blockEvento.current) {
      const ev = event as KeyboardEvent;
      const newValue = handleKeyDown(
        ev,
        '',
        true,
        !isNumeric,
        !isNumeric,
        0,
        100,
      );
      if (ev.key === "+" && isButtonAddTopKeyboard) {
        if (handleAdd)
          handleAdd();
      } else if (ev.key === "Enter") {
        if (handleSubmit)
          handleSubmit();
      }
      else if (ev.key === "Backspace") {
        if (handleBackSpace)
          handleBackSpace();
      }
      else if (ev.key === "Escape") {
        if (handleEscape)
          handleEscape();
      }
      else if (newValue.length > 0) {
        if (handleText)
          handleText(newValue);
      }
    }
  });

  const handleButtonAddValue = (value: number) => {
    handleClickFeedbackTatil();
    if (handleAddValue) handleAddValue(value);
  };

  const handleButtonMultiplyValue = (value: string) => {
    handleClickFeedbackTatil();
    if (handleMultiplyValue) handleMultiplyValue(value);
  };

  const handleClickButtonNumber = (value: number) => {
    handleClickFeedbackTatil();
    if (handleText) handleText(value.toString());
  };

  const handleClickButtonString = (value: string) => {
    handleClickFeedbackTatil();
    if (handleText) handleText(value);
  };

  const handleClickButtonFocus = (ref: HTMLInputElement) => {
    handleClickFeedbackTatil();
    if (handleClickFocus) {
      handleClickFocus(ref)
    }
  }

  return (
    <Grid container flexDirection="column" style={{ flexGrow: 1, flexWrap: 'nowrap' }}>
      {isButtonMultiplyTopKeyboard && (
        <ButtonKeyboardTopMultiply
          handleButtonMultiplyValue={handleButtonMultiplyValue}
        />
      )}
      {isButtonAddTopKeyboard && (
        <ButtonKeyboardTopAdd handleButtonAddValue={handleButtonAddValue} />
      )}
      <Grid item style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Grid container className={classes.keyboardContainer}>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={7}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={8}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={9}
            />
          </Grid>

          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={4}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={5}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={6}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={1}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={2}
            />
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={3}
            />
          </Grid>
          <Grid item xs={4}>
            {isButtonKeyboard00 && (
              <ButtonKeyboardString
                label="00"
                clickFunc={handleClickButtonString}
                value="00"
              />
            )}
            {isButtonKeyboardAdd && (
              <ButtonKeyboardAdd alt="Adicionar" clickFunc={handleAdd} />
            )}
            {isButtonComma && (
              <ButtonKeyboardString
                label=","
                clickFunc={handleClickButtonString}
                value=","
              />
            )}
            {isButtonCaracters && (
              <ButtonKeyboardString
                label="abc"
                clickFunc={handleClickButtonFocus}
                value=""
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <ButtonKeyboardNumber
              clickFunc={handleClickButtonNumber}
              value={0}
            />
          </Grid>
          <Grid item xs={4}>
            {isButtonBackspace && (
              <ButtonKeyboardBackspace
                alt="apagar"
                clickFunc={handleBackSpace}
              />
            )}
            {isButtonMultiply && (
              <ButtonKeyboardString
                clickFunc={handleClickButtonString}
                label="x"
                value="*"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
