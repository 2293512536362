import { useCallback, useEffect, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useHistory } from "react-router-dom";
import { EnumTour } from "model/enums/enum-tour";
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { MenuButtonHeader } from "views/pages/private/movimentacao/components/header/menu-button-header/menu-button-header";

export const DashboardHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { location } = useHistory()
    const { callTour } = useTourSaurus()
    const [finalizouTourMenu, setFinalizouTourMenu] = useState<boolean>(false)
    const [finalizouBoasVindas, setFinalizouBoasVindas] = useState<boolean>(false)
    const [finalizouWizard, setfinalizouWizard] = useState<boolean>(false)
    const { addHandler, removeHandler } = useEventTools()
    const { isMenuAberto } = useMenuPrincipal()
    const menuAberto = isMenuAberto()

    const leftArea = useCallback(() => {
        if (location.pathname.indexOf('venda-simples') > -1) {
            return (
                <MenuButtonHeader />
            );
        }
        return isMobile ? (
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={() => {
                    abrirMenu()
                }}
                id="menuInicial"
            ></ButtonPrivateHeader>
        ) : null
    }, [location.pathname, isMobile, abrirMenu]);

    const acessouMenu = useCallback((value: any) => {
        setFinalizouTourMenu(value.finalizado)
    }, [])

    const acessouBoasVindas = useCallback((value: any) => {
        setFinalizouBoasVindas(value.finalizado)
    }, [])

    const acessouWizard = useCallback((value: any) => {
        setfinalizouWizard(value.finalizado)
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.FinalizouWizard, acessouWizard)
        return () => {
            removeHandler(AppEventEnum.FinalizouWizard, acessouWizard)
        }
    }, [acessouWizard, addHandler, removeHandler])


    useEffect(() => {
        addHandler(AppEventEnum.TourFinish, acessouMenu)
        return () => {
            removeHandler(AppEventEnum.TourFinish, acessouMenu)
        }
    }, [acessouMenu, addHandler, removeHandler])

    useEffect(() => {
        addHandler(AppEventEnum.TourFinish2, acessouBoasVindas)
        return () => {
            removeHandler(AppEventEnum.TourFinish2, acessouBoasVindas)
        }
    }, [acessouBoasVindas, addHandler, removeHandler])

    useEffect(() => {
        if (finalizouWizard) {
            callTour(EnumTour.INICIO)
        }

    }, [callTour, finalizouWizard])

    useEffect(() => {
        if (finalizouWizard) {
            if (isMobile) {
                if (finalizouBoasVindas && !menuAberto) {
                    setTimeout(() => { callTour(EnumTour.MENUDENAVEGACAO) }, 1000)
                }
                if (menuAberto && finalizouTourMenu) {
                    setTimeout(() => { callTour(EnumTour.INICIARVENDA) }, 1000)
                }
            } else {
                if (finalizouBoasVindas) {
                    setTimeout(() => { callTour(EnumTour.INICIARVENDA) }, 1000)
                }
            }
        }
    }, [callTour, finalizouBoasVindas, finalizouTourMenu, finalizouWizard, isMobile, menuAberto])

    return (
        <>
            <PrivatePageHeader
                title="Dashboard"
                leftArea={leftArea()}
                bottomArea={<></>}
            />
        </>
    );
};
