import { Grid, Typography } from '@material-ui/core';
import { CardCargaProdutoProps } from './card-carga-produto-props';
import { useCallback } from 'react';
import { Styles } from './card-carga-produto-mobile-styles';
import { toDecimalString } from 'utils/to-decimal';
import { TpUploadCargaStatusMock } from 'data/mocks/tp-carga-tipo-mock';
import { EnumUploadCargaStatus } from 'model/enums/enum-upload-carga-status';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components';
import { TpProdutoFarmaciaMock } from 'data/mocks/tp-produto-mock';

export const CardCargaProdutoMobile = ({
  model,
  onClick,
}: CardCargaProdutoProps) => {
  const classes = Styles();

  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): any => {
    switch (model.status.codigo) {
      case EnumUploadCargaStatus.Processado:
        return '#10C822';
      case EnumUploadCargaStatus.Aguardando:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Analisando:
        return '#4A4A4A';
      case EnumUploadCargaStatus.Erro:
        return '#F44336';
      case EnumUploadCargaStatus.Processando:
        return theme.palette.warning.dark;
      case EnumUploadCargaStatus.AguardandoProcessamento:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Cancelado:
        return '#4A4A4A';
      default:
        return '#28EC87';
    }
  }, [model.status, theme.palette.warning.dark]);

  const status = TpUploadCargaStatusMock.filter((x) => x.Key === model.status.codigo)[0]?.Value;

  return (
    <DefaultCard onClick={() => onClick(model)}>
      <Grid container spacing={1} className={classes.cardContent}>
        <Grid item xs={5}>
          <Typography color="textPrimary" variant="caption">
            Código
          </Typography>

          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
          >
            {model.codigo || 'Sem Código'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="textPrimary" variant="caption">
            Tipo
          </Typography>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
          >
            {TpProdutoFarmaciaMock.find(x => x.Key === model.tpCadastro)?.Value || 'Produto'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <div
              className={classes.celulaGridStatus}
              style={{
                background: retornarCorStatus(),
                display: 'nowrap',
              }}
            >
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{
                  color: '#fff',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '12px',
                }}
              >
                {status}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textPrimary" variant="caption">
            Descrição
          </Typography>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
          >
            {model.descricao || 'Sem Descrição'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textPrimary" variant="caption">
            Categoria
          </Typography>

          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
          >
            {model.categoria || 'Sem Categoria'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color="textPrimary" variant="caption">
            Preço
          </Typography>
          <Typography
            className={'celula-grid-value'}
            color="textPrimary"
            variant="body1"
          >
            <strong>R$ {toDecimalString(model.preco || 0, 2)}</strong>
          </Typography>
        </Grid>
      </Grid>
    </DefaultCard>
  );
};
