import { Button, Grid } from '@material-ui/core';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useStyles } from './form-contrato-usuario-styles';
import { useFormContratoUsuarioValidation } from './form-contrato-usuario-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { ContratoUsuarioFormModel } from '../../../../../model/app/forms/master/contrato-usuario-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetUsuarioValidarCadastro } from 'data/api/gestao/usuario/get-validar-cadastro';
import { isEmpty, isEqual } from 'lodash';

export const FormContratoUsuario = React.forwardRef<
  DefaultFormRefs<ContratoUsuarioFormModel>,
  DefaultFormProps<ContratoUsuarioFormModel>
>((props: DefaultFormProps<ContratoUsuarioFormModel>, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const { FormContratoUsuarioValidationYup } =
    useFormContratoUsuarioValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new ContratoUsuarioFormModel('', '', '', ''),
  });
  const { isMobile } = useThemeQueries();
  const [emailDigitadoAnteriormente, setEmailDigitadoAnteriormente] = useState<string>('')
  const { getUsuarioValidarCadastro, carregando: loadingForm } = useGetUsuarioValidarCadastro()
  const preencherApelido = useRef<boolean>(true)

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue
  } = useForm<ContratoUsuarioFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormContratoUsuarioValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });
  const carregando = useMemo(() => {
    return (props.loading && props.showLoading) || loadingForm
  }, [loadingForm, props.loading, props.showLoading])

  const onSubmit = (form: ContratoUsuarioFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new ContratoUsuarioFormModel('', '', '', '') });
      reset();
      if (!isMobile) {
        txtEmail?.current?.focus();
      }
    },
    fillForm: (model: ContratoUsuarioFormModel) => {
      setModel({ modeloForm: model });
      reset({
        ...model,
      });
      if (!isMobile) {
        txtEmail?.current?.focus();
      }
    },
  }));

  const getEmailUsuario = useCallback(async () => {
    try {
      if (!preencherApelido.current) return
      const emailCompleto = getValues('email')
      if (!isEmpty(emailCompleto) && isEqual(emailCompleto, emailDigitadoAnteriormente)) {
        return
      }
      setEmailDigitadoAnteriormente(emailCompleto)

      const caracterDeCorte = emailCompleto.split('@')
      const apelido = caracterDeCorte[0]
      const res = await getUsuarioValidarCadastro('apelido', apelido)
      if (res.erro) throw res.erro
      if (!res.resultado?.data) {
        setValue('apelido', apelido)
      } else {
        setValue('apelido', '')
      }
      preencherApelido.current = false
    } catch (e: any) {
      console.log(e.message)
    }
  }, [emailDigitadoAnteriormente, getUsuarioValidarCadastro, getValues, setValue])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="EMAIL"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtEmail}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="E-mail"
                    placeholder="usuario@empresa.com.br"
                    error={Boolean(errors.email && errors.email.message)}
                    helperText={
                      touchedFields.email || errors.email
                        ? errors.email?.message
                        : undefined
                    }
                    {...field}
                    onBlur={() => { getEmailUsuario() }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="saudacao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Informe seu Nome"
                    placeholder="Ex: João da Silva"
                    error={Boolean(errors.saudacao && errors.saudacao.message)}
                    helperText={
                      touchedFields.saudacao || errors.saudacao
                        ? errors.saudacao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="apelido"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Apelido (Nome de usuário)"
                    placeholder="Ex: joao11"
                    error={Boolean(errors.apelido && errors.apelido.message)}
                    helperText={
                      touchedFields.apelido || errors.apelido
                        ? errors.apelido?.message
                        : undefined
                    }
                    {...field}
                    onBlur={() => {
                      field.onBlur()
                      preencherApelido.current = false
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="senha"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="PASSWORD"
                    allowSubmit={false}
                    disabled={props.loading}
                    showStartAdornment={true}
                    fullWidth={true}
                    autoComplete={'off'}
                    label="Senha"
                    placeholder="(6-32 caracteres)"
                    error={Boolean(errors.senha && errors.senha.message)}
                    helperText={
                      touchedFields.senha || errors.senha
                        ? errors.senha?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="confirmar"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    allowSubmit={true}
                    tipo="PASSWORD"
                    disabled={props.loading}
                    showStartAdornment={true}
                    fullWidth={true}
                    autoComplete={'off'}
                    label="Confirmação da Senha"
                    placeholder="(6-32 caracteres)"
                    error={Boolean(
                      errors.confirmar && errors.confirmar.message,
                    )}
                    helperText={
                      touchedFields.confirmar || errors.confirmar
                        ? errors.confirmar?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
