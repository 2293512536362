import { useCallback, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, ProcurarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useHistory, useLocation } from "react-router-dom";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";
import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { EnumCadastroStatus } from "model";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { stringNumeros } from "utils/string-numeros";


export const PessoaHeader = () => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const history = useHistory();
  const location = useLocation();
  const { abrirPessoasFiltroModal } = useFiltrosModais();

  const urlParams = new URLSearchParams(location.search)

  const filtros = {
    status: isEmpty(urlParams.get('status')) ? EnumCadastroStatus.LIBERADO : Number(urlParams.get('status')),
    termo: urlParams.get('termo') || '',
    tpCadastro: !isEmpty(urlParams.getAll('tpCadastro')) ? urlParams.getAll('tpCadastro').map(tp => Number(tp)) : [-1],
  }

  const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  const leftArea = useCallback(
    () => (
      <>
        {isMobile && !location.pathname.includes('/adicionar') && !location.pathname.includes('/pesquisar') ?
          <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
          null}
      </>
    ),
    [isMobile, location.pathname, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <Box display='flex' gridGap={8}>
        {!openTextfield && <ButtonPrivateHeader
          icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
          tooltip={'Abrir Pesquisa'}
          onClick={() => setOpenTextfield(true)}
        ></ButtonPrivateHeader>}
        <ButtonPrivateHeader
          icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
          tooltip={'Abrir Filtros'}
          onClick={() => {
            abrirPessoasFiltroModal({
              status: filtros.status,
              termo: filtros.termo,
              tpCadastro: filtros.tpCadastro
            })
          }}
        ></ButtonPrivateHeader>
      </Box>
    ),
    [abrirPessoasFiltroModal, filtros.status, filtros.termo, filtros.tpCadastro, openTextfield]
  );

  const searchTextfield = (
    <FormPesquisaHeader
      text={filtros.termo}
      closeSearch={() => setOpenTextfield(false)}
      onSubmit={(model) => {
        let documento = model.generico
        if(validarCPFCNPJ(stringNumeros(documento))){
          documento = stringNumeros(documento);
        }
        const searchs: Array<string | null> = [
          model.generico.length > 0 ? `termo=${documento}` : null,
          filtros.status !== undefined && filtros.status !== null ? `status=${filtros.status}` : null,
        ]

        filtros.tpCadastro.forEach((tp) => {
          searchs.push(`tpCadastro=${tp}`)
        })

        let query = ''

        searchs
          .filter(x => x)
          .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
          pathname: '/pessoas',
          search: query
        })
      }}
      placeholder="Buscar pessoa pelo nome ou documento..."
    />
  )


  return (
    <>
      <PrivatePageHeader
        title='Pessoas'
        leftArea={leftArea()}
        middleArea={(openTextfield) && searchTextfield}
        rightArea={(location.pathname === '/pessoas' || location.pathname === '/pessoas/') && rightArea()}
      />
    </>
  );
};
