import { EnumTpEmis } from "./../../../model/enums/enum-tp-emis";
import { usePutPedidoStatus } from "data/api/gestao/pedido-dados/put-pedido-status";
import { useGetPessoaByDoc } from "data/api/gestao/pessoa/get-pessoa-by-doc";
import { usePostNovaVenda } from "data/api/gestao/venda/post-nova-venda";
import { useGetUltimaNumeracao } from "data/api/gestao/ultima-numerao/get-ultima-numeracao";
import { useDeleteCancelarVenda } from "data/api/gestao/venda/delete-cancelar-venda";
import { usePutFinalizarVenda } from "data/api/gestao/venda/put-finalizar-venda";
import { TabelaNumeracao } from "database/interfaces/interface-tabela-numeracao";
import { TouchoneDBPrimary } from "database/touchone-database";
import { isEmpty, toInteger } from "lodash";
import {
  EnumCadastroTipo,
  EnumMovModelo,
  EnumPagTpMod,
  EnumPagTpTransacao,
  RetornoApiModel
} from "model";
import {
  InformacoesGeraisPedido,
  MovSimplesModel
} from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import {
  MovSimplesProdutoModel,
  RastroVenda
} from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { MovNumeracaoModel } from "model/api/gestao/movimentacao/utils/mov-numeracao";
import { PedidoModel } from "model/api/gestao/pedido/pedido-model";
import {
  PedidoProdutoModelVenda,
  RastroPedido
} from "model/api/gestao/pedido/pedido-produto-model";
import { PessoaContatosModel, PessoaModel } from "model/api/gestao/pessoa";
import { VendaModel } from "model/api/gestao/venda/venda-model";
import { VendaPagsModel } from "model/api/gestao/venda/venda-pags-model";
import { VendaProdutoModel } from "model/api/gestao/venda/venda-produto-model";
import { FinalizarVendaModel } from "model/app/mov-simples/finalizar-venda-model";
import { PaymentSuccessModel } from "model/app/pagamento-cartao/payment-success";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumBalanca } from "model/enums/enum-balanca";
import { EnumIndStatusMovPag } from "model/enums/enum-indstatus-movpag";
import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { guidEmpty } from "utils/guid-empty";
import { picker } from "utils/picker";
import {
  isPlanoConsumacao,
  isPlanoControleMesasComandas,
  isPlanoFarmaceutico,
  isPlanoFiscal
} from "utils/plano-utils";
import { roundTo } from "utils/round-to";
import { dataAtual } from "utils/to-date";
import { useCadastroPadrao } from "./cadastro-padrao";
import { useDevice } from "./device";
import { useEventTools } from "./events/event-tools";
import { useNumeracao } from "./gerenciar-numeracao";
import { useVenda } from "./gerenciar-venda";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useMovAnteriorStorage } from "./mov-anterior-storage";
import { useMovAtualStorage } from "./mov-atual-storage";
import { useMovSimples } from "./mov-simples";
import { usePDV } from "./pdv";
import { TabelaNCM } from "database/interfaces/interface-tabela-ncm";
import { calcVTrib } from "./utils/calcVTrib";
import { useSessaoPDV } from "./sessao-pdv";
import { useSincronizacaoGeral } from "./sincronizar-dados";
import { EnumSincronizacaoGeralStatus } from "model/enums/enum-sincronizacao-geral-status";
import { calcPercent } from "utils/calc-percent";
import { newGuid } from "utils/new-guid";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { EnumTpProduto } from "model/enums/enum-tp-produto";
import { EnumIndDesperdicio } from "model/enums/enum-ind-desperdicio";
import { EnumStatusSituacaoPedido } from "model/enums/enum-status-situacao-pedido";
import { useDeleteFechamentoParcialPedido } from "data/api/gestao/pedido/delete-fechamento-parcial-pedido";
import { consoleDev } from "utils/console-dev";
import { EnumTpSincronizacao } from "model/enums/enum-tp-sincronizacao";
import {
  CredenciamentoSafra,
  FinalizadoraModel
} from "model/api/gestao/finalizadora/finalizadora-model";
import { TabelaProdutos } from "database/interfaces/interface-tabela-produtos";
import { useCadastros } from "./cadastros";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import useFirebase, { EnumColectionFireBase } from "./firebase";
import { EnumPDVConfigCod } from "model/enums/enum-pdv-config";
import { PontosVendaCompletoModel } from "model/app/ponto-venda/ponto-venda-completo-model";
import { useContratoAtual } from "./contrato-atual";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";
import { useSessaoAtual } from "../providers/sessao-atual";
import { EnumTpCalculoModificador } from "model/enums/enum-tpcalculo-modificador";
import { stringNumeros } from "utils/string-numeros";
import { useEmpresaAtual } from "./empresa-atual";
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import {
  CobrModel,
  DupCobrModel,
  ReceitaMedicaModel
} from "model/api/gestao/venda/venda-completa-model";
import { MovSimplesPessoaModel } from "model/api/gestao/movimentacao/simples/mov-simples-pessoa-model";
import { EnumModeloDeTrabalho } from "model/enums/enum-modelo-de-trabalho";
import { EnumTipoFinalizacaoPDV } from "model/enums/enum-tipo-finalizacao-pdv";
import { EnumIdeStatus } from "model/enums/enum-ide-status";
import { getLocalISOString } from 'utils/date-iso-string';
import { usePostPromocaoProcessamento } from "data/api/gestao/promocao/post-promocao-processamento";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import { TabelaMedicamentos } from "database/interfaces/interface-tabela-medicamentos";
import { SincronizacaoDados } from "./sincronizacao-cadastros";
import { toDecimal } from "utils/to-decimal";
import { MovRotasMock } from "data/mocks/mov-rotas-mock";
import { useMovValidators } from './mov-validators';
import { useAguardandoPagamentoStore } from "views/pages/private/movimentacao/mov-processando-pagamento/components/aguardando-pagamento/aguardando-pagamento-store";
import { padEnd } from "utils/pad-string";
import { useControleMedicamento } from "./controle-medicamento";

export interface IniciarPagamentoParamsProps {
  pagamento: MovSimplesPagamentoModel
  naoAlteraTaxa: boolean
  credenciamento: CredenciamentoSafra
  credenciado?: boolean
  tpPayments?: EnumPagTpMod[] | undefined
}

export function useMovAtual() {
  //CHAMADAS DE API
  const { getPessoaByDoc, carregando: carregandoGetPessoaByDoc } =
    useGetPessoaByDoc();
  const { getConsumidor } = useCadastroPadrao();
  const { postNovaVenda, carregando: carregandoPostNovaVenda } =
    usePostNovaVenda();
  const { putFinalizarVenda, carregando: carregandoPutFinalizarVenda } =
    usePutFinalizarVenda();
  const { deleteCancelarVenda, carregando: carregandoDeleteCancelarVenda } =
    useDeleteCancelarVenda();
  const { postPromocaoProcessamento, carregando: carregandoPostPromocaoProcessamento } =
    usePostPromocaoProcessamento();

  const {
    getUltimaNumeracao: UltimaNumeracao,
    carregando: carregandoGetUltimaNumeracao
  } = useGetUltimaNumeracao();
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();
  const { deleteFechamentoParcialPedido, carregando: carregandoDeleteFechamentoParcial } =
    useDeleteFechamentoParcialPedido();

  // OFF
  //PROVIDERS E OUTROS AUXILIARES
  const { getEmpresaSelecionada, getPessoa, plano } = useSessaoAtual();
  const { getEmpresaAtual, getConfigByCod: getConfigEmp } = useEmpresaAtual();
  const { startPayment, carregando: carregandoDevice, printType } = useDevice();
  const {
    getPDV,
    getConfigByCod: getConfigPdv,
    carregando: carregandoPDV,
  } = usePDV();
  const history = useHistory();
  const planoFiscal = isPlanoFiscal(plano?.plano);
  const planoMesasComandas = isPlanoControleMesasComandas(plano?.plano);
  const empresaAtual = getEmpresaAtual();
  const { imprimirCupom, imprimirTicket } = useMovSimples();
  const { getRegistro, setRegistro } = useGestaoStorage();
  const { get: getMovStorage, persist: persistMovStorage } =
    useMovAtualStorage();
  const { persist: persistMovAnterior } = useMovAnteriorStorage();
  const { getNumeracao, setNumeracao } = useNumeracao();
  const { callEvent } = useEventTools();
  const { setVenda, cancelarVenda, getVendas } = useVenda();
  const { getSessao } = useSessaoPDV();
  const { getProdutoServico, getProdutoEntrega } = useCadastroPadrao();
  const {
    abrirDialogPix,
    abrirDialogCredenciarPix,
    fecharDialogCredenciarPix,
    abrirDialogImpressaoNfe
  } = useCadastros();
  const { getStatusSincronizacaoAtual, iniciarSincronizacaoGeral } =
    useSincronizacaoGeral();
  const { dadosIniciaisPendente } = useMovValidators();
  const setAguardandoPagamentoProps = useAguardandoPagamentoStore(state => state.setAguardandoPagamentoProps);
  const { retornarInfoMedicamento } = useControleMedicamento();

  const { getConfigByCod: getConfigContratoByCod } = useContratoAtual();
  const { logError } = useFirebase();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
  const tpSincronizacao = getConfigEmp(EnumEmpresaConfig.TipoSincronizacao);

  const carregando =
    carregandoGetPessoaByDoc ||
    carregandoPutFinalizarVenda ||
    carregandoDeleteCancelarVenda ||
    carregandoPostNovaVenda ||
    carregandoPDV ||
    carregandoPutPedidoStatus ||
    carregandoDeleteFechamentoParcial ||
    carregandoGetUltimaNumeracao ||
    carregandoPostPromocaoProcessamento;

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  consoleDev("MovAtual");

  // MARK: getMov
  const getMov = useCallback((): MovSimplesModel | undefined => {
    return getMovStorage();
  }, [getMovStorage]);

  //MARK: aplicaTaxaServicoMov
  const aplicaTaxaServicoMov = useCallback(() => {

    let aplicaTaxa = false;

    const configAplicarTaxa = getConfigPdv(EnumPDVConfigCod.TaxaServicoSemPedido)?.toLocaleLowerCase() === 'sim';
    const mov = getMov()

    if (!mov && isEmpty(mov)) {
      return false
    }

    if (mov?.mod === EnumMovModelo.PEDIDO) {
      aplicaTaxa = true
    } else {
      const hasPedidosImportados = (mov?.informacoesGeraisPedido?.pedidos ?? []).length > 0
      if (hasPedidosImportados) {
        aplicaTaxa = configAplicarTaxa
      }
    }

    return aplicaTaxa
  }, [getConfigPdv, getMov])

  // MARK: getUltimaNumeracao
  const getUltimaNumeracao = useCallback(
    async (
      pdv: PontosVendaCompletoModel | undefined,
      mod: number | null
    ): Promise<MovNumeracaoModel | undefined> => {
      let tpAmb = 2;
      // PROCURANDO O TIPO DO AMBIENTE NAS CONFIGURACOES DO EMISSOR
      const emissor = getPDV()?.emissor;
      if (emissor) {
        tpAmb = toInteger(emissor?.tpAmb ?? "2");
      }

      if (pdv === undefined) {
        throw new Error("Não foi possível identificarmos seu ponto de venda!");
      }

      const tipoFinalizacaoPDV = getConfigEmp(
        EnumEmpresaConfig.TipoFinalizacaoVendaPDV
      );

      if (
        mod === EnumMovModelo.NFCE ||
        mod === EnumMovModelo.NFE ||
        tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL ||
        tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE
      ) {
        const numeracaoAPI = await UltimaNumeracao(
          getEmpresaSelecionada()!.Id,
          `${mod ?? getMov()!.mod}`,
          `${+pdv!.numCaixa + 100}`,
          `${tpAmb}`
        );

        if (numeracaoAPI.erro) {
          if (mod === EnumMovModelo.ORCAMENTO) {
            // Tento obter a numeração local
            let numeracaoLocal: TabelaNumeracao | undefined = undefined;
            let respostaNumeradaAPI: RetornoApiModel | undefined = undefined;

            // TODO: IF TEMPORARIO PARA DESABILITAR BUSCA DA NUMERACAO OFFLINE
            if (
              getMov()!.mod === EnumMovModelo.NFCE ||
              getMov()!.mod === EnumMovModelo.NFE
            ) {
              numeracaoLocal = undefined;
            } else {
              numeracaoLocal = await getNumeracao(
                mod ?? getMov()!.mod ?? 10,
                +pdv!.numCaixa + 100,
                tpAmb
              );
            }

            if (!numeracaoLocal) {
              respostaNumeradaAPI = await UltimaNumeracao(
                getEmpresaSelecionada()!.Id,
                `${mod ?? getMov()!.mod}`,
                `${+pdv!.numCaixa + 100}`,
                `${tpAmb}`
              );

              if (
                respostaNumeradaAPI.erro ||
                respostaNumeradaAPI === undefined
              ) {
                if (
                  getMov()!.mod === EnumMovModelo.NFCE ||
                  getMov()!.mod === EnumMovModelo.NFE
                ) {
                  throw new Error("Erro ao buscar ultima numeração");
                }
                // SE NÃO CONSEGUIR BUSCAR NA API, RETORNO UNDEFINED PARA QUEM USA TRATAR.
                return undefined;
              } else {
                try {
                  await setNumeracao(
                    respostaNumeradaAPI.resultado?.data.mod,
                    respostaNumeradaAPI.resultado?.data.serie,
                    tpAmb,
                    respostaNumeradaAPI.resultado?.data.nnf
                  );
                } catch (e: any) { }
              }
            }

            const ultimo = new MovNumeracaoModel();

            ultimo.modelo = numeracaoLocal
              ? numeracaoLocal?.mod ?? 10
              : respostaNumeradaAPI?.resultado?.data.mod;
            ultimo.nNf = numeracaoLocal
              ? numeracaoLocal?.numero
              : respostaNumeradaAPI?.resultado?.data.nnf;
            ultimo.serie = numeracaoLocal
              ? numeracaoLocal?.serie
              : respostaNumeradaAPI?.resultado?.data.serie;
            ultimo.tpAmb = tpAmb;

            return ultimo;
          }
          throw new Error("Não foi possível identificar a numeração");
        }

        const ultimoNumeroApi = new MovNumeracaoModel();

        ultimoNumeroApi.modelo = numeracaoAPI?.resultado?.data.mod;
        ultimoNumeroApi.nNf = numeracaoAPI?.resultado?.data.nnf;
        ultimoNumeroApi.serie = numeracaoAPI?.resultado?.data.serie;
        ultimoNumeroApi.tpAmb = tpAmb;

        return ultimoNumeroApi;
      }

      // Tento obter a numeração local
      let numeracaoLocal: TabelaNumeracao | undefined = undefined;
      let respostaNumeradaAPI: RetornoApiModel | undefined = undefined;

      // TODO: IF TEMPORARIO PARA DESABILITAR BUSCA DA NUMERACAO OFFLINE
      if (
        getMov()!.mod === EnumMovModelo.NFCE ||
        getMov()!.mod === EnumMovModelo.NFE
      ) {
        numeracaoLocal = undefined;
      } else {
        numeracaoLocal = await getNumeracao(
          mod ?? getMov()!.mod ?? 10,
          +pdv!.numCaixa + 100,
          tpAmb
        );
      }

      if (!numeracaoLocal) {
        respostaNumeradaAPI = await UltimaNumeracao(
          getEmpresaSelecionada()!.Id,
          `${mod ?? getMov()!.mod}`,
          `${+pdv!.numCaixa + 100}`,
          `${tpAmb}`
        );

        if (respostaNumeradaAPI.erro || respostaNumeradaAPI === undefined) {
          if (
            getMov()!.mod === EnumMovModelo.NFCE ||
            getMov()!.mod === EnumMovModelo.NFE
          ) {
            throw new Error("Erro ao buscar ultima numeração");
          }
          // SE NÃO CONSEGUIR BUSCAR NA API, RETORNO UNDEFINED PARA QUEM USA TRATAR.
          return undefined;
        } else {
          try {
            await setNumeracao(
              respostaNumeradaAPI.resultado?.data.mod,
              respostaNumeradaAPI.resultado?.data.serie,
              tpAmb,
              respostaNumeradaAPI.resultado?.data.nnf
            );
          } catch (e: any) { }
        }
      }

      const ultimo = new MovNumeracaoModel();

      ultimo.modelo = numeracaoLocal
        ? numeracaoLocal?.mod ?? 10
        : respostaNumeradaAPI?.resultado?.data.mod;
      ultimo.nNf = numeracaoLocal
        ? numeracaoLocal?.numero
        : respostaNumeradaAPI?.resultado?.data.nnf;
      ultimo.serie = numeracaoLocal
        ? numeracaoLocal?.serie
        : respostaNumeradaAPI?.resultado?.data.serie;
      ultimo.tpAmb = tpAmb;

      return ultimo;
    },
    [
      UltimaNumeracao,
      getConfigEmp,
      getEmpresaSelecionada,
      getMov,
      getNumeracao,
      getPDV,
      setNumeracao
    ]
  );

  // MARK: getUltimoProduto
  const getUltimoProduto = useCallback(
    async (produtoGradeId: string) => {
      const movAtual = getMov();
      if (isEmpty(movAtual?.produtos)) return undefined;

      const produtos = movAtual!.produtos.filter(
        (x) => x.produtoGradeId === produtoGradeId && x.ativo
      );

      if (produtos.length === 0) return undefined;

      return produtos[produtos.length - 1];
    },
    [getMov]
  );

  // MARK: criarProdutoTaxa
  const criarProdutoTaxa = useCallback(
    async (vendedorId: string | null, qtdProd: number) => {
      const produtoIndexDb = await getProdutoServico();

      if (!produtoIndexDb || !produtoIndexDb?.ativo) {
        return;
      }

      const getVendedor = await TouchoneDBPrimary.clientes.get({
        id: vendedorId ?? ""
      });

      const movAtual = getMov();

      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      // let quantidadeProdutosNoCarrinho = qtdProd
      let NCMIndex;
      // quantidadeProdutosNoCarrinho += 1

      if (produtoIndexDb?.ncm ?? 0) {
        // NCM DO PRODUTO
        NCMIndex = await TouchoneDBPrimary.ncms.get({
          codigo: produtoIndexDb?.ncm ?? 0
        });
      }

      const categoria = await TouchoneDBPrimary.categorias.get({
        id: produtoIndexDb?.categoriaId ?? ""
      });

      const produtoVenda: MovSimplesProdutoModel = {
        ...new MovSimplesProdutoModel(),
        ativo: true,
        balanca: produtoIndexDb?.balanca ?? 0,
        cEan: "",
        cProd: produtoIndexDb?.codigo ?? "",
        cProdKit: "",
        categoria: categoria?.descricao ?? "",
        grupoImpostoId: produtoIndexDb?.grupoImpostoId ?? "",
        id: newGuid(),
        imgUrl: produtoIndexDb?.imagemUrl ?? "",
        infAdic: "",
        ncm: produtoIndexDb?.ncm ?? "",
        ncmId: produtoIndexDb?.ncmId ?? "",
        pedidoId: "",
        produtoGradeId: produtoIndexDb?.produtoGradeId ?? "",
        produtoId: produtoIndexDb?.produtoId ?? "",
        qCom: 1,
        tabelaPrecoId: movAtual?.cliente?.tabelaPrecoId ?? "",
        uCom: produtoIndexDb?.medida ?? "",
        vFinal: 0,
        vFrete: 0,
        vProd: 0,
        vSeg: 0,
        vDesc: 0,
        vDescEmbutido: 0,
        vOutro: 0,
        vOutroEmbutido: 0,
        vUnCom: 0,
        vendedorId: vendedorId || null,
        vendedorNome: getVendedor?.nome ?? "",
        xProd: produtoIndexDb?.nome ?? "",
        nSeq: 0,
        temImposto: false,
        comandaId: "",
        mesaId: "",
        setorId: produtoIndexDb?.setorId ?? "",
        salaoId: "",
        pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
        pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
        pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
        pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
        vTrib: 0,
        taxaServico: 0,
        valorServico: 0,
        cobraTaxaServico: false,
        indFin: true,
        subItens: [],
        adicionais: [],
        idAdicional: null,
        idDoProdutoPaiInfoSubItem: null,
        idGroup: null,
        infoSubItem: null,
        prodSubItem: [],
        produtoPaiId: null,
        tpProduto: EnumTpProduto.Produto,
        validacaoSubItem: true,
        indDesperdicio: EnumIndDesperdicio.NaoSeAplica,
        nivel: 0,
        produtoIdReferencia: produtoIndexDb?.produtoId ?? null,
        quantidadeMax: 1,
        codigoComanda: null,
        mesaCodigo: null,
        modificadores: [],
        modificadorId: null,
        vUnComOrig: 0,
        qComModificador: 0,
        ordem: 0,
        modificadorUnicoId: "",
        cProdANVISA: null,
        nSeqReceitaMedica: null,
        ignorarReceituario: true,
        rastro: null
      };

      return produtoVenda;
    },
    [getMov, getProdutoServico]
  );

  // MARK: calcularTaxaServico
  const calcularTaxaServico = useCallback(
    async (mov: MovSimplesModel) => {
      const servico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico);

      const ret = mov.produtos.filter((x) => x.produtoId === servico);

      const vendedores = Array.from(
        new Set<string>(
          mov.produtos
            .filter((x) => x.cobraTaxaServico && x.ativo && x.indFin && x.taxaServico && x.taxaServico > 0)
            .map((x) => x.vendedorId || "")
        )
      );

      const naoTemProdTaxa = vendedores
        .map((x) => (x.length > 0 ? x : null))
        .filter(
          (idVendedor) =>
            !ret.map((prodTaxa) => prodTaxa.vendedorId).includes(idVendedor)
        );

      if (naoTemProdTaxa.length > 0) {
        for await (const idVendedor of naoTemProdTaxa) {
          const novoProdTaxa = await criarProdutoTaxa(
            idVendedor,
            mov.produtos.length
          );

          if (!isEmpty(novoProdTaxa)) mov.produtos.push(novoProdTaxa);
        }
      }

      for (const x in mov.produtos) {
        if (mov.produtos[x].produtoId === servico) {
          let vFinal = 0;
          let vProd = 0;
          const produtosPraTaxa = mov.produtos.filter(
            (y) =>
              y.ativo &&
              y.vendedorId === mov.produtos[x].vendedorId &&
              y.cobraTaxaServico &&
              y.indFin &&
              y.taxaServico &&
              y.taxaServico > 0
          );
          produtosPraTaxa.forEach((x) => {
            const percent = calcPercent(x.vFinal, x.taxaServico ?? 0) ?? 0;
            vFinal += percent;
            vProd += percent;
          });
          mov.produtos[x] = {
            ...mov.produtos[x],
            vFinal: roundTo(vFinal),
            vProd: roundTo(vProd),
            vUnCom: roundTo(vFinal),
            indFin: roundTo(vFinal) <= 0 ? false : true,
            cobraTaxaServico: false
          };
        }
      }
    },
    [criarProdutoTaxa, getConfigContratoByCod]
  );

  // MARK: lancandoProduto
  const lancandoProduto = useCallback(() => {
    const mov = getMov();
    if (
      !isEmpty(mov?.informacoesGeraisPedido.comandaId) ||
      !isEmpty(mov?.informacoesGeraisPedido.mesaId)
    ) {
      return true;
    }

    return false;
  }, [getMov]);

  // MARK: persistMovDados
  const persistMovDados = useCallback(
    async (
      movAtual: MovSimplesModel | undefined,
      naoAlteraTaxa: boolean = false,
      alterarModeloVenda: boolean = false,
      mod?: EnumMovModelo
    ) => {
      const prodServico = getContratoConfigByCod(EnumContratoConfig.ProdutoServico)
      const taxaDeEntrega = getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega)

      if (movAtual) {
        let somaPagsIntegrado = 0
        let qCom = 0;
        let vNF = 0;
        let vProdMax = 0;
        let vProd = 0;
        let vTaxaEntrega = 0;
        let vOutro = 0;
        let vOutroEmbutido = 0;
        let vDesc = 0;
        let vDescEmbutido = 0;
        let vServicos = 0;
        let vTotTrib = 0;
        let qtdeItens = 0;
        let qComItens = 0;
        let vPago = 0;
        let vOrig = 0;

        const produtoIndexDb = await getProdutoServico();

        if (
          planoMesasComandas &&
          produtoIndexDb &&
          !lancandoProduto()
        ) {
          if (
            !naoAlteraTaxa &&
            !isFarmaceutico
          ) {
            await calcularTaxaServico(movAtual)
          }
        }

        movAtual.produtos
          .filter((item) => {
            if (item.idGroup) {
              const findPai = movAtual.produtos.find(prod => prod.id === item.idGroup)
              if (findPai) {
                return findPai.ativo
              }
            } else {
              return item.ativo
            }
            return false
          })
          .forEach((x) => {
            qComItens +=
              x.indFin && !x.produtoPaiId
                ? (x.balanca === EnumBalanca.Normal ||
                  x.balanca === EnumBalanca.Unitario) &&
                  x.tpProduto !== EnumTpProduto.Combo
                  ? x.qCom
                  : 1
                : 0;
            qCom +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.qCom : 0;
            vNF +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vFinal : 0;
            vProdMax +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ?
                ((x.med?.vPMC ?? 0) * x.qCom) > x.vProd ? ((x.med?.vPMC ?? 0) * x.qCom) : x.vProd
                : 0;
            vOutro += x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vOutro : 0;
            vOutroEmbutido += x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vOutroEmbutido : 0;
            vDesc += x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vDesc : 0;
            vDescEmbutido += x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vDescEmbutido : 0;
            vServicos += x.indFin && x.tpProduto !== EnumTpProduto.Combo && x.produtoId === prodServico ? x.vFinal : 0;
            vTaxaEntrega += x.indFin && x.tpProduto !== EnumTpProduto.Combo && x.produtoId === taxaDeEntrega ? x.vFinal : 0;
            vProd +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo
                ? x.vProd
                : 0;
            vOrig +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vProd : 0;
            vTotTrib += x.vTrib;
            qtdeItens +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.qCom : 0;
          });
        movAtual.pags.forEach((x) => {
          const filtroPagTpMod = [
            EnumPagTpMod.CARTAO_CREDITO,
            EnumPagTpMod.CARTAO_DEBITO,
            EnumPagTpMod.PAGAMENTO_INSTANTANEO,
            EnumPagTpMod.VALE_ALIMENTACAO,
            EnumPagTpMod.VALE_COMBUSTIVEL,
            EnumPagTpMod.VALE_PRESENTE,
            EnumPagTpMod.VALE_REFEICAO
          ];
          if (filtroPagTpMod.includes(x.modPagamento)) {
            somaPagsIntegrado += x.vPag
          }
          vPago += x.vPag;
        });

        if (!movAtual.cobr) {
          movAtual.cobr = new CobrModel();
        }
        movAtual.cobr = {
          ...movAtual.cobr,
          fat: {
            ...movAtual.cobr.fat,
            vOrig: roundTo(vOrig, 2),
            vDesc: movAtual.vDesc ?? 0,
            vLiq: roundTo(vNF, 2)
          }
        };
        movAtual.dEmi = dataAtual();
        movAtual.qCom = qCom;
        movAtual.vNF = roundTo(vNF, 2);
        movAtual.vProd = roundTo(vProd, 2);
        movAtual.vTotTrib = vTotTrib;
        movAtual.qtdeItens = qtdeItens;
        movAtual.qComItens = qComItens;
        movAtual.vTaxaEntrega = vTaxaEntrega;
        movAtual.vServicos = vServicos;
        movAtual.vProdMax = vProdMax;
        movAtual.vOutro = roundTo(vOutro, 2);
        movAtual.vOutroEmbutido = roundTo(vOutroEmbutido, 2);
        movAtual.vDesc = roundTo(vDesc, 2);
        movAtual.vDescEmbutido = roundTo(vDescEmbutido, 2);


        if (movAtual.informacoesGeraisPedido.pedidos.length <= 0 &&
          movAtual.mod !== EnumMovModelo.PEDIDO && movAtual.mod !== EnumMovModelo.DELIVERY
        ) {
          //CORRECAO PARA CONVERTER A VENDA EM PEDIDO CASO O USUARIO NAO POSSA FAZER VENDA
          const modeloVenda = getConfigPdv(EnumPDVConfigCod.ModeloTrabalho);

          if (modeloVenda !== EnumModeloDeTrabalho.APENAS_CAIXA) {
            movAtual.mod = EnumMovModelo.PEDIDO;
          }
        }

        if (alterarModeloVenda) {
          movAtual.mod = mod ?? EnumMovModelo.ORCAMENTO
        }

        movAtual.vPago = roundTo(vPago, 2);
        movAtual.vTroco = vPago - vNF > 0 ? roundTo(vPago - vNF) : 0;
        if (somaPagsIntegrado > movAtual.vNF) {
          throw new Error("Valor de pagamentos integrados não pode ser menor do que o valor final da venda ")
        }
        const produtosServicos = movAtual.produtos.filter(x => x.produtoId === prodServico || x.produtoId === taxaDeEntrega)
        const limite = 9999 - produtosServicos.reduce((acc, current) => acc + current.qCom, 0)
        if (qCom > limite) {
          throw new Error(`Revise sua venda, limite de ${limite} produtos/itens.`)
        }
      }
      persistMovStorage(movAtual);
      callEvent(AppEventEnum.MovAtualAlterada, movAtual);
    },
    [calcularTaxaServico, callEvent, getConfigPdv, getContratoConfigByCod, getProdutoServico, isFarmaceutico, lancandoProduto, persistMovStorage, planoMesasComandas]
  );

  // MARK: getVendedor
  const getVendedor = useCallback(async () => {
    return (
      getRegistro(GestaoStorageKeys.VendedorAtual, false) || getPessoa()?.pessoa
    );
  }, [getPessoa, getRegistro]);

  // MARK: setVendedor
  const setVendedor = useCallback(
    async (vendedor: PessoaModel | undefined, substituirProdutos: boolean) => {
      let vend: PessoaModel =
        vendedor || getPessoa()?.pessoa || new PessoaModel();

      setRegistro(GestaoStorageKeys.VendedorAtual, vend, false);
      if (substituirProdutos) {
        const movAtual = getMov();

        movAtual?.produtos.forEach((item) => {
          item.vendedorId = vend.id;
          item.vendedorNome = vend.nome;
        });
        await persistMovDados(movAtual);
      }
    },
    [getMov, getPessoa, persistMovDados, setRegistro]
  );

  // MARK: saveQtdPessoasPagamento
  const saveQtdPessoasPagamento = useCallback(
    (qtd: number) => {
      setRegistro(
        GestaoStorageKeys.QtdPessoasPagamento,
        {
          pessoas: qtd
        },
        false
      );
    },
    [setRegistro]
  );

  // MARK: resetQtdPessoasPagamento
  const resetQtdPessoasPagamento = useCallback(() => {
    setRegistro(
      GestaoStorageKeys.QtdPessoasPagamento,
      {
        pessoas: 1
      },
      false
    );
  }, [setRegistro]);

  // MARK: getQtdPessoasPagamento
  const getQtdPessoasPagamento = useCallback(() => {
    const obj = getRegistro(GestaoStorageKeys.QtdPessoasPagamento, false);
    return obj;
  }, [getRegistro]);

  // MARK: restaurarMov
  const restaurarMov = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (!isEmpty(movAtual)) {
      //TODO: IR A API E CONSULTAR STATUS DA VENDA
    }
  }, [getMov]);

  // MARK: defaultValueFaturadaMov
  const defaultValueFaturadaMov = useCallback(() => {
    const statusUltimaSinc = getRegistro(
      GestaoStorageKeys.UltimaSincronizacao,
      false
    ) as SincronizacaoDados;

    if (statusUltimaSinc?.possuiPromocao === false)
      return true;

    return false;
  }, [getRegistro]);

  // MARK: preencherClientePadrao
  const preencherClientePadrao = useCallback(
    async (mov: MovSimplesModel) => {
      const consumidorPadrao = await getConsumidor();
      //adicionando o consumidor padrão
      mov.cliente = new MovSimplesPessoaModel();
      mov.cliente.nome = consumidorPadrao?.nome || "";
      mov.cliente.contratoId = consumidorPadrao?.contratoId || guidEmpty();
      mov.cliente.regimeTributarioId =
        consumidorPadrao?.regimeTributarioId || guidEmpty();
      mov.cliente.tabelaPrecoId =
        consumidorPadrao?.tabelaPrecoId || guidEmpty();
      mov.cliente.contatos = [];
      mov.cliente.documentos = [];
      mov.cliente.cpfcnpj = consumidorPadrao?.cpfcnpj || "";
      mov.cliente.id = consumidorPadrao?.id || guidEmpty();
      mov.documento = consumidorPadrao?.cpfcnpj || "";
      mov.pessoaId = consumidorPadrao?.id || guidEmpty();
      mov.regimeTributarioId =
        consumidorPadrao?.regimeTributarioId || guidEmpty();
    },
    [getConsumidor]
  );

  // MARK: retornarModDaVenda
  const retornarModDaVenda = useCallback((): { mod: EnumMovModelo, modelo: string | undefined } => {
    const modelo = getConfigPdv(EnumPDVConfigCod.ModeloVenda);

    if (
      modelo?.toUpperCase() === "SAT CF-E" ||
      modelo?.toUpperCase() === "ECF"
    ) {
      throw new Error(`O modelo "${modelo}" ainda não foi implementado.`);
    }

    const modeloMap: Record<string, EnumMovModelo> = {
      "NFCE SINCRONO": EnumMovModelo.NFCE,
      "NFC-E": EnumMovModelo.NFCE,
      "NFCE": EnumMovModelo.NFCE,
      "NFC-SAT": EnumMovModelo.SAT,
      "NF-E": EnumMovModelo.NFE,
      "VENDA SIMPLES": EnumMovModelo.ORCAMENTO,
    };

    const mod: EnumMovModelo = modeloMap[(modelo ?? '')?.toUpperCase()] || EnumMovModelo.ORCAMENTO;

    return {
      mod,
      modelo
    }
  }, [getConfigPdv]);

  // MARK: iniciarMov
  const iniciarMov = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (movAtual && movAtual.vNF > 0) {
      throw new Error(
        "Já existe uma venda em Andamento. Finalize-a para iniciar outra venda."
      );
    }

    const mov = new MovSimplesModel();
    mov.contratoId = getPessoa().pessoa?.contratoId || guidEmpty();
    mov.operadorId = getPessoa()?.pessoa?.id || guidEmpty();
    mov.dEmi = new Date();
    mov.empresaId = getEmpresaSelecionada()?.Id || guidEmpty();
    mov.isFaturada = defaultValueFaturadaMov();

    const selCliente = getConfigEmp(EnumEmpresaConfig.SelecaoClienteVenda);
    const modeloVenda = getConfigPdv(EnumPDVConfigCod.ModeloTrabalho);

    const modVenda = retornarModDaVenda();
    mov.mod = modVenda.mod;

    //CONFERENCIA PRA FAZER NAO FISCAL COM PLANO E/CONFIG ERRADA
    if (!(planoFiscal && empresaAtual?.isFiscal)) {
      mov.mod = EnumMovModelo.ORCAMENTO;
    }

    // ALTERO O MOD PARA PEDIDO CASO O PDV NÂO SEJA APENAS CAIXA
    if (modeloVenda !== EnumModeloDeTrabalho.APENAS_CAIXA) {
      mov.mod = EnumMovModelo.PEDIDO;
    }

    //SE NAO FOR PRA SELECIONAR CLIENTE E NAO FOR NF-E (OBRIGATORIO)
    if (selCliente === "0" && !(modVenda.modelo === "NF-e")) {
      mov.clienteIdentificado = true;
    }

    const sessaoID = await getSessao();
    mov.sessaoId = sessaoID?.id ?? null;

    setVendedor(undefined, false);

    resetQtdPessoasPagamento();

    await preencherClientePadrao(mov);
    await persistMovDados(mov);
    callEvent(AppEventEnum.MovAtualAlterada, mov);
  }, [callEvent, defaultValueFaturadaMov, empresaAtual?.isFiscal, getConfigEmp, getConfigPdv, getEmpresaSelecionada, getMov, getPessoa, getSessao, persistMovDados, planoFiscal, preencherClientePadrao, resetQtdPessoasPagamento, retornarModDaVenda, setVendedor]);

  // MARK: atlerarTpMod
  const alterarTpMod = useCallback(
    async (tpMod: EnumMovModelo): Promise<void> => {
      const movAtual = getMov();
      const consumidorPadrao = await getConsumidor();
      if (!movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      movAtual.mod = tpMod;

      if (tpMod === EnumMovModelo.NFE) {
        if (
          consumidorPadrao?.id === movAtual.cliente?.id ||
          guidEmpty() === movAtual.cliente?.id ||
          stringNumeros(consumidorPadrao?.cpfcnpj ?? "") ===
          stringNumeros(movAtual.cliente?.cpfcnpj ?? "")
        ) {
          movAtual.clienteIdentificado = false;
        }
      }
      await persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualAlterada, movAtual);
    },
    [callEvent, getConsumidor, getMov, persistMovDados]
  );

  // MARK: isClienteCadastradoObrigatorio
  const isClienteCadastradoObrigatorio = useCallback(() => {
    return (getMov()?.mod === EnumMovModelo.NFE || getConfigEmp(EnumEmpresaConfig.AceitarSomenteClienteCadastrado) === '1')
  }, [getConfigEmp, getMov]);

  // MARK: setClienteByDoc 
  const setClienteByDoc = useCallback(
    async (
      documento: string,
      setAsIdentificado: boolean = true,
      fillWithConsumidorPadrao: boolean = true
    ): Promise<MovSimplesPessoaModel | string | undefined> => {
      const movAtual = getMov();
      const isClienteObrigatorio = isClienteCadastradoObrigatorio();
      const consumidorPadrao = await getConsumidor();
      const aceitarSomenteClienteCadastrado = getConfigEmp(EnumEmpresaConfig.AceitarSomenteClienteCadastrado) === '1';

      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o cliente "${documento}"`
        );
      }

      if (isEmpty(documento) && !aceitarSomenteClienteCadastrado) {
        movAtual.clienteIdentificado = setAsIdentificado;
        movAtual.documento = documento;
        movAtual.cliente!.cpfcnpj = documento;
        if (fillWithConsumidorPadrao) {
          await preencherClientePadrao(movAtual);
        }
        await persistMovDados(movAtual);
        return;
      }

      if (!validarCPFCNPJ(documento) && !aceitarSomenteClienteCadastrado) {
        throw new Error(`O documento ${documento} não é um CPF ou CNPJ válido`);
      }

      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.documento = documento;
      movAtual.cliente!.cpfcnpj = documento;
      let novocliente = new PessoaModel();

      const getCli = await getPessoaByDoc(documento);
      const cliente = getCli.resultado?.data.list?.[0] as PessoaModel | null;

      if (isClienteObrigatorio && !cliente) {
        throw new Error("Não encontramos o cadastro deste CPF/CNPJ");
      }
      if (isClienteObrigatorio && cliente?.id === consumidorPadrao?.id) {
        throw new Error("O cliente deve ser diferente do consumidor padrão.");
      }
      if (cliente) {
        movAtual.clienteIdentificado = true;
        novocliente = cliente;
        movAtual.cliente = {
          ...novocliente,
          endereco: null,
          tpCadastro: novocliente.tpCadastro ?? EnumCadastroTipo.CLIENTE
        };
        movAtual.telefone =
          novocliente.contatos.find((c) => c.tipo === 0)?.valor ?? "";
        movAtual.pessoaId = novocliente.id;
      } else {
        if (fillWithConsumidorPadrao) {
          await preencherClientePadrao(movAtual);
        }
      }

      movAtual.cliente!.cpfcnpj = documento;
      movAtual.documento = documento;

      await persistMovDados(movAtual);

      return cliente ? movAtual.cliente : documento;
    },
    [getConfigEmp, getConsumidor, getMov, getPessoaByDoc, isClienteCadastradoObrigatorio, persistMovDados, preencherClientePadrao]
  );

  // MARK: setDocumentoNaNota 
  const setDocumentoNaNota = useCallback(
    async (value: boolean) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o documento na nota`
        );
      }

      movAtual.documentoNaNota = value;
      await persistMovDados(movAtual);
    },
    [getMov, persistMovDados]
  );

  // MARK: setTelefoneCliente
  const setTelefoneCliente = useCallback(
    async (
      telefone: string,
      cliente: MovSimplesPessoaModel,
      setAsIdentificado: boolean = true
    ): Promise<PessoaModel | string | undefined> => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o telefone "${telefone}"`
        );
      }
      if (isEmpty(telefone)) {
        movAtual.clienteIdentificado = setAsIdentificado;
        if (isEmpty(cliente)) {
          preencherClientePadrao(movAtual);
        } else if (movAtual.cliente && movAtual.cliente.contatos) {
          movAtual.cliente.contatos = movAtual.cliente?.contatos.filter(
            (contato) => contato.tipo === EnumTipoPessoaContato.TELEFONE
          );
        }
        await persistMovDados(movAtual);
        return;
      }

      if (movAtual.cliente) {
        movAtual.cliente.contatos = movAtual.cliente.contatos.filter(contato => contato.tipo !== EnumTipoPessoaContato.TELEFONE);
        movAtual.cliente.contatos.push({
          ...new PessoaContatosModel(),
          tipo: EnumTipoPessoaContato.TELEFONE,
          valor: telefone
        });
      }
      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.telefone = telefone;

      await persistMovDados(movAtual);

      return telefone;
    },
    [getMov, persistMovDados, preencherClientePadrao]
  );

  // MARK: setClientePessoa
  const setClientePessoa = useCallback(
    async (
      cliente: MovSimplesPessoaModel,
      setAsIdentificado: boolean = true
    ): Promise<void> => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o cliente selecionado.`
        );
      }

      const isClienteObrigatorio = isClienteCadastradoObrigatorio();
      const consumidorPadrao = await getConsumidor();

      if (isClienteObrigatorio && cliente?.id === consumidorPadrao?.id) {
        throw new Error("O cliente deve ser diferente do consumidor padrão.");
      }

      if (isEmpty(cliente)) {
        preencherClientePadrao(movAtual);
        await persistMovDados(movAtual);
        return;
      }
      movAtual.cliente = cliente;
      movAtual.regimeTributarioId = cliente.regimeTributarioId || "";
      movAtual.pessoaId = cliente.id || "";
      movAtual.documento = cliente.cpfcnpj;
      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.telefone =
        cliente.contatos.find((c) => c.tipo === 0)?.valor ?? movAtual.telefone;

      await persistMovDados(movAtual);
    },
    [getConsumidor, getMov, isClienteCadastradoObrigatorio, persistMovDados, preencherClientePadrao]
  );

  // MARK: setTransportadora
  const setTransportadora = useCallback(
    async (transportadora: PessoaModel): Promise<void> => { },
    []
  );

  // MARK: calcularValorFinalProduto
  const calcularValorFinalProduto = useCallback((prod: MovSimplesProdutoModel) => {
    const vOutro =
      prod.acrescimos?.reduce((acc, cur) => acc + (!(cur.item?.embutido ?? false) ? cur.item?.valorAcrescimo ?? 0 : 0), 0) +
      prod.acrescimos?.reduce((acc, cur) => acc + (!(cur.pagamento?.embutido ?? false) ? cur.pagamento?.valorAcrescimo ?? 0 : 0), 0);

    const vOutroEmbutido =
      prod.acrescimos?.reduce((acc, cur) => acc + ((cur.item?.embutido ?? false) ? cur.item!.valorAcrescimo : 0 ?? 0), 0) +
      prod.acrescimos?.reduce((acc, cur) => acc + ((cur.pagamento?.embutido ?? false) ? cur.pagamento!.valorAcrescimo : 0 ?? 0), 0);

    const vDesc =
      prod.descontos?.reduce((acc, cur) => acc + (cur.item?.valorDesconto ?? 0), 0) +
      prod.descontos?.reduce((acc, cur) => acc + (cur.promocao?.tipoPromocaoRegra !== EnumPromocaoTipoRegra.DePor ? cur.promocao?.valorDesconto ?? 0 : 0 ?? 0), 0) +
      prod.descontos?.reduce((acc, cur) => acc + (cur.pagamento?.valorDesconto ?? 0), 0);
    ;

    const vDescEmbutido =
      prod.descontos?.reduce((acc, cur) => acc + (cur.promocao?.tipoPromocaoRegra === EnumPromocaoTipoRegra.DePor ? cur.promocao?.valorDesconto : 0 ?? 0), 0);
    ;
    prod.vOutro = vOutro;
    prod.vOutroEmbutido = vOutroEmbutido;

    prod.vDesc = vDesc;
    prod.vDescEmbutido = vDescEmbutido;

    prod.vProd = prod.vUnCom * prod.qCom + vOutroEmbutido;
    prod.vFinal = prod.vProd - prod.vDesc + prod.vOutro + prod.vSeg + prod.vFrete;

    //CALCULOS APOS CALCULAR O VALOR FINAL DO PRODUTO
    prod.valorServico = (prod.taxaServico ?? 0 * prod.vProd / 100);
  }, []);

  // MARK: limparDescontosMov
  const limparDescontosMov = useCallback(async (movAtual: MovSimplesModel) => {
    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    for (let i = 0; i < movAtual.produtos.length; i++) {

      movAtual.produtos[i].descontos = movAtual.produtos[i].descontos.filter(
        (desconto) =>
          (desconto.promocao && desconto.promocao?.tipoPromocaoRegra === EnumPromocaoTipoRegra.DePor) ||
          movAtual.pags.flatMap((x) => x.tefId).includes(desconto.pagamento?.movPagId ?? newGuid()) ||
          desconto.item
      );

      //CALCULA O VALOR DO PRODUTO
      calcularValorFinalProduto(movAtual.produtos[i]);

    }
    movAtual.isFaturada = defaultValueFaturadaMov();
  }, [calcularValorFinalProduto, defaultValueFaturadaMov]);

  // MARK: inserirProduto
  const inserirProduto = useCallback(
    async (
      produto: MovSimplesProdutoModel
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const idProdutoTaxa = getConfigContratoByCod(
        EnumContratoConfig.ProdutoServico
      );
      const idProdutoEntrega = getConfigContratoByCod(
        EnumContratoConfig.ProdutoEntrega
      );

      if (idProdutoTaxa === produto.produtoId) {
        throw new Error("Não é possível inserir o produto Taxa de Serviço.");
      }

      if (idProdutoEntrega === produto.produtoId) {
        throw new Error("Não é possível inserir o produto Taxa de Serviço.");
      }

      if (produto.qCom < 0 && produto.vFinal < 0 && produto.vUnCom < 0) {
        throw new Error("Os valores do produto informado estão inválidos.");
      }

      const produtosMaiorQueZero = movAtual.produtos.filter((x) => x.nSeq > 0);
      produto.nSeq =
        (produtosMaiorQueZero[produtosMaiorQueZero.length - 1]?.nSeq ?? 0) + 1;
      movAtual.produtos.push(produto);

      //ATENCAO, DEIXEI ESTE CODIGO AQUI PARA CALCULAR O VALOR CERTO DO DESCONTO DO PRODUTO,
      //ESTOU SOLICITANDO QUE SEMPRE QUE FOR PASSAR UM DESCONTO DE/POR, QUE O VALOR SEJA O VALOR UNITARIO
      //E AQUI EU FACO O CALCULO PELA QCOM, DEVIDO A GRANDE QUANTIDADE DE TIPOS DE INSERÇÃO DE PRODUTOS EM OUTROS HOOKS
      const depor = produto.descontos.filter(x => x.promocao?.tipoPromocaoRegra === EnumPromocaoTipoRegra.DePor);
      if (depor.length > 0) {
        depor.forEach(x => {
          x.promocao!.valorDesconto = toDecimal(x.promocao!.valorDesconto * produto.qCom, 2);
        })
      }

      limparDescontosMov(movAtual);

      await persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      return produto;
    },
    [callEvent, getConfigContratoByCod, getMov, limparDescontosMov, persistMovDados]
  );

  // MARK: alterarProdutoInterno
  const alterarProdutoInterno = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      naoAlteraTaxa: boolean,
      limparDescontos: boolean
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      const ret = movAtual.produtos.find((x) => x.id === produto.id && x.nSeq === produto.nSeq);

      if (!ret) {
        throw new Error("O produto informado não foi localizado na venda.");
      }

      if (ret.produtoGradeId !== produto.produtoGradeId) {
        throw new Error(
          "Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente"
        );
      }

      var index = movAtual.produtos.indexOf(ret);

      if (movAtual.produtos[index].qCom !== produto.qCom) {
        //TRECHO PARA RECALCULAR O DESCONTO
        const depor = produto.descontos.filter(x => x.promocao?.tipoPromocaoRegra === EnumPromocaoTipoRegra.DePor);
        if (depor.length > 0) {
          depor.forEach(x => {
            const promoAntigo = movAtual.produtos[index];
            //CORRIGE O VALOR DE DESCONTO DESTE ITEM CONSIDERANDO VALOR UNITARIO ANTERIOR * NOVA QTDE
            x.promocao!.valorDesconto = toDecimal((x.promocao!.valorDesconto / promoAntigo.qCom === 0 ? 1 : promoAntigo.qCom) * produto.qCom, 2);
          })
        }
      }

      movAtual.produtos[index] = produto;

      if (limparDescontos)
        limparDescontosMov(movAtual);

      await persistMovDados(movAtual, naoAlteraTaxa);

      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      return produto;
    },
    [callEvent, getMov, limparDescontosMov, persistMovDados]
  );

  // MARK: alterarProduto
  const alterarProduto = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      naoAlteraTaxa: boolean = false
    ): Promise<MovSimplesProdutoModel> => {
      return await alterarProdutoInterno(produto, naoAlteraTaxa, true);
    },
    [alterarProdutoInterno]
  );

  // MARK: alterarProdutos
  const alterarProdutos = useCallback(
    async (
      produtos: MovSimplesProdutoModel[],
      naoAlteraTaxa: boolean = false
    ): Promise<MovSimplesProdutoModel[]> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      for (let i = 0; i < produtos.length; i++) {
        const index = movAtual.produtos.indexOf(
          movAtual.produtos.find(prod => prod.id === produtos[i].id) || new MovSimplesProdutoModel()
        );
        if (index === -1) throw new Error(`${produtos[i].xProd} não está listado na sua venda!`)

        const ret = movAtual.produtos.find((x) => x.id === produtos[i].id && x.nSeq === produtos[i].nSeq);

        if (!ret) {
          throw new Error("O produto informado não foi localizado na venda.");
        }

        if (ret.produtoGradeId !== produtos[i].produtoGradeId) {
          throw new Error(
            "Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente"
          );
        }
        calcularValorFinalProduto(produtos[i]);
        movAtual.produtos[index] = produtos[i];
      }
      await persistMovDados(movAtual, naoAlteraTaxa);

      return produtos;
    },
    [calcularValorFinalProduto, getMov, persistMovDados]
  );

  // MARK: removerProdutoComSubItens
  const removerProdutoComSubItens = useCallback(
    async (
      produto: MovSimplesProdutoModel
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const ret = movAtual.produtos.find((x) => x.nSeq === produto.nSeq);

      if (!ret) {
        throw new Error("O produto informado não foi localizado na venda.");
      }

      if (ret.produtoGradeId !== produto.produtoGradeId) {
        throw new Error(
          "Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente"
        );
      }

      const filterProducts = movAtual.produtos.filter(
        (p) => p.id !== produto.id && p.idGroup !== produto.id
      );
      movAtual.produtos = filterProducts;
      persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      return produto;
    },
    [callEvent, getMov, persistMovDados]
  );

  // MARK: alterarSessaoId
  const alterarSessaoId = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    const sessao = await getSessao();

    if (!sessao) {
      throw new Error("Nenhuma sessão foi identificada.");
    }

    movAtual.sessaoId = sessao.id;
    persistMovDados(movAtual);
  }, [getMov, getSessao, persistMovDados]);

  // MARK: calcularVTroco
  const calcularVTroco = useCallback((movAtual: MovSimplesModel) => {
    if (!movAtual) return;

    let vPago = 0;
    movAtual.pags.forEach((x) => {
      x.vTroco = 0;
      if (x.status === EnumIndStatusMovPag.Aprovado) {
        vPago += x.vPag;
      }
    });

    if (vPago - movAtual.vNF > 0) {
      const pags = movAtual.pags
        .filter(
          (x) =>
            x.modPagamento === EnumPagTpMod.DINHEIRO ||
            x.modPagamento === EnumPagTpMod.OUTRO
        )
        .sort(function (a, b) {
          return b.vPag - a.vPag;
        });
      if (pags[0]) {
        pags[0].vTroco = roundTo(vPago - movAtual.vNF);
      }
    }
  }, []);

  // MARK: gerarCobranca
  const gerarCobranca = useCallback(
    (mov: MovSimplesModel, pagamento: MovSimplesPagamentoModel) => {
      if (!mov.cobr) {
        return;
      }
      let dup = mov.cobr.dup;
      const nParcelas = pagamento.nParcelas <= 0 ? 1 : pagamento.nParcelas;

      for (let sequencia = 0; sequencia < nParcelas; sequencia++) {
        let newDup = new DupCobrModel();
        const vPag = pagamento.vPag / nParcelas;
        const data = new Date();
        const dVenc = new Date(data.setMonth(data.getMonth() + sequencia));

        newDup.vDup = roundTo(vPag, 2);
        newDup.dVenc = dVenc;
        newDup.nDup = String(dup.length + 1);
        dup.push(newDup);
      }

      mov.cobr.dup = dup;
    },
    []
  );

  // MARK: iniciarPagamento
  const iniciarPagamento = useCallback(
    async ({
      pagamento,
      naoAlteraTaxa = false,
      credenciamento,
      credenciado,
      tpPayments,
    }: IniciarPagamentoParamsProps): Promise<MovSimplesPagamentoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      if (movAtual.mod === EnumMovModelo.DELIVERY) {
        pagamento.status = EnumIndStatusMovPag.Aprovado;
        movAtual.pags.push(pagamento);
        calcularVTroco(movAtual);
        gerarCobranca(movAtual, pagamento);
        await persistMovDados(movAtual, naoAlteraTaxa);
        return pagamento;
      }

      if (
        pagamento.modPagamento !== EnumPagTpMod.DINHEIRO &&
        pagamento.modPagamento !== EnumPagTpMod.OUTRO &&
        pagamento.vPag + movAtual.vPago > movAtual.vNF
      ) {
        throw new Error(
          "O Valor do Pagamento é maior do que o restante para a venda e não permite troco."
        );
      }
      if (
        pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY ||
        (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX &&
          (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) &&
          !isEmpty(tpPayments) &&
          tpPayments?.includes(pagamento.modPagamento) &&
          tpPayments?.[0] !== EnumPagTpMod.DINHEIRO)
      ) {
        return new Promise<MovSimplesPagamentoModel>(
          async (resolve, reject) => {
            try {
              pagamento.status = EnumIndStatusMovPag.Processando;
              await startPayment(
                pagamento.vPag,
                pagamento.modPagamento,
                pagamento.nParcelas,
                credenciamento,
                {
                  erro: async (e: any) => {
                    if (
                      pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX
                    ) {
                      const venda = picker(movAtual, new VendaModel());
                      const token = getRegistro(GestaoStorageKeys.Token, false);

                      await logError(
                        {
                          empresa: getEmpresaAtual()?.nomeFantasia,
                          documento: getEmpresaAtual()?.cpfcnpj,
                          empresaId: getEmpresaSelecionada()?.Id ?? "",
                          venda: venda,
                          vendaString: JSON.stringify(venda),
                          error: e,
                          data: new Date(),
                          token: token
                        },
                        EnumColectionFireBase.PIX
                      );
                    }
                    reject(e);
                  },
                  sucesso: async (e: PaymentSuccessModel) => {
                    if (movAtual) {
                      if (e.processando) {
                        setAguardandoPagamentoProps({
                          processando: e.processando,
                          mensagem: e.mensagem,
                          cancelavel: false,
                        })
                        return
                      }
                      pagamento.dhTransacao = e.dhTransacao;
                      pagamento.cAut = e.cAut;
                      pagamento.codNsu = e.codNsu;
                      pagamento.envioAPI = e.envioAPI;
                      pagamento.tid = e.tid;
                      pagamento.numCartao = e.numCartao;
                      pagamento.adquirente = e.adquirente;
                      pagamento.nParcelas = e.nParcelas;
                      pagamento.bandeira = e.bandeira;
                      pagamento.nomeCartao = e.nomeCartao;
                      pagamento.status = EnumIndStatusMovPag.Aprovado;
                      pagamento.retornoAPI = e.retornoAPI;
                      pagamento.viaCliente = e.viaCliente;
                      pagamento.viaLojista = e.viaLojista;

                      movAtual.pags.push(pagamento);
                      calcularVTroco(movAtual);
                      gerarCobranca(movAtual, pagamento);

                      const produtosComDescontoVazio = movAtual.produtos.filter(prod => {
                        const descontoVazio = prod.descontos.some(desc => desc.pagamento && desc.pagamento.finalizadoraId === guidEmpty())
                        return descontoVazio
                      });

                      const produtosComAcrescimoVazio = movAtual.produtos.filter(prod => {
                        const descontoVazio = prod.acrescimos.some(acresc => acresc.pagamento && acresc.pagamento.finalizadoraId === guidEmpty())
                        return descontoVazio
                      });

                      const addInfoAcrescimoDesconto = (itens: MovSimplesProdutoModel[], tp: 'acrescimos' | 'descontos') => {
                        for (let prod of itens) {
                          const index = movAtual.produtos.indexOf(prod);
                          if (index === -1) continue;

                          for (let i = 0; i <= prod[tp].length; i++) {
                            const item = prod[tp][i]
                            if (item.pagamento && item.pagamento?.finalizadoraId === guidEmpty()) {
                              item.pagamento.finalizadoraId = pagamento.pagamentoId;
                              item.pagamento.finalizadoraNome = pagamento.pagamentoNome;
                              item.pagamento.movPagId = pagamento.tid;

                              prod[tp][i] = item;
                            }
                          }

                          movAtual.produtos[index] = prod;
                        }
                      }

                      if (produtosComDescontoVazio.length > 0) {
                        addInfoAcrescimoDesconto(produtosComDescontoVazio, 'descontos');
                      }
                      if (produtosComAcrescimoVazio.length > 0) {
                        addInfoAcrescimoDesconto(produtosComAcrescimoVazio, 'acrescimos');
                      }

                      await persistMovDados(movAtual, naoAlteraTaxa);
                      resolve(pagamento);
                      return;
                    }
                    reject(new Error("Erro ao interpretar o Pagamento"));
                  }
                }
              );
            } catch (e: any) {
              reject(e);
            }
          }
        );
      }

      if (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX) {
        if (!navigator.onLine) {
          throw new Error(
            "É necessário conexão com a internet para realizar o PIX."
          );
        }

        if (!credenciado) {
          const sugerirCredenciamento = () =>
            new Promise<boolean>((resolve, reject) => {
              abrirDialogCredenciarPix(
                pagamento.pagamentoId,
                async (finalizadora: FinalizadoraModel) => {
                  try {
                    resolve(true);
                  } catch (e: any) {
                    reject(false);
                  }
                },
                async (message: string) => {
                  fecharDialogCredenciarPix();
                  reject(false);
                }
              );
            });

          const resultadoCredenciamento = await sugerirCredenciamento();

          if (!resultadoCredenciamento) {
            throw new Error("Não foi possível prosseguir com o pagamento PIX.");
          }
        }
        return new Promise((resolve, reject) => {
          abrirDialogPix(
            pagamento,
            async () => {
              pagamento.status = EnumIndStatusMovPag.Aprovado;
              movAtual.pags.push(pagamento);
              calcularVTroco(movAtual);
              gerarCobranca(movAtual, pagamento);
              await persistMovDados(movAtual, naoAlteraTaxa);
              resolve(pagamento);
            },
            () => reject(pagamento)
          );
        });
      }

      pagamento.status = EnumIndStatusMovPag.Aprovado;
      movAtual.pags.push(pagamento);
      calcularVTroco(movAtual);
      gerarCobranca(movAtual, pagamento);
      await persistMovDados(movAtual, naoAlteraTaxa);
      return pagamento;
    },
    [abrirDialogCredenciarPix, abrirDialogPix, calcularVTroco, fecharDialogCredenciarPix, gerarCobranca, getEmpresaAtual, getEmpresaSelecionada, getMov, getRegistro, logError, persistMovDados, setAguardandoPagamentoProps, startPayment]
  );

  // MARK: alterarPagamento
  const alterarPagamento = useCallback(
    async (
      pagamento: MovSimplesPagamentoModel
    ): Promise<MovSimplesPagamentoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const pgto = movAtual.pags.find((x) => x.tid === pagamento.tid);
      if (isEmpty(pgto)) {
        throw new Error("O Pagamento informado não foi Identificado");
      }

      const index = movAtual.pags.indexOf(
        pgto || new MovSimplesPagamentoModel()
      );
      movAtual.pags[index] = pagamento;

      calcularVTroco(movAtual);
      await persistMovDados(movAtual);

      return pagamento;
    },
    [calcularVTroco, getMov, persistMovDados]
  );

  // MARK: getPagamentoApi
  const getPagamentoApi = useCallback(async (nnf?: number) => {
    const movAtual = getMov();
    if (!movAtual) {
      throw new Error("Não existe uma venda em andamento!");
    }

    const replaceVia = (text: string) => text
      .replaceAll('@@nnf@@', padEnd((nnf ?? 0).toString(), 7))

    return movAtual.pags.map((item) => {
      return {
        // fazendo o picker dos pagamentos para enviar para venda
        ...picker<VendaPagsModel>(item, new VendaPagsModel()),
        viaEstabelecimento: replaceVia(item.viaLojista),
        viaLojista: replaceVia(item.viaLojista),
        viaConsumidor: replaceVia(item.viaCliente),
        viaCliente: replaceVia(item.viaCliente),
        qtdeParcela: item.nParcelas
      }
    });
  }, [getMov]);

  // MARK: getProdutosApi
  const getProdutosApi = useCallback(
    async (mov: MovSimplesModel) => {
      const movAtual = getMov();
      if (isEmpty(movAtual?.produtos) || !movAtual) {
        throw new Error("Não é possivel finalizar uma venda sem produtos.");
      }

      return movAtual.produtos.map((item) => {
        const produtoVenda = picker<VendaProdutoModel>(
          item,
          new VendaProdutoModel()
        );

        produtoVenda.qCom =
          item.infoSubItem?.modificadorTipoCalculo !==
            EnumTpCalculoModificador.Rateia
            ? item.qComModificador > 0
              ? item.qComModificador
              : item.qCom
            : item.qCom;

        if (
          item.infoSubItem?.modificadorTipoCalculo ===
          EnumTpCalculoModificador.Rateia
        ) {
          const vProd = produtoVenda.vProd;
          produtoVenda.vUnCom = +roundTo(vProd / produtoVenda.qCom, 4);
        }
        produtoVenda.vDescEmbutido = item.vDescEmbutido;
        produtoVenda.vOutroEmbutido = item.vOutroEmbutido;;
        produtoVenda.descontos = item.descontos;
        produtoVenda.acrescimos = item.acrescimos;
        produtoVenda.vDesc = item.vDesc;
        produtoVenda.vProd = produtoVenda.qCom * produtoVenda.vUnCom;
        produtoVenda.vOutro = item.vOutro;
        produtoVenda.cancelado = !item.ativo;
        produtoVenda.dataHoraLancamento = item.dataHoraLancamento.length > 0 ? item.dataHoraLancamento : getLocalISOString()

        if (
          !isEmpty(produtoVenda.setorId) &&
          getConfigPdv(EnumPDVConfigCod.DispararSetorFinalizacaoVenda)?.toLowerCase() === "sim" &&
          isEmpty(item.pedidoId)
        ) {
          mov.tpEmis = EnumTpEmis.NORMAL;
          produtoVenda.enviarSetor = true;
        } else if (!isEmpty(item.pedidoId)) {
          produtoVenda.pedidoId = item.pedidoId;
        }
        const prodPai = movAtual.produtos.find((prod) => {
          return (
            prod.produtoId === produtoVenda.produtoPaiId &&
            !isEmpty(prod.setorId)
          );
        });
        produtoVenda.rastro = item.rastro ? item.rastro : null;
        produtoVenda.med = item.med;

        if (prodPai) {
          produtoVenda.enviarSetor = true;
          produtoVenda.produtoPaiId = prodPai.produtoGradeId;
          //gui disse pra colocar o grade id pra enviar para o KDS
        }

        produtoVenda.depositoId = getPDV()?.depositoId ?? null;

        if (
          item.vProd === 0 ||
          item.vFinal === 0 ||
          item.tpProduto === EnumTpProduto.Combo
        ) {
          produtoVenda.indFin = false;
        }

        return produtoVenda;
      });
    },
    [getConfigPdv, getMov, getPDV]
  );

  // MARK: saveNovaVenda
  const saveNovaVenda = useCallback(
    async (movAtual: MovSimplesModel): Promise<string> => {

      const venda = picker<VendaModel>(movAtual, new VendaModel());
      if (venda.cobr && movAtual.cobr) {
        venda.cobr.dup = movAtual.cobr.dup;
      }
      if (venda.cliente && isEmpty(venda.cliente.fantasia)) venda.cliente.fantasia = venda.cliente.fantasia = venda.cliente.nome;
      venda.dEmi = dataAtual();
      venda.pags = (await getPagamentoApi(movAtual.nnf)) || [new VendaPagsModel()];
      venda.produtos = (await getProdutosApi(movAtual)) || [
        new VendaProdutoModel()
      ];
      venda.telefone = venda.telefone ? stringNumeros(venda.telefone) : "";
      venda.receitasMedicas = movAtual.receitasMedicas;
      if (movAtual.tpEmis === EnumTpEmis.NORMAL) {
        const ret = await postNovaVenda(venda, 120000);

        if (ret.erro) {
          //SE NAO VEIO ERRO DA API, NOS SALVAMOS LOCAL (SOMENTE SE A EMISSAO NAO FOR NORMAL)
          if (ret.statusCode === 0 && venda.tpEmis !== EnumTpEmis.NORMAL) {
            const vendaSalva = await setVenda(venda);
            return vendaSalva.id;
          }

          const token = getRegistro(GestaoStorageKeys.Token, false);
          const erro = {
            mensagem: ret.erro?.message,
            stack: ret.erro?.stack,
            statusCode: ret.statusCode
          };

          const objToLog = {
            empresa: getEmpresaAtual()?.nomeFantasia,
            documento: getEmpresaAtual()?.cpfcnpj,
            empresaId: getEmpresaSelecionada()?.Id ?? "",
            venda: venda,
            vendaId: venda.id,
            vendaString: JSON.stringify(venda),
            erro,
            data: new Date(),
            token,
            mod: venda.mod
          };

          try {
            logError(objToLog, EnumColectionFireBase.VENDAS);
          } catch (e: any) { }
          throw ret.erro;
        }

        await setVenda(venda, new Date().toDateString());
        return ret.resultado!.data.id;
      }

      const vendaSalva = await setVenda(venda);

      return vendaSalva.id;
    },
    [getEmpresaAtual, getEmpresaSelecionada, getPagamentoApi, getProdutosApi, getRegistro, logError, postNovaVenda, setVenda]
  );

  // MARK: inserirVendaAPI
  const inserirVendaAPI = useCallback(
    async (
      movAtual: MovSimplesModel,
      mod: EnumMovModelo | null = null
    ): Promise<FinalizarVendaModel> => {

      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const getUltimoNro = await getUltimaNumeracao(getPDV(), mod);
      const tipoFinalizacaoPDV = getConfigEmp(
        EnumEmpresaConfig.TipoFinalizacaoVendaPDV
      );

      const tpEmis = ([EnumMovModelo.NFCE, EnumMovModelo.NFE].includes(
        mod || EnumMovModelo.ORCAMENTO
      ) ||
        [EnumMovModelo.NFCE, EnumMovModelo.NFE].includes(
          getUltimoNro?.modelo || EnumMovModelo.ORCAMENTO
        ) ||
        tipoFinalizacaoPDV ===
        EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE ||
        tipoFinalizacaoPDV ===
        EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL) &&
        getEmpresaAtual()?.uf
        ? EnumTpEmis.NORMAL
        : EnumTpEmis.OFFLINE;

      if (
        (movAtual!.mod === EnumMovModelo.NFCE ||
          movAtual!.mod === EnumMovModelo.NFE) &&
        !getUltimoNro && tpEmis !== EnumTpEmis.OFFLINE
      ) {
        throw new Error("Não foi possível identificar a ultima numeração");
      }

      // verificando se o foi possível obter a numeracao
      if (!getUltimoNro) {
        return {
          error: null,
          status: "invalid",
          url: "/venda-simples/numeracao",
          adicional: mod
        };
      }

      movAtual.tpAmb = getUltimoNro.tpAmb;
      movAtual.nnf = getUltimoNro.nNf + 1;
      movAtual.serie = getUltimoNro.serie;
      movAtual.mod = mod ?? getUltimoNro.modelo;
      movAtual.dEmi = dataAtual();
      movAtual.tpEmis = tpEmis;


      if (movAtual.cobr) {
        movAtual.cobr.fat.nFat = String(movAtual.nnf);
      }
      try {
        const id = await saveNovaVenda(movAtual);
        movAtual.id = id;

        return {
          error: null,
          status: "salvo",
          url: "/venda-simples/finalizar-venda/transmitir",
          adicional: null
        };
      } catch (e: any) {
        return {
          error: e,
          status: "error",
          url: "/venda-simples/finalizar-venda/rejeicao",
          adicional: null
        };
      }
    },
    [getConfigEmp, getEmpresaAtual, getPDV, getUltimaNumeracao, saveNovaVenda]
  );

  // MARK: ignorarFaturamentoMov
  const ignorarFaturamentoMov = useCallback(async () => {
    const movAtual = getMov();

    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }
    limparDescontosMov(movAtual);

    movAtual.isFaturada = true;
    await persistMovDados(movAtual);

  }, [getMov, limparDescontosMov, persistMovDados]);

  // MARK: faturarMov
  const faturarMov = useCallback(async () => {
    const movAtual = getMov();

    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    if (movAtual.produtos.filter(x => x.ativo).length > 0) {

      const produtosRequisicao = (await getProdutosApi(movAtual)).filter(x => !x.cancelado);
      //FILTRANDO PROMOCOES PARA NAO ENVIAR PARA A API
      produtosRequisicao.forEach(x => x.descontos = (x.descontos || []).filter(y => (y?.promocao?.valorDesconto ?? 0) === 0));


      const resPromocoes = await postPromocaoProcessamento(produtosRequisicao)
      if (resPromocoes.erro) {
        throw new Error(resPromocoes.erro, { cause: "retorno-api" })
      }

      const prods = resPromocoes.resultado?.data as VendaProdutoModel[];
      //SE RETORNOU PRODUTOS
      if (prods.length > 0) {

        for (let i = 0; i < prods.length; i++) {
          //ACHA O PRODUTO COM PROMOCAO
          const iProd = movAtual.produtos.findIndex(x => x.id === prods[i].id);
          if (iProd === -1)
            continue;

          const prod = movAtual.produtos[iProd];
          if (!prod.descontos)
            prod.descontos = [];
          //ADICIONO DESCONTO DE PROMOCAO RETORNADO
          const descontosPermitidos = prod.descontos?.filter(x => x.promocao?.tipoPromocaoRegra === EnumPromocaoTipoRegra.DePor ||
            (x.item?.valorDesconto ?? 0) > 0 || (x.pagamento?.valorDesconto ?? 0) > 0);

          prod.descontos = []
          prod.descontos = [...descontosPermitidos, ...((prods[i].descontos || []).filter(x => x.promocao?.tipoPromocaoRegra !== EnumPromocaoTipoRegra.DePor && (x.promocao?.valorDesconto ?? 0) > 0))];
          prod.descontos.forEach(x => {
            if (x.promocao?.percentualDesconto)
              x.promocao.percentualDesconto = toDecimal(x.promocao?.percentualDesconto, 10)

            if (x.promocao?.valorDesconto)
              x.promocao.valorDesconto = toDecimal(x.promocao?.valorDesconto, 2)
          });
        }

        for (let i = 0; i < movAtual.produtos.length; i++) {
          calcularValorFinalProduto(movAtual.produtos[i]);
        }
      }
    }

    movAtual.isFaturada = true;
    await persistMovDados(movAtual);

  }, [calcularValorFinalProduto, getMov, getProdutosApi, persistMovDados, postPromocaoProcessamento]);

  // MARK: finalizarMov
  const finalizarMov = useCallback(
    async (
      mod: number | null = null
    ): Promise<FinalizarVendaModel> => {
      let retornoTransmissao: any = null;
      const movAtual = getMov();

      const idPedidoIsEqualIdMov = () => {
        if (movAtual?.informacoesGeraisPedido.pedidos && movAtual?.informacoesGeraisPedido.pedidos.length > 0) {
          return movAtual?.informacoesGeraisPedido.pedidos.some(pedido => pedido.id === movAtual?.id)
        }
      }

      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      let idVenda: string | undefined = undefined;

      try {
        //ZERO A VARIAVEL PARA TER CERTEZA QUE A INFORMAÇÃO SERÁ PREENCHIDA NOVAMENTE OU FICARA VAZIA
        movAtual.retornoFinalizacao = undefined;
        if (idPedidoIsEqualIdMov()) {
          movAtual.id = guidEmpty()
        }
        if ((movAtual.id || guidEmpty()) === guidEmpty()) {
          const retInserir = await inserirVendaAPI(movAtual, mod);
          movAtual!.retornoFinalizacao = retInserir;
          await persistMovDados(movAtual, true);

          if (retInserir.status === "invalid") {
            movAtual!.retornoFinalizacao = undefined;
            await persistMovDados(movAtual, true);
            return retInserir;
          }

          //SE VIR ERROR, ARMAZENO NA MOV O ULTIMO ERRO E RETORNO
          if (retInserir.error) {
            throw retInserir.error;
          }
        }

        idVenda = movAtual.id;

        const tipoFinalizacaoPDV = getConfigEmp(EnumEmpresaConfig.TipoFinalizacaoVendaPDV);

        if (movAtual.tpEmis === EnumTpEmis.NORMAL ||
          tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE ||
          tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL) {

          const ret = await putFinalizarVenda(idVenda!, 120000);
          if (ret.erro) {
            retornoTransmissao = ret.statusCode;
            if (
              movAtual.mod === EnumMovModelo.NFCE ||
              movAtual.mod === EnumMovModelo.NFE ||
              tipoFinalizacaoPDV ===
              EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE
            ) {
              throw ret.erro;
            }
          }
        }

        persistMovAnterior(movAtual);
        resetQtdPessoasPagamento();

        const configAuto = getConfigPdv(EnumPDVConfigCod.ImpressaoComprovante);
        if (configAuto) {
          if (configAuto.toLowerCase() === "automático") {
            if (movAtual.mod === EnumMovModelo.NFE) {
              abrirDialogImpressaoNfe(movAtual.id);
            } else {
              try {
                await imprimirCupom(
                  idVenda!,
                  movAtual.tpEmis,
                  movAtual.mod,
                  true
                );
              } catch (e: any) {
              }
            }
          }
        }

        if (
          getConfigPdv(EnumPDVConfigCod.TicketConsumacao) === "1" &&
          isPlanoConsumacao(plano?.plano) &&
          !(movAtual?.informacoesGeraisPedido?.pedidos.length > 0)
        ) {
          try {
            await imprimirTicket(
              movAtual.id,
              movAtual.tpEmis,
              true,
              printType()
            );
          } catch (e: any) {
          }
        }

        await setVendedor(undefined, false);
        await persistMovDados(undefined, true);

        callEvent(AppEventEnum.MovAtualAlterada, undefined);

        // adiciono a numeração utilizada na venda atual.
        await setNumeracao(
          movAtual.mod,
          movAtual.serie,
          movAtual.tpAmb,
          movAtual.nnf
        );

        if (movAtual.informacoesGeraisPedido.pedidos.length > 0) {
          return {
            error: null,
            status: "sucesso",
            url: MovRotasMock.finalizarPedidosRota,
            adicional: null
          };
        }

        if (getConfigEmp(EnumEmpresaConfig.AguardarIniciarVenda) === "-1") {
          const sincEmAndamento = getStatusSincronizacaoAtual();

          if (sincEmAndamento && sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento) {
            return {
              error: null,
              status: "sucesso",
              url: MovRotasMock.landingRota,
              adicional: null
            };
          }

          const vendas = await getVendas();

          if (vendas &&
            vendas.length > 0 &&
            navigator.onLine &&
            tpSincronizacao === EnumTpSincronizacao.Automatica) {
            iniciarSincronizacaoGeral();
          }

          return {
            error: null,
            status: "sucesso",
            url: MovRotasMock.landingRota,
            adicional: null
          };
        }
        return {
          error: null,
          status: "sucesso",
          url: MovRotasMock.finalizarVendaComprovanteRota,
          adicional: null
        };
      } catch (e: any) {
        movAtual.retornoFinalizacao = {
          error: e.message,
          status: "error",
          url: MovRotasMock.finalizarVendaRejeicaoRota,
          adicional: retornoTransmissao
        };

        await persistMovDados(movAtual, true);

        return movAtual.retornoFinalizacao;
      }
    },
    [getMov, getConfigEmp, persistMovAnterior, resetQtdPessoasPagamento, getConfigPdv, plano?.plano, setVendedor, persistMovDados, callEvent, setNumeracao, inserirVendaAPI, putFinalizarVenda, abrirDialogImpressaoNfe, imprimirCupom, imprimirTicket, printType, getStatusSincronizacaoAtual, getVendas, tpSincronizacao, iniciarSincronizacaoGeral]
  );

  // MARK: putCancelarVendaEmAndamento
  const putCancelarVendaEmAndamento = useCallback(
    async (idVenda: string, motivo: string, tpEmis: EnumTpEmis) => {
      if (tpEmis === EnumTpEmis.NORMAL) {
        const ret = await deleteCancelarVenda(idVenda, motivo);
        if (ret.erro) {
          throw ret.erro;
        }
      } else {
        await cancelarVenda(idVenda);
      }
    },
    [cancelarVenda, deleteCancelarVenda]
  );

  // MARK: cancelarMov
  const cancelarMov = useCallback(
    async (
      motivo: string,
      urlCallback?: string | undefined | null
    ): Promise<void> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      resetQtdPessoasPagamento();
      if (movAtual.informacoesGeraisPedido.pedidos.length > 0) {
        return history.push({
          pathname: MovRotasMock.cancelarImportacaoRota,
          state: {
            pedidos: movAtual.informacoesGeraisPedido.pedidos,
            motivo: motivo
          }
        });
      }

      movAtual.status = EnumIdeStatus.CANCELADO;

      if (isEmpty(movAtual.produtos)) {
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        callEvent(AppEventEnum.MovAtualAlterada, undefined);
        await setVendedor(undefined, false);

        return history.push(MovRotasMock.landingRota);
      }

      const getUltimoNro = await getUltimaNumeracao(getPDV(), retornarModDaVenda().mod);

      if (!getUltimoNro) {
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        await setVendedor(undefined, false);
      }

      movAtual.tpAmb = 2;
      movAtual.nnf = getUltimoNro!.nNf + 1;
      movAtual.serie = getUltimoNro!.serie;
      movAtual.mod = retornarModDaVenda().mod;
      movAtual.dEmi = new Date();
      if (movAtual.cobr) {
        movAtual.cobr.fat.nFat = String(movAtual.nnf);
      }

      let idVenda;
      try {
        if (movAtual.id === guidEmpty()) {
          idVenda = await saveNovaVenda(movAtual);
        } else {
          idVenda = movAtual.id;
        }
        await setNumeracao(
          movAtual.mod,
          movAtual.serie,
          movAtual.tpAmb,
          movAtual.nnf
        );
        try {
          await putCancelarVendaEmAndamento(idVenda, motivo, movAtual.tpEmis);
        } catch { }
      } finally {
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        callEvent(AppEventEnum.MovAtualAlterada, undefined);
        await setVendedor(undefined, false);
        setRegistro(GestaoStorageKeys.IsDelivery, false, false);

        if (urlCallback) {
          return history.push(urlCallback);
        }

        return history.push(MovRotasMock.landingRota);
      }
    },
    [callEvent, getMov, getPDV, getUltimaNumeracao, history, persistMovAnterior, persistMovDados, putCancelarVendaEmAndamento, resetQtdPessoasPagamento, retornarModDaVenda, saveNovaVenda, setNumeracao, setRegistro, setVendedor]
  );

  // MARK: refazerPagamento
  const refazerPagamento = useCallback(async () => {
    const movAtual = getMov();

    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    const notZero = movAtual?.pags.filter(
      (item) => item.tpTransacao !== EnumPagTpTransacao.NORMAL
    );

    const integradoNormal = movAtual!.pags.filter(
      (item) => item.tpTransacao === EnumPagTpTransacao.NORMAL
    );

    if (integradoNormal.length < 1)
      throw new Error("Não há pagamento não integrado.");

    movAtual.pags = notZero;
    limparDescontosMov(movAtual);
    await persistMovDados(movAtual);

    history.push(MovRotasMock.novoPagamentoRota);
  }, [getMov, history, limparDescontosMov, persistMovDados]);

  // MARK: setMesaId
  const setMesaId = useCallback(
    async (idMesa: string | null, codMesa?: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a mesa`);
      }

      if (idMesa) {
        if (codMesa) {
          movAtual.informacoesGeraisPedido.codigoMesa = codMesa;
        }
        movAtual.informacoesGeraisPedido.mesaId = idMesa;
        await persistMovDados(movAtual);
        return;
      }

      movAtual.informacoesGeraisPedido.mesaId = idMesa;
      movAtual.informacoesGeraisPedido.balcaoIdentificado = true;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: setSalaoId
  const setSalaoId = useCallback(
    async (idSalao: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir o salão`);
      }

      movAtual.informacoesGeraisPedido.salaoId = idSalao;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: limparInformacoesPedidos
  const limparInformacoesPedidos = useCallback(
    async () => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir o salão`);
      }

      movAtual.informacoesGeraisPedido = new InformacoesGeraisPedido();
      movAtual.id = guidEmpty();
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: setComandaId
  const setComandaId = useCallback(
    async (idComanda: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a comanda`);
      }

      if (!idComanda && idComanda !== "") {
        throw new Error(`A comanda não foi selecionada`);
      }

      movAtual.informacoesGeraisPedido.comandaId = idComanda;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: setIdentificadorPager
  const setIdentificadorPager = useCallback(
    async (identificador: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir o pager identificador`);
      }

      movAtual.informacoesGeraisPedido.identificador = identificador;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: setCodigoComanda
  const setCodigoComanda = useCallback(
    async (codigo: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a comanda`);
      }

      if (!codigo) {
        throw new Error(`A comanda não foi selecionada`);
      }

      movAtual.informacoesGeraisPedido.codigoComanda = codigo;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  // MARK: setTipoMovimentacao
  const setTipoMovimentacao = useCallback(
    async (tipoMov: EnumMovModelo, naoAlteraTaxa: boolean = false) => {
      const movAtual = getMov();
      const pendentes = await dadosIniciaisPendente(movAtual)
      if (pendentes) {
        return
      }
      await persistMovDados(movAtual, naoAlteraTaxa, true, tipoMov);
    },
    [dadosIniciaisPendente, getMov, persistMovDados]
  );

  // MARK: setPedidoExistente
  const setPedidoExistente = useCallback(
    async (
      idPedido: string,
      idComanda: string,
      idMesa: string,
      idSalao: string,
      codigoMesa: string,
      codigoComanda: string,
      codigoPedido: string,
      identificador: string,
      isDelivery: boolean = false
    ) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }

      if (!idPedido) {
        throw new Error(`Id do pedido não identificado.`);
      }

      movAtual.id = idPedido;
      movAtual.informacoesGeraisPedido = {
        mesaId: idMesa,
        salaoId: idSalao,
        comandaId: idComanda,
        codigoMesa: codigoMesa ?? "",
        codigoComanda: codigoComanda ?? "",
        balcaoIdentificado: idSalao ? true : false,
        pedidos: [],
        codigoPedido: codigoPedido,
        identificador: identificador ?? "",
        isDelivery
      };

      await persistMovDados(movAtual, false, true, isDelivery ? EnumMovModelo.DELIVERY : EnumMovModelo.PEDIDO);
    },
    [getMov, persistMovDados]
  );

  // MARK: addInfoDoPedidoNoProduto
  const addInfoDoPedidoNoProduto = useCallback((pedidos: PedidoModel[]) => {
    return pedidos.map((pedido) => {
      return pedido.produtos.map((produto) => {
        return {
          ...produto,
          pedidoId: pedido.id,
          comandaId: pedido.comandaId,
          mesaId: pedido.mesaId,
          mesaCodigo: pedido.mesaCodigo,
          codigoComanda: pedido.codigoComanda,
          codigoPedido: pedido.codigoPedido,
          identificador: pedido.identificador ?? ""
        };
      });
    });
  }, []);

  // MARK: rastroPedidosParaRastroVenda
  const rastroPedidosParaRastroVenda = (
    rastros: RastroPedido[]
  ): RastroVenda[] => {
    return rastros.map((rastro) => ({
      nLote: rastro.numeroLote,
      qLote: rastro.quantidadeLote,
      dFab: rastro.dataFabricacao,
      dVal: rastro.dataValidade,
      cAgreg: rastro.codigoAgregacao
    }));
  };

  // MARK: produtosPedidoParaProdutosVenda
  const produtosPedidoParaProdutosVenda = useCallback(
    async (produtosPedido: PedidoProdutoModelVenda[]) => {
      const venda = getMov();

      let quantidadeProdutosNoCarrinho =
        venda?.produtos[venda.produtos.length - 1]?.nSeq ?? 0;

      const produtos: MovSimplesProdutoModel[] = [];

      for await (const produto of produtosPedido) {
        let produtoIndexDb: TabelaProdutos | undefined = undefined;
        if (!produto.plu) {
          produtoIndexDb = await TouchoneDBPrimary.produtos
            .where("produtoId")
            .equals(produto.produtoIdReferencia!)
            .first();
        } else {
          produtoIndexDb = await TouchoneDBPrimary.produtos
            .where("codigo")
            .equals(produto.plu)
            .first();
        }

        quantidadeProdutosNoCarrinho += 1;

        if (!produtoIndexDb) {
          const todosProdutos = await TouchoneDBPrimary.produtos
            .where("numCodigos")
            .above(1)
            .toArray();

          produtoIndexDb = todosProdutos.find((item) =>
            item.codigos?.some((c) => c.codigo === produto.plu)
          );

          if (!produtoIndexDb) {
            throw new Error(
              `Falha na importação, o produto ${produto.descricao} não foi encontrado.`
            );
          }
        }

        const categoria = await TouchoneDBPrimary.categorias.get({
          id: produtoIndexDb?.categoriaId ?? ""
        });

        let NCMIndex: TabelaNCM | undefined = undefined;

        if (produtoIndexDb.ncm) {
          // NCM DO PRODUTO
          NCMIndex = await TouchoneDBPrimary.ncms.get({
            codigo: produtoIndexDb.ncm
          });
        }

        const rastro = rastroPedidosParaRastroVenda(produto?.rastros ?? []);
        let medicamento: TabelaMedicamentos | null = null;
        if (!isEmpty(produto.codigoAnvisa)) {
          medicamento = await TouchoneDBPrimary.medicamentos
            .where({ codigoAnvisa: produto.codigoAnvisa })
            .first() ?? null;
        }

        const produtoVenda: MovSimplesProdutoModel = {
          ...new MovSimplesProdutoModel(),
          ativo: produto.status.codigo === 1 ? true : false,
          balanca: produto.pesoVariavel ? 3 : 0,
          cEan: produto.codigoBarra,
          cProd: produto.plu,
          codigoPedido: produto.codigoPedido,
          cProdKit: "",
          categoria: categoria?.descricao ?? "",
          grupoImpostoId: produtoIndexDb.grupoImpostoId,
          id: produto.codigoReferencia,
          imgUrl: produtoIndexDb.imagemUrl ?? "",
          infAdic: produto.observacao,
          ncm: produtoIndexDb.ncm ?? "",
          ncmId: produtoIndexDb.ncmId ?? "",
          pedidoId: produto.pedidoId,
          produtoGradeId: produtoIndexDb.produtoGradeId,
          produtoId: produtoIndexDb.produtoId,
          qCom: produto.quantidade,
          tabelaPrecoId: venda?.cliente?.tabelaPrecoId ?? "",
          uCom: produtoIndexDb.medida ?? "",
          vFrete: produto.valorTotalFrete,
          vProd: produto.valorUnitario * produto.quantidade ?? 0,
          vDesc: produto.valorTotalDesconto,
          vDescEmbutido: produto.valorTotalDescontoEmbutido,
          vOutro: produto.valorTotalAdicional,
          vOutroEmbutido: produto.valorTotalAdicionalEmbutido,
          descontos: produto.descontos || [],
          acrescimos: produto.acrescimos || [],
          vSeg: 0,
          vFinal: 0, //SERA CALCULADO
          valorServico: 0, //SERA CALCULADO
          vUnCom: produto.valorUnitario,
          vendedorId: produto.vendedorId,
          vendedorNome: produto.vendedor,
          xProd: produto.descricao,
          nSeq: quantidadeProdutosNoCarrinho,
          temImposto: false,
          comandaId: produto.comandaId,
          mesaId: produto.mesaId,
          setorId: produto.setorId,
          salaoId: produto.salaoId,
          pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
          pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
          pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
          pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
          vTrib: calcVTrib(
            NCMIndex?.pTribManual ?? 0,
            NCMIndex?.pTribFederal ?? 0,
            NCMIndex?.pTribEstadual ?? 0,
            NCMIndex?.pTribMunicipal ?? 0,
            produto.valorTotal
          ),
          taxaServico: produto.taxaServico,
          cobraTaxaServico: produto.taxaServico > 0,
          subItens: produtoIndexDb?.subItens ?? [],
          prodSubItem:
            produto?.subItens?.length > 0
              ? await produtosPedidoParaProdutosVenda(produto.subItens)
              : [],
          infoSubItem: null,
          idDoProdutoPaiInfoSubItem: produto.produtoPai,
          indFin: produto.indFin,
          produtoPaiId: produto.produtoPai,
          tpProduto: produto.tpProduto.codigo,
          validacaoSubItem: true,
          idAdicional: produto.adicionalId,
          idGroup: produto.groupId,
          adicionais: [],
          indDesperdicio: produto.indDesperdicio.codigo,
          nivel: produto.nivel,
          produtoIdReferencia: produtoIndexDb.produtoId,
          quantidadeMax: produto.quantidade,
          codigoComanda: produto.codigoComanda,
          mesaCodigo: produto.mesaCodigo,
          identificador: produto.identificador,
          modificadores: [],
          modificadorId: null,
          vUnComOrig: produtoIndexDb.vPreco,
          qComModificador: 0,
          ordem: 0,
          modificadorUnicoId: "",
          cProdANVISA: produto.codigoAnvisa,
          nSeqReceitaMedica: produto.numeroSequenciaReceitaMedica,
          rastro: rastro.length > 0 ? rastro : null,
          ignorarReceituario: (produto.numeroSequenciaReceitaMedica ?? 0) > 0 ? true : false,
          med: {
            cProdANVISA: produto.codigoAnvisa,
            nSeqReceitaMedica: produto.numeroSequenciaReceitaMedica,
            vendaControlada: produto.numeroSequenciaReceitaMedica ? true : false,
            vPMC: produto.precoMaximoConsumidor === 0 ? 0 : produto.precoMaximoConsumidor,
            xMotivoIsencao: null,
            medicamento: medicamento
          },
          dataHoraLancamento: produto.dataHoraLancamento
        };
        calcularValorFinalProduto(produtoVenda);
        produtos.push(produtoVenda);
      }

      return produtos;
    },
    [calcularValorFinalProduto, getMov]
  );

  // MARK: transformarPedidosEmVendaSimples
  const transformarPedidosEmVendaSimples = useCallback(
    async (pedidos: PedidoModel[]) => {
      const venda = getMov();

      if (!venda) {
        throw new Error("Venda não iniciada");
      }

      // setando que a venda recebeu pedidos para importação
      venda.informacoesGeraisPedido.pedidos = [
        ...venda.informacoesGeraisPedido.pedidos,
        ...pedidos
      ];
      const produtos = addInfoDoPedidoNoProduto(pedidos)
        .map((produtos) => produtos)
        .reduce((arr, obj) => arr.concat(obj), []) as PedidoProdutoModelVenda[];

      const produtosVenda = await produtosPedidoParaProdutosVenda(produtos);
      venda.produtos = [...venda.produtos, ...produtosVenda];

      // Cliente
      const pedido = pedidos[0]
      if (pedido.cliente.cpfCnpj) {
        venda.clienteIdentificado = true;
        let emails: string[] = []
        let telefones: string[] = []
        if(!isEmpty(pedido.cliente.email)){
           emails = pedido.cliente.email.split(';')
        }
        if(!isEmpty(pedido.cliente.telefone)){
          telefones = pedido.cliente.telefone.split(', ')
        }
        const contatosEmail = emails.map( email => new PessoaContatosModel('','', EnumTipoPessoaContato.EMAIL, email) )
        const contatosTelefone = telefones.map( telefone => new PessoaContatosModel('','', EnumTipoPessoaContato.TELEFONE, telefone))
        const listaContatos = contatosEmail.concat(contatosTelefone[0])
        venda!.cliente!.contatos = listaContatos
        venda!.cliente!.id =
          pedido.cliente.referenceId ?? venda!.cliente!.id;
        venda!.cliente!.nome =
          pedido.cliente.nomeFantasia ?? venda!.cliente!.nome;
        venda!.cliente!.cpfcnpj =
          pedido.cliente.cpfCnpj ?? venda!.cliente!.cpfcnpj;
        venda!.cliente!.ierg = pedido.cliente.ieRg ?? venda!.cliente!.ierg;

        venda.documento = pedido.cliente.cpfCnpj ?? venda.documento;
      }

      // receitas
      venda.receitasMedicas = pedido.receitasMedicas;

      const mod = retornarModDaVenda().mod

      await persistMovDados(venda, false, true, mod);
    },
    [addInfoDoPedidoNoProduto, getMov, persistMovDados, produtosPedidoParaProdutosVenda, retornarModDaVenda]
  );

  // MARK: removerItensdoPedidodaVendaSimples
  const removerItensdoPedidodaVendaSimples = useCallback(
    async (idPedido: string) => {
      const venda = getMov();

      if (!venda) {
        throw new Error("Venda não iniciada");
      }

      //REMOVE OS PRODUTOS DAQUELE PEDIDO
      venda.produtos = venda.produtos.filter(
        (produto) => produto.pedidoId !== idPedido
      );

      //REMOVE O PEDIDO DA VENDA
      venda.informacoesGeraisPedido.pedidos =
        venda.informacoesGeraisPedido.pedidos.filter(
          (pedido) => pedido.id !== idPedido
        );

      await persistMovDados(venda);

      callEvent(AppEventEnum.MovAtualProdAlterado, venda);
    },
    [callEvent, getMov, persistMovDados]
  );

  // MARK: removerPedidoDoCarrinho
  const removerPedidoDoCarrinho = useCallback(
    async (idPedido: string) => {
      const venda = getMov();

      if (!venda) {
        throw new Error("Venda não iniciada");
      }

      const pedido = getMov()?.informacoesGeraisPedido.pedidos.find(
        (pedido) => pedido.id === idPedido
      );
      if (pedido) {
        if (
          pedido.statusAutomacao.codigo ===
          EnumStatusSituacaoPedido.FECHAMENTO_PARCIAL
        ) {
          const respostaDeleteStatus = await deleteFechamentoParcialPedido(
            getEmpresaSelecionada()?.Id ?? "",
            idPedido
          );

          if (respostaDeleteStatus.erro) {
            throw respostaDeleteStatus.erro;
          }
        } else {
          const respostaPutStatus = await putPedidoStatus(
            getEmpresaSelecionada()?.Id ?? "",
            idPedido,
            EnumStatusPedido.FECHADO
          );

          if (respostaPutStatus.erro) {
            throw respostaPutStatus.erro;
          }
        }
      }
      await removerItensdoPedidodaVendaSimples(idPedido);
    },
    [deleteFechamentoParcialPedido, getEmpresaSelecionada, getMov, putPedidoStatus, removerItensdoPedidodaVendaSimples]
  );

  // MARK: criarTaxaEntrega
  const criarTaxaEntrega = useCallback(
    async (valor: number, tipoEntrega: "propria" | "terceirizada") => {
      const produtoIndexDb = await getProdutoEntrega();
      if (produtoIndexDb && !produtoIndexDb.ativo) {
        return;
      }

      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      let NCMIndex;
      let quantidadeProdutosNoCarrinho = movAtual.produtos.length;
      quantidadeProdutosNoCarrinho += 1;

      if (produtoIndexDb?.ncm ?? 0) {
        // NCM DO PRODUTO
        NCMIndex = await TouchoneDBPrimary.ncms.get({
          codigo: produtoIndexDb?.ncm ?? 0
        });
      }

      const vendedor: PessoaModel = await getVendedor();

      const categoria = await TouchoneDBPrimary.categorias.get({
        id: produtoIndexDb?.categoriaId ?? ""
      });

      const produtoVenda: MovSimplesProdutoModel = {
        ...new MovSimplesProdutoModel(),
        ativo: true,
        balanca: produtoIndexDb?.balanca ?? 0,
        cEan: "",
        cProd: produtoIndexDb?.codigo ?? "",
        cProdKit: "",
        categoria: categoria?.descricao ?? "",
        grupoImpostoId: produtoIndexDb?.grupoImpostoId ?? "",
        id: newGuid(),
        imgUrl: produtoIndexDb?.imagemUrl ?? "",
        infAdic:
          tipoEntrega === "propria"
            ? "Entrega Própria"
            : "Entrega Terceirizada",
        ncm: produtoIndexDb?.ncm ?? "",
        ncmId: produtoIndexDb?.ncmId ?? "",
        pedidoId: "",
        produtoGradeId: produtoIndexDb?.produtoGradeId ?? "",
        produtoId: produtoIndexDb?.produtoId ?? "",
        qCom: 1,
        tabelaPrecoId: movAtual?.cliente?.tabelaPrecoId ?? "",
        uCom: produtoIndexDb?.medida ?? "",
        vFinal: valor,
        vFrete: valor,
        vProd: valor,
        vOutro: 0,
        vOutroEmbutido: 0,
        vDesc: 0,
        vSeg: 0,
        vUnCom: valor,
        vendedorId: vendedor.id,
        vendedorNome: vendedor.nome,
        xProd: produtoIndexDb?.nome ?? "",
        nSeq: quantidadeProdutosNoCarrinho,
        temImposto: false,
        comandaId: "",
        mesaId: "",
        setorId: produtoIndexDb?.setorId ?? "",
        salaoId: "",
        pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
        pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
        pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
        pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
        vTrib: 0,
        taxaServico: 0,
        valorServico: 0,
        cobraTaxaServico: false,
        indFin: true,
        subItens: [],
        adicionais: [],
        idAdicional: null,
        idDoProdutoPaiInfoSubItem: null,
        idGroup: null,
        infoSubItem: null,
        prodSubItem: [],
        produtoPaiId: null,
        tpProduto: EnumTpProduto.Produto,
        validacaoSubItem: true,
        indDesperdicio: EnumIndDesperdicio.NaoSeAplica,
        nivel: 0,
        produtoIdReferencia: produtoIndexDb?.produtoId ?? null,
        quantidadeMax: 1,
        codigoComanda: null,
        mesaCodigo: null,
        modificadores: [],
        modificadorId: "",
        ordem: 0,
        qComModificador: 0,
        vUnComOrig: 0,
        modificadorUnicoId: ""
      };

      return produtoVenda;
    },
    [getMov, getProdutoEntrega, getVendedor]
  );

  // MARK: calcularTaxaEntrega
  const calcularTaxaEntrega = useCallback(
    async (valor: number, tipoEntrega: "propria" | "terceirizada") => {
      const mov = getMov();
      const entrega = getConfigContratoByCod(EnumContratoConfig.ProdutoEntrega);
      if (!mov) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }
      const prodEntrega = mov.produtos.find(
        (prod) => prod.produtoId === entrega
      );

      if (isEmpty(prodEntrega)) {
        const res = await criarTaxaEntrega(valor, tipoEntrega);
        if (!res) {
          throw new Error(
            "A venda não está aberta ou não foi configurado o Produto de Entrega."
          );
        }
        mov.produtos.push(res);
      } else {
        var index = mov.produtos.indexOf(prodEntrega);
        const prod: MovSimplesProdutoModel = {
          ...prodEntrega,
          vFinal: valor,
          vFrete: valor,
          vProd: valor,
          vUnCom: valor,
          indFin: valor > 0,
          infAdic:
            tipoEntrega === "propria"
              ? "Entrega Própria"
              : "Entrega Terceirizada"
        };
        mov.produtos[index] = prod;
      }

      await persistMovDados(mov);
      callEvent(
        AppEventEnum.MovAtualProdAlterado,
        new MovSimplesProdutoModel()
      );
    },
    [
      callEvent,
      criarTaxaEntrega,
      getConfigContratoByCod,
      getMov,
      persistMovDados
    ]
  );

  // MARK: editarInfAdicional
  const editarInfAdicional = useCallback(
    async (infAdicional: string) => {
      const mov = getMov();
      if (!mov) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }

      mov.infAdicional = infAdicional;
      await persistMovDados(mov);
      callEvent(
        AppEventEnum.MovAtualProdAlterado,
        new MovSimplesProdutoModel()
      );
    },
    [callEvent, getMov, persistMovDados]
  );

  // MARK: retornaDescontoTotal
  const retornaDescontoTotal = useCallback(() => {
    const mov = getMov();
    let vDescTotal = 0;

    mov?.produtos?.map((item) => {
      if (item.ativo) {
        return (vDescTotal += item.vDesc);
      }
      return item;
    });

    return vDescTotal;
  }, [getMov]);

  // MARK: retornaAcrescimoTotal
  const retornaAcrescimoTotal = useCallback(() => {
    const mov = getMov();
    let vAcrescTotal = 0;

    mov?.produtos?.map((item) => {
      if (item.ativo) {
        return (vAcrescTotal += item.vOutro + item.vOutroEmbutido);
      }
      return item;
    });

    return vAcrescTotal;
  }, [getMov]);

  // MARK: verificaProdutosControlados
  const verificaProdutosControlados = useCallback(async () => {
    if (!isPlanoFarmaceutico(plano?.plano)) {
      return [];
    }
    const mov = getMov();
    const medicamentos =
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? [];
    const medicamentosVerificados: MovSimplesProdutoModel[] = [];
    for (let med of medicamentos) {
      const ret = await retornarInfoMedicamento(med.cProdANVISA, med.produtoId);
      med.med = ret;
      await alterarProdutoInterno(med, false, false);
      if (ret?.vendaControlada && !ret.nSeqReceitaMedica) {
        medicamentosVerificados.push(med);
      }
    }
    return medicamentosVerificados;
  }, [alterarProdutoInterno, getMov, plano?.plano, retornarInfoMedicamento]);

  // MARK: retornaProdutosMedicamentos
  const retornaProdutosMedicamentos = useCallback(() => {
    const mov = getMov();
    return (
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? []
    );
  }, [getMov]);

  // MARK: naoInformarDadosDaReceita
  const naoInformarDadosDaReceita = useCallback(async () => {
    const mov = getMov();
    const medicamentos =
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? [];

    for await (let med of medicamentos) {
      await alterarProdutoInterno({ ...med, ignorarReceituario: true }, false, false);
    }
  }, [alterarProdutoInterno, getMov]);

  // MARK: adicionarReceitaNaVenda
  const adicionarReceitaNaVenda = useCallback(
    async (
      receita: ReceitaMedicaModel,
      medicamentos: MovSimplesProdutoModel[]
    ) => {
      const mov = getMov();

      if (!mov) {
        throw new Error("Venda não iniciada");
      }
      const qtdReceitas = mov?.receitasMedicas.length ?? 0;

      mov.receitasMedicas = [
        ...(mov?.receitasMedicas ?? []),
        { ...receita, nSeq: qtdReceitas + 1 }
      ];

      await persistMovDados(mov);


      for await (let medicamento of medicamentos) {
        await alterarProdutoInterno({
          ...medicamento,
          nSeqReceitaMedica: qtdReceitas + 1,
          ignorarReceituario: true,
          med: {
            ...medicamento.med!,
            nSeqReceitaMedica: qtdReceitas + 1,
          }
        }, false, false);
      }
    },
    [alterarProdutoInterno, getMov, persistMovDados]
  );

  // MARK: alterarNNF
  const alterarNNF = async (nnf: number) => {
    const mov = getMov();

    if (!mov) {
      throw new Error("Venda não iniciada");
    }

    mov.nnf = nnf;

    await persistMovDados(mov);
  };

  // MARK: currentClienteIsPadrao
  const currentClienteIsPadrao = useCallback(async (): Promise<boolean> => {
    const mov = getMov();
    let response = false
    if (mov) {
      if (mov.cliente) {
        const consumidorPadrao = await getConsumidor()
        response = (consumidorPadrao?.id === mov.cliente.id)
      }
    }

    return response
  }, [getConsumidor, getMov]);

  // MARK: hasTaxaServicoProduto
  const hasTaxaServicoProduto = useCallback(() => {
    const mov = getMov()
    if (!mov?.produtos) return false

    const prodTaxaServico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico)
    const prodsTaxa = mov.produtos.filter(prod => prod.produtoId === prodTaxaServico)
    const prodsCalculaTaxa = mov.produtos.filter(prod => prod.taxaServico && prod.taxaServico > 0)

    return prodsTaxa.length > 0 && prodsCalculaTaxa.length > 0
  }, [getConfigContratoByCod, getMov]);

  // MARK: hasTaxaEntregaProduto
  const hasTaxaEntregaProduto = useCallback(() => {
    const mov = getMov()
    if (!mov?.produtos) return false
    const prodTaxaEntrega = getConfigContratoByCod(EnumContratoConfig.ProdutoEntrega)
    const temProd = mov?.produtos.find(x => x.produtoId === prodTaxaEntrega)
    return !isEmpty(temProd)
  }, [getConfigContratoByCod, getMov])

  return {
    getUltimoProduto,

    //SESSAO VENDEDOR
    getVendedor,
    setVendedor,

    //SESSAO MOV
    getMov,
    iniciarMov,
    restaurarMov,
    finalizarMov,
    faturarMov,
    ignorarFaturamentoMov,

    cancelarMov,
    alterarSessaoId,
    removerProdutoComSubItens,
    alterarTpMod,
    retornaDescontoTotal,
    retornaAcrescimoTotal,
    retornaProdutosMedicamentos,
    verificaProdutosControlados,
    naoInformarDadosDaReceita,
    adicionarReceitaNaVenda,
    alterarNNF,
    carregando,

    //SESSAO CLIENTE
    isClienteCadastradoObrigatorio,
    setClienteByDoc,
    setClientePessoa,
    setTelefoneCliente,
    setDocumentoNaNota,

    //SESSAO TRANSPORTADORA
    setTransportadora,

    //SESSAO PRODUTO
    alterarProduto,
    alterarProdutos,
    inserirProduto,
    aplicaTaxaServicoMov,
    hasTaxaServicoProduto,
    hasTaxaEntregaProduto,

    //SESSAO PAGAMENTO
    iniciarPagamento,
    alterarPagamento,
    refazerPagamento,
    carregandoPgto: carregandoDevice,

    // SESSAO PEDIDO
    setMesaId,
    setSalaoId,
    setComandaId,
    setIdentificadorPager,
    setCodigoComanda,
    setPedidoExistente,
    transformarPedidosEmVendaSimples,
    removerPedidoDoCarrinho,
    removerItensdoPedidodaVendaSimples,
    limparInformacoesPedidos,

    // TIPO DA MOVIMENTAÇÃO
    setTipoMovimentacao,
    retornarModDaVenda,

    //DIVIDIR CONTA
    saveQtdPessoasPagamento,
    resetQtdPessoasPagamento,
    getQtdPessoasPagamento,

    // SESSAO DELIVERY
    criarTaxaEntrega,
    calcularTaxaEntrega,
    editarInfAdicional,

    //Outros
    currentClienteIsPadrao,
  };
}
