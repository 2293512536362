import { useCallback, useMemo, useState } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon, ProcurarIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useHistory } from "react-router-dom";
import { FormPesquisaHeader } from "views/components/form/form-pesquisa-header/form-pesquisa-header";
import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";

export const PromocaoHeader = () => {
    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const history = useHistory();
    const { abrirPromocoesFiltroModal } = useFiltrosModais();

    const filtros = useMemo(() => {
        const urlParams = new URLSearchParams(history.location.search);
        return {
            ativo: isEmpty(urlParams.get('ativo'))
                ? 'true'
                : urlParams.get('ativo')?.toLowerCase(),
            termo: urlParams.get('termo') || ''
        };
    }, [history.location.search]);

    const [openTextfield, setOpenTextfield] = useState<boolean>(true);

    const leftArea = useCallback(
        () => (
            <>
                {isMobile && !history.location.pathname.includes('/adicionar') && !history.location.pathname.includes('/pesquisar') ?
                    <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
                    null}
            </>
        ),
        [isMobile, history.location.pathname, abrirMenu]
    );

    const rightArea = useCallback(
        () => (
            <Box display='flex' gridGap={8}>
                {!openTextfield && <ButtonPrivateHeader
                    icon={<ProcurarIcon tipo='PRIVATE_HEADER' />}
                    tooltip={'Abrir Pesquisa'}
                    onClick={() => setOpenTextfield(true)}
                ></ButtonPrivateHeader>}
                <ButtonPrivateHeader
                    icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
                    tooltip={'Abrir Filtros'}
                    onClick={() => {
                        abrirPromocoesFiltroModal({
                            ativo: filtros.ativo ?? 'true',
                            termo: filtros.termo,
                        })
                    }}
                ></ButtonPrivateHeader>
            </Box>
        ),
        [abrirPromocoesFiltroModal, filtros.ativo, filtros.termo, openTextfield]
    );

    const searchTextfield = (
        <FormPesquisaHeader
            text={filtros.termo}
            closeSearch={() => setOpenTextfield(false)}
            onSubmit={(model) => {
                const searchs: Array<string | null> = [
                    model.generico.length > 0 ? `termo=${model.generico}` : null,
                    filtros.ativo !== undefined ? (`ativo=${filtros.ativo}`) : null,
                ]

                let query = ''
                searchs
                    .filter(x => !isEmpty(x))
                    .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

                history.push({
                    pathname: '/promocoes',
                    search: query
                })
            }}
            placeholder="Buscar campanha"
        />
    )


    return (
        <>
            <PrivatePageHeader
                title='Campanha de Promoções'
                leftArea={leftArea()}
                middleArea={(openTextfield) && searchTextfield}
                rightArea={(history.location.pathname === '/promocoes' || history.location.pathname === '/promocoes/') && rightArea()}
            />
        </>
    );
};
