import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { DesfazerIcon, FiltroIcon, MenuIcon } from 'views/components/icons';
import { MovGerenciamentoSessoesSearchProps } from '../mov-gerenciamento-sessoes-list/mov-gerenciamento-sessoes-search-props';
import { PesquisaGerenciamentoSessoes } from './components/pesquisa-gerenciamento-sessoes/pesquisa-gerenciamento-sessoes';
import { useLocation } from 'react-router-dom';
import { useThemeQueries } from 'views/theme';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { MenuButtonHeader } from '../../../components/header/menu-button-header/menu-button-header';

export interface MovGerenciamentoSesoesHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: MovGerenciamentoSessoesSearchProps) => any;
}

export const MovGerenciamentoSessoesHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: MovGerenciamentoSesoesHeaderProps) => {

  const { pathname } = useLocation()
  const { isMobile } = useThemeQueries()
  const { abrirMenu } = useMenuPrincipal();

  const leftArea = useCallback(
    () => (
      isMobile && pathname === '/gerenciamento-sessoes' ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : pathname === '/gerenciamento-sessoes' ? null :
        <MenuButtonHeader />
    ), [abrirMenu, isMobile, pathname],
  );

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER" />
          )
        }
        tooltip={!openPesquisa ? 'Pesquisar' : 'Voltar'}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ),
    [setOpenPesquisa, openPesquisa],
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: MovGerenciamentoSessoesSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps],
  );

  const pesquisa = (
    <PesquisaGerenciamentoSessoes
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title="Gerenciamento de Sessões"
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
