import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minWidth: '150px',
    maxWidth: '200px',
    minHeight: "140px",
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
    "& span": {
      fontSize: "0.7em",
      textOverflow: 'ellipsis'
    },
    "& p": {
      fontSize: "1.0em",
      textOverflow: 'ellipsis'
    },
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  topContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    overflow: 'hidden',
    alignItems: 'center',
    marginBottom: '10px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-end',
      flexDirection: 'column'
    },
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  hourContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: theme.spacing(1),
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  codigoComanda: {
    fontSize: '20px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    overflow: 'hidden',
    textAlign: 'right',
    userSelect: 'none',
    '-webkit-user-select': 'none',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90px'
    },
  },
  pessoaLabel: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
      fontSize: '1em'
    },
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  pessoasContainer: {
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  textInfo: {
    fontWeight: 600,
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  textBottomInfo: {
    color: theme.palette.grey[700],
    fontSize: '10px',
    textAlign: 'right',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  fechamentoContainer: {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.warning.light,
    color: '#FFF',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  importadoContainer: {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[500],
    color: '#FFF',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  identificador: {
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  handleOverflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: '150px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '90px'
    },
  }
}));

