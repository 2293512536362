import { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  Fade
} from '@material-ui/core';
import { Box, Button, Grid, Typography } from 'views/design-system';

import { TransitionProps } from '@material-ui/core/transitions';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { CloseIcon } from 'views/components/icons/close-icon';
import { useStyles } from './dialog-saurus-styles';
import { DialogSaurusProps } from './dialog-saurus-props';
import classNames from 'classnames';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const DialogSaurus = (props: DialogSaurusProps) => {
  const {
    aberto,
    children,
    icone,
    carregando,
    titulo,
    tamanho,
    zIndex,
    isButtonTitleClose,
    handleClickClose,
    fullScreen,
    TransitionComponent,
    bottomArea,
    subTitulo,
    propagateClick,
    fullHeight,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      PaperProps={fullHeight ? {
        className: classNames(classes.dialog)
      } : undefined}
      fullScreen={fullScreen}
      classes={{
        paper: classes.container
      }}
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={TransitionComponent ?? Transition}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
      onClick={propagateClick ? (e) => {
        try {
          //propago o click para alguma div abaixo caso possua (isso serve para fechar o dialog caso clique fora)
          const underElement = document.elementsFromPoint(e.pageX, e.pageY);
          if (underElement.length > 2) {
            /* 
              No exemplo do dialog de aviso, o elemento que fecha retorna no index 3,
              acredito que se aplique a todos os casos, mas se vc tiver problemas
              pode criar uma prop number para alterar dinamicamente o index em cada caso
            */
            (underElement[3] as any).click();
          }
        } catch { }
      } : undefined}
    >
      {props.titulo ?
        <Grid className={classes.dialogTitle}>
          {isButtonTitleClose ? (
            <Grid
              className={classes.containerTitle}
              style={{ justifyContent: props.titulo ? 'space-between' : 'unset' }}
            >
              <Box flex alignItems='center'>
                <Typography
                  variant="h6"
                  className={classes.title}
                  style={{ color: props.colorTitulo || 'unset' }}
                >
                  {titulo}
                </Typography>
                {subTitulo && (
                  <Typography
                    variant="body1"
                    className={classes.subTitle}
                    style={{ color: props.colorSubTitulo || 'unset' }}
                  >
                    {subTitulo}
                  </Typography>
                )}
              </Box>
              <Button
                rounded
                className={classes.closeButton}
                onClick={handleClickClose}
              >
                <CloseIcon tipo="BUTTON" fill="#666" style={{ width: 30 }} />
              </Button>
            </Grid>
          ) : (
            <Grid
              className={classes.titleCentralizado}
              style={{
                justifyContent: props.centralizarTitulo ? 'center' : 'flex-start',
                alignItems: props.centralizarTitulo ? 'center' : 'flex-start'
              }}
            >
              {icone ? icone : null}
              <Box>
                <Typography
                  variant="h6"
                  className={classes.title}
                  style={{ color: props.colorTitulo || 'unset' }}
                >
                  {titulo}
                </Typography>
                {subTitulo && (
                  <Typography
                    variant="body1"
                    className={classes.subTitle}
                    style={{ color: props.colorSubTitulo || 'unset' }}>
                    {subTitulo}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        : null}
      <DialogContent
        className={classes.dialogContent}
        style={{
          padding: props.removerPaddingContent ? 0 : undefined,
          height: props.fullHeightContent ? '100%' : undefined
        }}
      >
        {carregando ? <CircularLoading tipo="FULLSIZED" /> : children}
      </DialogContent>
      {bottomArea && <div>{bottomArea}</div>}
    </Dialog>
  );
};
