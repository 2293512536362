import { Box, Button, Typography } from "@material-ui/core"
import { CloseIconCircle } from "views/components/icons/close-icon-circle"
import { useThemeQueries } from "views/theme"
import { useStyles } from "./questionario-etapa-styles"
import { OkIcon } from "views/components/icons"

interface QuestionarioEtapaProps {
    pergunta: string,
    funcaoBotao1: ()=> void,
    funcaoBotao2: ()=> void,
    textoBotao1: string,
    textoBotao2: string
}


export const QuestionarioEtapa = ({ pergunta, funcaoBotao1, funcaoBotao2, textoBotao1, textoBotao2, }: QuestionarioEtapaProps,) => {

    const { isMobile, theme } = useThemeQueries()
    const classes = useStyles()
    return (
        <Box height='100%' width='100%' display='flex' flexDirection='column' justifyContent='space-between' >
            <Typography align={isMobile ? 'left' : 'center'} variant='body1' className={classes.question}>
                {pergunta}
            </Typography>
            <Box className={classes.botoesQuestaoAtivaModulo} >
                <Box display='flex' alignItems='flex-end' width='50%' marginRight='16px' >
                    <Button
                        type="submit"
                        color="primary"
                        variant="outlined"
                        size="large"
                        fullWidth={true}
                        onClick={funcaoBotao1}>
                        <CloseIconCircle tipo='BUTTON' fill={theme.palette.primary.main} />
                        {textoBotao1}
                    </Button>
                </Box>
                <Box display='flex' alignItems='flex-end' width='50%'>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth={true}
                        onClick={funcaoBotao2}>
                        <OkIcon tipo='BUTTON' fill={theme.palette.common.white} />
                        {textoBotao2}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}