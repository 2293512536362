import { isEmpty } from 'lodash';
import { EnumMovModelo } from 'model';
import { PessoaContatosModel } from 'model/api/gestao/pessoa';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { consoleDev } from 'utils/console-dev';
import { DialogEnviarCupomEmail } from 'views/components/dialog/dialog-enviar-cupom-venda-por-email/dialog-enviar-cupom-venda-por-email';

class EnviarEmailDialogProps {
  constructor(
    public aberto: boolean = false,
    public id: string = '',
    public mod: number = 0,
    public contatos: PessoaContatosModel[] = []
  ) { }
}

export const EnviarEmailDialog = () => {
  consoleDev('EnviarEmailDialog');

  const { addHandler, removeHandler } = useEventTools();
  const { enviarCupomPorEmail, enviarCupomVendaOffline } = useMovSimples();

  const [carregando, setCarregando] = useState<boolean>(false);
  const [email, setEmail] = useState<string[]>([]);
  const [modalState, setModalState] = useState<EnviarEmailDialogProps>(
    new EnviarEmailDialogProps()
  );

  const { fecharEnviarEmailDialog } = useCadastros();
  const { showToast } = useToastSaurus();

  const modalAlterado = useCallback(({ aberto, id, mod, contatos }: any) => {
    setModalState({
      aberto: aberto,
      id,
      mod,
      contatos
    });
  }, []);

  const emails = useMemo(() => {
    if (modalState.contatos && (modalState.contatos.length  > 0 && !isEmpty(modalState.contatos)) ) {
      return modalState.contatos
        .filter(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)
        .map(email => email.valor)
    }
  }, [modalState])

  useEffect(()=>{
    if (modalState.contatos) {
      setEmail(emails ?? [])
    }
  },[emails, modalState.contatos])
  
  useEffect(() => {
    addHandler(AppEventEnum.EnviarEmailDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.EnviarEmailDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  const handleClickEnviarComprovantePorEmail = async () => {
    try {
      setCarregando(true);
  
      if (
        modalState.mod === EnumMovModelo.NFCE ||
        modalState.mod === EnumMovModelo.NFE
      ) {
        await enviarCupomPorEmail(modalState.id, email.join(';'));
      } else {
        await enviarCupomVendaOffline(modalState.id, email.join(';'));
      }
      setCarregando(false);
      showToast('success', `Cupom enviado para ${(email ?? []).length > 1 ? 'os E-Mails: ' :' o E-mail: '}${email}`);
      setEmail([]);
      fecharEnviarEmailDialog();
    } catch (err: any) {
      showToast('error', err.message);
      setCarregando(false);
    }
  };

  return (
    <>
      {modalState.aberto && (
        <DialogEnviarCupomEmail
          loading={carregando}
          openned={modalState.aberto}
          closeModal={fecharEnviarEmailDialog}
          email={email}
          setEmail={setEmail}
          enviarEmail={() => {
            handleClickEnviarComprovantePorEmail();
          }}
        />
      )}
    </>
  );
};
