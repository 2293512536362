import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useStyles } from './button-add-e-sub-styles';
import { isEmpty } from 'lodash';
import { LixoIcon, MaisIcon } from 'views/components/icons';
import { useConfirm } from 'material-ui-confirm';
import { EnumBalanca } from 'model/enums/enum-balanca';
import { toDecimalString } from 'utils/to-decimal';
import { Button, Grid, Typography } from 'views/design-system';
import { MenosSimplesIcon } from 'views/components/icons/menos-simples-icon';

interface ButtonAddESubProps {
  model: MovSimplesProdutoModel;
  alterarQtdeProduto: (model: MovSimplesProdutoModel, qtde: number, fator: 'sub' | 'add') => void;
  inativarProduto: (model: MovSimplesProdutoModel) => Promise<void>;
}

export const BotoesAddESub = ({
  model,
  alterarQtdeProduto,
  inativarProduto,
}: ButtonAddESubProps) => {
  const classes = useStyles();
  const { infoSubItem } = model;
  const confirm = useConfirm();

  const handleClick = () => {
    if (model.qCom === 1 || (model.balanca === EnumBalanca.Glaciado || model.balanca === EnumBalanca.PesadoVenda || model.balanca === EnumBalanca.Pesavel)) {
      confirm({
        title: 'Cancelamento do Produto',
        description: `Deseja confirmar o cancelamento do produto "${model.xProd}" ?`,
        confirmationText: 'Confirmar',
        cancellationText: 'Voltar',
      }).then(async () => {
        await inativarProduto(model);
      })
    }
    else {
      alterarQtdeProduto(model, 1, 'sub')
    }
  }

  return (
    <div className={classes.containerQtde}>
      {model.ativo && isEmpty(model.cProdKit) && (
        <Button
          variant="contained"
          onClick={handleClick}
          className={classes.btnAlterarQuantidade}
          color={
            (model.qCom === 1 || model.balanca === EnumBalanca.Glaciado || model.balanca === EnumBalanca.PesadoVenda || model.balanca === EnumBalanca.Pesavel)
              ? 'error'
              : 'primary'
          }
          rounded
        >
          {(model.qCom === 1 || model.balanca === EnumBalanca.PesadoVenda || model.balanca === EnumBalanca.Pesavel || model.balanca === EnumBalanca.Glaciado) ? (
            <LixoIcon tipo='GERAL' />
          ) : (
            <MenosSimplesIcon tipo='GERAL' />
          )}
        </Button>
      )}
      <Grid className={classes.textQCom}>
        {(model.balanca === EnumBalanca.PesadoVenda || model.balanca === EnumBalanca.Pesavel || model.balanca === EnumBalanca.Glaciado) ?
          <Typography variant="h6">
            {toDecimalString(model.qCom, 3)}
          </Typography> :
          <Typography variant="h6">
            {model.qCom}
          </Typography>}
        <Typography variant="caption">{model.uCom}</Typography>
      </Grid>
      {model.ativo && (model.balanca === EnumBalanca.Normal || model.balanca === EnumBalanca.Unitario) && isEmpty(model.cProdKit) && (
        <Button
          variant="contained"
          color="primary"
          rounded
          disabled={!model.ativo || model.indFin === false || ((infoSubItem?.qMax ?? 0) - 1) === model.qCom}
          onClick={() => alterarQtdeProduto(model, 1, 'add')}
          className={classes.btnAlterarQuantidade}
        >
          <MaisIcon tipo='GERAL' />
        </Button>
      )}
    </div>
  );
};
