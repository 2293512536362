import { useCallback, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { FiltroIcon, MenuIcon, ProcurarIcon } from 'views/components/icons';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { useThemeQueries } from 'views';
import { produtoPageNome, produtoPageUrl } from '../../produto-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { FormPesquisaHeader } from 'views/components/form/form-pesquisa-header/form-pesquisa-header';
import { Box } from '@material-ui/core';
import { useFiltrosModais } from 'services/app/hooks/filtros-modais';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { ProdutoListSearchProps } from '../produto-list/produto-list-search-props';
import { EnumFiltroStatusProduto } from 'views/components/form/receita/list-products/produto-list/produto-list-search-props';

export interface ProdutoHeaderProps {
  tipo: EnumTipoProduto;
  isFarmaceutico: boolean;
}

export const ProdutoHeader = ({
  tipo,
  isFarmaceutico = false
}: ProdutoHeaderProps) => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { abrirProdutoFiltroModal } = useFiltrosModais();
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const filtros: ProdutoListSearchProps = {
    status: isEmpty(urlParams.get('status')) ? EnumFiltroStatusProduto.ATIVO : Number(urlParams.get('status')),
    tipo: isEmpty(urlParams.get('tipo')) ? -1 : Number(urlParams.get('tipo')),
    descricao: urlParams.get('descricao') || '',
    codigo: urlParams.get('codigo') || '',
    termo: urlParams.get('termo') || '',
    categoria: urlParams.get('categoria') || '',
    marca: urlParams.get('marca') || '',

  };

  const [openTextfield, setOpenTextfield] = useState<boolean>(true);

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const rightArea = useCallback(
    () => (
      <Box display="flex" gridGap={8}>
        {!openTextfield && (
          <ButtonPrivateHeader
            icon={<ProcurarIcon tipo="PRIVATE_HEADER" />}
            tooltip={'Abrir Pesquisa'}
            onClick={() => setOpenTextfield(true)}
          ></ButtonPrivateHeader>
        )}
        <ButtonPrivateHeader
          icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
          tooltip={'Abrir Filtros'}
          onClick={() =>
            abrirProdutoFiltroModal({
              codigo: filtros.codigo,
              descricao: filtros.descricao,
              status: filtros.status,
              termo: filtros.termo,
              tipo: filtros.tipo,
              categoria: filtros.categoria,
              marca: filtros.marca
            })
          }
        ></ButtonPrivateHeader>
      </Box>
    ),
    [abrirProdutoFiltroModal, filtros.categoria, filtros.codigo, filtros.descricao, filtros.marca, filtros.status, filtros.termo, filtros.tipo, openTextfield]
  );

  const searchTextfield = (
    <FormPesquisaHeader
      text={filtros.termo}
      closeSearch={() => {
        setOpenTextfield(false);
      }}
      onSubmit={(model) => {
        const searchs: Array<string | null> = [
          model.generico.length > 0 ? `termo=${model.generico}` : null
        ];

        if (!isEmpty(filtros.codigo)) {
          searchs.push(`codigo=${filtros.codigo}`);
        }
        if (filtros.status) {
            searchs.push(`status=${filtros.status}`);
        }
        if (!isEmpty(filtros.descricao)) {
          searchs.push(`descricao=${filtros.descricao}`);
        }
        if (filtros.tipo > -1) {
          searchs.push(`tipo=${filtros.tipo}`);
        }
        if (!isEmpty(filtros.categoria)) {
          searchs.push(`categoria=${filtros.categoria}`);
        }
        if (!isEmpty(filtros.marca)) {
          searchs.push(`marca=${filtros.marca}`);
        }

        let query = '';

        searchs
          .filter((x) => x)
          .forEach((x, i) =>
            i === 0 ? (query += `?${x}`) : (query += `&${x}`)
          );

        history.push({
          pathname: produtoPageUrl(),
          search: query
        });
      }}
      placeholder="Buscar produto pelo nome ou código..."
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={produtoPageNome(tipo, true, isFarmaceutico)}
        leftArea={leftArea()}
        middleArea={openTextfield && searchTextfield}
        rightArea={rightArea()}
      />
    </>
  );
};
