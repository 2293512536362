import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { picker } from 'utils/picker';
import { useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from 'views/design-system';
import { LojaIcon, SalvarIcon } from 'views/components/icons';
import { useSessaoAtual } from 'services/app';

import { useGetEmpresaId } from 'data/api/gestao/empresa/get-empresa-id';
import { EmpresaModel } from 'model/api/gestao/master';
import { usePutEmpresa } from 'data/api/gestao/empresa/put-empresa';
import { isEqual } from 'utils/is-equal';
import { EmpresaEditFormModel } from 'model/app/forms/master/empresa-edit-form-model';
import { FormEmpresaEdit, FormEmpresaEditRef, InpuEmpresaConfigEdit } from 'views/components/form/master/empresa-edit/form-empresa-edit';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { toDateString } from '../../../../../../utils/to-date';
import { CardAdicionarEmpresa } from './components/card-adicionar-empresa/card-adicionar-empresa';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

interface Props {
  setUF: (value: string) => void
}

export const AccordionDadosEmpresa = ({ setUF }: Props) => {
  // STATES E REFS
  const empresaFormRef = useRef<FormEmpresaEditRef>(null);
  const refEmpresaModel = useRef<EmpresaModel>(new EmpresaModel());
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [empresaFormState, setEmpresaFormState] =
    useState<EmpresaEditFormModel>(new EmpresaEditFormModel());

  // PROVIDERS
  const { getEmpresaSelecionada, usuario, refreshEmpresa } = useSessaoAtual();

  // CHAMADAS API
  const { getEmpresaId, carregando: carregandoGetEmpresa } = useGetEmpresaId();
  const { putEmpresa, carregando: carregandoPutEmpresa } = usePutEmpresa();
  const { addHandler, removeHandler } = useEventTools();


  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();

  const carregando =
    carregandoFromForms ||
    carregandoGetEmpresa ||
    preenchendoTela ||
    carregandoPutEmpresa;

  const getEmpresaByIdWrapper = useCallback(async () => {
    const empresaId = getEmpresaSelecionada()?.Id || '';
    const res = await getEmpresaId(empresaId);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as EmpresaModel;
    refEmpresaModel.current = ret;
    setUF(ret.uf)
    const empresa = picker<EmpresaEditFormModel>(
      ret,
      new EmpresaEditFormModel(),
    );
    return empresa;
  }, [getEmpresaId, getEmpresaSelecionada, setUF]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        try {
          setPreenchendoTela(true);
          const empresa = await getEmpresaByIdWrapper();
          setEmpresaFormState(empresa);
          setPreenchendoTela(false);
        } catch (e: any) {
          showToast('error', e.message);
          setPreenchendoTela(false);
        }
      })();
      return () => {
        //funcao de limpeza
        setEmpresaFormState(new EmpresaEditFormModel());
      };
    }, 300);
  }, [getEmpresaByIdWrapper, showToast]);

  const recarregarForm = useCallback((model: EmpresaEditFormModel) => {
    empresaFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(empresaFormState);
  }, [empresaFormState, recarregarForm]);

  useEffect(() => {
    const openAccordion = ({focusField = 'uf'} : {focusField?: InpuEmpresaConfigEdit}) => {
      setOpenAccordion(true);
      setTimeout(() => {
        empresaFormRef.current?.focusField(focusField);
      }, 500)
    }
    addHandler(AppEventEnum.AbrirConfiguracoesEmpresa, openAccordion);
    return () => {
      removeHandler(AppEventEnum.AbrirConfiguracoesEmpresa, openAccordion);
    }
  }, [addHandler, removeHandler])

  const saveChangesEmpresa = useCallback(
    async (empresaModel: EmpresaEditFormModel) => {
      const empresa = picker<EmpresaModel>(
        empresaModel,
        refEmpresaModel.current,
      );
      empresa.dNascimento = toDateString(
        empresaModel.dNascimento,
        'yyyy-MM-DDTHH:mm:ss',
      );

      try {
        const ret = await putEmpresa(empresa);
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Dados da empresa alterados com sucesso!');
        setEmpresaFormState(empresaModel);
      } catch (e: any) {
        showToast('error', e.message);
        empresaFormRef.current?.resetForm();
      }
    },
    [putEmpresa, showToast],
  );

  const handleSubmit = useCallback(
    async (model: EmpresaEditFormModel, beforeModel: EmpresaEditFormModel) => {
      let empresaEqual = isEqual(model, beforeModel);
      if (!empresaEqual) {
        await saveChangesEmpresa(model);
        await refreshEmpresa()
      }

      if (empresaEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
      }
    },
    [refreshEmpresa, saveChangesEmpresa, showToast],
  );

  const form = useMemo(() => {
    return (
      <div
        className={classNames(
          classesModal.contentForms,
          preenchendoTela ? classesModal.contentFormsLoading : '',
        )}
        style={{ height: '100%' }}
      >
        <FormEmpresaEdit
          showLoading={carregando}
          ref={empresaFormRef}
          setCarregandoExterno={setCarregandoFromForms}
          loading={carregando}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }, [
    carregando,
    classesModal.contentForms,
    classesModal.contentFormsLoading,
    handleSubmit,
    preenchendoTela,
  ]);

  const buttonSalvar = useMemo(() => {
    return (
      <div className={classesModal.acoes}>
        <Grid item xs={12}>
          <Button
            disabled={carregando}
            onClick={() => empresaFormRef.current?.submitForm()}
            variant="contained"
            color="primary"
            fullWidth
          >
            <SalvarIcon tipo="BUTTON_PRIMARY" />
            Salvar
          </Button>
        </Grid>
      </div>
    );
  }, [carregando, classesModal.acoes]);

  return (
    <AccordionSaurus
      labelPrimary={'Dados da Empresa'}
      tituloChildren={
        <LojaIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={true}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          {form}
          {buttonSalvar}
          {
            usuario!.empresa.length === 1 &&
            usuario?.confirmado.toLowerCase() === "true" && (
              <div className={classesModal.acoes}>
                <Grid item xs={12}>
                  <CardAdicionarEmpresa />
                </Grid>
              </div>
            )}
        </div>
      </div>
    </AccordionSaurus>
  );
};
