import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormEditMesaValidation } from './form-edit-mesa-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { MesasNovaModel } from 'model/api/gestao/mesa/mesa-nova-model';
import { MesaStatusMock } from 'data/mocks/mesas-mock';
// import { SimNaoBooleanMock } from 'data/mocks/sim-nao-mock';

export const FormEditMesa = forwardRef<
  DefaultFormRefs<MesasNovaModel>,
  DefaultFormProps<MesasNovaModel>
>((props: DefaultFormProps<MesasNovaModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormEditMesaValidationYup } = useFormEditMesaValidation();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<MesasNovaModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormEditMesaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: MesasNovaModel) => {
    props.onSubmit(values, props.model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...props.model });
      refInputCodigo.current?.focus();
    },
    fillForm: (model: MesasNovaModel) => {
      reset({ ...model });
      setTimeout(() => {
        refInputCodigo.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>  
              <Controller
                name="codigo"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    autoComplete='new-password'
                    fullWidth
                    variant="outlined"
                    label="Código da Mesa"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={'EX: 1'}
                    error={Boolean(errors.codigo && errors.codigo.message)}
                    helperText={
                      touchedFields.codigo || errors.codigo
                        ? errors.codigo?.message
                        : undefined
                    }
                    {...field}
                    value={getValues('codigo')}
                    onChange={(e: any) => {
                      if (e.target.value === '' || e.target.value === null) {
                        setValue('codigo', '')
                      }
                      var numberPattern = /\d+/g;
                      const value = e.target.value.match(numberPattern)

                      if (value) {
                        setValue('codigo', value.join(''))
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Status da Mesa"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={MesaStatusMock}
                    error={Boolean(errors.status && errors.status.message)}
                    helperText={
                      touchedFields.status || errors.status
                        ? errors.status?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = MesaStatusMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('status', item);
                      }
                    }}
                    value={getValues('status')}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
            alterar os tamanhos dos grids acima para 6 quando descomentar esse campo
              <Controller
                name="pedidoComQrCode"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    label="Permitir Pedido por QRcode?"
                    variant="outlined"
                    fullWidth
                    disabled={props.loading}
                    conteudo={SimNaoBooleanMock}
                    error={Boolean(errors.pedidoComQrCode && errors.pedidoComQrCode.message)}
                    helperText={
                      touchedFields.pedidoComQrCode || errors.pedidoComQrCode
                        ? errors.pedidoComQrCode?.message
                        : undefined
                    }
                    {...field}
                    onChange={(event) => {
                      const item = SimNaoBooleanMock.filter(
                        (item) => item.Key === event.target.value,
                      )[0]?.Key;
                      if (item !== undefined) {
                        setValue('pedidoComQrCode', item);
                      }
                    }}
                    value={getValues('pedidoComQrCode')}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
