import React, { useRef, useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useStyles } from '../opcoes-entrada-styles';
import useCardStyles from 'views/components/cards/card-produto-importacao-xml/card-produto-importacao-xml-styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  OpcoesEntradaImportacaoXMLTemplateProps,
  tipoEdit
} from './opcoes-entrada-importacao-xml-template-interfaces';
import { useThemeQueries } from 'views/theme';
import { ProdsXMLModel, VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';
import { ProdsModel } from 'model/api/gestao/venda/venda-completa-model';
import FormEntradaCadastroProdutos from 'views/components/form/entrada/entrada-cadastro-xml/form-entrada-cadastro-xml-produtos';
import { useSalvarXML } from '../../../hooks/salvar-xml';
import { useShowAviso } from 'services/app/hooks/show-aviso';

interface OriginalQtdsProps {
  produtosOriginais: ProdsModel[],
  infoProdutosRef: React.MutableRefObject<ProdsModel[]>;
  stepInicial?: number,
  originalQtds: {
    vCompra: number,
    vProd: number,
    vQtd: number
  }[],
}

export default function OpcoesEntradaImportacaoXMLItensTemplate({
  carregando: carregandoExterno,
  setEditing,
  originalQtds,
  produtosOriginais,
  infoProdutosRef,
  stepInicial = 0
}: Readonly<OpcoesEntradaImportacaoXMLTemplateProps & OriginalQtdsProps>): React.ReactElement {
  const cadClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { showAviso } = useShowAviso();
  const cardProdutoImporcataoXMLClasses = useCardStyles();
  const [activeProduct, setActiveProduct] = useState(stepInicial);
  const { isMobile } = useThemeQueries();
  const { saveChangesXML, carregando: carregandoSalvar } = useSalvarXML();

  const carregando = carregandoExterno || carregandoSalvar

  const opennedAccordion = useRef<boolean>(false);

  const { control, getValues, setValue } = useFormContext<VendaCompletaXMLModel>();

  const { fields: produtos, replace } = useFieldArray({
    control,
    name: 'infMov.prod',
    keyName: '_id',
  });

  const alterouRef = useRef<boolean>(false);

  const nextProduct = async () => {
    if (alterouRef.current) {
      const sucesso = await saveChangesXML(getValues(), false)
      if (!sucesso) {
        setValue(`infMov.prod.${activeProduct}`, produtos[activeProduct])
        showAviso('error', 'Houve um erro ao atualizar o produto.')
        return
      }
    }
    alterouRef.current = false;
    replace(getValues('infMov.prod'))
    setActiveProduct((prevActiveStep) => prevActiveStep + 1);
  };

  const backProduct = async () => {
    if (alterouRef.current) {
      const sucesso = await saveChangesXML(getValues(), false)
      if (!sucesso) {
        setValue(`infMov.prod.${activeProduct}`, produtos[activeProduct])
        showAviso('error', 'Houve um erro ao atualizar o produto.')
        return
      }
    }
    alterouRef.current = false;
    replace(getValues('infMov.prod'))
    setActiveProduct((prevActiveStep) => prevActiveStep - 1);
  };

  const handleShowStepper = async () => {
    if (alterouRef.current) {
      const sucesso = await saveChangesXML(getValues(), false)
      if (!sucesso) {
        setValue(`infMov.prod.${activeProduct}`, produtos[activeProduct])
        showAviso('error', 'Houve um erro ao atualizar o produto.')
        return
      }
    }
    alterouRef.current = false;
    setEditing({
      tela: tipoEdit.Stepper,
    });
  };

  const handleBack = async () => {
    if (activeProduct === 0) {
      handleShowStepper();
    } else {
      backProduct();
    }
  };

  const leftArea = () => (
    <ButtonPrivateHeader
      icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
      tooltip="Menu"
      onClick={handleShowStepper}
    ></ButtonPrivateHeader>
  );

  const ultimoProduto = activeProduct === produtos.length - 1;
  const substringBreakpoint = isMobile ? 20 : 45;


  // const indicadorProdutos = (
  //   <Box className={classes.indicadorProdutos}
  //   >
  //     <Typography>{activeProduct + 1}/{produtos.length}</Typography>
  //   </Box>
  // )

  const possuiCAnvisa = produtos[activeProduct].cProdANVISA

  const nomeProd = getValues(`infMov.prod.${activeProduct}.xProd`);

  return (
    <>
      <Grid className={cadClasses.header}>
        <PrivatePageHeader
          title={nomeProd.length > substringBreakpoint ? nomeProd.substring(0, substringBreakpoint) + '...' : nomeProd}
          leftArea={leftArea()}
        />
      </Grid>
      <Container maxWidth="md" className={classes.container}>
        <Box bgcolor="white" p={2}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              md={3}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                Produto
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>
                  {activeProduct + 1}/{produtos.length}
                </strong>
              </Typography>
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                NCM
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].ncm}</strong>
              </Typography>
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                CFOP
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].cfop}</strong>
              </Typography>
            </Grid>

            {produtos[activeProduct].icms.cst ? <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                CST
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].icms.cst}</strong>
              </Typography>
            </Grid> : null}
            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                EAN
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].cEan}</strong>
              </Typography>
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                EAN Trib.
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].cEanTrib}</strong>
              </Typography>
            </Grid>

            {possuiCAnvisa && <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                Código ANVISA.
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].cProdANVISA}</strong>
              </Typography>
            </Grid>}


            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                Código
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].cProd}</strong>
              </Typography>
            </Grid>

            <Grid
              item
              md={3}
              xs={6}
              className={cardProdutoImporcataoXMLClasses.gridProdutoItem}
            >
              <Typography color="textPrimary" variant="caption">
                Medida
              </Typography>
              <Typography color="textPrimary" variant="body1">
                <strong>{produtos[activeProduct].uCom}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box flexGrow={1} mt={2}>
          {produtos.map((product, index) => {
            if (index !== activeProduct) return null
            return <FormEntradaCadastroProdutos produto={product as ProdsXMLModel} index={activeProduct}
              originalQtds={originalQtds[activeProduct]} produtoOriginal={produtosOriginais[activeProduct]}
              infoProdutosRef={infoProdutosRef} alterouRef={alterouRef} opennedAccordion={opennedAccordion}
            />
          })}
        </Box>

        <Box pt={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  handleBack()
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Anterior
              </Button>
            </Grid>

            <Grid item xs={6}>
              {ultimoProduto ? (
                <Button
                  disabled={carregando}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleShowStepper();
                  }}
                >
                  <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                  Concluir
                </Button>
              ) : (
                <Button
                  disabled={carregando}
                  onClick={() => {
                    nextProduct()
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Avançar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
