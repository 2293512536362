import { Grid, Tooltip, Typography } from '@material-ui/core';
import { CardPessoaSelecionarProps } from './card-pessoa-selecionar-props';
import { useCallback } from 'react';
import React from 'react';
import { useStyles } from './card-pessoa-selecionar-styles';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views/theme';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { EnumCadastroStatus } from 'model';
import { StatusCadastroMock } from 'data/mocks';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';

function CardPessoaSelecionar({
  model,
  onClick,
  selected,
  select,
  onCheck,
  selectedDisabled
}: CardPessoaSelecionarProps) {
  const classes = useDefaultCardStyles();
  const pessoaClasses = useStyles();
  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.status) {
      case EnumCadastroStatus.LIBERADO:
        return theme.palette.success.main;
      case EnumCadastroStatus.BLOQUEADO:
        return theme.palette.warning.main;
      case EnumCadastroStatus.DESATIVADO:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.primary.main,
    model.status
  ]);

  const descricao = StatusCadastroMock.filter(
    (x) => x.Key === model.status
  )?.[0]?.Value;

  const nomeItem = useCallback(
    () => (
      <Grid item xs={12} md={12} className={pessoaClasses.item}>
        <Typography color="textSecondary" variant="caption">
          Nome:
        </Typography>
        <Typography color="textPrimary" style={{ wordBreak: 'break-all' }}>{model.nome}</Typography>
      </Grid>
    ),
    [model.nome, pessoaClasses.item]
  );

  return (
    <>
      <DefaultCard
        isSelected={selected}
        disabled={selectedDisabled}
        onClick={() => onClick(model)}
      >
        <Tooltip arrow title={descricao ?? 'Não encontrado'} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, pessoaClasses.card)}>
          <Grid container>
            {nomeItem()}
            <Grid item xs={12} md={12} className={pessoaClasses.item}>
              <Typography color="textSecondary" variant="caption">
                CPF/CNPJ:
              </Typography>
              <Typography color="textPrimary">
                {!isEmpty(model.cpfcnpj)
                  ? formatarCPFCNPJ(model.cpfcnpj)
                  : 'Não informado'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Tooltip arrow title="Selecionar pessoa">
          <div
            className={classNames(
              classes.cardRightButton,
              pessoaClasses.iconRight
            )}
          >
            <div
              style={{
                borderLeftWidth: '2px',
                borderColor: '#E6E6E6',
                borderStyle: 'solid',
                height: '45px'
              }}
            />
            {select ? (
              <CheckboxDefault checked={selected} />
            ) : (
              <SaurusRadio checked={selected} />
            )}
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
}

export default React.memo(CardPessoaSelecionar);
