import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useFormNcmCadastroValidation } from './form-ncm-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { NcmCadastroFormModel } from 'model/app/forms/ncm/ncm-cadastro-form-model';
import { AutocompleteNcmsMaster } from '../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-master/autocomplete-ncms-master';
import { NcmMasterSummaryModel } from 'model/api/ncm-master/ncm-master-model';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormNcmCadastroProps
  extends DefaultFormProps<NcmCadastroFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}

export const FormNcmCadastro = forwardRef<
  DefaultFormRefs<NcmCadastroFormModel>,
  FormNcmCadastroProps
>(({ loading, ...props }: FormNcmCadastroProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] = useState<NcmCadastroFormModel>(
    new NcmCadastroFormModel(),
  );
  const { FormNcmCadastroYupValidation } = useFormNcmCadastroValidation();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<NcmCadastroFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormNcmCadastroYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: NcmCadastroFormModel) => {
    const model = picker<NcmCadastroFormModel>(
      values,
      new NcmCadastroFormModel(),
    );

    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new NcmCadastroFormModel());
      if (!isMobile) refInputCodigo.current?.focus();
      reset();
    },
    fillForm: () => {
      if (!isMobile) refInputCodigo.current?.focus();
    },
  }));
  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteNcmsMaster
                      modo="campoFormatado"
                      inputRef={refInputCodigo}
                      loadingExterno={loading}
                      label="Código NCM"
                      placeholder="NCM:"
                      error={Boolean(errors.codigo && errors.codigo.message)}
                      helperText={
                        touchedFields.codigo || errors.codigo
                          ? errors.codigo?.message
                          : undefined
                      }
                      {...field}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          const ncm = picker<NcmMasterSummaryModel>(
                            retorno.value,
                            new NcmMasterSummaryModel(),
                          );
                          setValue('codigo', ncm.codigo);
                          setValue('cest', ncm.cest);
                          setValue('descricao', ncm.descricao);
                          setValue('ncmId', ncm.id);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cest"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo="NUMERO"
                      fullWidth
                      manterMascara
                      label="Cest"
                      variant="outlined"
                      error={Boolean(errors.cest && errors.cest.message)}
                      helperText={
                        touchedFields.cest || errors.cest
                          ? errors.cest?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      allowSubmit
                      readOnly
                      label="Descrição"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
