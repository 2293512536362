import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    rootContent: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        background: '#f5f5f5',
        zIndex: 9999,
        minHeight: '100%',
    },
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden',
    },
    header: {
        flex: '0 1 auto',
        background: '#fff',
    },
    defaultContainer: {

        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
    },
    maxLine2: {
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%',
    },
    btnRetaguarda: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
    },
    btnRoot: {
        backgroundColor: '#FFF',
        zIndex: 2,
        '&:hover': {
            backgroundColor: '#f5f5f5',
        }
    },
    drawerVersion: {
        bottom: 0,
        width: '100%'
    },

    btnsFixosContainer: {
        width: '100%',
        padding: theme.spacing(1),
        paddingTop: 0,
        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: 2,
        display: 'flex'

    },
    btnFixoContent: {
        textAlign: 'left',
        '& p': {
            fontWeight: 'normal',
        },
        '& svg':
        {
            marginLeft: theme.spacing(1),
        }
    },
    divisorBtns: {
        width: 10,
        height: '100%',
    },
    contentPerfil: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));
