import { Box } from '@material-ui/core';
import { useCallback, useEffect, useRef } from 'react';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { VoltarIcon } from 'views/components/icons';
import { DefaultModal, ModalHeader } from 'views/components/modals/components';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils';
import { CriarComissaoApuracao } from './components/criar-apuracao-comissao';
import { ApurarComissao } from './apurar-comissao';
import { useComissaoApuracaoService } from './useComissaoApuracaoService';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { EnumTpSituacaoApuracao } from 'data/api/gestao/comissao/patch-campanha-apuracao-descondierar-comissionado';
import { useCadastros, useToastSaurus } from 'services/app';
import { useApuracaoStore } from './stores/apuracao-store';

export interface ComissaoApuracaoModalProps {
  comissaoId: string;
  openned: boolean;
}

export const ComissaoApuracaoModal = ({
  comissaoId,
  openned
}: ComissaoApuracaoModalProps) => {
  const modalClasses = useModalStyles();

  const comissionados = useApuracaoStore(
    (state) => state.stateComissaoApuracao.comissionados
  );
  const acaoApurar = useApuracaoStore(
    (store) => store.stateComissaoApuracao.ui.acaoApurar
  );
  const acaoApuracao = useApuracaoStore(
    (store) => store.stateComissaoApuracao.ui.acao
  );
  const dialogTitle = useApuracaoStore(
    (store) => store.stateComissaoApuracao.ui.dialogTitle
  );
  const carregando = useApuracaoStore(
    (store) => store.stateComissaoApuracao.ui.carregando
  );
  const selectedComissionado = useApuracaoStore(
    (store) => store.stateComissaoApuracao.selectedComissionado
  );
  const {
    resetStoreComissaoApuracao,
    setComissaoApuracaoUIDialogTitle,
    setComissaoApuracaoApurar,
    resetSelectedComissionado,
    resetBlackListVendas,
    setComissaoApuracaoAcao
  } = useApuracaoStore((store) => store);
  const isGetApuracaoRef = useRef<boolean>(false)

  const { getComissao, getApuracaoListByComissaoId, updateSituacaoApuracao } =
    useComissaoApuracaoService();

  const { showToast } = useToastSaurus();
  const { fecharComissaoApuracao } = useCadastros();

  useEffect(() => {
    if (!isGetApuracaoRef.current && openned) {
      resetStoreComissaoApuracao();
      if (comissaoId && comissaoId.length > 0) {
        getComissao(comissaoId);
        setComissaoApuracaoUIDialogTitle('Gerar Apuração');
        getApuracaoListByComissaoId(comissaoId, 1, 1, undefined, true);
        isGetApuracaoRef.current = true
      }
    }
    if (!openned) {
      isGetApuracaoRef.current = false
    }
  }, [
    getApuracaoListByComissaoId,
    getComissao,
    comissaoId,
    resetStoreComissaoApuracao,
    setComissaoApuracaoUIDialogTitle,
    openned,
  ]);

  useEffect(() => {
    // Se houver apuração, então redireciona para a tela de apuração
    if (comissionados.length > 0) {
      setComissaoApuracaoAcao('apurar');
      setComissaoApuracaoUIDialogTitle('Apuração de Comissão');
    }
  }, [
    comissionados.length,
    setComissaoApuracaoAcao,
    setComissaoApuracaoUIDialogTitle,
    setComissaoApuracaoApurar
  ]);

  const menuOptions = useCallback(() => {
    const options: any[] = [
      new MenuOptionsModel('Desconsiderar Comissionado', <></>, () => {
        if (
          selectedComissionado?.situacao === EnumTpSituacaoApuracao.Pendente
        ) {
          updateSituacaoApuracao({
            situacao: EnumTpSituacaoApuracao.Descinvulado
          });
        } else {
          showToast(
            'info',
            'Não é possível desconsiderar um comissionado que já foi confirmado.'
          );
        }
      })
    ];
    return options;
  }, [selectedComissionado?.situacao, showToast, updateSituacaoApuracao]);

  return (
    <DefaultModal
      minWidth="400px"
      open={openned || false}
      variant={'temporary'}
      anchor="right"
    >
      <Box className={modalClasses.rootClass}>
        <ModalHeader
          title={dialogTitle}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={() => {
                if (acaoApuracao === 'criar' || acaoApurar === 'comissionado') {
                  fecharComissaoApuracao();
                  return;
                }
                if (acaoApurar === 'vendas') {
                  setComissaoApuracaoApurar('comissionado');
                  resetSelectedComissionado();
                  resetBlackListVendas();
                  return;
                }
              }}
            />
          }
          rightArea={
            acaoApurar === 'vendas' ? (
              <MenuOptions options={menuOptions()} labels={['Opções']} />
            ) : (
              <></>
            )
          }
        />


        <Box
          style={{
            height: '100%'
          }}
        >
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          {
            <>
              {acaoApuracao === 'criar' && (
                <>
                  <CriarComissaoApuracao id={comissaoId} />
                </>
              )}
              {acaoApuracao !== 'criar' && (
                <>
                  <ApurarComissao comissaoId={comissaoId} />
                </>
              )}
            </>
          }
        </Box>
      </Box>
    </DefaultModal>
  );
};
