import { Grid, Tooltip, Typography } from 'views/design-system';
import { useCallback } from 'react';
import { EditarIcon } from '../../../icons/editar-icon';
import { DefaultCard } from '../../components/default-card';
import { useDefaultCardStyles } from '../../components/default-card/default-card-styles';
import { useThemeQueries } from '../../../..';
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { toDecimalString } from 'utils/to-decimal';
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { CardPromocaoDetalheProps } from '../card-promocao-detalhe-props';
import { useStyles } from '../card-promocao-detalhe-styles';

const CardPromocaoRegraAtacadoValor = ({ detalhe, item, onClick, onCheck, selected }: CardPromocaoDetalheProps) => {
  const classes = useDefaultCardStyles();
  const promocaoClasses = useStyles();
  const { theme } = useThemeQueries();

  const status = !detalhe.validado ? 'Pendente' : detalhe.adicionado ? 'Adicionado' : detalhe.alterado ? 'Alterado' : 'Cadastro Anteriormente';
  const retornarCorStatus = useCallback((): string => {
    if (status === 'Pendente')
      return theme.palette.error.main;
    else if (status === 'Adicionado')
      return theme.palette.warning.main;
    else if (status === 'Alterado')
      return theme.palette.warning.main;
    else
      return theme.palette.success.main;
  }, [status, theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main]);
  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          if (onClick)
            onClick(item?.id ?? '');
          else if (onCheck)
            onCheck(item?.id ?? '');
        }}
      >
        <Tooltip arrow title={status} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classNames(classes.cardContent, promocaoClasses.card)}>
          <Grid container>
            <Grid item className={classNames(promocaoClasses.item, classes.celulaGridFull)}>
              {!isEmpty(item.codigo) && (
                <Typography color='textSecondary' variant='caption'>
                  Código: <b>{item.codigo}</b>
                </Typography>
              )}
              <Typography color='textPrimary'>
                <b>{item.produto}{item.variacao && ` ${item.variacao}`}</b>
              </Typography>
            </Grid>
            {status !== 'Cadastro Anteriormente' && (
              <Grid className={classNames(promocaoClasses.item, classes.celulaGrid, promocaoClasses.badge, status === 'Adicionado' ? promocaoClasses.badgeLaranja : status === 'Alterado' ? promocaoClasses.badgeLaranja : promocaoClasses.badgeVermelho)} flex flexDirection='column' alignItems='flex-end'>
                {status}
              </Grid>
            )}
            <Grid item className={classNames(promocaoClasses.item, classes.celulaGrid)} style={{ minWidth: 60, textAlign: 'right' }}>
              <Typography color='textSecondary' variant='caption'>
                Qtd. Mínima
              </Typography>
              <Typography color='textPrimary'>
                {toDecimalString(detalhe.quantidade)}
              </Typography>
            </Grid>
            <Grid item className={classNames(promocaoClasses.item, classes.celulaGrid)} style={{ minWidth: 80, textAlign: 'right' }}>
              <Typography color='textSecondary' variant='caption'>
                <b>Desconto</b>
              </Typography>
              <Typography color='primary'>
                <b>{toDecimalString(detalhe.valor)}%</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classNames(classes.cardRightButton, promocaoClasses.iconRight)}>
          {onCheck && (
            <CheckboxDefault checked={selected} />
          )}
          {onClick && (
            <EditarIcon tipo="BUTTON" />
          )}
        </div>
      </DefaultCard>
    </>
  );
};

export default React.memo(CardPromocaoRegraAtacadoValor);
