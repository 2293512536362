import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mov-importar-styles';
import { VoltarIcon } from 'views/components/icons';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { IrAoCarrinhoIcon } from 'views/components/icons/ir-ao-carrinho-icon';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCadastros } from 'services/app';
import { useCallback, useState } from 'react';
import { CircularLoading } from 'views/components/utils';
import { useMovRota } from 'services/app/hooks/mov-rota';

interface DialogMovImportarProps {
  pedido: PedidoModel;
  openned: boolean;
  handleImportar: (pedido: PedidoModel) => Promise<void>;
  dispatchEvent: AppEventEnum
}

export const DialogMovImportar = ({
  openned,
  handleImportar,
  pedido,
  dispatchEvent
}: DialogMovImportarProps) => {

  // HOOKS
  const { callEvent } = useEventTools();
  const { fecharImportarPedidorDialog } = useCadastros();
  const { redirectCarrinho } = useMovRota();

  // AUX
  const classes = useStyles();

  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false);

  const importar = useCallback(async (model: PedidoModel, goToCarrinho: boolean) => {
    try {
      setCarregandoInterno(true);

      await handleImportar(model);

      if (goToCarrinho) {
        await redirectCarrinho();
      }

      callEvent(dispatchEvent, pedido.id);
      fecharImportarPedidorDialog();

    } finally {
      setCarregandoInterno(false);
    }
  }, [callEvent, dispatchEvent, fecharImportarPedidorDialog, handleImportar, pedido.id, redirectCarrinho])

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Importar Pedido"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        {carregandoInterno && <CircularLoading tipo='FULLSIZED' />}
        <Typography style={{ marginBottom: '16px' }}>
          Após a importação do pedido, não será mais possivel lançar produtos.
        </Typography>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => importar(pedido, false)}
              >
                <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                Importar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => importar(pedido, true)}
              >
                <IrAoCarrinhoIcon tipo="BUTTON_PRIMARY" />
                Importar e ir ao carrinho
              </Button>
            </Grid>

            <Grid item xs={12}>
              {fecharImportarPedidorDialog && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={fecharImportarPedidorDialog}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
