import { useTheme } from '@material-ui/core';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { KeyboardFragmentProps, TipoValorComissao } from './keyboard-fragment-props';
import { useStyles } from './keyboard-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Grid, Typography } from 'views/design-system';

const KeyBoardFragment = ({ textChanged, submit, tipo, ...props }: KeyboardFragmentProps) => {
  // STATES E REFS
  const [inicial, setInicial] = useState<boolean>(true);
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>(props?.valor ?? '')
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  // HOOKS
  const { addHandler, removeHandler } = useEventTools()
  const {
    getFormattedText,
    addValue,
    sendText,
    addKey,
    backSpaceKey,
  } = useKeyboard({
    maxLength: 7,
    floatCases: 2,
    isNumeric: true,
    handleTextChanged: textChanged,
    handleSubmit: submit,
    textoAtual: textoAtual,
    digitado: digitado
  });

  // AUX
  const defaultClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const theme = useTheme();

  const attValueDisplay = useCallback((any: any) => {
    const v = getFormattedText();
    if (valueRef.current) valueRef.current.textContent = v;
  }, [getFormattedText])

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler])

  //EFFECT PARA PREENCHER O VALOR DO CAMPO NO CARREGAMENTO TELA
  useEffect(() => {
    if (inicial && props?.valor && Number(props.valor) > 0) {
      setInicial(false);
      const a = props?.valor.replace('.', '');
      sendText(a);
    }
  }, [inicial, sendText, props.valor]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const display = useMemo(() => {
    return (
      <Grid className={classes.contentTela}>
       {tipo === TipoValorComissao.ValorFixoProduto && <span
          style={{ marginBottom: '3em', fontSize: '1rem', fontWeight: '700', color: theme.palette.primary.main }}
        >
          R$
        </span>}
        <Typography
          style={{
            fontSize: '3rem',
            fontWeight: 700,
          }}
          ref={valueRef}
        >
          0,00
        </Typography>
        {tipo === TipoValorComissao.Porcentagem && <span
          style={{ marginBottom: '3em', fontSize: '1rem', fontWeight: '700', color: theme.palette.primary.main }}
        >
          %
        </span>}
      </Grid>
    );
  }, [classes.contentTela, theme.palette.primary.main, tipo]);

  const teclado = React.useMemo(() => {
    return (
      <>
       <Keyboard
          isButtonKeyboard00
          handleAdd={handleAdd}
          handleBackSpace={handleBackSpace}
          handleAddValue={handleAddValue}
          handleSubmit={() => {}}
          handleText={handleText}
          isNumeric
          isButtonBackspace
        />
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleText]);

  return (
    <Grid className={defaultClasses.root}>
      <Grid className={classNames(classes.containerTela, classes.unselectable)}>
        {display}
      </Grid>
      <Grid
        container
        className={classes.unselectable}
        style={{ flex: 1, overflowX: 'hidden' }}
      >
        {teclado}
      </Grid>
    </Grid>
  );
};

export default KeyBoardFragment;
