import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: 'flex',
    flexGrow: 1
  },
  rootTeclado: {
    background: theme.palette.grey[100],
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  topHeader: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    color: theme.palette.secondary.main
  },
  middleContainer: {
    alignItems: 'center',
    background: theme.palette.common.white,
    padding: theme.spacing(1),
  },
  middleContent: {
    flexWrap: 'nowrap',
    height: "100%",
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  valorContainer: {
    "& .valorMonetario": {
      display: "flex",
      color: "#696969",
      "& .unidade": {
        fontWeight: "600",
        marginRight: "1.5vw",
        [theme.breakpoints.up("sm")]: {
          marginRight: "1vw",
        },
        [theme.breakpoints.up("md")]: {
          marginRight: "0.7vw",
        },
      },
      "& .valor": {
        fontWeight: "bold",
      },
    },
  },

  buttonContainer: {
    maxHeight: 210,
    padding: theme.spacing(1, 0, 0, 1),
    overflow: 'hidden',
  },

  list: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
  },

  keyboardArea: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "hidden",
    position: "relative",
    display: 'flex',
    flexDirection: 'column'
  },

  container: ({ paymentScreen }: any) => {
    return {
      display: 'flex', flexWrap: paymentScreen ? 'nowrap' : 'wrap', overflowX: paymentScreen ? 'auto' : 'hidden',
      flexDirection: 'column',
      '&::-webkit-scrollbar': {
        height: theme.spacing(0.5),
        width: theme.spacing(0.5)
      }
    }
  },
  row: {
    display: 'flex',
  }
}));