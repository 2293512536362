import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDadosEntradaValidation } from './form-dados-entrada-validation';
import { FormDadosEntradaProps } from './form-dados-entrada-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DadosEntradaFormModel } from 'model/app/forms/entrada/dados-entrada-form-model';
import { FinNFeMock } from 'data/mocks/fin-nfe-mock';
import { TpNFMock } from 'data/mocks/tp-nf-mock';
import { IdDestMock } from 'data/mocks/id-dest-mock';
import { IndFinalMock } from 'data/mocks/ind-final-mock';
import { IndPresMock } from 'data/mocks/ind-pres-mock';
import { IndIntermedMock } from 'data/mocks/ind-intermed-mock';
import { TpAmbMock } from 'data/mocks/tp-amb-mock';
import { TpEmisMock } from 'data/mocks/tp-emis-mock';
import { TpImpMock } from 'data/mocks/tp-imp-mock';
import { TpHistoricoVendaMock } from 'data/mocks/tp-historico-venda-mock';
import { EnumIndIntermed } from 'model/enums/enum-ind-intermed';
import { OrigemEmissaoMock } from 'data/mocks/origem-emissao-mock';
import { useGetDeposito } from 'data/api/gestao/deposito';
import { useToastSaurus } from 'services/app';
import { KeyValueModel } from 'model';
import { DepositoListModel } from 'views/components/modals/produto/components/produto-edit/components/produto-estoque/produto-estoque';

export const FormDadosEntrada = forwardRef<
    DefaultFormRefs<DadosEntradaFormModel>,
    FormDadosEntradaProps
>(({ mov, ...props }, ref) => {
    const utilClasses = makeUtilClasses();
    const { formDadosEntradaValidationYup } = useFormDadosEntradaValidation();
    const [model, setModel] = useState<DadosEntradaFormModel>(new DadosEntradaFormModel())
    const modelFormRef = useRef<DadosEntradaFormModel>(new DadosEntradaFormModel())
    const [att, setAtt] = useState<boolean>(false)
    const { getDeposito, carregando } = useGetDeposito()
    const { showToast } = useToastSaurus()
    const [depositoList, setDepositoList] = useState<KeyValueModel[]>([])

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues
    } = useForm<DadosEntradaFormModel>({
        resolver: yupResolver(formDadosEntradaValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: any) => {
        props.onSubmit(values, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: DadosEntradaFormModel) => {
            reset({
                ...model,
                serie: `${model.serie}`.padStart(3, '0')
            })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    useEffect(() => { }, [att])

    const handleAtt = () => setAtt(prev => !prev)

    const handleDepositos = useCallback(async () => {
        try {
            const query = `pageSize=100`
            const res = await getDeposito(query, 1)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data?.list as DepositoListModel[]

            const keyValueList: KeyValueModel[] = resultado.map(x => new KeyValueModel(x.id, x.nome))
            setDepositoList(keyValueList ?? [])
        } catch (error: any) {
            showToast('error', 'Ocorreu um erro ao carregar os depósitos. Detalhe: ' + error.message)
        }
    }, [getDeposito, showToast])

    useEffect(() => {
        handleDepositos()
    }, [handleDepositos])

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {(props.loading && props.showLoading) || carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="nnf"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        maxTexto={9}
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.nnf && errors.nnf.message)}
                                        helperText={
                                            touchedFields.nnf || errors.nnf
                                                ? errors.nnf?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="serie"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Série"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.serie && errors.serie.message)}
                                        helperText={
                                            touchedFields.serie || errors.serie
                                                ? errors.serie?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="dhEmi"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DATA_HORA'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Data/Hora de Emissão"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.dhEmi && errors.dhEmi.message)}
                                        helperText={
                                            touchedFields.dhEmi || errors.dhEmi
                                                ? errors.dhEmi?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="dhSaiEnt"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DATA_HORA'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Data/Hora de Saída"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.dhSaiEnt && errors.dhSaiEnt.message)}
                                        helperText={
                                            touchedFields.dhSaiEnt || errors.dhSaiEnt
                                                ? errors.dhSaiEnt?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="finNFe"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Finalidade"
                                        allowSubmit
                                        conteudo={FinNFeMock}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.finNFe && errors.finNFe.message)}
                                        helperText={
                                            touchedFields.finNFe || errors.finNFe
                                                ? errors.finNFe?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = FinNFeMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("finNFe", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="origemEmissao"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        disabled
                                        label="Origem da Emissão"
                                        allowSubmit
                                        conteudo={OrigemEmissaoMock}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.origemEmissao && errors.origemEmissao.message)}
                                        helperText={
                                            touchedFields.origemEmissao || errors.origemEmissao
                                                ? errors.origemEmissao?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = OrigemEmissaoMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("origemEmissao", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="tpNF"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Tipo de Operação"
                                        conteudo={TpNFMock}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.tpNF && errors.tpNF.message)}
                                        helperText={
                                            touchedFields.tpNF || errors.tpNF
                                                ? errors.tpNF?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = TpNFMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("tpNF", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="idDest"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Destino da Operação"
                                        conteudo={IdDestMock}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.idDest && errors.idDest.message)}
                                        helperText={
                                            touchedFields.idDest || errors.idDest
                                                ? errors.idDest?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = IdDestMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("idDest", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="indFinal"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Consumidor Final"
                                        conteudo={IndFinalMock}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.indFinal && errors.indFinal.message)}
                                        helperText={
                                            touchedFields.indFinal || errors.indFinal
                                                ? errors.indFinal?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = IndFinalMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("indFinal", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="indPres"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Presença do Comprador"
                                        conteudo={IndPresMock}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.indPres && errors.indPres.message)}
                                        helperText={
                                            touchedFields.indPres || errors.indPres
                                                ? errors.indPres?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = IndPresMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            setValue("indPres", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="indIntermed"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Indicação do Intermediador"
                                        disabled={props.loading}
                                        conteudo={IndIntermedMock}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.indIntermed && errors.indIntermed.message)}
                                        helperText={
                                            touchedFields.indIntermed || errors.indIntermed
                                                ? errors.indIntermed?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = IndIntermedMock.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            handleAtt()
                                            if (value === EnumIndIntermed.OP_SEM_INTERMEDIADOR) {
                                                setValue('infIntermediador.cnpj', '')
                                                setValue('infIntermediador.idCadIntTran', '')
                                            }
                                            setValue("indIntermed", value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="infIntermediador.cnpj"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'CNPJ'}
                                        fullWidth
                                        readOnly={getValues('indIntermed') === EnumIndIntermed.OP_SEM_INTERMEDIADOR}
                                        disabled={props.loading}
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="CNPJ do Intermediador"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.infIntermediador?.cnpj && errors.infIntermediador.cnpj.message)}
                                        helperText={
                                            touchedFields.infIntermediador?.cnpj || errors.infIntermediador?.cnpj
                                                ? errors.infIntermediador?.cnpj?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="infIntermediador.idCadIntTran"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        fullWidth
                                        readOnly={getValues('indIntermed') === EnumIndIntermed.OP_SEM_INTERMEDIADOR}
                                        autoComplete='new-password'
                                        disabled={props.loading}
                                        variant="outlined"
                                        label="Nome do Intermediador"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.infIntermediador?.idCadIntTran && errors.infIntermediador.idCadIntTran.message)}
                                        helperText={
                                            touchedFields.infIntermediador?.idCadIntTran || errors.infIntermediador?.idCadIntTran
                                                ? errors.infIntermediador?.idCadIntTran?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="natOp"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Natureza da Operação"
                                        disabled={props.loading}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.natOp && errors.natOp.message)}
                                        helperText={
                                            touchedFields.natOp || errors.natOp
                                                ? errors.natOp?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="depositoId"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Depósito"
                                        disabled={props.loading}
                                        conteudo={depositoList}
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.depositoId && errors.depositoId.message)}
                                        helperText={
                                            touchedFields.depositoId || errors.depositoId
                                                ? errors.depositoId?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            const value = depositoList.filter(
                                                (x) => x.Key === event.target.value,
                                            )[0]?.Key;
                                            handleAtt()
                                            setValue("depositoId", value ?? '')
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AccordionSaurus
                                labelPrimary='Mais Dados da Nota Fiscal'
                                showDivider
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="idNFe"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'TEXTO'}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Chave de Acesso"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.idNFe && errors.idNFe.message)}
                                                    helperText={
                                                        touchedFields.idNFe || errors.idNFe
                                                            ? errors.idNFe?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="cdv"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'NUMERO'}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Dígito Verificador"
                                                    allowSubmit
                                                    maxTexto={1}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.cdv && errors.cdv.message)}
                                                    helperText={
                                                        touchedFields.cdv || errors.cdv
                                                            ? errors.cdv?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="versao"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'NUMERO'}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Versão"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.versao && errors.versao.message)}
                                                    helperText={
                                                        touchedFields.versao || errors.versao
                                                            ? errors.versao?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="cuf"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    disabled={props.loading}
                                                    fullWidth
                                                    tipo="NUMERO"
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Código da UF"
                                                    allowSubmit
                                                    maxTexto={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.cuf && errors.cuf.message)}
                                                    helperText={
                                                        touchedFields.cuf || errors.cuf
                                                            ? errors.cuf?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="cnf"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'NUMERO'}
                                                    maxTexto={8}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="C.N.F"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.cnf && errors.cnf.message)}
                                                    helperText={
                                                        touchedFields.cnf || errors.cnf
                                                            ? errors.cnf?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="mod"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    conteudo={TpHistoricoVendaMock}
                                                    disabled
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Modelo"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.mod && errors.mod.message)}
                                                    helperText={
                                                        touchedFields.mod || errors.mod
                                                            ? errors.mod?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="cMunFG"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'TEXTO'}
                                                    disabled={props.loading}
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Cód. Mun. Gerador"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.cMunFG && errors.cMunFG.message)}
                                                    helperText={
                                                        touchedFields.cMunFG || errors.cMunFG
                                                            ? errors.cMunFG?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="tpAmb"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    fullWidth
                                                    disabled={props.loading}
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Tipo de Ambiente"
                                                    conteudo={TpAmbMock}
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.tpAmb && errors.tpAmb.message)}
                                                    helperText={
                                                        touchedFields.tpAmb || errors.tpAmb
                                                            ? errors.tpAmb?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="tpEmis"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    disabled={props.loading}
                                                    variant="outlined"
                                                    label="Tipo de Emissão"
                                                    conteudo={TpEmisMock}
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.tpEmis && errors.tpEmis.message)}
                                                    helperText={
                                                        touchedFields.tpEmis || errors.tpEmis
                                                            ? errors.tpEmis?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="tpImp"
                                            control={control}
                                            render={({ field }) => (
                                                <SelectSaurus
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    disabled={props.loading}
                                                    variant="outlined"
                                                    label="Tipo de Impressão"
                                                    conteudo={TpImpMock}
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.tpImp && errors.tpImp.message)}
                                                    helperText={
                                                        touchedFields.tpImp || errors.tpImp
                                                            ? errors.tpImp?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="versao"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'TEXTO'}
                                                    readOnly
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Versão do Arquivo"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.versao && errors.versao.message)}
                                                    helperText={
                                                        touchedFields.versao || errors.versao
                                                            ? errors.versao?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                    value={4.00}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="schemaValidador"
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    tipo={'TEXTO'}
                                                    readOnly
                                                    fullWidth
                                                    autoComplete='new-password'
                                                    variant="outlined"
                                                    label="Schema Validador"
                                                    allowSubmit
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={Boolean(errors.schemaValidador && errors.schemaValidador.message)}
                                                    helperText={
                                                        touchedFields.schemaValidador || errors.schemaValidador
                                                            ? errors.schemaValidador?.message
                                                            : undefined
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSaurus>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
