import { Box, Grid, IconButton, Button, Paper, Typography } from "@material-ui/core"
import { useEffect, useCallback, useMemo } from "react"

import { ExportarExcelIcon } from "views/components/icons/export-excel-icon"
import { Paginacao } from "views/components/paginacao"
import { useComissaoApuracaoService } from "../../useComissaoApuracaoService"
import { ApuracaoCard } from "../cards/apuracao-card"
// import { DateFilterHeader } from "../date-filter-header"
import { CardNaoEncontrado } from "views/components/cards"
import { EditarIcon, InformacaoIcon } from "views/components/icons"
import { toDateString } from "utils/to-date"
import { useThemeQueries } from "views/theme"
import { useComissaoStore } from "views/components/modals/comissao/comissao-modal/stores/comissao-store"
import { useApuracaoStore } from "../../stores/apuracao-store"
import { useCadastros } from "services/app"

export const ApuracoesList = () => {
    const {
        setComissaoApuracaoUIDialogTitle,
        setComissaoApuracaoAcao,
    } = useApuracaoStore()
    const comissionados = useApuracaoStore(state => state.stateComissaoApuracao.comissionados)
    const comissao = useComissaoStore(state => state.state.comissao)
    const { selectComissionadoApuracao, getApuracaoListByComissaoId, exportCampanhaApuracaoListExcel } = useComissaoApuracaoService()

    const { abrirCadastroComissao, fecharComissaoApuracao } = useCadastros()

    const pagination = useApuracaoStore(state => state.stateComissaoApuracao.ui.apuracoesPagination)

    useEffect(() => {
        setComissaoApuracaoUIDialogTitle(`${comissao.nome} - Comissionados`)
    }, [comissao.nome, setComissaoApuracaoUIDialogTitle])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= (pagination?.totalPages ?? 0) || newPage > 0) {
                getApuracaoListByComissaoId(comissao.id, newPage, 1, undefined, true)
            }
        },
        [comissao.id, getApuracaoListByComissaoId, pagination.totalPages],
    );

    const heightScroll = useMemo(() => {
        return window.innerHeight - 290
    }, [])

    const { theme } = useThemeQueries()

    const handleEditComissao = () => {
        abrirCadastroComissao(comissao.id, '', true)
        fecharComissaoApuracao()
    }

    return <>
        {
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid style={{
                        height: '70px',
                    }} item xs={12}>
                        <Paper>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography variant="caption" style={{}}>
                                        Nome
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {
                                            comissao.nome
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        Data Campanha
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            toDateString(comissao.dataInicial)
                                        } - {
                                            toDateString(comissao.dataFinal)
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            handleEditComissao()
                                        }}
                                    >
                                        <EditarIcon fill={theme.palette.primary.main} tipo="BUTTON_PRIMARY" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                        {/* <DateFilterHeader /> */}
                    </Grid>

                    <Grid style={{
                        height: '70px'
                    }} item xs={12}>
                        <Paginacao
                            pageChanged={pageChanged}
                            totalPages={pagination.totalPages ?? 0}
                            currentPage={pagination.page}
                            totalRegisters={pagination.totalRegisters}
                            countLabel="Comissionados"
                            action={<>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        exportCampanhaApuracaoListExcel()
                                    }}
                                >
                                    <ExportarExcelIcon tipo="BUTTON_PRIMARY" />
                                </IconButton>
                            </>}
                        />
                    </Grid>
                    <Grid style={{
                        height: heightScroll,
                        overflowY: 'auto',
                    }} item xs={12}>
                        {
                            comissionados.length === 0 && <Grid item xs={12}>
                                <CardNaoEncontrado
                                    mensagem={`Nenhuma apuração encontrada.`}
                                    icon={<InformacaoIcon tipo="GERAL" />}
                                />
                            </Grid>
                        }
                        <Grid container spacing={1}>
                            {
                                comissionados.map((comissionado) => {
                                    return <Grid item xs={12} key={comissionado.id}>
                                        <ApuracaoCard comissionado={comissionado} onClick={() => {
                                            selectComissionadoApuracao(comissionado.id)
                                        }} />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        height: '70px',
                    }}>
                        <Button color="primary" fullWidth variant="contained" onClick={() => {
                            setComissaoApuracaoAcao('criar')
                        }}>
                            Nova Apuração
                        </Button>
                    </Grid>
                </Grid>
            </Box >

        }

    </>
}