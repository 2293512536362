import { Button, Divider, Grid, Typography } from "views/design-system"
import { useCallback } from "react";
import { useHistory } from "react-router-dom"
import { GestaoStorageKeys, useGestaoStorage, useToastSaurus } from "services/app";
import { useDevice } from "services/app/hooks/device";
import { useSessaoAtual } from 'services/app';
import { useSincronizacaoCadastros } from "services/app/hooks/sincronizacao-cadastros";
import { EmpresaIcon } from "views/components/icons"
import { PdvIcon } from "views/components/icons/pdv-icon";
import { useThemeQueries } from "views/theme";

type Props = {
    onClick: () => void;
}

export const PDVDiferenteEmpresa = ({ onClick }: Props) => {

    const history = useHistory()
    const { delRegistro } = useGestaoStorage()
    const { getRegistro } = useGestaoStorage()
    const { selecionarEmpresa } = useSessaoAtual()
    const { isMobile } = useThemeQueries()
    const { showToast } = useToastSaurus()
    const { limparCadastros } = useSincronizacaoCadastros()
    const { getSerialNumber } = useDevice();


    const clickOutroPDV = useCallback(async () => {
        localStorage.removeItem('deviceId');
        delRegistro(GestaoStorageKeys.PDV, false);
        await getSerialNumber();
        setTimeout(() => {
            onClick()
        }, 500)
    }, [delRegistro, getSerialNumber, onClick])

    const clickEmpresaPDV = useCallback(async () => {
        try {
            await limparCadastros()
            const empresa = getRegistro(GestaoStorageKeys.PDV, false)?.empresaId
            await selecionarEmpresa(empresa || '')

            setTimeout(() => {
                onClick()
            }, 500)
        } catch {
            showToast('error', 'Ocorrreu um erro ao selecionar a empresa.')
        }
    }, [getRegistro, limparCadastros, onClick, selecionarEmpresa, showToast])

    return (
        <Grid item style={{ maxWidth: 500, textAlign: 'center', padding: 16, borderRadius: 10, background: isMobile ? 'none' : '#FFF' }}>
            <Typography variant="h4" color="primary">
                Conflito no PDV
            </Typography>
            <Divider style={{ marginBottom: 16, background: 'rgb(85 85 85 / 25%)', marginTop: 8 }} />
            <Typography variant="body1" style={{ marginBottom: 32 }}>
                Este PDV é dessa conta, porém de uma empresa diferente. Clique em uma das opções que deseja para resolvermos este conflito.
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={clickOutroPDV}
                    >
                        <PdvIcon tipo="BUTTON_PRIMARY" /> Usar outro PDV
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={clickEmpresaPDV}
                    >
                        <EmpresaIcon tipo="BUTTON_PRIMARY" /> Ir à empresa do PDV
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="text"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => history.push('/dashboard')}
                    >
                        Voltar
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}