import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useFormProdutoNcmPreCadastroValidation } from './form-produto-imposto-pre-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { ProdutoImpostoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imposto-form-model';
import { AutocompleteNcmsGestao } from '../../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { AutocompleteGrupoImpostos } from '../../../../controles/autocompletes/autocomplete-grupo-impostos/autocomplete-grupo-impostos';
import { ProdutoFiscalModel } from '../../../../../../model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormProdutoImpostoPreCadastroProps
  extends DefaultFormProps<ProdutoImpostoPreCadastroFormModel> {}

export const FormProdutoNcmPreCadastro = forwardRef<
  DefaultFormRefs<ProdutoImpostoPreCadastroFormModel>,
  FormProdutoImpostoPreCadastroProps
>(({ loading, ...props }: FormProdutoImpostoPreCadastroProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNcm = useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] =
    useState<ProdutoImpostoPreCadastroFormModel>(
      new ProdutoImpostoPreCadastroFormModel(),
    );
  const { FormProdutoNcmYupValidation } =
    useFormProdutoNcmPreCadastroValidation();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ProdutoImpostoPreCadastroFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormProdutoNcmYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ProdutoImpostoPreCadastroFormModel) => {
    const model = picker<ProdutoImpostoPreCadastroFormModel>(
      values,
      new ProdutoImpostoPreCadastroFormModel(),
    );

    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ProdutoImpostoPreCadastroFormModel());
      if (!isMobile) refInputNcm.current?.focus();
      reset();
    },
    fillForm: (model: ProdutoImpostoPreCadastroFormModel) => {
      setInitialValues(model);
      reset({ ...model });
      if (!isMobile) refInputNcm.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="codigoNcm"
                control={control}
                render={({ field }) => (
                  <AutocompleteNcmsGestao
                    modo="campoFormatado"
                    inputRef={refInputNcm}
                    loadingExterno={loading}
                    label="NCM do Produto"
                    placeholder="Ex: 84109000"
                    error={Boolean(
                      errors.codigoNcm && errors.codigoNcm.message,
                    )}
                    helperText={
                      touchedFields.codigoNcm || errors.codigoNcm
                        ? errors.codigoNcm?.message
                        : undefined
                    }
                    {...field}
                    onChange={(retorno) => {
                      if (!retorno.isString) {
                        const ncm = picker<NcmModel>(
                          retorno.value,
                          new NcmModel(),
                        );
                        setValue('codigoNcm', ncm.codigo);
                      }
                    }}
                    value={getValues('codigoNcm')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="imposto"
                control={control}
                render={({ field }) => (
                  <AutocompleteGrupoImpostos
                    allowSubmit
                    loadingExterno={loading}
                    label="Grupo de Imposto"
                    placeholder="-Selecione-"
                    error={Boolean(errors.imposto && errors.imposto.message)}
                    helperText={
                      touchedFields.imposto || errors.imposto
                        ? errors.imposto?.message
                        : undefined
                    }
                    {...field}
                    onChange={(retorno) => {
                      if (!retorno.isString) {
                        const imposto = picker<ProdutoFiscalModel>(
                          retorno.value,
                          new ProdutoFiscalModel(),
                        );
                        setValue('imposto', imposto.descricao);
                        setValue('impostoId', imposto.id);
                      }
                    }}
                    value={getValues('imposto')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
