import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  displayNone: {
    display: "none",
  },
  display: {},
  list: {
  },
  disabled: {
    color: `${theme.palette.text.primary}!important`
  },
  input: {
    "& svg": {
      fill: theme.palette.text.primary,
      marginRight: -50,
    }
  },
  cadeadoIcon: {
    marginRight: -24,
  },
  inputComNovo: {
    "& svg": {
      fill: theme.palette.text.primary,
      marginRight: -24,
    }
  },
  paper: {
    background: theme.palette.grey[100],
    boxShadow: theme.shadows[10],
    color: theme.palette.text.primary,
    "& .MuiAutocomplete-noOptions": {
      color: theme.palette.text.primary,
    },
    "& .MuiAutocomplete-option[data-focus='true']": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  },
  textField: {
    backgroundColor: theme.palette.common.white,
  },
  notchedOutline: {
    // borderColor: 'rgba(0, 0, 0, 0.23)!important'
  }

}));
