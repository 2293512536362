import { EnumPagTpMod, EnumPagTpTransacao } from "model"
import { CredenciamentoSafra, FinalizadoraModel } from "model/api/gestao/finalizadora/finalizadora-model"
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model"
import { useCallback } from "react"
import { VariaveisAmbiente } from "config"
import { isEmpty } from "lodash"
import { EnumIndStatusMovPag } from "model/enums/enum-indstatus-movpag"
import { useDevice } from "./device"
import { EnumDeviceType } from "model/enums/enum-device-type"
import { PaymentSuccessModel } from "model/app/pagamento-cartao/payment-success"
import { useToastSaurus } from "./toast-saurus"
import { useCadastros } from "./cadastros"
import { usePostFaturaAnoMesPagamento } from "data/api/gestao/conta-pessoa/post-fatura-ano-mes-pagamento"
import { ConvenioSolicitarCreditoModel } from "model/api/gestao/convenio/convenio-solicitar-credito-model"
import { useEmpresaAtual } from "./empresa-atual"
import { useMovSimples } from "./mov-simples"
import { usePDV } from "./pdv"
import { newGuid } from "utils/new-guid"
import { impressaoParcelasGuilhotina } from "utils/impressao-parcelas-guilhotina"

export interface IniciarPagamentoFaturaParamsProps {
    pagamento: MovSimplesPagamentoModel
    credenciamento: CredenciamentoSafra
    credenciado?: boolean
    tpPayments?: EnumPagTpMod[] | undefined
}

export interface HandlePagamentoProps {
    anoMesCompetencia: string,
    faturaId: string,
    pagamento: MovSimplesPagamentoModel
}

export const usePagamentoFaturaConvenio = () => {

    const { startPayment } = useDevice();
    const { showToast } = useToastSaurus()
    const { abrirDialogPix, abrirDialogCredenciarPix, fecharDialogCredenciarPix } = useCadastros()
    const { postFaturaAnoMesPagamento } = usePostFaturaAnoMesPagamento()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { imprimirPagamento } = useMovSimples()
    const { getPDV } = usePDV()

    const juntarPagamentoEParcelas = useCallback((viaImpressao, parcelas) => {
        return `
        ${viaImpressao}
        <guilhotina>
        ${parcelas}
        <br>
        <br>
        <br>
        <br>
        <guilhotina>
        `
    }, [])

    const impressaoPagamento = useCallback(async ({ viaImpressao, parcelas }: ConvenioSolicitarCreditoModel) => {
        if (viaImpressao) {
            imprimirPagamento(
                juntarPagamentoEParcelas(
                    viaImpressao,
                    parcelas.some((p) => p.viaImpressao) ? impressaoParcelasGuilhotina(parcelas.map(x => x.viaImpressao)) : ''
                )
            )
        }
    }, [imprimirPagamento, juntarPagamentoEParcelas])

    const handlePagamento = useCallback(async ({ anoMesCompetencia, faturaId, pagamento }: HandlePagamentoProps) => {
        const tefId = newGuid()
        const res = await postFaturaAnoMesPagamento({
            anoMesCompetencia,
            empresaId: getEmpresaAtual()?.id ?? '',
            faturaId,
            pagamento: {
                caixaId: getPDV()?.id ?? '',
                infAdic: 'Pagamento da Fatura',
                tefId: tefId,
                valor: pagamento.vPag,
                dadosPagamento: {
                    ...pagamento,
                    tefId,
                }
            }
        })
        if (res.erro) throw res.erro

        const resultado = res.resultado?.data as ConvenioSolicitarCreditoModel
        try {
            await impressaoPagamento(resultado)
        } catch (err: any) {
            showToast('error', `Ocorreu um erro ao imprimir o pagamento. Detalhe: ${err.message}`)
        }

        showToast('success', 'Pagamento da fatura feita com sucesso!')
    }, [getEmpresaAtual, getPDV, impressaoPagamento, postFaturaAnoMesPagamento, showToast])

    const iniciarPagamentoFatura = useCallback(async ({ credenciamento, pagamento, credenciado, tpPayments }: IniciarPagamentoFaturaParamsProps) => {

        if (
            pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY ||
            (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX &&
                (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
                    VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) &&
                !isEmpty(tpPayments) &&
                tpPayments?.includes(pagamento.modPagamento) &&
                tpPayments?.[0] !== EnumPagTpMod.DINHEIRO)
        ) {
            return new Promise<MovSimplesPagamentoModel>(
                async (resolve, reject) => {
                    try {
                        pagamento.status = EnumIndStatusMovPag.Processando;
                        await startPayment(
                            pagamento.vPag,
                            pagamento.modPagamento,
                            pagamento.nParcelas,
                            credenciamento,
                            {
                                erro: async (e: any) => {
                                    if (
                                        pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX
                                    ) {
                                    }
                                    reject(e);
                                },
                                sucesso: async (e: PaymentSuccessModel) => {
                                    if (e) {
                                        pagamento.dhTransacao = e.dhTransacao;
                                        pagamento.cAut = e.cAut;
                                        pagamento.codNsu = e.codNsu;
                                        pagamento.envioAPI = e.envioAPI;
                                        pagamento.tid = e.tid;
                                        pagamento.numCartao = e.numCartao;
                                        pagamento.adquirente = e.adquirente;
                                        pagamento.nParcelas = e.nParcelas;
                                        pagamento.bandeira = e.bandeira;
                                        pagamento.nomeCartao = e.nomeCartao;
                                        pagamento.status = EnumIndStatusMovPag.Aprovado;
                                        pagamento.retornoAPI = e.retornoAPI;
                                        pagamento.viaCliente = e.viaCliente;
                                        pagamento.viaLojista = e.viaLojista;

                                        resolve(pagamento);
                                    }
                                    reject(new Error("Erro ao interpretar o Pagamento"));
                                }
                            }
                        );
                    } catch (e: any) {
                        reject(e);
                    }
                }
            );
        }

        if (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX) {
            if (!navigator.onLine) {
                throw new Error(
                    "É necessário conexão com a internet para realizar o PIX."
                );
            }

            if (!credenciado) {
                const sugerirCredenciamento = () =>
                    new Promise<boolean>((resolve, reject) => {
                        abrirDialogCredenciarPix(
                            pagamento.pagamentoId,
                            async (finalizadora: FinalizadoraModel) => {
                                try {
                                    resolve(true);
                                } catch (e: any) {
                                    reject(false);
                                }
                            },
                            async (message: string) => {
                                if (message.length > 0) showToast("error", message);
                                fecharDialogCredenciarPix();
                                reject(false);
                            }
                        );
                    });

                const resultadoCredenciamento = await sugerirCredenciamento();

                if (!resultadoCredenciamento) {
                    throw new Error("Não foi possível prosseguir com o pagamento PIX.");
                }
            }
            return new Promise((resolve, reject) => {
                abrirDialogPix(
                    pagamento,
                    async () => {
                        pagamento.status = EnumIndStatusMovPag.Aprovado;
                        resolve(pagamento);
                    },
                    () => reject(pagamento)
                );
            });
        }

        pagamento.status = EnumIndStatusMovPag.Aprovado;
        return pagamento;
    }, [abrirDialogCredenciarPix, abrirDialogPix, fecharDialogCredenciarPix, showToast, startPayment])

    return {
        iniciarPagamentoFatura,
        handlePagamento,
    }
}