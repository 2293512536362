import { EnumMenu, KeyValueModel } from "model";

export const ModulosPai: KeyValueModel[] = [
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, "Venda de Produtos"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_FINALIZAR_VENDA, "Finalizar venda"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, "Administrativo e Configurações"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, "Outras Operações"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, "Operações de Salão"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY, "Delivery"),
    new KeyValueModel(EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RELATORIOS, "Relatórios"),
    new KeyValueModel(EnumMenu.CADASTRO_GROUP, "Cadastros"),
    new KeyValueModel(EnumMenu.FARMA_GROUP, "Farmácias"),
    new KeyValueModel(EnumMenu.RELATORIOS_GROUP, "Relatórios"),
    new KeyValueModel(EnumMenu.ADMINISTRATIVO_GROUP, "Administrativo"),
    new KeyValueModel(EnumMenu.FISCAL_GROUP, "Fiscal"),
    new KeyValueModel(EnumMenu.SUPORTE_GROUP, "Suporte Especializado"),
];
