import { PromocaoTipoRegraRotaMock } from "data/mocks/promocao-mock";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PromocaoRegraModal } from "views/components/modals/promocao-regra/promocao-regra";

export const CadastroPromocaoRegrasModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        promocaoId: '',
        tipoRegra: EnumPromocaoTipoRegra.DePor,
        aberto: false,
        ultimaUrl: '',
        search: '',
    });

    const promocaoRegraPrincipalRoute = `/promocoes/`;
    const promocaoRegraRoute = `/regras-`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroPromocaoRegraModal')

    useEffect(() => {

        if (location.pathname.indexOf(promocaoRegraPrincipalRoute) === 0 &&
            location.pathname.indexOf(promocaoRegraRoute) === 47
        ) {
            try {
                const rota = location.pathname.split('/');

                const id = rota[4];
                const promocaoId = rota[2];
                const tipo = rota[3].replace('regras-', '');

                const tipoEnum = PromocaoTipoRegraRotaMock.find(x => x.Value === tipo)?.Key ?? EnumPromocaoTipoRegra.DePor;

                if (id.length > 0 && promocaoId.length > 0) {
                    setModalState(prev => ({
                        id: id === novoCadastroRoute ? '' : id,
                        promocaoId: promocaoId,
                        aberto: true,
                        tipoRegra: tipoEnum,
                        ultimaUrl: `${rota[0]}/${rota[1]}/${rota[2]}`,
                        search: prev.search
                    }));
                    return;
                }
            } catch { }
        }
    }, [location.pathname, promocaoRegraRoute, promocaoRegraPrincipalRoute]);

    const modalAlterado = useCallback(({ id, promocaoId, tipoRegra, openned, callbackUrl, trocarUrl }: any) => {
        const tipoRota = PromocaoTipoRegraRotaMock.find(x => x.Key === tipoRegra)?.Value;
        if (tipoRota === undefined && openned) throw new Error('Tipo de Regra não homologado nesta versão.');

        let url = promocaoRegraPrincipalRoute + promocaoId + promocaoRegraRoute + tipoRota + "/";
        if (callbackUrl.length > 0) {
            url = callbackUrl
        }

        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
        setModalState({
            id: id === novoCadastroRoute ? '' : id,
            promocaoId: promocaoId,
            tipoRegra: tipoRegra,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
        if (!openned && !trocarUrl) {
            push({
                pathname: url,
                search: prevSearch
            })
        }
        if (trocarUrl) {
            push(url + (id?.length === 0 ? novoCadastroRoute : id));
        }
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, promocaoRegraRoute, push, promocaoRegraPrincipalRoute])

    useEffect(() => {
        addHandler(AppEventEnum.PromocaoRegraModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PromocaoRegraModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            {modalState.aberto && <PromocaoRegraModal openned={modalState.aberto} key="mdlPromocaoRegra" id={modalState.id} promocaoId={modalState.promocaoId} tipoRegra={modalState.tipoRegra} />}
        </>
    )
}