import { useEffect, useState, useCallback, useRef } from 'react';
import { Grid } from 'views/design-system';
import { useStyles } from './selecionar-vendedor-produto-list-styles';
import { VendedorListData } from './selecionar-vendedor-produto-list-data';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { useHistory } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { isEmpty } from 'lodash';
import { EnumCadastroTipo } from 'model';
import { useKeyNavigation } from 'services/app/hooks/key-navigation';

interface SelecionarVendedorListProps {
  pedido: PedidoModel;
}

export const SelecionarVendedorList = ({
  pedido,
}: SelecionarVendedorListProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState<Array<PessoaModel>>([]);
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const termoPesquisar = useVendaHeaderStore(state => state.props.search) || '';
  const selectedItem = useRef<number>(-1);

  const search = useCallback(async () => {
    try {
      setLoading(true);
      const res = await TouchoneDBPrimary.clientes
        .filter((vendedor) => {
          return vendedor.status === 0 && [EnumCadastroTipo.FUNCIONARIO, EnumCadastroTipo.REPRESENTANTE].includes(vendedor.tpCadastro);
        })
        .toArray();
      setQueryStatus(res as PessoaModel[]);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setLoading(false);
    }
  }, [showToast]);

  const timer = useRef({} as NodeJS.Timeout);
  const ultimoTermoPesquisado = useRef('');

  const queryStatusFiltered = queryStatus.filter((item) => {
    if (isEmpty(termoPesquisar)) {
      return item;
    }
    return item.nome.toLowerCase().includes(termoPesquisar.toLowerCase())
  },
  );

  useEffect(() => {
    (async () => {
      clearTimeout(timer.current);

      if (
        ultimoTermoPesquisado.current !== termoPesquisar ||
        termoPesquisar.length === 0
      ) {
        timer.current = setTimeout(async () => {
          await search();
        }, 500);
      }
      return () => {
        clearTimeout(timer.current);
      };
    })();
  }, [search, termoPesquisar]);

  const onCardSelected = async (model: PessoaModel) => {
    history.push({
      pathname: MovRotasMock.transferirItensRota,
      state: {
        pedido,
        vendedor: model,
        transferirPedido: false,
        alterarVendedor: true,
      },
    });
  };

  useKeyNavigation(
    queryStatusFiltered.length,
    selectedItem,
    (index) => {
      if (queryStatusFiltered[index])
        onCardSelected(queryStatusFiltered[index]);
    }
  );

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <VendedorListData
              carregando={loading}
              list={queryStatusFiltered}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
