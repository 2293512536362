import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { InformacoesIniciaisProps } from "./informacoes-iniciais-props"
import classNames from "classnames"
import { FormGerenciarCartaoConvenio } from "views/components/form/convenio/form-gerenciar-cartao-convenio/form-gerenciar-cartao-convenio"
import { GerenciarCartaoConvenioFormModel } from "model/app/forms/convenio/gerenciar-cartao-convenio-form-model"
import { DefaultFormRefs } from "views/components/form/utils"
import { useCallback, useEffect, useRef } from "react"
import { Button, Grid } from "@material-ui/core"
import { usePostConvenio } from "data/api/gestao/conta-pessoa/post-conta-pessoa"
import { SalvarIcon, VoltarIcon } from "views/components/icons"
import { CircularLoading } from "views/components/utils"
import { CartaoConvenioModel } from "model/api/gestao/convenio/cartao-convenio-model"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import { useToastSaurus } from "services/app"
import { EnumFaseGerenciarConvenio } from "../../convenio-gerenciar"
import { useHistory } from "react-router-dom"
import { ConvenioModel } from "model/api/gestao/convenio/convenio-model"
import { toDateString } from "utils/to-date"
import { useStyles } from "./informacoes-iniciais-styles"
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config"
import { ConvenioPostModel } from "model/api/gestao/convenio/convenio-post-model"
import { toDecimal } from "utils/to-decimal"

export const InformacoesIniciais = ({ convenio, setFaseGerenciarConvenio, abrirSelecaoPessoa, alterarId }: InformacoesIniciaisProps) => {
    const classesModal = useDefaultCadastroStyles()
    const classes = useStyles()
    const { getEmpresaAtual, getConfigByCod } = useEmpresaAtual()
    const { showToast } = useToastSaurus()
    const history = useHistory()

    const { postConvenio, carregando: carregandoPost } = usePostConvenio()

    const diaFechamentoPadrao = getConfigByCod(EnumEmpresaConfig.DiaFechamentoPadrao)
    const diaPagamentoPadrao = getConfigByCod(EnumEmpresaConfig.DiasPagamentoPadrao)

    const carregando = [carregandoPost].includes(true)

    const refEditForm = useRef<DefaultFormRefs<GerenciarCartaoConvenioFormModel>>(null);

    const preencherCampos = useCallback(() => {
        const cartao = convenio.cartoes?.[0] ?? new CartaoConvenioModel()
        const dataValidade = new Date()
        dataValidade.setFullYear(dataValidade.getFullYear() + 5)
        const form: GerenciarCartaoConvenioFormModel = {
            bloquearPessoa: true,
            limite: cartao.limite,
            senha: cartao.senha,
            nomeCartao: cartao.nomeCartao,
            numeroCartao: cartao.numeroCartao,
            pessoaId: cartao.pessoaId,
            id: '',
            dataValidade: toDateString(dataValidade, 'yyyy-MM-DD') ?? '',
        }
        refEditForm.current?.fillForm(form)
    }, [convenio.cartoes])

    useEffect(() => {
        preencherCampos()
    }, [preencherCampos])

    const criarNovoConvenio = async (model: ConvenioPostModel) => {
        const res = await postConvenio(model, getEmpresaAtual()?.id ?? '')
        if (res.erro) throw res.erro
        const convenio = res.resultado?.data as ConvenioModel
        showToast('success', 'Convênio adicionado com sucesso!')
        history.push(`/convenios/${convenio.id}`)
        alterarId(convenio.id)
        setFaseGerenciarConvenio(EnumFaseGerenciarConvenio.InformacoesConvenio)
    }

    const handleSubmit = async (model: GerenciarCartaoConvenioFormModel) => {
        try {
            const dataExpiracao = new Date()
            dataExpiracao.setFullYear(dataExpiracao.getFullYear() + 5)
            const newConvenio: ConvenioPostModel = {
                ...new ConvenioPostModel(),
                pessoaId: convenio.pessoaId,
                empresaId: getEmpresaAtual()?.id ?? '',
                cartoes: [
                    {
                        limite: toDecimal(model.limite),
                        nomeCartao: model.nomeCartao,
                        numeroCartao: model.numeroCartao,
                        pessoaId: convenio.pessoaId,
                        senha: model.senha,
                        dataValidade: model.dataValidade
                    }
                ],
                diaFechamento: Number(diaFechamentoPadrao),
                diasParaPagamento: Number(diaPagamentoPadrao),
            }

            await criarNovoConvenio(newConvenio)
        } catch (err: any) {
            showToast('error', 'Ocorreu algum erro ao adicionar o novo Convênio. Detalhe: ' + err.message)
        }
    }

    return (
        <>
            <div className={classNames(classesModal.content, classes.content)}>
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <div className={classNames(classesModal.contentForms)}>
                    <FormGerenciarCartaoConvenio
                        onSubmit={handleSubmit}
                        showLoading={false}
                        loading={false}
                        ref={refEditForm}
                    />
                </div>
                <div className={classesModal.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    abrirSelecaoPessoa()
                                }}
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <VoltarIcon tipo="BUTTON" />
                                Voltar
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Button
                                disabled={carregando}
                                onClick={() => {
                                    refEditForm.current?.submitForm();
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <SalvarIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}