import classNames from 'classnames';
import { useStyles } from './card-dia-semana-styles'
import { CheckboxDefault } from 'views/components/controles/checkboxes';
import { Typography } from 'views/design-system';
import { DefaultCard } from '../components';

interface CardDiaSemanaProps {
    label: string,
    type: number,
    onClick: (type: number) => void;
    isCheck: boolean
}

export const CardDiaSemana = ({ label, onClick, type, isCheck }: CardDiaSemanaProps) => {
    const classes = useStyles();

    const handleClickApuracao = () => {
        onClick(type);
    }

    return (
        <DefaultCard hasTagStatus={false} className={classNames(classes.container)} onClick={handleClickApuracao}>
            <div className={classes.content}>
                <CheckboxDefault
                    checked={isCheck}
                />
                <Typography className={classes.label} variant='subtitle2'>{label}</Typography>
            </div>
        </DefaultCard>
    )
}