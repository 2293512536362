import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useFormUploadCargaCadastroValidation } from './form-finalizadora-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadCargaCadastroFormModel } from 'model/app/forms/carga/upload-carga-cadastro-form-model';
import ArquivoInput from 'views/components/controles/inputs/arquivo-input/arquivo-input';
import { TpUploadCargaTipoMock } from 'data/mocks/tp-upload-carga-tipo-mock';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { EnumTipoRetornoArquivo } from 'views/components/controles/inputs/arquivo-input/arquivo-input-props';

export const FormUploadCargaCadastro = forwardRef<
  DefaultFormRefs<UploadCargaCadastroFormModel>,
  DefaultFormProps<UploadCargaCadastroFormModel>
>(
  (
    { loading, ...props }: DefaultFormProps<UploadCargaCadastroFormModel>,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputDescricao = useRef<HTMLInputElement>(null);
    const [initialValues, setInitialValues] =
      useState<UploadCargaCadastroFormModel>(
        new UploadCargaCadastroFormModel(),
      );
    const { FormUploadCargaYupValidation } =
      useFormUploadCargaCadastroValidation();
    const { isMobile } = useThemeQueries();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      getValues,
      setValue,
      reset,
    } = useForm<UploadCargaCadastroFormModel>({
      defaultValues: { ...initialValues },
      resolver: yupResolver(FormUploadCargaYupValidation),
      criteriaMode: 'all',
      mode: 'onChange',
    });

    const onSubmit = (values: UploadCargaCadastroFormModel) => {
      const model = picker<UploadCargaCadastroFormModel>(
        values,
        new UploadCargaCadastroFormModel(),
      );

      props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        setInitialValues(new UploadCargaCadastroFormModel());
        reset({
          conversao: '',
          tipo: EnumUploadCargaTipo.PESSOA,
        });

        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
      fillForm: () => {
        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
    }));
    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                  <Typography variant="caption">
                    Arquivo de Carga(.csv/.txt)
                  </Typography>
                  <Controller
                    name="conversao"
                    control={control}
                    render={({ field }) => (
                      <ArquivoInput
                        disabled={loading}
                        tipoRetorno={EnumTipoRetornoArquivo.String}
                        nameArquivo={getValues('conversao')}
                        error={Boolean(
                          errors.conversao && errors.conversao.message,
                        )}
                        helperText={
                          touchedFields.conversao || errors.conversao
                            ? errors.conversao?.message
                            : undefined
                        }
                        {...field}
                        onChange={({ base64 }: any) => {
                          setValue('conversao', base64);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="tipo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Tipo de Carga"
                      variant="outlined"
                      fullWidth
                      allowSubmit
                      disabled={loading}
                      conteudo={TpUploadCargaTipoMock}
                      error={Boolean(errors.tipo && errors.tipo.message)}
                      helperText={
                        touchedFields.tipo || errors.tipo
                          ? errors.tipo?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpUploadCargaTipoMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('tipo', item);
                        }
                      }}
                      value={getValues('tipo')}
                    />
                  )}
                />
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
