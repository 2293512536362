import { useEffect, useState, useCallback } from "react";
import { useStyles } from "./convenio-fatura-list-styles";
import { ConvenioFaturaListData } from "./convenio-fatura-list-data";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { ConvenioFaturaModel } from "model/api/gestao/convenio";
import { useGetFaturaAnoMes } from "data/api/gestao/conta-pessoa";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { CardConvenioFaturaPreco } from "views/components/cards/card-convenio-fatura-preco";
import { getAnoMes } from "utils/get-ano-mes";
import { AnoMesList } from "./components/ano-mes-list/ano-mes-list";
import { toDecimalString } from "utils/to-decimal";
import { Grid } from "views/design-system";

export const ConvenioFaturaList = () => {
  const classes = useStyles();
  const { abrirConvenioResumoFaturas } = useCadastros();
  const { getFaturaAnoMes, carregando: carregandoGetFatura } = useGetFaturaAnoMes()
  const { getEmpresaAtual } = useEmpresaAtual()
  const [anoMes, setAnoMes] = useState<number>(getAnoMes(new Date()))
  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(true)

  const carregando = [carregandoGetFatura, carregandoInterno].includes(true)

  const { showToast } = useToastSaurus();
  // const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    totalFaturas: 0,
    list: Array<ConvenioFaturaModel>(),
  });
  // const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ConvenioFaturaModel>,
      totalFaturas: number,
    ) => {
      setQueryStatus({
        page,
        list,
        totalResults,
        totalPages,
        totalFaturas,
      });
    },
    []
  );

  // const urlParams = new URLSearchParams(history.location.search)
  // const filtros = {
  //   status: isEmpty(urlParams.get('status')) ? 1 : Number(urlParams.get('status')),
  //   termo: urlParams.get('termo') || ''
  // }

  const search = useCallback(
    async (newPage: number) => {
      const empresaId = getEmpresaAtual()?.id ?? ''
      const query = "pageSize=10"

      const params = {
        anoMes,
        empresaId,
        query,
        page: newPage
      }

      try {
        const res = await getFaturaAnoMes(params);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
          res.resultado?.data?.totalFaturas
        );
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getEmpresaAtual, anoMes, getFaturaAnoMes, fillResult, showToast]
  );

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    abrirConvenioResumoFaturas({ pessoaId: id, anoMesSelecionado: anoMes });
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  const selecionarAnoMes = useCallback(async (anoMes: number) => {
    try {
      setAnoMes(anoMes)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [showToast])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <AnoMesList
            anoMesAtual={anoMes}
            onClick={selecionarAnoMes}
            empresaId={getEmpresaAtual()?.id ?? ''}
            setCarregandoInterno={setCarregandoInterno}
          />
        </Grid>
        <div className={classes.listAdjust}>
          <div className={classes.content}>
            <Grid container>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.page}
              />
              <Grid item xs={12} className={classes.listContainer}>
                <ConvenioFaturaListData
                  carregando={carregando}
                  list={queryStatus.list}
                  selectedList={selectedList}
                  onCardSelected={onCardSelected}
                  onCardChecked={onCardChecked}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.containerPrecos}>
            <Grid container spacing={2}>
              <Grid item xs>
                <CardConvenioFaturaPreco
                  label="Quantidade Fatura"
                  value={queryStatus.totalResults}
                />
              </Grid>
              <Grid item xs>
                <CardConvenioFaturaPreco
                  label="Valor Total"
                  value={`R$ ${toDecimalString(queryStatus.totalFaturas)}`}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
