import { Divider, Grid } from 'views/design-system';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { MenuPrincipalTop } from './components/menu-principal-top/menu-principal-top';
import { MenuPrincipalPerfil } from './components/menu-principal-perfil/menu-principal-perfil';
import { EnumMenu } from 'model';
import { useGetMenu } from 'data/app';
import { useStyles } from './menu-principal-styles';
import { ListModuloPai } from './components/list-modulo-pai/list-modulo-pai';
import { useThemeQueries } from 'views/theme';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { ListModuloFilho } from './components/list-modulo-filho/list-modulo-filho';
import { MenuModel } from '../../../../../model/app';
import classNames from 'classnames';
import { useSessaoAtual } from 'services/app';
import { VariaveisAmbiente } from 'config';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { isEqual } from 'lodash';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { EnumMenuPrincipalModelo } from 'model/enums/enum-menu-principal-modelo';
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { MenuPrincipalProps } from './menu-principal-props';
import { EnvironmentInfo } from 'config/components/environment-info';

export const MenuPrincipal = (props: MenuPrincipalProps) => {
  // HOOKS
  const { alterarModelo, fecharMenu } = useMenuPrincipal();
  const { isMobile, theme } = useThemeQueries();
  const { get } = useMenuStorage();
  const { addHandler, removeHandler } = useEventTools();
  const classes = useStyles();
  const { getMenu } = useGetMenu();
  const { getEmpresaSelecionada } = useSessaoAtual();

  const [menuAtual, setMenuAtual] = useState(get());
  const [modulosRetornados, setModulosRetornados] = useState([] as MenuModel[]);
  const opcaoPrincipal = EnumMenu.OPERACIONAL_FORAVENDA_INICIAR_VENDA;

  const retMenuAlterado = useCallback((menu: MenuPrincipalModel) => {
    setMenuAtual((prev) => {
      const menuEqual = isEqual(prev, menu);
      if (!menuEqual) return menu;
      return prev;
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
    return () => removeHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
  }, [addHandler, removeHandler, retMenuAlterado]);

  //CORRECAO DE MOBILE PARA O MENU
  const CorrecaoModeloAtual = isMobile
    ? EnumMenuPrincipalModelo.Completo
    : menuAtual.modelo;

  useEffect(() => {
    if (isMobile && menuAtual.modelo !== EnumMenuPrincipalModelo.Completo)
      alterarModelo(EnumMenuPrincipalModelo.Completo);
  }, [alterarModelo, isMobile, menuAtual.modelo]);

  const refDrawer = useRef<HTMLDivElement>(null);

  const pinClicked = useCallback(() => {
    if (isMobile) {
      fecharMenu();
      return;
    }

    if (menuAtual.modelo === EnumMenuPrincipalModelo.Completo) {
      alterarModelo(EnumMenuPrincipalModelo.Mini);
    } else {
      alterarModelo(EnumMenuPrincipalModelo.Completo);
    }
  }, [menuAtual.modelo, isMobile, fecharMenu, alterarModelo]);

  const retOpcaoPrincipal = useMemo(() => {
    const opcao = modulosRetornados.find((x) => x.codigo === opcaoPrincipal);
    if (!opcao) {
      return <></>;
    }

    return (
      <ListModuloFilho
        key={opcao.codigo}
        isFavorite={true}
        modeloAtual={CorrecaoModeloAtual}
        codigoModulo={opcao?.codigo}
        dataValidade={opcao.dataValidade}
        nomeModulo={opcao?.nome}
        descricaoModulo={opcao.descricao}
        rota={opcao?.rota}
        id='menuOpcaoPrincipal'
      />
    );
  }, [CorrecaoModeloAtual, modulosRetornados, opcaoPrincipal]);

  useEffect(() => {
    const ret = getMenu();
    setModulosRetornados(ret);
  }, [getMenu]);

  const leftAtual = !isMobile
    ? 0
    : menuAtual.situacao === EnumMenuPrincipalStatus.Aberto
      ? 0
      : menuAtual.modelo === EnumMenuPrincipalModelo.Completo
        ? -350
        : -75;

  const listaModulos = useMemo(() => {
    return (
      <>
        {modulosRetornados
          .filter((x) => x.codigoPai === 0)
          .filter((x) => modulosRetornados.filter(y => y.codigoPai === x.codigo).length > 0)
          .map((item) => (
            <ListModuloPai
              key={item.codigo}
              modeloAtual={CorrecaoModeloAtual}
              codigoModulo={item.codigo}
              nomeModulo={item.nome}
              descricao={item.descricao}
              modulos={modulosRetornados
                .filter((model) => {
                  if (
                    !VariaveisAmbiente.performanceDebugMode &&
                    EnumMenu.RELATORIOS_PERFORMANCE === model.codigo
                  ) {
                    return false;
                  }
                  if (
                    VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS
                    && (
                      EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO === model.codigo ||
                      EnumMenu.FARMA_SNGPC === model.codigo)
                  ) {
                    return false
                  }
                  return true;
                })
                .filter(
                  (x) =>
                    opcaoPrincipal !== x.codigo
                )}
            />
          ))}
      </>
    );
  }, [CorrecaoModeloAtual, modulosRetornados, opcaoPrincipal]);

  const itensMenu = useMemo(() => {
    return (
      <Grid className={classes.drawerOverflowMenu}>
        <Divider className={classes.divider} />
        {retOpcaoPrincipal}
        {listaModulos}
        {menuAtual.modelo === EnumMenuPrincipalModelo.Completo && (
          <EnvironmentInfo />
        )}
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.divider, classes.drawerOverflowMenu, classes.drawerVersion, classes.versionControl, getEmpresaSelecionada, listaModulos, menuAtual.modelo, retOpcaoPrincipal]);

  const menuPrincipalPerfil = useMemo(
    () => (
      <MenuPrincipalPerfil
        theme={theme}
        isMobile={isMobile}
        modeloAtual={CorrecaoModeloAtual}
      />
    ),
    [CorrecaoModeloAtual, isMobile, theme]
  );

  const menuPrincipalTop = useMemo(
    () => (
      <MenuPrincipalTop
        isMobile={isMobile}
        modeloAtual={CorrecaoModeloAtual}
        pinClicked={pinClicked}
      />
    ),
    [CorrecaoModeloAtual, isMobile, pinClicked]
  );

  const component = useMemo(() => {
    return (
      <>
        {isMobile && (
          <div
            className={classNames(
              classes.drawerBackground,
              isMobile && menuAtual.situacao === EnumMenuPrincipalStatus.Aberto
                ? classes.drawerBackgroundAberto
                : undefined,
              VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS ? classes.drawerSlow : undefined
            )}
            onClick={fecharMenu}
          ></div>
        )}
        <div
          className={classNames(
            classes.drawer,
            isMobile ? classes.drawerMobile : undefined,
            menuAtual.situacao === EnumMenuPrincipalStatus.Aberto
              ? isMobile
                ? classes.openDrawerMobile
                : classes.openDrawer
              : undefined,
            menuAtual.modelo === EnumMenuPrincipalModelo.Mini
              ? classes.drawerReduzido
              : undefined,
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS ? classes.drawerSlow : undefined
          )}
          style={{ left: leftAtual }}
        >
          {
            <Grid ref={refDrawer} className={classes.drawerContainer}>
              <Grid className={classes.drawerTop}>
                {menuPrincipalTop}
                {menuPrincipalPerfil}
              </Grid>
              {itensMenu}
              <Grid className={classes.drawerBottom}>
                <Divider className={classes.divider} />
                <ListModuloFilho
                  key={EnumMenu.MASTER_SAIR}
                  isFavorite={false}
                  modeloAtual={CorrecaoModeloAtual}
                  codigoModulo={EnumMenu.MASTER_SAIR}
                  nomeModulo={'Sair'}
                  rota={'/logout'}
                />
              </Grid>
            </Grid>
          }
        </div>
      </>
    );
  }, [isMobile, classes.drawerBackground, classes.drawerBackgroundAberto, classes.drawerSlow, classes.drawer, classes.drawerMobile, classes.openDrawerMobile, classes.openDrawer, classes.drawerReduzido, classes.drawerContainer, classes.drawerTop, classes.drawerBottom, classes.divider, menuAtual.situacao, menuAtual.modelo, fecharMenu, leftAtual, menuPrincipalTop, menuPrincipalPerfil, itensMenu, CorrecaoModeloAtual]);

  return component;
};
