import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Box, Grid } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from '../../../../../views/theme';
import { picker } from '../../../../../utils/picker';
import { CircularLoading } from '../../../../../views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from '../../../../../views/components/form/utils/form-default-props';
import { UsuarioEditFormModel } from '../../../../../model/app/forms/perfil/perfil-usuario-edit-form-model';
import { KeyValueModel, PerfilModel } from 'model';
import { useGetPerfis } from 'data/api/gestao/perfil/get-perfis';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { StatusConviteMock } from 'data/mocks/status-convite-mock';
import { Controller, useForm } from 'react-hook-form';
import { isPlanoComVendedor } from 'utils/plano-utils';

export const FormUsuarioEdit = forwardRef<
  DefaultFormRefs<UsuarioEditFormModel>,
  DefaultFormProps<UsuarioEditFormModel>
>(({ loading, ...props }: DefaultFormProps<UsuarioEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInput = useRef<HTMLInputElement>(null);
  const { showToast } = useToastSaurus();
  const [modelForm, setModelForm] = useState<UsuarioEditFormModel>(
    new UsuarioEditFormModel(),
  );
  const { isMobile } = useThemeQueries();

  const [perfis, setPerfis] = useState<PerfilModel[]>([]);

  const { getPerfisDrop, carregando: carregandoDrop } = useGetPerfis();

  const { plano } = useSessaoAtual()
  const planoComVendedor = isPlanoComVendedor(plano?.plano)

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<UsuarioEditFormModel>({
    defaultValues: { ...new UsuarioEditFormModel() },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = async (values: UsuarioEditFormModel) => {
    const perfil = picker<UsuarioEditFormModel>(
      values,
      new UsuarioEditFormModel(),
    );

    props.onSubmit(perfil, modelForm);
  };

  const handleGetPerfisDrop = useCallback(async () => {
    const res = await getPerfisDrop();
    if (res.erro) {
      throw res.erro;
    }

    return res.resultado?.data?.list;
  }, [getPerfisDrop]);

  useEffect(() => {
    (async () => {
      try {
        const usePerfis = await handleGetPerfisDrop();
        setPerfis(usePerfis);
      } catch (e: any) {
        showToast('error', e.message);
      }
    })();
  }, [handleGetPerfisDrop, showToast]);

  const mock: Array<KeyValueModel> = perfis.map((valor) => {
    return new KeyValueModel(valor.id, valor.nome);
  });

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInput.current?.focus();
    },
    fillForm: (model: UsuarioEditFormModel) => {
      setModelForm(model);
      reset({
        ...model,
      });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInput.current?.focus();
      }, 500);
    },
  }));

  const carregando = loading || carregandoDrop

  return (
    <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        label="E-mail"
                        readOnly
                        fullWidth={true}
                        variant="outlined"
                        error={Boolean(errors.email && errors.email.message)}
                        helperText={
                          touchedFields.email || errors.email
                            ? errors.email?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {getValues('status') !== 2 ? (
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          conteudo={StatusConviteMock.filter(
                            (item) => item.Key !== 2 && item,
                          )}
                          fullWidth
                          variant="outlined"
                          label="Status"
                          error={Boolean(errors.status && errors.status.message)}
                          helperText={
                            touchedFields.status || errors.status
                              ? errors.status?.message
                              : undefined
                          }
                          {...field}
                          onChange={(event) => {
                            if (event) {
                              setValue(
                                'status',
                                StatusConviteMock.filter(
                                  (item) => item.Key === event.target.value,
                                )[0]?.Key,
                              );
                            }
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          label="Status"
                          readOnly
                          fullWidth={true}
                          variant="outlined"
                          error={Boolean(errors.status && errors.status.message)}
                          helperText={
                            touchedFields.status || errors.status
                              ? errors.status?.message
                              : undefined
                          }
                          {...field}
                          value={
                            StatusConviteMock.filter(
                              (item) => item.Key === getValues('status')
                            )[0]?.Value
                          }
                        />
                      )}
                    />
                  )}
                </Grid>
                {planoComVendedor && (
                  <Grid item xs={12}>
                    <Controller
                      name="perfilId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          conteudo={mock}
                          fullWidth
                          variant="outlined"
                          label="Selecione o perfil de acesso"
                          error={Boolean(errors.perfilId && errors.perfilId.message)}
                          helperText={
                            touchedFields.perfilId || errors.perfilId
                              ? errors.perfilId?.message
                              : undefined
                          }
                          {...field}
                          onChange={(event) => {
                            if (event) {
                              setValue(
                                'perfilId',
                                perfis.filter(
                                  (item) => item.id === event.target.value,
                                )[0]?.id,
                              );
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <button style={{ display: 'none' }} type="submit"></button>
            </form>
          </div>
        </Box>
    </>
  );
});
