import { makeStyles } from "@material-ui/core";

export const makeUtilClasses = makeStyles((theme) => ({
    rootContainer: {
        position: "relative",
        maxWidth: "850px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
        },
    },
    contentCenter: {
        width: "100%",
        minHeight: "100vh",
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    controlLoading: {
        opacity: 0.6,
    },
    formContainer: {
        position: "relative",
        "& form": {
            transition: "0.3s all",
        },
        width: "100%",
    },
    btnSticky: {
        position: "sticky",
        bottom: "0",
        zIndex: 2,
        backgroundColor: "#FFFFFF",
    },
    acoes: {
        flex: "0 0 auto",
    },

    selectEndAdorment: {
        marginLeft: '8px',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: '#333',
        fontSize: '14px',
        cursor: 'pointer',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        transition:
            'border-color 0.2s ease, box-shadow 0.2s ease'
    }
}));
