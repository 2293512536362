import { forwardRef, useImperativeHandle, useState } from 'react';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  EnumCondicoes,
  FiltrosRelatoriosAvancadosModel,
  OrdenadoresRelatoriosAvancadosModel,
  RelatoriosAvancadosModel,
  TipoCampoRelatorioEnum
} from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { Box, Button, Grid } from 'views/design-system';
import { toDateString } from 'utils/to-date';

export interface FormRelatoriosAvancadosValues {
  dataInicial: string;
  dataFinal: string;
  filtros: FiltrosRelatoriosAvancadosModel[];
  ordenador: string;
  relatorio: number;
}

export interface FormRelatoriosAvancadosPost {
  dataInicial: string;
  dataFinal: string;
  filtros: Array<{
    tipo: number;
    campo: string;
    valor: string;
    condicao: number;
  }>;
  ordenador: string;
  relatorio: number;
}

export interface FormCategoriaEditProps
  extends DefaultFormProps<FormRelatoriosAvancadosPost> {
  formFiltros: Array<FiltrosRelatoriosAvancadosModel>;
  ordenadores: Array<OrdenadoresRelatoriosAvancadosModel>;
}

const generateValidationSchema = (
  fields: FiltrosRelatoriosAvancadosModel[]
) => {
  const shape: any = {};
  // TODO: SHAPE VALIDATIONS
  // fields.forEach((field, index) => {
  //   if (field.Obrigatorio) {
  //     shape[`filtros.${index}`] = Yup.string().required(
  //       `${field.nome} é obrigatório(a)`
  //     );
  //   }
  // });

  // shape['dataInicial'] = Yup.string().required('Data Inicial é obrigatória');
  // shape['dataFinal'] = Yup.string().required('Data Final é obrigatória');

  return Yup.object().shape(shape);
};

const condicoesOptions = [
  { value: EnumCondicoes.Igual, label: '=' },
  { value: EnumCondicoes.Diferente, label: '≠' },
  { value: EnumCondicoes.Maior, label: '>' },
  { value: EnumCondicoes.MaiorIgual, label: '≥' },
  { value: EnumCondicoes.Menor, label: '<' },
  { value: EnumCondicoes.MenorIgual, label: '≤' },
  { value: EnumCondicoes.Contenha, label: '∈' },
  { value: EnumCondicoes.NaoContenha, label: '∉' }
];

export const FormRelatoriosAvancadosEdit = forwardRef<
  DefaultFormRefs<RelatoriosAvancadosModel>,
  FormCategoriaEditProps
>(({ loading, ...props }: FormCategoriaEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const [, setAtt] = useState<number>(0);

  const validationSchema = generateValidationSchema(props?.formFiltros ?? []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues
  } = useForm<FormRelatoriosAvancadosValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      filtros: props.formFiltros,
      dataInicial: '',
      dataFinal: '',
      ordenador: '',
      relatorio: 0
    },
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const handleChangeCondicao = (event: any, name: string) => {
    setAtt((prev) => prev + 1);
    setValue(name as any, event.target.value as EnumCondicoes);
  };

  const { fields } = useFieldArray({
    control,
    name: 'filtros'
  });

  const fieldsSerializados = fields.map((fields, index) => {
    return {
      ...fields,
      indexOriginal: index
    };
  });

  const onSubmit: SubmitHandler<FormRelatoriosAvancadosValues> = (data) => {
    const fieldErros = [];

    if (data.dataInicial.length <= 0) {
      const msg = 'Data Inicial é obrigatória';
      setError('dataInicial', {
        type: 'required',
        message: msg
      });
      fieldErros.push(msg);
    } else {
      clearErrors('dataInicial');
    }

    if (data.dataFinal.length <= 0) {
      const msg = 'Data Final é obrigatória';
      setError('dataFinal', {
        type: 'required',
        message: msg
      });
      fieldErros.push(msg);
    } else {
      if (new Date(data.dataFinal) < new Date(data.dataInicial)) {
        const msg = 'A data final deve ser igual ou posterior à data inicial.';
        setError('dataFinal', {
          type: 'required',
          message: msg
        });
        fieldErros.push(msg);
      } else {
        clearErrors('dataFinal');
      }
    }

    data.filtros.forEach((field, index) => {
      const isRequired = field.obrigatorio;
      const isNumberField = field.tipo === TipoCampoRelatorioEnum.Numero;
      const value = field.valor;

      if (isRequired) {
        if (!value || value.length === 0) {
          const msg = `${field.nome} é obrigatório`;
          setError(`filtros.${index}`, {
            type: 'required',
            message: msg
          });
          fieldErros.push(msg);
        } else {
          clearErrors(`filtros.${index}`);
        }
      }

      if (isNumberField && (value?.length ?? 0) > 0 && isNaN(Number(value))) {
        const msg = `${field.nome} deve ser um número`;
        setError(`filtros.${index}`, {
          type: 'type',
          message: msg
        });
        fieldErros.push(msg);
      }
    });

    if (fieldErros.length > 0) {
      return;
    }

    const formattedData: FormRelatoriosAvancadosPost = {
      filtros: props.formFiltros.map((field) => {
        const campo = {
          tipo: field.tipo,
          campo: field.campo,
          valor: data.filtros.find((f) => f.campo === field.campo)?.valor ?? '',
          condicao: Number(data.filtros.find((f) => f.campo === field.campo)?.comparacao ?? 0)
        }
        return campo
    }).filter(filtro => filtro.valor.length >= 1),
      dataInicial: data.dataInicial,
      dataFinal: data.dataFinal,
      ordenador: data.ordenador,
      relatorio: data.relatorio
    };

    props.onSubmit(formattedData);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {},
    fillForm: (model: RelatoriosAvancadosModel) => {
      reset({
        filtros: props?.formFiltros.map(f => {
          return {
            ...f,
            comparacao: (f.condicao?.[0] ?? 0) as EnumCondicoes
          }
        }) ?? [],
        relatorio: model.codigo,
        dataFinal: toDateString(new Date(), 'yyyy-MM-DD')
      });
    }
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  name="dataInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DATA"
                      autoComplete="new-password"
                      fullWidth
                      disabled={loading}
                      label="Data inicial"
                      variant="outlined"
                      error={Boolean(
                        errors.dataInicial && errors.dataInicial.message
                      )}
                      helperText={
                        errors.dataInicial
                          ? errors.dataInicial?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dataFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DATA"
                      autoComplete="new-password"
                      fullWidth
                      disabled={loading}
                      label="Data Final"
                      variant="outlined"
                      error={Boolean(
                        errors.dataFinal && errors.dataFinal.message
                      )}
                      helperText={
                        errors.dataFinal ? errors.dataFinal?.message : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>

              {fieldsSerializados.map((fieldItem, index) => (
                <Grid
                  item
                  xs={(fieldItem?.espaco ?? 12) as any}
                  md={(fieldItem?.espaco ?? 12) as any}
                  key={fieldItem.nome}
                >
                  <Controller
                    name={`filtros.${index}.valor`}
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        label={fieldItem.nome}
                        fullWidth={true}
                        variant="outlined"
                        placeholder={fieldItem.descricao}
                        endAdornmentButton={
                          fieldItem.condicao.length > 0 ? (
                            <select
                              value={getValues(`filtros.${index}.comparacao`)}
                              onChange={(e) =>
                                handleChangeCondicao(
                                  e,
                                  `filtros.${index}.comparacao`
                                )
                              }
                              className={utilClasses.selectEndAdorment}
                            >
                              {condicoesOptions
                                .filter((c) =>
                                  fieldItem.condicao.includes(c.value)
                                )
                                .map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          ) : undefined
                        }
                        error={Boolean(errors.filtros?.[index])}
                        helperText={
                          errors.filtros?.[index]
                            ? errors.filtros?.[index]?.message
                            : undefined
                        }
                        {...field}
                        onChange={(item) => {
                          setValue(`filtros.${index}.valor`, item.target.value);
                          clearErrors(`filtros.${index}`);
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}

              {props.ordenadores.length > 0 && (
                <Grid item xs={12}>
                  <Controller
                    name="ordenador"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={loading}
                        conteudo={props.ordenadores.map((valor, index) => {
                          return new KeyValueModel(index, valor.conteudo);
                        })}
                        {...field}
                        fullWidth
                        error={Boolean(
                          errors.ordenador && errors.ordenador.message
                        )}
                        helperText={
                          errors.ordenador
                            ? errors.ordenador?.message
                            : undefined
                        }
                        label="Ordernar por"
                        onChange={(ev) => {
                          setValue('ordenador', ev.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
