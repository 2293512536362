import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerError: {
        height: '100%',
    },
    imageError: {
        textAlign: 'center',
        marginBottom: 30,
        "& svg": {
            width: 150,
            height: 150,

        },
    },
    btnTentarNovamente: {
        marginTop: 30,
    },
    containerLoading: {
        height: '100%',
        flexGrow: 1,
        "& .loader-container": {
            width: 150,
            height: 150,
        }
    }
}));