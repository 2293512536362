import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, merge } from "lodash";
import LRU from "lru-cache";
import { VariaveisAmbiente } from "../../../config";
import {
  GestaoStorageKeys,
  useGestaoStorage,
} from "../../../services/app/hooks/gestao-storage";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { useHistory } from 'react-router';
import { RetornoApiModel } from "../../../model";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { consoleDev } from "utils/console-dev";
import { useSessaoAtual } from "services/app";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
  ignorarConnectionClose?: boolean;
  token?: string;
};

const defaultTimeout = 30 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.apiUrl}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O tempo da requisição foi excedido. Verifique sua conexão com internet e tente novamente.",
  }),

  cache: new LRU({ max: 10 }),
});


export function useApiBase(props?: ApiBaseProps) {

  const { refreshUser, deslogar, converterToken, validaUsuarioConectado } = useSessaoAtual();
  const history = useHistory();
  const { callEvent } = useEventTools()

  const { getRegistro } = useGestaoStorage();
  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel> => {

    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;
    let semConexao = false
    let isTimeout = false;

    resultado = undefined;
    erro = undefined;
    let mensagemErro = `Você não possui acesso à internet.`

    //SE PASSAR O TOKEN DIRETO PELA FUNÇÃO PRIORIZO ELE, SE N PEGO DO STORAGE
    const tokenStorage = cfg?.token ? cfg?.token : getRegistro(GestaoStorageKeys.Token, false);
    const userToken = converterToken(tokenStorage);

    const noToken = cfg?.enviarTokenUsuario && isEmpty(tokenStorage)
    if ((!navigator.onLine && cfg?.ignorarConnectionClose !== true) || noToken) {
      if (noToken) {
        mensagemErro = ''
      } else {
        callEvent(AppEventEnum.SemConexao, { ativo: true, message: 'Sem internet, verifique a sua conexão.' });
      }

      deslogar('');
      history.push('/login/expirou');

      return {
        resultado: {} as AxiosResponse<any>,
        erro: new Error(
          mensagemErro
        ),
        tipoRetorno: EnumRetornoApiBase.Local,
        statusCode: 0,
        isTimeout: isTimeout,
      }
    } else {
      callEvent(AppEventEnum.SemConexao, { ativo: false, message: '' });
    }


    try {
      const tokenEnvio = cfg?.enviarTokenUsuario
        ? `Bearer ${tokenStorage}`
        : null;

      resultado = await invocarAxios(
        {
          ...cfg,
          headers: {
            ...(!cfg?.enviarTokenUsuario ? null : { Authorization: tokenEnvio }),
            'FrontUrl': VariaveisAmbiente.frontUrl,
            ...cfg?.headers,
          },
        },
        options
      );
      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

    } catch (e: Error | any) {
      if (VariaveisAmbiente.isDev) consoleDev(e);
      erro = e;
      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (Object.keys(e.response?.data?.errors).length > 1) {
            let errosArr: string[] = [];

            Object.keys(e.response?.data?.errors)?.forEach((key) => {
              e.response?.data?.errors[key]?.forEach((erro: string) => {
                errosArr.push(`${key}: ${erro}. `);
              });
            });

            erro = new Error(errosArr.join('').trim());
          } else {
            const firstError = Object.entries(e.response?.data?.errors)
            erro = new Error(firstError.length > 0 ? firstError[0].join(' ') : e.response?.data?.title);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }

      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          isTimeout = true;
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {

            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (msg.toLowerCase().indexOf("network error") > -1 && cfg?.ignorarConnectionClose !== true) {
            semConexao = true
            let retorno = () => {
              return `Não foi possível conectar ao servidor. Verifique sua conexão com internet e tente novamente.` +
                (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            }
            erro = new Error(retorno());
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 401) {
            if (cfg?.enviarTokenUsuario) {
              // TRATAMENTO DO REFRESHTOKEN AUTOMÁTICO
              if (validaUsuarioConectado() && tentarNovamente) {
                const retRefresh = await refreshUser(userToken!.originalToken);
                if (retRefresh) {
                  return await invocarApi(cfg, options);
                }
                else {
                  erro = new Error(
                    `Ocorreu um problema ao executar novamente. Realize o login novamente.`
                  );
                  deslogar(userToken?.usuarioId || '');
                  history.push('/login/expirou');
                }
              } else {
                erro = new Error(
                  `Sua sessão expirou. Realize o login novamente para continuar. (401)`
                );
                deslogar(userToken?.usuarioId || '');
                history.push('/login/expirou');
              }

            }
          }
          if (retStatus === 402) {
            resultado = e.response
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }

      resultado = e.response
    }

    if (semConexao && cfg?.ignorarConnectionClose !== true) {
      callEvent(AppEventEnum.SemConexao, { ativo: true, message: 'Sem internet, verifique a sua conexão.' });
    } else {
      callEvent(AppEventEnum.SemConexao, { ativo: false, message: '' });
    }


    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
      isTimeout: isTimeout,
    };
  },
    [getRegistro, converterToken, invocarAxios, validaUsuarioConectado, refreshUser, deslogar, history, callEvent]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
