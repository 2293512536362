import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    background: theme.palette.common.white,
    padding: '8px'
  },
  botoesPagamento: {

  },
  moreArea: {
    marginTop: 8,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  labelMoreActions: {
    fontSize: 10,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    color: theme.palette.secondary.main,
    textAlign: 'center'
  },
  paymentArea: {
    display: 'flex',
    marginLeft: theme.spacing(1) * -1,
    marginRight: theme.spacing(1) * -1,
    flexDirection: 'row'
  }
}));
