import { RelatoriosAvancadosHeader } from "./components/relatorios-avancados-header/relatorios-avancados-header";
import { RelatoriosAvancadosList } from "./components/relatorios-avancados-list/relatorios-avancados-list";
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { Grid } from 'views/design-system';

export const RelatoriosAvancadosPage = () => {
  // AUX
  const classes = useDefaultCadastroStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <RelatoriosAvancadosHeader/>
      </Grid>
      <Grid className={classes.list}>
        <RelatoriosAvancadosList />
      </Grid>
    </Grid>
  );
};

export default RelatoriosAvancadosPage;
