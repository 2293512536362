import { Grid, Tooltip, Typography } from 'views/design-system';
import React, { useMemo } from 'react';
import { useStyles } from './card-promocao-modelo-styles';
import { CardPromocaoModeloProps } from './card-promocao-modelo-props';
import classNames from 'classnames';
import { DefaultCard } from '../components';
import { EnumPromocaoTipoRegra } from 'model/enums/enum-promocao-tipo-regra';
import { Badge } from 'views/design-system';
import { formatDecimalInteger } from 'utils/to-decimal';
import { PromocaoDePorIcon } from 'views/components/icons/promocao-depor-icon';
import { PromocaoAtacadoIcon } from 'views/components/icons/promocao-atacado-icon';
import { PromocaoProgressivoIcon } from 'views/components/icons/promocao-progressivo-icon';
import { PromocaoLeveXPagueY } from 'views/components/icons/promocao-levex-paguey-icon';

export const CardPromocaoModelo = ({
  model,
  onClick,
  onCheck,
  selected
}: CardPromocaoModeloProps) => {
  const classes = useStyles();

  const cardIcon = useMemo(() => {
    switch (model.tipo) {
      case EnumPromocaoTipoRegra.DePor:
        return <PromocaoDePorIcon tipo="GERAL" />;
      case EnumPromocaoTipoRegra.AtacadoValor:
        return <PromocaoAtacadoIcon tipo="GERAL" />;
      case EnumPromocaoTipoRegra.AtacadoPerc:
        return <PromocaoAtacadoIcon tipo="GERAL" />;
      case EnumPromocaoTipoRegra.LeveMaisPagueMenosQtde:
        return <PromocaoLeveXPagueY tipo="GERAL" />;
      case EnumPromocaoTipoRegra.ProgressivoPerc:
        return <PromocaoProgressivoIcon tipo="GERAL" />;
    }
  }, [model.tipo]);

  const embreveClass = model.status.toUpperCase() !== 'ATIVO' ? classes.emBreve : undefined;

  return (
    <DefaultCard hasTagStatus={false} onClick={() => {
      if (!embreveClass)
        onClick(model)
    }} className={classNames(classes.cardContainer, embreveClass)}>
      {(model.qtdRegras || 0) > 0 && (
        <Badge
          className={classes.badgeQtde}
          badgeContent={(model.qtdRegras || 0) > 99 ? '99+' : formatDecimalInteger(model.qtdRegras || 0, 3)}
          color="error"
        >
        </Badge>
      )}
      <Grid my={2} className={classes.cardContent} flex alignItems='center' justifyContent='center' flexDirection='column' >
        <Tooltip arrow title={`${model.nome}`}>
          <Grid className={classes.cardIcon}>
            {cardIcon}
          </Grid>
        </Tooltip>
        <Grid mt={1} flexDirection='column' className={classes.celulaGrid}>
          <Typography
            className={classNames(classes.text)}
            variant="caption"
          >
            {model.nome}
          </Typography>
          <Typography
            className={classNames(classes.info)}
            variant="caption"
            align='center'
          >
            {model.detalhe}
          </Typography>
          {embreveClass && (
            <Typography
              className={classNames(classes.badge)}
              variant="caption"
              align='center'
            >
              {model.status}
            </Typography>
          )}
        </Grid>
      </Grid>
    </DefaultCard>
  );
};

export default React.memo(CardPromocaoModelo);
