import { Grid } from "views/design-system";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { ClienteDeliveryHeader } from "./cliente-delivery-header/cliente-delivery-header";
import { ClienteDeliveryCadastro } from "./cliente-delivery-cadastro/cliente-delivery-cadastro";

const MovClienteDeliveryPage = () => {
  const classes = useDefaultCadastroStyles();


  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ClienteDeliveryHeader title={"Identificar Cliente"} />
      </Grid>
      <Grid className={classes.list}>
        <ClienteDeliveryCadastro />
      </Grid>
    </Grid>
  );
};

export default MovClienteDeliveryPage;
