import { Button, Grid } from "@material-ui/core";
import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect, useState } from "react";
import { ModalHeader } from "views/components/modals/components/modal-header/modal-header";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header/button-modal-header";
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { SalvarEditarIcon, SalvarNovoIcon, VoltarIcon } from "views/components/icons";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { ImpressoraModel } from "model/api/gestao/impressora/impressora-model";
import { usePostEquipamento } from "data/api/gestao/equipamento";
import { FormImpressoraCadastro } from "views/components/form/impressora/form-impressora-cadastro/form-impressora-cadastro";
import { EnumTour } from "model/enums/enum-tour";
import { useTourSaurus } from "services/app/hooks/tour-saurus";

export const ImpressoraCadastro = () => {
  const { postEquipamento, carregando: carregandoPost } = usePostEquipamento();
  const { fecharCadastroEquipamentos, abrirCadastroEquipamentos } = useCadastros();
  const cadNcmFormRef = useRef<DefaultFormRefs<ImpressoraModel>>(null);
  const [formInSubmit, setFormInSubmit] = useState(false);
  const redirect = useRef<boolean>(true);
  const classes = useModalStyles();
  const { getEmpresaSelecionada } = useSessaoAtual()
  const {callTour } = useTourSaurus()
  const carregando = carregandoPost || formInSubmit;
  useEffect(() => {
    cadNcmFormRef.current?.fillForm(new ImpressoraModel());
  }, []);

  const history = useHistory();
  const pathName = history.location.pathname;

  const { showToast } = useToastSaurus();

  const saveNewImpressora = useCallback(
    async (model: ImpressoraModel) => {
      try {
        const ret = await postEquipamento(model, getEmpresaSelecionada()?.Id ?? '');
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          abrirCadastroEquipamentos(ret.resultado?.data.id, pathName, true);
        } else {
          cadNcmFormRef.current?.resetForm();
        }
        if (!redirect.current) {
          showToast("success", "Impressora adicionada com sucesso!", );
        }
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [postEquipamento, getEmpresaSelecionada, abrirCadastroEquipamentos, pathName, showToast]
  );
  const handleSubmit = useCallback(
    async (model: ImpressoraModel) => {

      return saveNewImpressora(model);
    },
    [saveNewImpressora]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadNcmFormRef.current?.submitForm();
  }, []);
  const onCloseClick = useCallback(() => {
    fecharCadastroEquipamentos();
  }, [fecharCadastroEquipamentos]);

  useEffect(()=>{
    if(pathName === '/impressoras/adicionar')
     setTimeout(()=>{callTour(EnumTour.IMPRESSORASFORM)},600) 
  },[callTour, pathName])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Cadastro de Impressora"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={classNames(classes.contentForms, carregando ? classes.contentFormsLoading : undefined)}>
          <FormImpressoraCadastro
            ref={cadNcmFormRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            setCarregandoExterno={setFormInSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid id="tour-impressoras-salvar" item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid id="tour-impressoras-salvar-e-editar" item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
