import { Grid } from 'views/design-system';
import { useCallback } from 'react';
import { useCadastros } from 'services/app';
import { ButtonFab } from 'views/components/controles';
import { DocumentoAdicionarIcon } from 'views/components/icons/documento-adicionar-icon';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { DocumentoFiscalHeader } from './components/documento-fiscal-header/documento-fiscal-header';
import { DocumentoFiscalList } from './components/documento-fiscal-list/documento-fiscal-list';

const DocumentoFiscalPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirCadastroRetirada } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroRetirada('', '', true);
  }, [abrirCadastroRetirada]);

  return (
    <Grid className={classes.root} style={{ minWidth: 0 }}>
      <Grid className={classes.header}>
        <DocumentoFiscalHeader />
      </Grid>
      <Grid className={classes.list}>
        <DocumentoFiscalList />
      </Grid>
      <ButtonFab
        tooltip="Adicionar Documento XML"
        icon={<DocumentoAdicionarIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default DocumentoFiscalPage;
